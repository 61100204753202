import {
  SET_SC_DASHBOARD_LOADING,
  SET_SC_DASHBOARD_DETAILS_LOADING,
  SET_SC_DASHBOARD,
  SET_USER_DETAILS,
  SET_NO_SHOW,
  SET_EARLY_CLOCKOUT,
  SET_LATE_CLOCKIN,
  SET_MISSED_BREAKS,
  SET_UNSCHEDULED,
  SET_AUTO_CLOCKOUT,
  SET_NO_SHOW_MORE_DATA,
  SET_EARLY_CLOCKOUT_MORE_DATA,
  SET_TIME_CARDS,
  SET_SC_DASHBOARD_ROLE,
  SET_SC_DASHBOARD_USER,
  SET_ROLE_NAME_DETAILS,
  SET_ROLE_MISSED_BREAKS_DETAILS,
  SET_ROLE_NO_SHOW_DETAILS,
  SET_ROLE_LATE_CLOCKIN_DETAILS,
  SET_ROLE_EARLY_CLOCKOUT_DETAILS,
  SET_ROLE_UNSCHEDULED_SHIFTS_DETAILS,
  SET_ROLE_AUTO_CLOCKOUT_DETAILS,
  SET_ROLE_TOTAL_SCHEDULED_HOURS_DETAILS,
  SET_ROLE_TOTAL_PAID_HOURS_DETAILS,
  SET_ROLE_TOTAL_ACTUAL_HOURS_DETAILS,
  SET_ROLE_TOTAL_PENDING_TIMECARD_DETAILS,
  SET_ROLE_TIME_OFF_DETAILS,
  SET_ROLE_TOTAL_TIMECARD_DETAILS,
  SET_USER_TOTAL_TIMECARD_DETAILS,
  SET_USER_TOTAL_MISSED_BREAKS_DETAILS,
  SET_USER_TOTAL_NO_SHOW_DETAILS,
  SET_USER_TOTAL_LATE_CLOCKIN_DETAILS,
  SET_USER_TOTAL_EARLY_CLOCKOUT_DETAILS,
  SET_USER_TOTAL_AUTO_CLOCKOUT_DETAILS,
  SET_USER_UNSCHEDULED_SHIFT_DETAILS,
  SET_USER_SCHEDULED_HOURS_DETAILS,
  SET_USER_TOTAL_PAID_HOURS_DETAILS,
  SET_USER_TOTAL_ACTUAL_HOURS_DETAILS,
  SET_USER_TOTAL_PENDING_TIMECARD_DETAILS,
  SET_USER_TOTAL_TIMEOFF_REQUEST_DETAILS
} from "./constants";

const initialState = {
  isLoading: true,
  isDetailLoading: true,
  dashboardData: [],
  dashboardRoleData:[],
  dashboardUserData:[],
  userDetails: [],
  noShowDetails: [],
  timeCardsDetails: [],
  autoClockoutDetails: [],
  earlyClockoutDetails: [],
  lateClockoutDetails: [],
  missedBreakDetails: [],
  unscheduledDetails: [],
  rolemissedBreaksDetailss:[],
  roleNoShowDetailss:[],
  roleearlyclockoutDetailss:[],
  roleAutoclockoutDetail:[],
  roleTotalScheduledHoursDetail:[],
  roleTotalPaidHoursDetail:[],
  roleTotalActualHoursDetail:[],
  roleTotalPendingTimecardDetail:[],
  roleTimeOffDetail:[],
  roleTotalTimecardDetail:[],
  userTotalTimecardDetail:[],
  userTotalMissedBreaksDetail:[],
  userTotalNoShowDetail:[],
  userLateClockinDetail:[],
  userEarlyCLOCKOUTDetail:[],
  userAutoCLOCKOUTDetail:[],
  userUnscheuledShifts:[],
  userScheduledHoursShifts:[],
  userTotalPaidHoursShifts:[],
  userTotalActualHoursShifts:[],
  userTotalPendingTimecard:[],
  userTotalTimoffReq:[]
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SC_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SC_DASHBOARD_DETAILS_LOADING:
      return {
        ...state,
        isDetailLoading: payload
      };
    case SET_SC_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        dashboardData: payload.locationData,
        custom_startDate:payload.custom_startDate,
        custom_endDate:payload.custom_endDate,
        current_startDate: payload.current_startDate,
        current_endDate: payload.current_endDate,
        previous_startDate: payload.previous_startDate,
        previous_endDate: payload.previous_endDate,
        yesterdayDate: payload.yesterdayDate,
        month_sartDate: payload.month_sartDate,
        month_endDate: payload.month_endDate,
        lastMonth_startDate: payload.lastMonth_startDate,
        lastMonth_endDate: payload.lastMonth_endDate,
        week_startDate: payload.week_startDate,
        week_endDate: payload.week_endtDate,
        lastWeek_startDate: payload.lastWeek_startDate,
        lastWeek_endDate: payload.lastWeek_endtDate,
      };
      case SET_SC_DASHBOARD_ROLE :
        return {
          ...state,
          isLoading:false,
          dashboardRoleData:payload.Role_data
        }

    case SET_SC_DASHBOARD_USER:
          return {
            ...state,
            isLoading:false,
            dashboardUserData:payload.user_Data
          }
    case SET_USER_DETAILS:
      return {
        ...state,
        isDetailLoading: false,
        userDetails: payload
      }
    case SET_NO_SHOW:
      return {
        ...state,
        isDetailLoading: false,
        noShowDetails: payload
      }
    case SET_EARLY_CLOCKOUT_MORE_DATA : 
    return{
      ...state,
      earlyClockoutDetailsUserDetails:payload.detailData,
      isDetailLoading: false,
      noShowuserName:payload.user_name
    }
    case SET_NO_SHOW_MORE_DATA: 
    return{
      ...state,
      noShowDetailsUserDetails:payload.detailData,
      isDetailLoading: false,
      noShowuserName:payload.user_name
    }
    case SET_TIME_CARDS:
      return {
        ...state,
        isDetailLoading: false,
        timeCardsDetails: payload
      }
    case SET_EARLY_CLOCKOUT:
      return {
        ...state,
        isDetailLoading: false,
        earlyClockoutDetails: payload
      }
    case SET_LATE_CLOCKIN:
      return {
        ...state,
        isDetailLoading: false,
        lateClockinDetails: payload
      }
    case SET_MISSED_BREAKS:
      return {
        ...state,
        isDetailLoading: false,
        missedBreakDetails: payload.detailData
      }
    case SET_UNSCHEDULED:
      return {
        ...state,
        isDetailLoading: false,
        unscheduledDetails: payload.detailData
      }
    case SET_AUTO_CLOCKOUT:
      return {
        ...state,
        isDetailLoading: false,
        autoClockoutDetails: payload
      }
      case SET_ROLE_NAME_DETAILS :
        return{
          ...state,
          isDetailLoading: false,
          rolenameDetailss: payload.usersData
        }
        case SET_ROLE_MISSED_BREAKS_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            rolemissedBreaksDetailss: payload.detailData 

        }
        case SET_ROLE_NO_SHOW_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            roleNoShowDetailss: payload.detailData 

        }
        case SET_ROLE_LATE_CLOCKIN_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            rolelateClockinDetailss: payload.detailData 

        }
        case SET_ROLE_EARLY_CLOCKOUT_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            roleearlyclockoutDetailss: payload.detailData 

        }
        case SET_ROLE_UNSCHEDULED_SHIFTS_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            roleunscheduledShiftDetailss: payload.detailData 

        }
        case SET_ROLE_AUTO_CLOCKOUT_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            roleAutoclockoutDetail: payload.detailData 

        }
        case SET_ROLE_TOTAL_SCHEDULED_HOURS_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            roleTotalScheduledHoursDetail: payload.detailData 

        } 
        case SET_ROLE_TOTAL_PAID_HOURS_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            roleTotalPaidHoursDetail: payload.detailData 

        } 
        case SET_ROLE_TOTAL_ACTUAL_HOURS_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            roleTotalActualHoursDetail: payload.detailData 

        } 
        case SET_ROLE_TOTAL_PENDING_TIMECARD_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            roleTotalPendingTimecardDetail: payload.detailData 

        } 
        case SET_ROLE_TIME_OFF_DETAILS :
          return{
            ...state,
            isDetailLoading: false,
            roleTimeOffDetail: payload.detailData 

        } 
        case SET_ROLE_TOTAL_TIMECARD_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            roleTotalTimecardDetail: payload.detailData 

        } 
        case SET_USER_TOTAL_TIMECARD_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalTimecardDetail: payload.detailData 

        } 
        case SET_USER_TOTAL_MISSED_BREAKS_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalMissedBreaksDetail: payload.detailData 

        } 
        case SET_USER_TOTAL_NO_SHOW_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalNoShowDetail: payload.detailData 
        } 
        case SET_USER_TOTAL_LATE_CLOCKIN_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userLateClockinDetail: payload.detailData 
        } 
        case SET_USER_TOTAL_EARLY_CLOCKOUT_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userEarlyCLOCKOUTDetail: payload.detailData 
        } 
        case SET_USER_TOTAL_AUTO_CLOCKOUT_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userAutoCLOCKOUTDetail: payload.detailData 
        } 
        case SET_USER_UNSCHEDULED_SHIFT_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userUnscheuledShifts: payload.detailData 
        } 
        case SET_USER_SCHEDULED_HOURS_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userScheduledHoursShifts: payload.detailData 
        } 
        case SET_USER_TOTAL_PAID_HOURS_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalPaidHoursShifts: payload.detailData 
        }      
        case SET_USER_TOTAL_ACTUAL_HOURS_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalActualHoursShifts:payload.detailData 
        } 
        case SET_USER_TOTAL_PENDING_TIMECARD_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalPendingTimecard:payload.detailData 
        } 
        case SET_USER_TOTAL_TIMEOFF_REQUEST_DETAILS:
          return{
            ...state,
            isDetailLoading: false,
            userTotalTimoffReq:payload.detailData 
        } 
    default:
      return state;
  }
};

export default Reducer;