import { useCallback } from "react";
import { wave } from "../../../../assets/images/mobileView/icons";
import { Link } from "react-router-dom";
import { mobileThemeNews } from "../../constant";
import { MdOutlineDashboard } from "react-icons/md";
import { BsBellFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";

// icons
import { TbSortDescending } from "react-icons/tb";

// components
import { Search } from "../../utilities";
// import { ProgressBar } from "../../../../utilities/v5/common";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { history } from "../../../../history";
import "./mobileViewNewsTopNav.scss";
import { useSearch } from "../../context/MobileNewsSearchContext";

const NewsTopNav = (props) => {
  const location = useLocation();
  const { searchQuery, updateSearchQuery, newsTitle } = useSearch();

  const handleSearchChange = useCallback(
    (e) => {
      updateSearchQuery(e.target.value);
    },
    [updateSearchQuery]
  );

  return location.pathname === "/newsandupdate/reports" ? (
    <div className="mvNewsTopNavWithBackRoot">
      {/* <div onClick={() => history.push("/elearning/feedbacks")}>
        <MdOutlineArrowBackIosNew
          style={{ fontSize: "17px", color: "#1B3F8F" }}
        />
      </div> */}
      <div className="Text">Report</div>
    </div>
  ) : location.pathname === "/newsandupdate/news/add" ? (
    <div className="mvNewsTopNavWithBackRoot">
      <div onClick={() => history.push("/newsandupdate/news")}>
        <MdOutlineArrowBackIosNew className="backArrowIcon" />
      </div>
      <div className="Text">Create News</div>
    </div>
  ) : /^\/newsandupdate\/news\/preview\/\w+$/.test(location.pathname) ? (
    <div className="mvNewsTopNavWithBackRoot">
      <div onClick={() => history.push("/newsandupdate/news")}>
        <MdOutlineArrowBackIosNew className="backArrowIcon" />
      </div>
      <div className="Text">{newsTitle && newsTitle}</div>
    </div>
  ) : /^\/newsandupdate\/mynews\/preview\/\w+$/.test(location.pathname) ? (
    <div className="mvNewsTopNavWithBackRoot">
      <div onClick={() => history.push("/newsandupdate/mynews")}>
        <MdOutlineArrowBackIosNew className="backArrowIcon" />
      </div>
      <div className="Text">{location.state.data}</div>
    </div>
  ) : (
    <div
      style={{
        height: location.pathname === "/news/grading" ? "56px" : "120px",
        background: mobileThemeNews,
      }}
      className="mvNewsTopNavDefaultRoot"
    >
      <div className="contentContainer">
        <div className="greetingsWrapper">
          <img alt="waving hand" src={wave} />
          <div>Hi {props.userName}</div>
        </div>

        <div className="iconWrapper">
          <Link to="/dashboard">
            <MdOutlineDashboard
              className={"newsMobileTopNavDashboardIcon"}
            />
          </Link>
        </div>
      </div>

      <div className="searchAndIconContainer">
        <Search
          placeholder={"Search news..."}
          handleSearch={handleSearchChange}
          value={searchQuery}
        />
        {/* <div>
          <TbSortDescending className="icon" />
        </div> */}
      </div>
    </div>
  );
};

export default NewsTopNav;
