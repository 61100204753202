export const SET_NEWSUPDATE_LOADING = "SET_NEWUPDATE_LOADING";
export const SET_NEWSUPDATE = "SET_NEWSUPDATE";
export const SET_ASSIGN_USERS = "SET_ASSIGN_USERS";
export const SET_ASSIGN_USERS_LOCATION = "SET_ASSIGN_USERS_LOCATION";
export const SET_ASSIGN_ROLES_AND_USERS = "SET_ASSIGN_ROLES_AND_USERS";
export const ADD_NEWS = "ADD_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const ROLE_ID_DATA = "ROLE_ID_DATA";
export const LOCATION_ID_DATA = "LOCATION_ID_DATA";
export const SET_MY_NEWS_DATA = "SET_MY_NEWS_DATA";
export const SET_MY_NEWS_DATA_LOADING = "SET_MY_NEWS_DATA_LOADING";
