import {
  pptIcon,
  txtIcon,
  videoFileIcon,
} from "../../../../assets/images/mobileView/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const styles = {
  LibraryCardLink: {
    color: "#212121",
    textDecoration: "none !important",
    width: "100%",
    maxWidth: "350px",
  },
  LibraryCardArticle: {
    width: "100%",
    height: "109px",
    borderRadius: "20px",
    boxShadow: "0px -5px 15px 5px rgba(0,0,0,0.08)",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    alignItems: "center",
  },
  LibraryCardContentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
  },
  LibraryCardImageContainer: {
    width: "80px",
    height: "80px",
    borderRadius: "5px",
    backgroundColor: "rgba(116, 85, 246, 0.2)",
  },
  LibraryCardImage: {
    width: "80px",
    height: "80px",
    borderRadius: "5px",
    objectFit: "contain",
  },
  LibraryCardTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    height: "80px",
  },
  LibraryCardTextWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "0px",
    margin: "0px",
  },
  LibraryCardLesson: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "15px",
    color: "#212121",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  LibraryCardCategory: {
    fontSize: "12px",
    color: "rgba(33, 33, 33, 0.7)",
  },
  LibraryCardDescription: {
    fontSize: "12px",
    color: "rgba(33, 33, 33, 0.7)",
  },
  LibraryCardResult: {
    fontSize: "8px",
    color: "rgba(33, 33, 33, 0.7)",
  },
  LibraryCardIconsContainer: {
    height: "100%",
    display: "flex",
    alignItems: "start",
  },
  LibraryCardIconImagesWrapper: {
    display: "flex",
    gap: "2px",
    alignItems: "start",
    justifyContent: "start",
  },
  LibraryCardIconImage: {
    width: "16px",
    aspectRatio: "auto",
  },
};

const LibraryCard = ({ lessonData, categoryName, classes }) => {
  const ecidLink =
    typeof lessonData.ecid === "object" ? lessonData.ecid._id : lessonData.ecid;

  let minute = moment(new Date()).diff(lessonData.updatedAt, "minute");
  let hours = moment(new Date()).diff(lessonData.updatedAt, "hours");
  let newss = minute - hours * 60;
  let days = moment(new Date()).diff(lessonData.updatedAt, "days");
  let newssss = hours - days * 24;
  let months = moment(new Date()).diff(lessonData.updatedAt, "months");
  let newone = days - months * 30;

  let Result = months > 0 ? months + " months " : "";
  // Only append "Days" if days is greater than 0
  if (days > 0) {
    Result += newone + " Days ";
  }
  // Only append "Hours" if hours is greater than 0 and less than 24
  if (hours > 0 && hours < 24) {
    Result += newssss + " Hours ";
  }
  // Only append "min" if minutes is greater than 0
  if (minute > 0) {
    Result += newss + " min ";
  }

  // Trim the trailing space if necessary
  Result = Result.trim();

  return (
    <Link
      to={`/elearning/myhelpdesk/slider/${ecidLink}/${lessonData._id}`}
      className={classes.LibraryCardLink}
    >
      <Paper className={classes.LibraryCardArticle}>
        <Box className={classes.LibraryCardContentContainer}>
          <Box className={classes.LibraryCardImageContainer}>
            <img
              src={lessonData?.lesson_image}
              alt="lesson"
              className={classes.LibraryCardImage}
            />
          </Box>
          <Box className={classes.LibraryCardTextContainer}>
            <Box className={classes.LibraryCardTextWrapper}>
              <Typography className={classes.LibraryCardLesson}>
                {lessonData?.lesson_name.slice(0, 20)}
                {lessonData?.lesson_name.length > 20 ? "..." : ""}
              </Typography>
              <Typography className={classes.LibraryCardCategory}>
                {categoryName}
              </Typography>
              <Typography className={classes.LibraryCardDescription}>
                {lessonData?.lesson_description.slice(0, 20)}
                {lessonData?.lesson_description.length > 20 ? "..." : ""}
              </Typography>
            </Box>

            <Typography className={classes.LibraryCardResult}>
              {Result}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.LibraryCardIconsContainer}>
          <Box className={classes.LibraryCardIconImagesWrapper}>
            {lessonData.lesson_slides.find(
              (slide) => slide.lesson_type === 3
            ) && (
              <img
                alt="ppt doc icon"
                src={pptIcon}
                className={classes.LibraryCardIconImage}
              />
            )}
            {lessonData.lesson_slides.find(
              (slide) => slide.lesson_type === 1
            ) && (
              <img
                alt="txt doc icon"
                src={txtIcon}
                className={classes.LibraryCardIconImage}
              />
            )}
            {lessonData.lesson_slides.find(
              (slide) => slide.lesson_type === 2
            ) && (
              <img
                alt="video file icon"
                src={videoFileIcon}
                className={classes.LibraryCardIconImage}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Link>
  );
};

export default withStyles(styles)(LibraryCard);
