//TextField
import React, { Component } from "react";
import SearchBar from "material-ui-search-bar";
import { withStyles } from "@material-ui/core/styles";
import {
        IconButton
}
        from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Close';
const styles = (theme) => ({
        SearchBar: {
                height: '40px',
                border:'1px solid #0000001a',
                borderRadius:'100px',
                boxShadow:'none',
        },
        icon: {
                color: 'red'
        }
});

class MySearchBar extends Component {
        render() {
                const { classes } = this.props;
                return (
                        <SearchBar
                                placeholder={this.props.placeholder}
                                className={classes.SearchBar}
                                // searchIcon={
                                //         <IconButton size="small"
                                //         >
                                //                 <SearchIcon />
                                //         </IconButton>
                                // }
                                closeIcon={
                                        <IconButton size="small"
                                        >
                                                <ClearIcon
                                                        onClick={this.props.clearSearch}
                                                />
                                        </IconButton>
                                }
                                value={this.props.value}
                                onChange={(value) => { this.props.handleSearch(value) }}
                        />
                )
        }
}
export default withStyles(styles)(MySearchBar);