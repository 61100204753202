import { Box, Typography, withStyles } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { MdPlayCircleOutline } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import {
  pptIcon,
  txtIcon,
  videoFileIcon,
} from "../../../../../assets/images/mobileView/icons";

const styles = (theme) => ({
  tabContainer: {
    width: "90.75px",
    height: "65px",
    cursor: "pointer",
    textAlign: "center",
    boxShadow: "0px 0px 4px 0px #00000040",
    boxSizing: "border-box",
    visibility: "block",
    borderRadius: "10px",
    position: "relative",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "10px",
    overflow: "hidden",
    zIndex: 10,
    border: "2px solid transparent",
    "&:hover": {
      border: "2px solid #1950DE",
    },
  },
  selectedTab: {
    border: "2px solid #1950DE",
  },
  videoIcon: {
    position: "absolute",
    top: "30%",
    left: "35%",
  },
  iconWrapper: {
    position: "absolute",
    right: "2px",
    bottom: "2px",
    zIndex: "50",
  },
  completedIconWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#07070730",
    borderRadius: "10px",
    zIndex: 15,
    position: "absolute",
    top: "0",
  },
  completedIcon: {
    fontSize: "20px",
    color: "#69CF65",
    zIndex: 60,
    opacity: 1,
  },
  text: {
    padding: "7px",
    overflow: "hidden",
    fontSize: "3px",
    lineHeight: "4px",
    textAlign: "left",
    display: "flex",
    gap: "1px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
  },
  tabLabel: {
    position: "absolute",
    bottom: "-3px",
    left: "-3px",
    background: "#B4B7BD",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    color: "rgba(33, 33, 33, 0.7)",
    padding: "5px 15px",
    borderRadius: "0px 20px 0px 10px",
    fontWeight: "700",
    fontSize: "12px",
    zIndex: 20,
    "&.selected": {
      background: "#1B3F8F",
      color: "white",
    },
  },
  playIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    color: "#666666",
  },
  copyIcon: {
    fontSize: "25px",
    color: "white",
  },
  icon: {
    width: "12px",
    height: "12px",
  },
});

const LessonTab = ({
  id,
  lesson_type,
  selected,
  data,
  index,
  handleSlide,
  completedFlag,
  slideAccess,
  slideAccessFlag,
  classes,
}) => {
  return (
    <Box mt={3}>
      <Box
        onClick={() =>
          slideAccessFlag
            ? slideAccess !== "none"
              ? handleSlide(index)
              : null
            : handleSlide(index)
        }
        className={`${classes.tabContainer} ${
          selected === index ? classes.selectedTab : ""
        }`}
        sx={{ pointerEvents: slideAccessFlag ? slideAccess : "auto" }}
        // style={{
        //   pointerEvents: slideAccessFlag ? slideAccess : "auto",
        // }}
        key={index}
      >
        {lesson_type === 1 ? (
          <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          />
        ) : lesson_type === 2 ? (
          <Box className={classes.videoIcon}>
            <PlayCircleOutlineIcon className={classes.playIcon} />
          </Box>
        ) : (
          <FileCopyIcon className={classes.copyIcon} />
        )}

        {(lesson_type === 1 || lesson_type === 2 || lesson_type === 3) && (
          <Box className={classes.iconWrapper}>
            <img
              alt="txt"
              src={
                lesson_type === 1
                  ? txtIcon
                  : lesson_type === 2
                  ? videoFileIcon
                  : pptIcon
              }
              className={classes.icon}
            />
          </Box>
        )}

        {completedFlag && (
          <Box className={classes.completedIconWrapper}>
            <BsFillCheckCircleFill className={classes.completedIcon} />
          </Box>
        )}

        <Typography
          className={`${classes.tabLabel} ${
            selected === index ? "selected" : ""
          }`}
        >
          {id}
        </Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(LessonTab);
