//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
        TextField
} from '@material-ui/core';

const styles = (theme) => ({
        input: {
                //padding: '5px 14px !important',
                fontSize: '16px',
                fontWeight: 500,
                backgroundColor: '#f4f2f26b !important',
                // backgroundColor:'white',
                borderRadius: 5,
                marginBottom: 0
        },
        labelColor: {
                // color: '#b6b5b5'
                color: 'gray'
        }
});

class MySelectFieldNew extends Component {

        render() {
                const { classes, children } = this.props;
                return (
                        <div>
                                <TextField
                                        select
                                        SelectProps={{ native: true }}
                                        required={this.props.required ? this.props.required : false}
                                        label={this.props.label}
                                        name={this.props.name}
                                        onChange={this.props.onChange}
                                        onFocus={this.props.onFocus}
                                        value={this.props.value}
                                        error={this.props.error}
                                        helperText={this.props.helperText}
                                        placeholder={this.props.placeholder}
                                        disabled={this.props.disabled}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        inputlabelprops={{ classes: { root: classes.labelColor } }}
                                        inputProps={{
                                                className: classes.input
                                        }}
                                >
                                        {children}
                                </TextField>
                        </div >
                )
        }
}

export default withStyles(styles)(MySelectFieldNew);