import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function invalidToken(message) {
    cookies.remove('ovUid');
    cookies.remove('ovToken');
    cookies.remove('ovUserName');
    cookies.remove('ovUserImage');
    cookies.remove('temp_ovUid');
    cookies.remove('temp_ovToken');
    cookies.remove('temp_ovUserName');
    cookies.remove('temp_ovUserImage');
    window.location.href = '/'
}