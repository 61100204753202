//MyQuestionsModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
} from '@material-ui/core';
const styles = (theme) => ({
  dialog: {
    borderRadius: '5px',
  },
  buttonSignup: {
    boxShadow: 'none',
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: 'green',
    borderRadius: 0,
    border: '1px solid green',
    padding: '5px 22px'
  },
  buttonLogin: {
    boxShadow: 'none',
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    border: '1px solid red',
    padding: '5px 22px'
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    float: "left",
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: '2rem'
  },
  dialogcontent: {
    backgroundColor: 'white',
    padding: '20px 20px 40px 20px'
  }
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class MyQuestionsModalMobile extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        fullScreen
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent
          className={classes.dialogcontent}
          dividers>
          {children}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(MyQuestionsModalMobile);