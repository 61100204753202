import {
  SET_MY_COURSE_LOADING,
  SET_DISPLAY_SECTION,
  SET_MY_COURSE,
  SET_MY_COURSE_LESSONS,
  SET_MY_COURSE_LESSONS_DETAILS,
  SET_MY_COURSE_LESSONS_DETAILS_LOADING,
  SET_MY_COURSE_QUESTION_COMPLETE,
  SET_MY_COURSE_INITIAL,
  GET_QUIZ_SUMMARY_LOADING,
  GET_QUIZ_SUMMARY,
  SET_MY_COURSE_LESSONS_COUNT,
  SET_MY_COURSE_LESSON_COUNT_LOADING,
} from "./constants";

const initialState = {
  isLoading: true,
  lessonDetailsLoading: true,
  courseLessonsDataLoading: true,
  displaySection: "course",
  courseLessonsData: [],
  active_lesson_details: {},
  active_course_details: {},
  myCourseData: [],
  quizSummaryLoading: true,
  quizSummaryData: {},
  lessonData: [],
  lessonName: "",
  lessonId: "",
  myCourse_settings: "",
  myHelpdesk_setting: "",
  totalAttempts: 0,
  courseLessonDataCount: {},
  courseLessonCountLoading: true,
  lessonDetailsData: {},
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MY_COURSE_INITIAL:
      return {
        ...state,
        isLoading: true,
        lessonDetailsLoading: true,
        courseLessonsDataLoading: true,
        myCourseData: [],
        courseLessonsData: [],
        lessonSlider: [],
        active_course_details: {},
        displaySection: "course",
      };
    case SET_MY_COURSE_LOADING:
      return {
        ...state,
        courseLessonsDataLoading: true,
        isLoading: payload,
      };
    case SET_MY_COURSE_QUESTION_COMPLETE:
      var temp_data = state.active_lesson_details;
      temp_data.active_question_index =
        parseInt(temp_data.active_question_index) + parseInt(1);
      return {
        ...state,
        active_lesson_details: temp_data,
      };

    case SET_MY_COURSE_LESSONS_DETAILS_LOADING:
      return {
        ...state,
        lessonDetailsLoading: payload,
      };
    case SET_MY_COURSE_LESSONS_DETAILS:
      return {
        ...state,
        lessonDetailsLoading: false,
        lessonSlider: payload.lessonData,
        lessonName: payload.lesson_name,
        lessonId: payload.lesson_id,
        lessonDetailsData: payload,
        totalAttempts: payload.lessonData?.total_attempts || null,
      };
    case SET_MY_COURSE_LESSONS:
      return {
        ...state,
        isLoading: false,
        // active_course_details: payload.course.course_id,
        courseLessonsDataLoading: false,
        courseLessonsData: payload.lessonData,
        myCourse_settings: payload.myCourse_settings,
        myHelpdesk_setting: payload.myHelpdesk_setting,
      };
    case SET_MY_COURSE_LESSON_COUNT_LOADING:
      return { ...state, courseLessonCountLoading: payload };
    case SET_MY_COURSE_LESSONS_COUNT:
      return {
        ...state,
        courseLessonCountLoading: false,
        courseLessonDataCount: payload.result,
      };
    case GET_QUIZ_SUMMARY_LOADING:
      return {
        ...state,
        quizSummaryLoading: payload,
      };
    case GET_QUIZ_SUMMARY:
      return {
        ...state,
        quizSummaryLoading: false,
        quizSummaryData: payload.data,
      };
    case SET_DISPLAY_SECTION:
      return {
        ...state,
        displaySection: "lesson",
      };
    case SET_MY_COURSE:
      return {
        ...state,
        isLoading: false,
        myCourseData: payload.myCourseData,
      };
    default:
      return state;
  }
};

export default Reducer;
