import {
  RoutineDashboard,
  RoutineDashboardInactive,
  myTask,
  Requests,
  feed_image_campaign,
  feed_image_campaign_active,
  Library,
  list,
  list4,
  feed_image_settings,
  feed_image_settings_active,
  pulse,
  active_task_library,
} from "../icons";

export const RoutineRoutes = [
  {
    path: "/routine",
    label: "Dashboard",
    image: RoutineDashboardInactive,
    active_image: RoutineDashboard,
    divider: true,
  },
  {
    path: "/routine/assignedToMe",
    label: "Task",
    image: myTask,
    active_image: myTask,
    divider: true,
    childData: [
      {
        path: "/routine/assignedToMe",
        label: "Assigned To me",
        image: pulse,
        active_image: pulse,
      },
      {
        path: "/routine/assignedByMe",
        label: "Assigned By me",
        image: pulse,
        active_image: pulse,
      },
    ],
  },
  {
    path: "/",
    label: "Requests",
    image: Requests,
    active_image: Requests,
    divider: true,
    childData: [
      {
        //path: "/feedback/campaign/management",
        label: "Revisions",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
      {
        //path: "/feedback/settings/scoreboard",
        label: "Settings",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
    ],
  },
  {
    path: "/routine/tasklibrary",
    label: "Task Library",
    image: Library,
    active_image: active_task_library,
    divider: true,
    childData: [
      {
        //path: "/feedback/campaign/management",
        label: "HR Task",
        image: pulse,
        active_image: pulse,
      },
      {
        //path: "/feedback/settings/scoreboard",
        label: "Manager Task",
        image: pulse,
        active_image: pulse,
      },
      {
        //path: "/feedback/settings/scoreboard",
        label: "Marketing Task",
        image: pulse,
        active_image: pulse,
      },
    ],
  },
  {
    path: "/",
    label: "WireFrame List",
    image: list,
    active_image: list,
    divider: false,
  },

  {
    path: "/",
    label: "Design List",
    image: list,
    active_image: list,
    divider: true,
  },

  {
    path: "/routine/list/:id",
    label: "List",
    image: list,
    active_image: list4,
    divider: true,
  },
  // {
  //     path: "/",
  //     label: "Old ui",
  //     image: feed_image_campaign,
  //     active_image: feed_image_campaign_active,
  //     childData: [
  //         {
  //             path: "/feedback/dashboard",
  //             label: "Dashboard",
  //             image: feed_image_dashboard,
  //             active_image: feed_image_dashboard_active
  //         },
  //         {
  //             path: "/feedback/followups",
  //             label: "Followups",
  //             image: feed_image_followups,
  //             active_image: feed_image_followups_active
  //         },
  //         {
  //             path: "/feedback/campaign",
  //             label: "Campaign",
  //             image: noun_campaigner,
  //             active_image: noun_campaigner_active
  //         },
  //         {
  //             path: "/feedback/location",
  //             label: "Locations",
  //             image: feed_image_campaign,
  //             active_image: feed_image_campaign_active
  //         },
  //         {
  //             path: "/feedback/reports",
  //             label: "Reports",
  //             image: feed_image_reports,
  //             active_image: feed_image_reports_active
  //         },
  //         {
  //             path: "/feedback/settings",
  //             label: "Settings",
  //             image: feed_image_settings,
  //             active_image: feed_image_settings_active
  //         },
  //         {
  //             path: "/feedback/qr",
  //             label: "SendFeedback",
  //             image: feed_image_settings,
  //             active_image: feed_image_settings_active
  //         }
  //     ]
  // },
];
