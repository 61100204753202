import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const usersServices = {
  fetchData,
  addData,
  editData,
  deleteData,
  fetchlocationData,
};

// function fetchData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/publicholidays`, HEADER_TOKEN)
//     .then((response) => response);
// }
// function fetchData(page) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/publicholidays/paginated?pageNumber=${page}&nPerPage=10`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchData(page) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/public-holidays/paginated?pageNumber=${page}&nPerPage=10`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
function fetchlocationData() {
  return axios
    .get(`${MoNGO_DB_API_URL}/publicholidays/locations`, HEADER_TOKEN)
    .then((response) => response);
}
// function fetchlocationData() {
//   return axios
//     .get(
//       `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/publicholidays/locations`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

// function addData(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/publicholidays`,
//       {
//         holiday_date: data.holiday_date,
//         holiday_name: data.holiday_name,
//         isrepeate: data.isrepeate,
//         selectedloc: data.selectedloc,
//       },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function addData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/public-holidays`,
      {
        holiday_date: data.holiday_date,
        holiday_name: data.holiday_name,
        isrepeate: data.isrepeate,
        selectedloc: data.selectedloc,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editData(data, id) {
//   return axios
//     .put(`${MoNGO_DB_API_URL}/publicholidays/${id}`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function editData(data, id) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/public-holidays/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteData(uid) {
//   return axios
//     .delete(`${MoNGO_DB_API_URL}/publicholidays/${uid}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function deleteData(uid) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/public-holidays/${uid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
