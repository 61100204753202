import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { mobileTheme } from "../../constant";

const useStyles = makeStyles((theme) => ({
  accordion: {
    border: "1px solid #E3E3E3 !important",
    boxShadow: "none !important",
    borderRadius: "8px !important",
  },
  expandIcon: {
    fontSize: "23px",
    color: (props) => (props.expanded ? "#ffffff" : mobileTheme),
  },
  accordionSummary: {
    // border: "1px solid #E3E3E3 !important",
    borderRadius: (props) =>
      props.expanded ? "6px 6px 0 0 !important" : "8px !important",
    height: "40px !important",
    background: (props) =>
      props.expanded ? `${props.themeColor} !important` : `#ffffff !important`,
  },
  expandedSummary: {
    // backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  headingText: {
    width: "70%",
    flexShrink: 0,
    fontSize: "14px",
  },
}));

export default function MobileAccordion({
  index,
  currentIndex,
  onChange,
  heading,
  details,
  themeColor,
}) {
  const expanded = index === currentIndex;
  const classes = useStyles({ expanded, themeColor });

  return (
    <Accordion
      expanded={expanded}
      onChange={() => onChange(index)}
      className={classes.accordion}
      sx={{ "& .MuiAccordionSummary-root": { minHeight: "40px" } }}
    >
      <AccordionSummary
        expandIcon={
          <div>
            <ExpandMoreIcon className={classes.expandIcon} />
          </div>
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={`${classes.accordionSummary}`}
      >
        <Typography
          className={classes.headingText}
          style={{ color: expanded ? "#FFFFFF" : "#00000080" }}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "10px" }}>{details}</AccordionDetails>
    </Accordion>
  );
}
