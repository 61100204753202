import {
  SET_PAYROLL_LOADING,
  SET_PAYROLL,
  ADD_PAYROLL,
  EDIT_PAYROLL,
  DELETE_PAYROLL,
  SET_PE_LOADING,
  SET_PE,
  ADD_PE,
  EDIT_PE,
  DELETE_PE,
  SET_PAYROLL_ASSIGN_USERS,
  SET_TIMECODE_LOADING,
  SET_TIMECODESSS,
  SET_USERS_LOADING,
  SET_USERS,
  EDIT_CODE,
  EDIT_CUSTOMCODE,
  SET_CODEUSERS_LOADING,
  SET_CODEUSERS,
  SET_HEADER_LOADING,
  SET_HEADER,
  ADD_HEADER,
  EDIT_HEADER,
  DELETE_HEADER,
  MODAL_OPEN,
  ADDDELETEROW,
} from "./constants";

const initialState = {
  modalOpen: false,
  isLoading: true,
  isPeLoding: true,
  isHeaderLoding: true,
  payrollData: [],
  peData: [],
  timecodeData: [],
  usersData: [],
  timeCodeCustomData:[],
  headerData:[],
  totalPage:1,
  totalPagePE:1,
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MODAL_OPEN:
      return {
        ...state,
        modalOpen: !state.modalOpen
      };
    case SET_PAYROLL_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TIMECODE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TIMECODESSS:
      return {
        ...state,
        isLoading: false,
        timecodeData: payload.TimecodeData
      };
    case SET_USERS_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_USERS:
      return {
        ...state,
        usersData: payload.UserData,
      };
    case EDIT_CODE:
      return {
          ...state,
          isLoading: false,
          usersData: state.UserData && state.UserData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
      case SET_CODEUSERS_LOADING:
        return {
          ...state,
          isLoading: payload
        };
      case SET_CODEUSERS:
        return {
          ...state,
          isLoading: false,
          timeCodeCustomData: payload.TimecodeData
        };
      case EDIT_CUSTOMCODE:
        return {
            ...state,
            isLoading: false,
            timeCodeCustomData: state.TimecodeData && state.TimecodeData.map((item) => item._id === payload._id ? { ...payload } : item)
        }
    case SET_PAYROLL:
      return {
        ...state,
        isLoading: false,
        totalPage:payload.total_page,
        payrollData: payload.payrollData
      };
    case ADD_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: [...state.payrollData, payload]
      }
    case EDIT_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: state.payrollData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: state.payrollData.filter((item) => item._id !== payload)
      }

    case SET_PE_LOADING:
      return {
        ...state,
        isPeLoding: payload
      };
    case SET_PE:
      return {
        ...state,
        isPeLoding: false,
        totalPagePE:payload.total_page,
        peData: payload.PayrollData
      };
    case ADD_PE:
      return {
        ...state,
        isPeLoding: false,
        peData: [...state.peData, payload]
      }
    case EDIT_PE:
      return {
        ...state,
        isPeLoding: false,
        peData: state.peData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_PE:
      return {
        ...state,
        isPeLoding: false,
        peData: state.peData.filter((item) => item._id !== payload)
      }
      case SET_HEADER_LOADING:
        return {
          ...state,
          isHeaderLoding: payload
        };
      case SET_HEADER:
        return {
          ...state,
          isPeLoding: false,
          headerData: payload.ExportHeader,
          isHeaderLoding : false
        };
      case ADD_HEADER:
        return {
          ...state,
          isPeLoding: false,
          headerData: [...state.headerData, payload]
        }
      case EDIT_HEADER:
        return {
          ...state,
          isPeLoding: false,
          headerData: state.headerData.map((item) => item._id === payload._id ? { ...payload } : item)
        }
      case DELETE_HEADER:
        return {
          ...state,
          isPeLoding: false,
          headerData: state.headerData.filter((item) => item._id !== payload)
        }
        case ADDDELETEROW:
          return {
            ...state,
            isPeLoding: false,
            headerData: state.headerData.filter((item) => item._id !== payload._id)
          }
    default:
      return state;
  }
};

export default Reducer;