import { PageCard } from "../../components/Cards";
import {
  EarningIcon,
  FeedbackIcon,
} from "../../../../assets/images/mobileView/icons/svgs";
import { Typography } from "@mui/material";
import "./MoreTabs.scss";

const MoreElearningPages = () => {
  const pagesTabs = [
    {
      id: 1,
      pageName: "earning",
      pageIcon: <EarningIcon />,
      route: "/elearning/earning/my",
    },
    {
      id: 2,
      pageName: "feedback",
      pageIcon: <FeedbackIcon />,
      route: "/elearning/feedbacks",
    },
  ];
  return (
    <div className="pages-tabs-root">
      <Typography
        sx={{ fontSize: "22px", color: "#000000", fontWeight: "700" }}
      >
        More
      </Typography>

      <div className="pages-tabs-container">
        {pagesTabs.map((item, index) => (
          <PageCard
            key={item.id}
            pageIcon={item.pageIcon}
            pageName={item.pageName}
            route={item.route}
          />
        ))}
      </div>
    </div>
  );
};

export default MoreElearningPages;
