import {
    SET_DEVICES_LOADING,
    SET_DEVICES,
    ADD_DEVICES,
    EDIT_DEVICES,
    DELETE_DEVICES
  } from "./constants";
  
  const initialState = {
    isLoading: true,
    devicesData: []
  }
  
  const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_DEVICES_LOADING:
        return {
          ...state,
          isLoading: payload,
          devicesData: []        
        };
      case SET_DEVICES:
        return {
          ...state,
          isLoading: false,
          devicesData: payload.deviceData
        };
      case ADD_DEVICES:
        return {
          ...state,
          isLoading: false,
          devicesData: [...state.devicesData, payload]
        }
      case EDIT_DEVICES:
        return {
          ...state,
          isLoading: false,
          devicesData: state.devicesData.map((item) => item._id === payload._id ? { ...payload } : item)
        }
      case DELETE_DEVICES:
        return {
          ...state,
          isLoading: false,
          devicesData: state.devicesData.filter((item) => item._id !== payload)
        }
      default:
        return state;
    }
  };
  
  export default Reducer;