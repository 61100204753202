import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    feedbackColor,
    eLearningColor,
    todoColor,
    schedulerColor
} from '../../config'
import { Typography } from '@material-ui/core';
import moment from "moment";
import { utcToLocal } from "../../utilities";
import { LoadingData } from '../../components'
import {
    feedback,
    elearning,
    todo,
    scheduler,
    data_not_found
} from '../../icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    ListItem: {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
        borderRadius: "6px",
        marginBottom: '1rem'
    },
    avatarText: {
        textTransform: 'uppercase',
        height: '45px',
        width: '45px',
        fontWeight: 800,
        fontSize: '15px',
        color: theme.palette.primary.main
    }
}));

export default function CheckboxListSecondary(props) {
    const classes = useStyles();
    return (
        props.notificationLoading ?
            <Grid>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                    }}
                >
                    <CircularProgress color="secondary" />
                </div>
            </Grid>
            :
            props.notificationsData.length > 0 ?
                <List dense disablePadding className={classes.root}>
                    {props.notificationsData.map((value) => {
                        return (
                            <ListItem
                                className={classes.ListItem}
                                key={value} button
                                style={{ borderLeft: `5px solid ${value.product === 1 ? feedbackColor.main : value.product === 2 ? eLearningColor.main : value.product === 3 ? todoColor.main : schedulerColor.main}` }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={
                                            value.product === 1 ? feedback
                                                : value.product === 2 ? elearning
                                                    : value.product === 3 ? todo
                                                        : scheduler
                                        }
                                        style={{
                                            backgroundColor: value.product === 1 ? feedbackColor.main
                                                : value.product === 2 ? eLearningColor.main
                                                    : value.product === 3 ? todoColor.main
                                                        : schedulerColor.main
                                        }}
                                        className={classes.avatarText}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography
                                                style={{ display: "contents", float: "left" }}
                                                variant="h5"
                                            >
                                                {value.product === 1 ?
                                                    "eLearning" :
                                                    value.product === 2 ?
                                                        "Feedback" :
                                                        value.product === 3 ?
                                                            "Scheduler" :
                                                            "Todo"
                                                }
                                            </Typography>
                                            <Typography
                                                style={{ lineHeight: "25px", float: "right", display: "block", }}
                                                variant="body2"
                                            >
                                                {moment(utcToLocal(value.created_at)).format("DD MMM YYYY,hh:mm A")}
                                            </Typography>
                                        </>}
                                    secondary={
                                        <Typography
                                            style={{ fontSize: "12px", color: "#000000", display: "inline-block" }}
                                            variant="body2"
                                        >
                                            {value.title}
                                        </Typography>}
                                />
                            </ListItem>
                        );
                    })}
                </List> :
                <Grid
                    style={{
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        verticalAlign: "center"
                    }}
                >
                    <img
                        alt='dsa'
                        style={{
                            height: "200px"
                        }}
                        src={data_not_found}
                    />
                </Grid>
    );
}