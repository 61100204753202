import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MyTextfield, MySelectField, MyMultiSelect, LoadingData } from '../../../components'
import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Avatar
}
    from '@material-ui/core';
import { schedulerColor, FILE_URL } from '../../../config'

const styles = (theme) => ({
    root: {
        padding: theme.spacing(1),
    }
});


class UserDetails extends Component {

    // componentDidMount = () => {
    //     this.setState({
    //         locationsDetails: this.props.locationsDetails
    //     })
    // }

    // toggleUserCheckbox = (e) => {
    //     let checked = e.target.checked;
    //     let name = e.target.name;
    //     this.setState(prevState => ({
    //       locationsDetails: {
    //         ...prevState.locationsDetails,
    //         [name]: checked ? 1 : 0
    //       }
    //     }))
    //   }


    render() {
        const {
            classes,
            userItem,
            errors,
            errorsHelper,
            rolesData,
            userName,
            userImage,
            usersLocations,
            selectedLocationIds,
            locationsDetails,
            user,
            scheduleData,
            unique_pin,
            selectedPayroll
        } = this.props;

        var selectedPayrollRule = this.props.payrollData.filter((item) =>
            this.props.payroll_id === item._id);
        var selectedPayrollRuleObj = Object.assign({}, ...selectedPayrollRule)



        return (
            <Grid
                className={classes.root}
                container
                spacing={2}
            >
                {Object.keys(locationsDetails).length === 0 ?
                    <Grid item sm={12} xs={12} style={{ margin: "auto" }}
                    >
                        <LoadingData />
                    </Grid>
                    :
                    <Grid className={classes.root}
                        container
                        spacing={2}>
                        <Grid sm={3} xs={3}
                            style={{ display: "flex", justifyContent: "center", margin: 'auto 0' }}
                        >
                            {user.user_image ?
                                <Avatar
                                    src={FILE_URL + user.user_image}
                                    className={classes.avatarText}
                                />
                                :
                                <Avatar className={classes.avatarText} >
                                    {user.user_name.charAt(0)}
                                </Avatar>
                            }
                        </Grid>
                        <Grid style={{ margin: 'auto' }} xs={9} sm={9} >
                            <Typography
                                style={{ lineHeight: '16px', color: 'red', fontWeight: '600' }}
                                className={classes.userName}
                            >
                                {user.user_name}
                            </Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <MyTextfield
                                name="unique_pin"
                                label="Unique PIN"
                                onChange={this.props.handleChangeUser}
                                onFocus={this.props.handleFocus}
                                value={unique_pin}
                                type="text"
                                error={errors.unique_pin}
                                helperText={errors.unique_pin ? errorsHelper.unique_pin : ""}
                                placeholder="Unique PIN"
                            />
                        </Grid>

                        <Grid item xs={12}  >
                            <FormControlLabel
                                label={`Show ${user.user_name} in location schedule`}
                                control={
                                    <Checkbox
                                        style={{ color: schedulerColor.main }}
                                        name="show_location_schedule"
                                        // value={locationsDetails.show_location_schedule}
                                        checked={locationsDetails.show_location_schedule === 1}
                                        size="medium"
                                        // onClick={(e) => this.props.toggleUserCheckbox(e, "show_location_schedule")}
                                        onChange={this.props.toggleUserCheckbox}
                                    />
                                }
                            />

                            <FormControlLabel
                                label={`Exclude ${user.user_name} from location alerts`}
                                control={
                                    <Checkbox
                                        style={{ color: schedulerColor.main }}
                                        name="exclude_location_alert"
                                        // value={locationsDetails.exclude_location_alert}
                                        checked={locationsDetails.exclude_location_alert === 1}
                                        size="medium"
                                        // onClick={(e) => this.props.toggleUserCheckbox(e, "exclude_location_alert")}
                                        onChange={this.props.toggleUserCheckbox}
                                    />
                                }
                            />

                            <FormControlLabel
                                label={`Exclude ${user.user_name}  from timeclock errors`}
                                control={
                                    <Checkbox
                                        style={{ color: schedulerColor.main }}
                                        name="exclude_timeclock_errors"
                                        // value={user.exclude_timeclock_errors}
                                        checked={locationsDetails.exclude_timeclock_errors === 1}
                                        size="medium"
                                        // onClick={(e) => this.props.toggleUserCheckbox(e, "exclude_timeclock_errors")}
                                        onChange={this.props.toggleUserCheckbox}
                                    />
                                }
                            />

                            <FormControlLabel
                                label={`Eligible ${user.user_name} for open shifts`}
                                control={
                                    <Checkbox
                                        style={{ color: schedulerColor.main }}
                                        name="eligible_open_shifts"
                                        // value={locationsDetails.eligible_open_shifts}
                                        checked={locationsDetails.eligible_open_shifts === 1}
                                        size="medium"
                                        // onClick={(e) => this.props.toggleUserCheckbox(e, "eligible_open_shifts")}
                                        onChange={this.props.toggleUserCheckbox}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MySelectField
                                label=""
                                name="payroll_id"
                                onChange={this.props.handleChangeUser}
                                onFocus={this.props.handleFocus}
                                value={this.props.payroll_id}
                                // error={errors.payroll_id}
                                // helperText={errors.payroll_id ? errorsHelper.payroll_id : ""}
                                placeholder="Payroll Template"
                            >
                                {
                                    this.props.payrollData.map((item) => {
                                        return (
                                            <option key={item._id} value={item._id}>
                                                {item.template_name}
                                            </option>
                                        );
                                        // }
                                    })}
                            </MySelectField>
                        </Grid>
                        {/* <Grid item xs={12} style={{display:"flex"}}>
                <Typography style={{marginRight:10,fontWeight:700}}>
                                     Payroll rule:
                                    </Typography>
                    {this.props.payrollData.map((item) => {
                        if(this.props.payroll_id === item._id){
                            return(
                                item.payroll_type === 1 ? (
                                    <Typography >
                                      {" "}
                                      Fixed hourly rate ${item.rate}
                                    </Typography>
                                  ) : item.payroll_type === 2 ? (
                                    <Typography >
                                      First {item.hours_limit} Hours @ ${item.rate} and
                                      then ${item.overlimit_rate}
                                    </Typography>
                                  ) : (
                                    <Typography >
                                      Always pay minimum {item.hours_limit} hours @ $
                                      {item.rate}{" "}
                                    </Typography>
                              )
                            )
                        }
                    })}
                </Grid> */}

                        <Grid item xs={12}>
                            <Typography
                                style={{ fontWeight: 'bold', fontsize: '14px' }}>
                                Payroll rule: {selectedPayrollRuleObj.payroll_rule}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }
}

export default withStyles(styles)(UserDetails);