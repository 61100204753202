import {
  GET_PROGRESS_DATA,
  SET_PROGRESS_LOADING,
  GET_MY_LESSON_NAMES_LOADING,
  GET_MY_LESSON_NAMES,
} from "./constants";

const initialState = {
  isProgressLoading: true,
  isMyLessonNamesDataLoading: true,
  ProgressData: [],
  myLessonNamesData: [],
  pageNumber: 1,
  totalPage: 1,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROGRESS_LOADING:
      return {
        ...state,
        isProgressLoading: payload,
      };
    case GET_PROGRESS_DATA:
      return {
        ...state,
        isProgressLoading: false,
        ProgressData: payload.result,
        totalPage: payload.totalPages,
        pageNumber: payload.currentPage,
      };
    case GET_MY_LESSON_NAMES_LOADING:
      return {
        ...state,
        isMyLessonNamesDataLoading: payload,
      };
    case GET_MY_LESSON_NAMES:
      return {
        ...state,
        isMyLessonNamesDataLoading: false,
        myLessonNamesData: payload,
      };

    default:
      return state;
  }
};

export default Reducer;
