import {
  SET_HELPDESK_LOADING,
  SET_HELPDESK,
  ADD_HELPDESK,
  EDIT_HELPDESK,
  DELETE_HELPDESK,
  SET_VIEW_LESSONS,
  MANAGE_DEVICE_COUNT,
  TOGGLE_HELPDESK_ASSIGN_LESSONS,
  TOGGLE_HELPDESK_ASSIGN_DEVICE,
  TOGGLE_ASSIGN_ROLES,
  SET_ELE_CATEGORY_LOADING,
  SET_ELE_CATEGORY,
  ADD_ELE_CATEGORY,
  EDIT_ELE_CATEGORY,
  BINDATA_DELETE,
  DELETE_ELE_CATEGORY,
  ADD_SLIDE,
  DELETE_SLIDE,
  FETCH_DEVICE,
  FETCH_BINDATA,
  BINDATA,
  SET_HELPDESK_DEVICE_LOADING
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { helpdeskServices } from './services';

export function toggleHelpDeskAssignUser(data) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_HELPDESK_ASSIGN_LESSONS,
      payload: data
    });
  };
}

export function toggleHelpDeskAssignDevice(data) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_HELPDESK_ASSIGN_DEVICE,
      payload: data
    });
  };
}

export function manageDeviceCount(data) {
  return function (dispatch) {
    dispatch({
      type: MANAGE_DEVICE_COUNT,
      payload: data
    });
  };
}

export function fetchData(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_HELPDESK_LOADING,
        payload: true
      });
    }

    helpdeskServices.fetchData()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_HELPDESK,
            payload: response.data
          });
        }
      });
  };
}

export function binData(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: BINDATA,
        payload: true
      });
    }

    
    helpdeskServices.binData()
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: FETCH_BINDATA,
            payload: response.data
          });
        }
      });
  };
}

export function fetchAllDevices(data) {
  return function (dispatch) {
      dispatch({
        type: SET_HELPDESK_DEVICE_LOADING,
        payload: true
      });

    helpdeskServices.fetchAllDevices(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: FETCH_DEVICE,
            payload: response.data
          });
        }
      });
  };
}



export function deleteData(_id) {
  // var data = {
  //   desk_id,
  //   pushStatus: parseInt(pushStatus)
  // }
  return function (dispatch) {
    dispatch({
      type: SET_HELPDESK_LOADING,
      payload: true
    });
    helpdeskServices.deleteData(_id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } 
        else if (response.data.success === 1){
          alert.success(response.data.message)
        }
        else {
          alert.error(response.data.message)
          dispatch({
            type: DELETE_HELPDESK,
            payload: _id
          });
        }
      });
  };
}

export function deleteHelpdesk(_id) {
  // var data = {
  //   desk_id,
  //   pushStatus: parseInt(pushStatus)
  // }
  return function (dispatch) {
    dispatch({
      type: BINDATA,
      payload: true
    });
    helpdeskServices.deleteHelpdesk(_id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } 
        else if (response.data.success === 1){
          alert.success(response.data.message)
        }
        else {
          alert.error(response.data.message)
          dispatch({
            type: BINDATA_DELETE,
            payload: _id
          });
        }
      });
  };
}



export function addData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_HELPDESK_LOADING,
      payload: true
    });
    helpdeskServices.addData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        }
        else {
          alert.success(response.data.message)
          dispatch({
            type: ADD_HELPDESK,
            payload: response.data.post
          });
        }
      });
  };
}

export function addSlide(data) {
  return function (dispatch) {
    helpdeskServices.addSlide(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        }
        else {
          alert.success(response.data.message)
          dispatch({
            type: ADD_SLIDE,
            payload: response.data.post
          });
        }
      });
  };
}

export function deleteSlide(_id) {
  // var data = {
  //   desk_id,
  //   pushStatus: parseInt(pushStatus)
  // }
  return function (dispatch) {
    helpdeskServices.deleteSlide(_id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } 
        else if (response.data.success === 1){
          alert.success(response.data.message)
        }
        else {
          alert.error(response.data.message)
          dispatch({
            type: DELETE_SLIDE,
            payload: _id
          });
        }
      });
  };
}



export function fetchViewLessons(id) {
  return function (dispatch) {
    helpdeskServices.fetchViewLessons(id)
    .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_VIEW_LESSONS,
            payload: response.data
          });
        }
      });
  };
}
export function editData(data, id) {
 
  return function (dispatch) {
    dispatch({
      type: SET_HELPDESK_LOADING,
      payload: true
    });
    helpdeskServices.editData(data, id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)

          dispatch({
            type: EDIT_HELPDESK,
            payload: response.data.post
          });
        }
      });
  };
}

export function fetchDataCategory(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_ELE_CATEGORY_LOADING,
        payload: true
      });
    }
   
    helpdeskServices.fetchDataCategory().then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_ELE_CATEGORY,
          payload: response.data
        });
      }
    });
  };
}


export function addDataCategory(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ELE_CATEGORY_LOADING,
      payload: true
    });
    helpdeskServices.addDataCategory(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_ELE_CATEGORY,
          payload: response.data.post
        });
      }
    });
  };
}

export function editDataCategory(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ELE_CATEGORY_LOADING,
      payload: true
    });
    helpdeskServices.editDataCategory(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_ELE_CATEGORY,
          payload: response.data.post
        });
      }
    });
  };
}

// export function deleteDataCategory(_id) {
//   return function (dispatch) {
//     dispatch({
//       type: SET_ELE_CATEGORY_LOADING,
//       payload: true
//     });
//     helpdeskServices.deleteDataCategory(_id).then(response => {
//       if (response.data.success === 2) {
//         invalidToken(response.data.message)
//       } else if (response.data.success === 1) {
//         alert.success(response.data.message)
//         dispatch({
//           type: DELETE_ELE_CATEGORY,
//           payload: _id
//         });
//       } else {
//         alert.error(response.data.message)
//         dispatch({
//           type: SET_ELE_CATEGORY_LOADING,
//           payload: false
//         });
//       }
//     });
//   };
// }
