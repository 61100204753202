import { useEffect, useState } from "react";
import {
  TextareaAutosize,
  Paper,
  Stack,
} from "@mui/material";
import { GradingLoading } from "../../../../pages/eLearning/Grading/utils/GradingLoading";
import { SlidesModal } from "../../utilities";
import { useGradingLessonDetail } from "../../context/gradingContext";
import { GradingHeader } from "../../components";
import "./MobileGradingVideoQuiz.scss";

export const MobileGradingVideoQuiz = ({
  handleStatus,
  handleSubmit,
  status,
  note,
  handleNote,
  videoURL,
  videoLoadingFlag,
  LessonDetail,
}) => {
  const { userName, role } = useGradingLessonDetail();
  const [openSlides, setOpenSlides] = useState(false);

  const handleSlides = () => {
    setOpenSlides(true);
  };

  const handleCloseSlides = () => {
    setOpenSlides(false);
  };

  useEffect(() => {
    localStorage.setItem("gradingToggelTabState", JSON.stringify("team"));
  }, []);

  return (
    <div className={"GradingVideoQuizMainContainer"}>
      <div className={"contentContainer"}>
        <GradingHeader
          LessonDetail={LessonDetail}
          handleSlides={handleSlides}
          role={role}
          userName={userName}
        />

        <div className={"videoGradeContentContainer"}>
          <div className="d-flex justifyContent center">
            <h4 className={"videoTitle"}>Uploaded Video</h4>
          </div>
          <div className={"videoGradeVideoContainer"}>
            <Paper className={"paperContainer"}>
              {!videoLoadingFlag ? (
                <video
                  className={"videoGradeVideoBox"}
                  controls
                  onLoadedData={() => console.log("Video loaded successfully")}
                >
                  <source src={videoURL} type="video/mp4" />
                  <source src={videoURL} type="video/webm" />
                </video>
              ) : (
                <Stack spacing={2} m={"1rem"}>
                  <GradingLoading />
                </Stack>
              )}
            </Paper>
          </div>
          <>
            <div className={"statusContainer"}>
              <h5 className={"statusText"}>Status:</h5>
              <div className={"statusButtonsContainer"}>
                <div className={"statusButtonGroup"}>
                  <button
                    value="Pass"
                    onClick={(e) => {
                      handleStatus(e.target.value);
                    }}
                    style={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      height: "32px",
                      width: "87px",
                      borderRadius: "29px",
                      backgroundColor:
                        status === "Pass"
                          ? `#135098`
                          : "rgba(27, 63, 143, 0.3)",
                      color: status === "Pass" ? "white" : "#1B3F8F",
                    }}
                  >
                    Pass
                  </button>
                  <button
                    value="Reassigned"
                    onClick={(e) => {
                      handleStatus(e.target.value);
                    }}
                    style={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      height: "32px",
                      width: "87px",
                      borderRadius: "29px",
                      backgroundColor:
                        status === "Reassigned"
                          ? `#135098`
                          : "rgba(27, 63, 143, 0.3)",
                      color: status === "Reassigned" ? "white" : "#1B3F8F",
                    }}
                  >
                    Reassign
                  </button>
                </div>
              </div>
            </div>
            <div>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Add notes"
                value={note}
                className={"notesContainer"}
                onChange={(e) => handleNote(e.target.value)}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
              />
            </div>
          </>
        </div>
      </div>

      <div className={"submitBtnContainer"}>
        <button className={"submitButton"} onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <SlidesModal
        open={openSlides}
        handleClose={handleCloseSlides}
        LessonDetail={LessonDetail}
      />
    </div>
  );
};
