import {
  SET_PAYROLL_LOADING,
  SET_PAYROLL,
  ADD_PAYROLL,
  EDIT_PAYROLL,
  DELETE_PAYROLL,
  SET_PAYROLL_ASSIGN_USERS
} from "./constants";

const initialState = {
  isLoading: true,
  payrollData: [],
  payrollHistoryData: [],
  payrollAssignUsers: [],
  payrollUsersData: [],
  AllLevelsUserData:{}
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PAYROLL_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: payload.payrollData,
        AllLevelsUserData:payload
      };
    case ADD_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: [...state.payrollData, payload]
      }
    case EDIT_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: state.payrollData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_PAYROLL:
      return {
        ...state,
        isLoading: false,
        payrollData: state.payrollData.filter((item) => item._id !== payload)
      }
    case SET_PAYROLL_ASSIGN_USERS:
      return {
        ...state,
        isLoading: false,
        payrollAssignUsers: payload.payrollUsersData || []
      }
    default:
      return state;
  }
};

export default Reducer;