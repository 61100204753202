import { useContext, createContext, useState } from "react";

const ThemeContext = createContext();

export function NewsContextProvider({ children }) {
  const [previewFlag, setPreviewFlag] = useState(false);
  const [previewBuilderFlag, setPreviewBuilderFlag] = useState(false);

  return (
    <ThemeContext.Provider
      value={{
        previewFlag,
        setPreviewFlag,
        previewBuilderFlag,
        setPreviewBuilderFlag,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function usePreviewNewsDetails() {
  const NewsContextPreview = useContext(ThemeContext);
  return NewsContextPreview;
}
