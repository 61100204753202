import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const timecodeServices = {
  fetchData,
  addData,
  defaultTimecode,
  editData,
  deleteData,
};

// function fetchData(page) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/payroll_timecode/v2/paginated?pageNumber=${page}&nPerPage=5`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchData(page) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/timecode/paginated?pageNumber=${page}&nPerPage=5`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(`${MoNGO_DB_API_URL}/payroll_timecode`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function defaultTimecode(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/payroll_timecode/setdefault`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editData(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/payroll_timecode/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteData(_id) {
  return axios
    .delete(`${MoNGO_DB_API_URL}/payroll_timecode/${_id}`, HEADER_TOKEN)
    .then((response) => response);
}
