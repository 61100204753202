import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withWindowSize } from "../mobileView/hoc/withWindowSize";

const useStyles = makeStyles(() => ({
  root: {
    margin: '0px 0px 5px 0px',
    height: '100%',
    width: "100%",
    overflow: "hidden"
  },
  rootMobile: {
    margin: '0px 0px 5px 0px',
    height: '100%',
    width: "100% !important",
    top: "unset !important",
    left: "unset !important",
    border: "none !important",
    position: "unset !important",
    transform: "none !important",
    maxHeight: "unset !important"
  },
  content: {
    backgroundColor: '#ffffff',
  },
  contentMobile: {
    backgroundColor: '#ffffff',
    position: "unset !important"
  }
}));

const PlainLayout = props => {
  const { children } = props;
  const classes = useStyles();

  const { width } = props.windowSize;
    const isMobile = width < 768;

  return (
    <div className={isMobile ? classes.rootMobile : classes.root}>
      <div className={isMobile ? classes.contentMobile : classes.content}>
        <div className={classes.container}>
          {children}
        </div>
      </div>
    </div>
  );
};

const ConnectedWithWindowSize = withWindowSize(PlainLayout);

export default (ConnectedWithWindowSize);
