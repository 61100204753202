import {
    SET_ELE_MYHELPDESK_LOADING,
    SET_ELE_MYHELPDESK
  } from "./constants";
  
  const initialState = {
    isLoading: true,
    myHelpdeskData: []
  }
  
  const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_ELE_MYHELPDESK_LOADING:
        return {
          ...state,
          isLoading: payload
        };
      case SET_ELE_MYHELPDESK:
        return {
          ...state,
          isLoading: false,
          myHelpdeskData: payload.myhelpdeskData,
        };
      default:
        return state;
    }
  };
  
  export default Reducer;