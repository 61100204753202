import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { SlideDetail, SlidesBox } from "../../../components";
import { useEffect, useState } from "react";
import "./SlideDetailModal.scss";

export const SlideDetailModal = ({
  open,
  handleClose,
  LessonDetail,
  selectedSlide,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (selectedSlide) {
      setSlideIndex(selectedSlide);
    }
  }, [selectedSlide]);

  const handleSlideIndex = (index) => {
    setSlideIndex(index);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={"SlideDetailModalRoot"}
    >
      <div className={"SlideDetailModalModalContainer"}>
        <div className={"SlideDetailModalHeaderContainer"}>
          <Typography
            fontSize={"16px"}
            fontWeight={700}
            color={"#1B3F8F"}
            className={"SlideDetailModalSlideNumber"}
          >
            Slide no {slideIndex + 1}
          </Typography>
          <CloseIcon
            className={"SlideDetailModalCloseIcon"}
            onClick={handleClose}
          />
        </div>

        <div className={"SlideDetailModalContentContainer"}>
          {LessonDetail && LessonDetail?.lessonData && (
            <div className={"SlideDetailModalSlidesDetailContainer"}>
              <SlideDetail description={LessonDetail?.lessonData[slideIndex]} />
            </div>
          )}

          {LessonDetail && LessonDetail?.lessonData && (
            <div className={"SlideDetailModalSlidesBox"}>
              <SlidesBox
                handleSlide={handleSlideIndex}
                slideIndex={slideIndex}
                slides={LessonDetail.lessonData}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
