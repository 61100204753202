import React from "react";
import { Link } from 'react-router-dom';
import {
  Avatar,
  Typography,
  ListItem,
  ListItemIcon
} from "@material-ui/core";
// import {
//   elearning_asad2,
//   schedule_asad,
//   feedback_asad,
//   routine_asad,
//   news_asad,
//   chat_asad,
//   hr_asad,
//   star_asad,
// } from "../../icons";
import { menu, dashboard } from '../../icons'
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  link: {
    padding: '20px 12px',
    marginBottom: '1.5rem',
    textDecoration: "none",
    "&:hover": {
      backgroundColor: '#ffffff',
    },
  },
  dashboardavatar: {
    width: 18,
    height: 18
  },
  avatar: {
    width: 24,
    height: 24
  },
  linkIcon: {
    minWidth: 24,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    width: 24,
  },
  linkText: {
    width: 120,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 20,
  },
  linkTextHidden: {
    opacity: 0,
  }
}));


export default function SidebarLink({ activeProduct, isSidebarOpen, productModalToggle, handleSidebarToggle }) {
  var classes = useStyles();

  return (
    <ListItem
      button
      classes={{ root: classes.link }}
    >
      {/* <img src={schedule_asad}></img> */}
      {/* asad hiding sidebar expand */}
      <ListItemIcon
        style={{ float: "right" }}
        onClick={handleSidebarToggle}
        className={classnames(classes.linkIcon)}
      >
        <Avatar
          variant="square"
          className={classes.avatar}
          src={menu}
        />
      </ListItemIcon>

      <Link to="/dashboard"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          textDecoration: 'none'
        }}>
        <Typography
          variant="h5"
          color="secondary"
          className={classes.linkText}
        >
          {activeProduct ? activeProduct : "Dashboard"}
        </Typography>
        <Avatar
          variant="square"
          className={classes.dashboardavatar}
          src={dashboard}
        />
      </Link>
      {/* <ListItemIcon
        onClick={productModalToggle}
        className={classes.linkIcon}
      >
        <Avatar
          variant="square"
          className={classes.dashboardavatar}
          src={dashboard}
        />

      </ListItemIcon> */}
    </ListItem>
  );
}
