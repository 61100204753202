import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import HomeIcon from "@mui/icons-material/Home";
// import FeedbackIcon from "@mui/icons-material/Feedback";
import "./styles.scss";
import { Paper, Modal, Box, Typography, FormGroup, Checkbox, Grid, Button } from '@mui/material';
import { history } from "../../../../history";
// import { usePreviewNewsDetails } from "../../context";

import {
  MyNewsIcon,
  NewsBuilderIcon,
  ReportsIcon,
} from "../../../../assets/images/mobileView/icons/svgs";
import { useSearch } from "../../context/MobileTeamSearchContext";

const tabs = [
  { label: "Rate Team ", icon: <MyNewsIcon />, path: "/teamfeedback/rateTeam" },
  {
    label: "Rating",
    icon: <NewsBuilderIcon />,
    path: "/teamfeedback/rating",
  },
  { label: "My Rating", icon: <ReportsIcon />, path: "/teamfeedback/myRating" },
];

export function TeamFeedbackBottomNav() {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const { resetSearchQuery,review,currentReview,nextButton,previousButton,confirmButton } = useSearch();

  useEffect(() => {
    // Compare the current pathname with the paths of the tabs and set the selected tab
    const selectedIndex = tabs.findIndex((tab) =>
      location.pathname.startsWith(tab.path)
    );
    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }

    resetSearchQuery();
  }, [location.pathname]);

  return  /^\/teamfeedback\/rating\/form\/\w+$/.test(location.pathname) ||
  /^\/teamfeedback\/rating\/formDetail\/[\w/]+$/.test(location.pathname)  ||
  /^\/teamfeedback\/rateTeam\/form\/\w+$/.test(location.pathname) ||
  /^\/teamfeedback\/rateTeam\/formDetail\/[\w/]+$/.test(location.pathname) ||
  /^\/teamfeedback\/myRating\/formDetail\/\w+$/.test(location.pathname)||
    location.pathname === "/teamfeedback/rating/addForm" ? (
  ""
   ) : 
//    /^\/teamfeedback\/rateTeam\/formDetail\/[\w/]+$/.test(location.pathname)  ?(
//     <div className="team-bottom-appbar">
//     <div className="tabs">
//     <div style={{ textAlign: "center" }}>
//     {review.length > 1 && currentReview < review.length - 1 && (
//         <Button
//             style={{ background: "rgba(12, 22, 111, 1)", color: "white", textTransform: "capitalize", marginRight: '10px' }}
//             size="medium"
//             onClick={nextButton}
//         >
//             Next
//         </Button>
//     )}
//     {currentReview > 0 && (
//         <Button
//             style={{ background: "rgba(12, 22, 111, 1)", color: "white", textTransform: "capitalize", marginRight: '10px' }}
//             size="medium"
//             onClick={previousButton}
//         >
//             Previous
//         </Button>
//     )}
//     {currentReview !== review.length - 1 ? "" :
//         <Button
//             style={{ background: "rgba(12, 22, 111, 1)", color: "white", textTransform: "capitalize" }}
//             size="medium"
//             onClick={confirmButton}
//         >
//             Confirm
//         </Button>}
// </div>
// </div>
// </div>
// ):
   (
    <div className="team-bottom-appbar">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            className={`tab ${
              value === index
                ? "tab--fab"
                : value === index - 1
                ? "tab--right"
                : value === index + 1
                ? "tab--left"
                : ""
            }`}
            onClick={() => history.push(tab.path)}
          >
            {value === index ? (
              <div className="top">
                <div className="fab">
                  {React.cloneElement(tab.icon, {
                    fill: "rgb(12, 22, 111)",
                    width: "22px",
                    height: "22px",
                    color: "#ffffff",
                  })}
                </div>
              </div>
            ) : (
              <>
                <div>
                  {React.cloneElement(tab.icon, {
                    fill: "#ffffff",
                    width: "22px",
                    height: "22px",
                    color: "#ffffff",
                  })}
                </div>
                <span style={{ color: "#ffffff" }}>{tab.label}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )

}
