import {
  SET_TRADE_LOADING,
  SET_TRADE,
  ADD_TRADE,
  EDIT_TRADE,
  DELETE_TRADE
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { tradeServices } from './services'

export function fetchData(data, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_TRADE_LOADING,
        payload: true
      });
    }

    tradeServices.fetchData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_TRADE,
          payload: response.data
        });
      }
    });
  };
}


export function deleteData(id) {
  return function (dispatch) {
    tradeServices.deleteData(id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: DELETE_TRADE,
            payload: id
          });
        }
      });
  };
}


export function addData(data) {
  return function (dispatch) {
    tradeServices.addData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: ADD_TRADE,
            payload: response.data.post
          });
        }
      });
  };
}

export function editData(data) {
  return function (dispatch) {
    tradeServices.editData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_TRADE,
            payload: response.data.post
          });
        }
      });
  };
}