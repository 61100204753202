import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loading from "./Loader";

export default class LoadingData extends Component {
  render() {
    return (
      // <div className="LoadingData"></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          // zIndex: '-1',
          width: "100vw",
          backgroundColor: "transparent",
        }}
      >
        <Loading />
      </div>
    );
  }
}
