import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import {
  Add as AddIcon
} from "@material-ui/icons";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  link1: {
    position: "fixed",
    bottom: '0px',
    textDecoration: "none",
    width : 200,
    backgroundColor: '#dddddd',
    "&:hover": {
      backgroundColor: '#ffffff',
    }
  },
  link2: {
    position: "fixed",
    bottom: '0px',
    textDecoration: "none",
    width : 56,
    backgroundColor: '#dddddd',
    "&:hover": {
      backgroundColor: '#ffffff',
    }
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    width: 24,
  },
  linkText: {
    padding: 0,
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextHidden: {
    opacity: 0,
  }
}));


export default function SidebarLink({ isSidebarOpen, addListOpen }) {
  var classes = useStyles();

  return (
    <ListItem
      button
      className={isSidebarOpen ? classes.link1 : classes.link2}
      disableRipple
      onClick={addListOpen}
    >
      <ListItemIcon
        className={classnames(classes.linkIcon)}
      >
        <AddIcon />
      </ListItemIcon>

      <ListItemText
        classes={{
          primary: classnames(classes.linkText, {
            [classes.linkTextHidden]: !isSidebarOpen,
          }),
        }}
        primary="Add New List"
      />
    </ListItem>
  );
}
