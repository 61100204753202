import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { paymentLogsTableHead } from "./constant";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdArrowDropDownCircle } from "react-icons/md";

import "./PaymentLogsMobile.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addEarningComment,
  fetchEarningCommentsData,
  fetchUserPaymentLogsData,
} from "../../../../../pages/eLearning/Earning/modules/actions";
import { AddNotesModal } from "../../../../../pages/eLearning/Earning/Components/Modal/AddNotesModal";
import { history } from "../../../../../history";

export const PaymentLogsMobile = ({ userId }) => {
  const dispatch = useDispatch();
  const userPaymentLogsData = useSelector(
    (state) => state.eLearningEarning.userPaymentLogsData
  );
  const userPaymentLogsLoading = useSelector(
    (state) => state.eLearningEarning.userPaymentLogsLoading
  );
  const earningCommentsData = useSelector(
    (state) => state.eLearningEarning.earningCommentsData
  );
  const earningCommentsLoading = useSelector(
    (state) => state.eLearningEarning.earningCommentsLoading
  );

  const [expanded, setExpanded] = useState("");

  const toggleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  // const [isNoteModalFlag, setIsNoteModalFlag] = useState(false);
  // const [addCommentPaymentDateAndTime, setAddCommentPaymentDateAndTime] =
  //   useState(null);

  const handleOpenAddNote = (paymentId) => {
    // setAddCommentPaymentDateAndTime(paymentDateAndTime);
    // setIsNoteModalFlag(true);

    history.push({
      pathname: `/elearning/earning/addnote/${userId}`,
      state: { paymentId },
    });
  };

  // const handleCloseAddNote = () => {
  //   setIsNoteModalFlag(false);
  // };

  const handleFetchEarningCommentsData = async ({ paymentId }) => {
    let data = {
      uid: userId,
      paymentId: paymentId,
    };

    dispatch(fetchEarningCommentsData(data));
  };

  // const handleAddNote = async (comment) => {
  //   let data = {
  //     uid: userId,
  //     earning_comment: comment,
  //     paymentDateAndTime: addCommentPaymentDateAndTime,
  //   };

  //   dispatch(addEarningComment(data));
  // };

  const handleFetchUserPaymentLogsData = async () => {
    dispatch(fetchUserPaymentLogsData(userId));
  };

  useEffect(() => {
    handleFetchUserPaymentLogsData();
  }, [userId, dispatch]);

  return (
    <>
      <div className="paymentLogs-mobile-root-container">
        {userPaymentLogsLoading ? (
          <></>
        ) : (
          userPaymentLogsData?.result?.map((item, index) => {
            const dateTime = new Date(item?.createdAt);

            const paymentLogDate = dateTime.toLocaleDateString();
            let formattedDate = "";
            if (paymentLogDate) {
              const date = new Date(paymentLogDate);
              const year = date.getFullYear();
              const month = date.getMonth(); // Note: Months are zero-based in JavaScript
              const day = date.getDate();

              // Array of month names
              const monthNames = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];

              // Format the date as "23 Mar 2023"
              formattedDate = `${day < 10 ? "0" : ""}${day} ${
                monthNames[month]
              } ${year}`;
            }

            const time = dateTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            return (
              <Accordion
                expanded={expanded === index}
                onChange={(event, isExpanded) => {
                  toggleAccordion(index);
                  setExpanded(isExpanded ? index : false);
                  if (isExpanded) {
                    handleFetchEarningCommentsData({
                      paymentId: item._id,
                    });
                  }
                }}
                className="paymentLogs-accordian-root"
              >
                <AccordionSummary
                  style={{
                    position: "none",
                    background:
                      expanded === index
                        ? "linear-gradient(45deg, #1B3F8F, #635BFF)"
                        : "transparent",
                    borderRadius: expanded === index ? "6px 6px 0px 0px" : "",
                  }}
                  expandIcon={
                    <div
                      style={{
                        fontSize: "20px",
                        borderRadius: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: expanded === index ? "white" : "#135098",
                      }}
                    >
                      {/* <ExpandMoreIcon
                        style={{
                          color: expanded === index ? "#1B3F8F" : "white",
                        }}
                      /> */}
                      <MdArrowDropDownCircle />
                    </div>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    className="accordian-summary-text"
                    sx={{ fontSize: "12px" }}
                    style={{
                      color: expanded === index ? "white" : "black",
                    }}
                  >
                    {formattedDate} at {time}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordian-details-container">
                  <TableContainer
                    // component={Paper}
                    className="table-container-root"
                  >
                    <Table sx={{ marginTop: "29px" }} aria-label="simple table">
                      <TableHead className="table-head-root-container">
                        <TableRow className="table-head-row">
                          {paymentLogsTableHead?.map((item, index) => (
                            <TableCell
                              key={item.id}
                              className="table-cell"
                              align="center"
                            >
                              {item.heading}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item?.earnings?.map(
                          ({
                            _id,
                            lesson_name,
                            earningAmounts,
                            earningPoints,
                            lessonExpiryDays,
                            lessonExpiryHours,
                            lessonExpiryMinutes,
                          }) => (
                            <TableRow
                              key={_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                className="table-body-cell table-body-cell-first-child"
                                align="start"
                              >
                                {lesson_name}
                              </TableCell>
                              <TableCell
                                className="table-body-cell"
                                align="center"
                              >
                                {earningAmounts}
                              </TableCell>
                              <TableCell
                                className="table-body-cell"
                                align="center"
                              >
                                {earningPoints}
                              </TableCell>
                              <TableCell
                                className="table-body-cell"
                                align="center"
                              >
                                {`${lessonExpiryDays} Days ${lessonExpiryHours} Hours ${lessonExpiryMinutes} Minutes`}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="notes-container">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#1B3F8F",
                      }}
                    >
                      Note
                    </Typography>

                    <Box
                      className="notes-input-field"
                      onClick={() => handleOpenAddNote(item?._id)}
                    >
                      {earningCommentsData?.allComment?.map(
                        (comment, index) => (
                          <ListItem key={index} className="notes-list-item">
                            {comment.earning_comment}
                          </ListItem>
                        )
                      )}
                    </Box>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}

        {userPaymentLogsLoading && (
          <div className="loaderContainer">
            <CircularProgress style={{ color: "#1B3F8F" }} />
          </div>
        )}
        {!userPaymentLogsLoading &&
          userPaymentLogsData?.PaymentLogs?.length === 0 && (
            <Box textAlign="center">
              <Typography
                style={{
                  color: "#848484",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                spacing={2}
                m={"1rem"}
              >
                No Record Found
              </Typography>
            </Box>
          )}
      </div>
      {/* <AddNotesModal
        open={isNoteModalFlag}
        handleAddNote={handleAddNote}
        handleCloseAddNote={handleCloseAddNote}
      /> */}
    </>
  );
};
