import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const myCourseServices = {
  fetchData,
  getLessonByCourses,
  getLessonDetailsById,
  questionCompleted,
  lessonCompleted,
  getQuestionsByLessonId,
  updateSlideCompelete,
  getMyLessons,
  getMyLessonsCount,
  // getMyCourseLessons,
  getQuizSummary,
};

// function fetchData() {
//     return axios.get(`${MoNGO_DB_API_URL}/elearning/mycourse`, HEADER_TOKEN).then(response => response)
// };
// function fetchData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function getLessonByCourses(id) {
//     return axios.get(`${MoNGO_DB_API_URL}/elearning/mycourse/${id}`, HEADER_TOKEN).then(response => response)
// };

// function getLessonByCourses(id) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/${id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function getLessonByCourses(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function getMyLessons(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/lessons/all`,
//       {
//         ecid: data,
//       },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function getMyLessons(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/lessons`,
      {
        ecid: data,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getMyLessonsCount() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/app/elearning/mycourse/lessons/count`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// Api for mobile view mycourse screen
// function getMyCourseLessons(data) {
//   return axios
//     .post(
//       `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/lessons`,
//       {
//         data,
//       },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

// function updateSlideCompelete(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/slide/complete`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function updateSlideCompelete(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/slide/complete`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function getLessonDetailsById(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/lesson_details`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function getLessonDetailsById(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/lesson_details`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function getQuestionsByLessonId(slide_id) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/slide/${slide_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function getQuestionsByLessonId(slide_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/slide/${slide_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function questionCompleted(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/question/complete`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function questionCompleted(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/question/complete`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function lessonCompleted(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/mycourse/v2/lesson/complete`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function lessonCompleted(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/slide/complete`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getQuizSummary(slide_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/slide/${slide_id}/summary`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
