import {
  SET_CAMPAIGN_LOADING,
  SET_CAMPAIGN,
  ADD_CAMPAIGN,
  EDIT_CAMPAIGN,
  DELETE_CAMPAIGN,
  TOGGLE_CAMPAIGN_LOCATIONS,
  FETCH_ASSIGN_LOCATIONS
} from "./constants";

const initialState = {
  isLoading: true,
  campaignsData: [],
  emojiData: [],
  campaignlocationData: [],
  assignLocationsData: []
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CAMPAIGN_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        campaignsData: payload.campaignsData,
        campaignlocationData: payload.locationData,
        emojiData: payload.emojiData,
      };
    case ADD_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        campaignsData: [...state.campaignsData, payload],
      };
    case TOGGLE_CAMPAIGN_LOCATIONS:
      let existCampaign = state.campaignsData.find(
        (item) => payload.campaign_id === item._id
      );
      var myLocations = existCampaign.lid ? existCampaign.lid : [];
      if (payload.isSelected) {
        myLocations.push(payload.lid);
      } else {
        myLocations.splice(myLocations.indexOf(payload.lid), 1);
      }
      existCampaign.lid = myLocations.join();
      return {
        ...state,
        campaignsData: state.campaignsData.map((item) =>
          item.campaignId === existCampaign._id ? { ...existCampaign } : item
        ),
      };
    case EDIT_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        campaignsData: state.campaignsData.map((item) =>
          item.campaignId === parseInt(payload.campaign_id)
            ? { ...payload }
            : item
        ),
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        campaignsData: state.campaignsData.filter(
          (item) => item._id !== payload
        ),
      };
      case FETCH_ASSIGN_LOCATIONS:
      return {
        ...state,
        isLoading: false,
        assignLocationsData: payload.LocationData,
      };
    default:
      return state;
  }
};

export default Reducer;
