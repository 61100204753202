import { MoNGO_DB_API_URL, HEADER_TOKEN, MY_TOKEN } from "../../../../config";
import axios from "axios";

export const progressServices = {
  fetchData,
  getMyLessonNames,
};

// function fetchData(dataNew) {
//   var data = {
//     isvideo_quiz: dataNew.isvideo_quiz,
//     practical_quiz: dataNew.practical_quiz,
//   };
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/v2/elearning/myprogress?page=${dataNew.page}&limit=${dataNew.nPerPage}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function fetchData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/progress`,
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        params: data,
      }
    )
    .then((response) => response);
}

function getMyLessonNames() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/my`,
      {
        headers: { Authorization: MY_TOKEN },
      }
    )
    .then((response) => response);
}
