export const headMyGradingElements = [
  { id: 1, heading: "Lesson Name" },
  { id: 2, heading: "Lesson Status" },
  { id: 3, heading: "Manager Quiz" },
  { id: 4, heading: "Video Quiz" },
  { id: 5, heading: "Status" },
  { id: 8, heading: "Action" },
];

export const headTeamGradingElements = [
  { id: 1, heading: "Lesson Name" },
  { id: 2, heading: "Trainee" },
  { id: 3, heading: "Role" },
  { id: 4, heading: "Lesson Status" },
  { id: 5, heading: "Manager Quiz" },
  { id: 6, heading: "Video Quiz" },
  { id: 7, heading: "Status" },
  { id: 8, heading: "Action" },
];

export const bodyMyGradingElements = [
  {
    id: 1,
    lessonName: "test-lesson-1",
    lessonQuiz: "Pending",
    managerQuiz: "Pass",
    videoQuiz: "Pass",
    status: "Pending",
  },
  {
    id: 2,
    lessonName: "test-lesson-2",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pass",
  },
  {
    id: 3,
    lessonName: "test-lesson-3",
    lessonQuiz: "Pass",
    managerQuiz: "Pending",
    videoQuiz: "Pending",
    status: "Pending",
  },
  {
    id: 4,
    lessonName: "test-lesson-4",
    lessonQuiz: "Pending",
    managerQuiz: "Pass",
    videoQuiz: "Pass",
    status: "Pending",
  },
  {
    id: 5,
    lessonName: "test-lesson-5",
    lessonQuiz: "Pass",
    managerQuiz: "Pending",
    videoQuiz: "Pass",
    status: "Pass",
  },
  {
    id: 6,
    lessonName: "test-lesson-6",
    lessonQuiz: "Pending",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pass",
  },
  {
    id: 7,
    lessonName: "test-lesson-7",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pass",
  },
  {
    id: 8,
    lessonName: "test-lesson-8",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pass",
    status: "Pending",
  },
  {
    id: 9,
    lessonName: "test-lesson-9",
    lessonQuiz: "Pending",
    managerQuiz: "Pending",
    videoQuiz: "Pending",
    status: "Pending",
  },
  {
    id: 10,
    lessonName: "test-lesson-10",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pending",
  },
];

export const bodyTeamGradingElements = [
  {
    id: 1,
    lessonName: "test-lesson-1",
    trainee: "Zunaira",
    role: "Testing Role",
    lessonQuiz: "Pending",
    managerQuiz: "Pass",
    videoQuiz: "Pass",
    status: "Pending",
  },
  {
    id: 2,
    lessonName: "test-lesson-2",
    trainee: "John",
    role: "Development Role",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pass",
  },
  {
    id: 3,
    lessonName: "test-lesson-3",
    trainee: "Alice",
    role: "Design Role",
    lessonQuiz: "Pass",
    managerQuiz: "Pending",
    videoQuiz: "Pending",
    status: "Pending",
  },
  {
    id: 4,
    lessonName: "test-lesson-4",
    trainee: "Bob",
    role: "Testing Role",
    lessonQuiz: "Pending",
    managerQuiz: "Pass",
    videoQuiz: "Pass",
    status: "Pending",
  },
  {
    id: 5,
    lessonName: "test-lesson-5",
    trainee: "Eva",
    role: "Development Role",
    lessonQuiz: "Pass",
    managerQuiz: "Pending",
    videoQuiz: "Pass",
    status: "Pass",
  },
  {
    id: 6,
    lessonName: "test-lesson-6",
    trainee: "Charlie",
    role: "Design Role",
    lessonQuiz: "Pending",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pass",
  },
  {
    id: 7,
    lessonName: "test-lesson-7",
    trainee: "Sophie",
    role: "Testing Role",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pass",
  },
  {
    id: 8,
    lessonName: "test-lesson-8",
    trainee: "David",
    role: "Development Role",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pass",
    status: "Pending",
  },
  {
    id: 9,
    lessonName: "test-lesson-9",
    trainee: "Fiona",
    role: "Design Role",
    lessonQuiz: "Pending",
    managerQuiz: "Pending",
    videoQuiz: "Pending",
    status: "Pending",
  },
  {
    id: 10,
    lessonName: "test-lesson-10",
    trainee: "George",
    role: "Testing Role",
    lessonQuiz: "Pass",
    managerQuiz: "Pass",
    videoQuiz: "Pending",
    status: "Pending",
  },
];

export const headCellStyle = {
  fontSize: "12px !important",
  background: "#1B3F8F !important",
  fontWeight: "700 !important",
  color: "white !important",
  whiteSpace: "nowrap !important",
  textOverflow: "ellipsis !important",
  overflow: "hidden !important",
  padding: "4px 7px !important",
  textAlign: "center !important",
  // width: "25%",
};

export const bodyCellStyle = {
  fontSize: "10px !important",
  fontWeight: "400 !important",
  color: "black !important",
  width: "25% !important",
  textOverflow: "ellipsis !important",
  overflow: "hidden !important",
  textAlign: "center !important",
};
