export const Tab = ({ text, bgColor, textColor, rounded, size, selected }) => {
  return (
    <button
      style={{
        background: selected ? bgColor.selectedColor : bgColor.unselectedColor,
        fontSize: text.size,
        color: selected ? textColor.selectedColor : textColor.unselectedColor,
        borderRadius: rounded,
        width: size.width,
        height: size.height,
        boxShadow: `2px ${
          selected ? bgColor.selectedColor : bgColor.unselectedColor
        }`,
      }}
    >
      {text.content}
    </button>
  );
};
