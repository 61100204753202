import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const settingsServices = {
  getLocationdata,
  getStatedata,
  getCityData,
  updateLocationdata,
  getScheduleData,
  updateScheduleData,
  getBreakData,
  deleteBreakData,
  platform,
  verification,
  location,
  liveTracking,
  deviceLock,
  workRestriction,
  Reminders,
  alertsandnotification,
  breaks,
  addWorkScheduler,
  previewBreaks,
  deleteBreaks,
  fetchRoleData,
};

function getLocationdata(lid) {
  return axios
    .get(`${MoNGO_DB_API_URL}/scheduler/settings/location/${lid}`, HEADER_TOKEN)
    .then((response) => response);
}
function fetchRoleData() {
  return axios
    .get(`${MoNGO_DB_API_URL}/ovrating/feedback/role`, HEADER_TOKEN)
    .then((response) => response);
}
function getStatedata(state_id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/settings/location/state/${state_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getCityData(city_id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/settings/location/city/${city_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function updateLocationdata(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/location/${data._id}`,
      {
        data,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getScheduleData(lid) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/settings/timezone/new/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function updateScheduleData(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/timezone/new/${data.lid}`,
      {
        data,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getBreakData(lid) {
  return axios
    .get(`${MoNGO_DB_API_URL}/scheduler/settings/breaks/${lid}`, HEADER_TOKEN)
    .then((response) => response);
}

function deleteBreakData(break_id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/scheduler/settings/breaks/${break_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

///TimeTracking

function platform(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/platforms/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function verification(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/verification/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function location(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/location/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function liveTracking(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/livetracking/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deviceLock(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/devicelock/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

//Schedulimg
function workRestriction(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/workrestriction/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function Reminders(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/reminders/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function alertsandnotification(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/alertsnotifications/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function breaks(lid, data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/breaks/${lid}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

////////////////////// Working Scheduler ////////////////////////

function addWorkScheduler(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/workschedule`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function previewBreaks(lid) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/Break/payrolls/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteBreaks(lid) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/scheduler/settings/v2/Break/payrolls/delete/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
