import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const MobileLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loaderContainer}>
      <CircularProgress
        color="secondary"
        style={{ background: "transparent" }}
      />
    </Box>
  );
};

export const MobileLinearLoader = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};
