import {
  SET_FEEDBACK_BUSINESS_LOCATION_LOADING,
  SET_FEEDBACK_BUSINESS_LOCATION,
  EDIT_FEEDBACK_BUSINESS_LOCATION
} from "./constants";

const initialState = {
  isLoading: true,
  locationData: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEEDBACK_BUSINESS_LOCATION_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FEEDBACK_BUSINESS_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: payload.LocationData,
      };
    case EDIT_FEEDBACK_BUSINESS_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationData: state.locationData.map((item) =>
          item.location_id === payload.location_id ? { ...payload } : item
        ),
      };
    default:
      return state;
  }
};

export default Reducer;
