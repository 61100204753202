import React, { Component } from 'react'
import {
        Grid,
        Popover,
        Typography,
        MenuItem,
        Button
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import DateRangeCurrent from './DateRangeCurrent';
import DateRangeCustom from './DateRangeCustom';
import DateRangeToday from './DateRangeToday';
import { schedulerColor } from '../../../config';
import { composeFunctions } from 'rsuite/esm/utils';


const styles = (theme) => ({
        PopoverClass: {
                minHeight: '300px',
                minWidth: '550px'
        },
        menu: {
                minWidth: '200px',
                borderRadius: '5px',
                backgroundColor: "#fafafa"
        },
        MenuItem: {
                '&:hover': {
                        backgroundImage: theme.palette.primary.secondary,
                }
        },
        Typography: {
                width: "100%",
                fontSize: '15px',
                lineHeight: '2rem'
        },
        buttonLogin: {
                fontWeight: 600,
                color: '#ffffff',
                backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
                borderRadius: "2px",
                padding: '8px 40px'
        },
});

class PopoverDemo extends Component {
        render() {
                const { classes, anchorEl, payPeriod } = this.props;
                const open = Boolean(anchorEl);
                const id = open ? 'simple-popover' : undefined;
              
                return (
                        <Popover
                                className={classes.Popover}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.props.handlePopoverClose}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                }}
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                }}
                        >
                                <Grid container className={classes.PopoverClass}>
                                        <Grid item sm={4} xs={8}>
                                                {this.props.payrollFilterData.map((items) => {
                                                        return (
                                                                <MenuItem
                                                                        style={{ backgroundColor: payPeriod === items.id ? "silver" : "#ffffff" }}
                                                                        onClick={() => { this.props.selectPayPeriod(items.id) }}
                                                                        className={classes.MenuItem}
                                                                >
                                                                        <Typography
                                                                                className={classes.Typography}
                                                                                color="textPrimary"
                                                                                variant="inherit"
                                                                        >
                                                                                {items.title}
                                                                        </Typography>
                                                                </MenuItem>
                                                        )
                                                })}
                                        </Grid>

                                        <Grid
                                                style={{ borderLeft: "1px solid lightgray" }}
                                                item sm={8} xs={8}>
                                                {payPeriod === "current" || payPeriod === "previous" ?
                                                        <DateRangeCurrent
                                                                startDate={payPeriod === "current" ? this.props.current_startDate : this.props.previous_startDate}
                                                                endDate={payPeriod === "current" ? this.props.current_endDate : this.props.previous_endDate}
                                                        />
                                                        : ""
                                                }

                                                {/* {  ?
                                                                < DateRangePrevious
                                                                startDate={}
                                                                endDate={ }
                                                        />
                                                        
                                                        : ""} */}
                                                {payPeriod === "today" ?
                                                        <DateRangeToday
                                                                currentDate={this.props.todayDate}
                                                        />
                                                        : ""}
                                                {payPeriod === "custom" ?
                                                        <DateRangeCustom
                                                                startDate={this.props.custom_startDate}
                                                                endDate={this.props.custom_endDate}
                                                                onSelectStartEndDate={this.props.onSelectStartEndDate}
                                                        />
                                                        : ""}
                                                <Grid
                                                        sm={12}
                                                        style={{ textAlign: "center", margin: "5px" }}
                                                >
                                                        <Button
                                                                className={classes.buttonLogin}
                                                                onClick={this.props.applyPayPeriod}
                                                        >
                                                                Apply
                                                        </Button>
                                                </Grid>
                                        </Grid>
                                </Grid>
                        </Popover>
                )
        }
}
export default withStyles(styles)(PopoverDemo);