import { Typography } from "@mui/material";
import { cloneElement } from "react";
import "./PageCard.scss";
import { history } from "../../../../history";

export const PageCard = ({ pageName, pageIcon, route }) => {
  return (
    <div
      className="page-card-root-container"
      onClick={() => history.push(route)}
    >
      <div className="page-icon">
        {cloneElement(pageIcon, {
          fill: "#1B3F8F",
          width: "50px",
          height: "50px",
          color: "#ffffff",
        })}
      </div>
      <div className="page-name-container">
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "700",
            color: "#FFFFFF",
            textTransform: "capitalize",
          }}
        >
          {pageName}
        </Typography>
      </div>
    </div>
  );
};
