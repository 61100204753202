import {
  SET_ROLES_LOADING,
  SET_ROLES,
  ADD_ROLES,
  EDIT_ROLES,
  DELETE_ROLES,
  SET_USER_PERMISSIONS,
  SET_USER_PERMISSIONS_LOADING
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { rolesServices } from './services'

export function fetchData(isReload,page) {
  return function (dispatch) {
   
      dispatch({
        type: SET_ROLES_LOADING,
        payload: true
      });
    

    rolesServices.fetchData(page).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_ROLES,
          payload: response.data
        });
      }
    });
  };
}

export function userPermissions(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_USER_PERMISSIONS_LOADING,
        payload: true
      });
    }
    rolesServices.userPermissions().then(response => {
      localStorage.setItem("productsColorsData" , JSON.stringify(response.data.ProductColor))
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_USER_PERMISSIONS,
          payload: response.data
        });
      }
    });
  };
}

export function deleteData(_id) {
  return function (dispatch) {
    rolesServices.deleteData(_id).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      }
      else if (response.data.success === 1) {
        alert.success(response.data.message)
        dispatch({
          type: DELETE_ROLES,
          payload: _id
        });
      }
      else {
        alert.error(response.data.message)
      }
    });
  };
}


export function cloneRoles(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ROLES_LOADING,
      payload: true
    });
    rolesServices.cloneRoles(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_ROLES,
          payload: response.data.post
        });
      }
    });
  };
}

export function addData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ROLES_LOADING,
      payload: true
    });
    rolesServices.addData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_ROLES,
          payload: response.data.post
        });
      }
    });
  };
}

export function editData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ROLES_LOADING,
      payload: true
    });
    rolesServices.editData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_ROLES,
          payload: response.data.post
        });
      }
    });
  };
}