import React from "react";
import { makeStyles } from "@mui/styles";
import { hexToRgb } from "../../../../utilities/v5/common";
import { mobileTheme } from "../../constant";
import { IoMdSearch } from "react-icons/io";
import { ProfileLogo } from "../../../../assets/images/mobileView/profile";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { history } from "../../../../history";
import { useLocation } from "react-router-dom";
import { useSearch } from "../../context/MobileHRSearchContext";
import { hrMobileHeaderPattern } from "../../../../assets/images/mobileView/hr";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import "./HrMobileTopNav.scss";
import { EventSearchMobile } from "../Search";
import SearchRole from "../../pages/Directory/SearchRole";
import moment from "moment";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    position: "fixed",
    padding: "10px",
    height: "56px",
    marginBottom: "30px",
    alignItems: "center",
    background: "white",
    boxShadow: "0px 1px 4px 0px #0000001F",
    justifyContent: "center",
    zIndex: 99,
  },
  backButton: {
    position: "absolute",
    left: "20px",
    color: mobileTheme,
  },
  title: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#212121BF",
  },
  header: {
    width: "100%",
    display: "flex",
    position: "fixed",
    padding: "10px",
    height: "100px",
    marginBottom: "30px",
    alignItems: "center",
    background: "white",
    justifyContent: "start",
    zIndex: 99,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  profileContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "7px",
  },
  profileImage: {
    width: "40px",
    height: "40px",
    background: mobileTheme,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileImg: {
    width: "26px",
    height: "26px",
  },
  userInfoContainer: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    justifyContent: "start",
    gap: "1px",
  },
  userName: {
    fontSize: "16px",
    fontWeight: "700",
    color: "black",
  },
  welcomeText: {
    fontSize: "13px",
    color: "#78787880",
  },
  searchIconContainer: {
    width: "30px",
    height: "30px",
    background: `rgba(${hexToRgb(mobileTheme)}, 0.15)`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  searchIcon: {
    fontSize: "16px",
    color: mobileTheme,
  },
}));

function HrMobileTopNav(props) {
  const cookies = new Cookies();
  const location = useLocation();
  const classes = useStyles();
  const { searchQuery, updateSearchQuery } = useSearch();
  const [openModal, setOpenModal] = React.useState("");
  var ovUserName = cookies.get("ovUserName");
  var CurrentDate = moment(new Date).locale("en-Us").format("DD,MMMM, YYYY");

  const handleSearchChange = (e) => {
    updateSearchQuery(e.target.value);
  };
  const openSearchBar = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return location.pathname === "/hr/roles/addrole" ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/roles")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Add Role</div>
    </div>
  ) : location.pathname.startsWith("/hr/roles/editRole/") ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/roles")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Edit Role</div>
    </div>
  ) : location.pathname === "/hr/users/adduser" ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/users")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Add User</div>
    </div>
  ) : location.pathname.startsWith("/hr/users/edituser/") ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/users")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Edit User</div>
    </div>
  ) : location.pathname === "/hr/events" ? (
    <header className="EventListingHeader">Events</header>
  ) : location.pathname.startsWith("/hr/events/publicholiday") ? (
    <header className="eventDetailsTopNavRoot">
      <div
        className="eventDetailsTopNavContainer"
        style={{ background: `rgba(${hexToRgb(mobileTheme)}, 0.15)` }}
      >
        <div className="cornerPatternImageContainer">
          <img src={hrMobileHeaderPattern} alt="" />
        </div>
        <div className="detailContainer">
          <div className="title">Public Holiday</div>
          <div className="date">{CurrentDate}</div>
        </div>

        <EventSearchMobile
          value={searchQuery}
          handleSearch={handleSearchChange}
          placeholder={"Search holiday by title"}
        />
      </div>
    </header>
  ) : location.pathname === "/hr/events/add/publicholiday" ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/events/publicholiday")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Add Public Holiday</div>
    </div>
  ) : location.pathname.startsWith("/hr/events/edit/publicholiday") ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/events/publicholiday")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Edit Public Holiday</div>
    </div>
  ) : location.pathname === "/hr/v5/search/roles" ? (
    <div className={"hrMobileHeaderRoot"}>
      <div
        className={"backButton"}
        style={{ color: mobileTheme }}
        onClick={() => history.push("/hr/events/publicholiday")}
      >
        <MdOutlineArrowBackIosNew className={"backIcon"} />
      </div>
      <div className={"title"}>Add Public Holiday</div>
    </div>
  ) : (
    <>
      {openModal ? (
        <SearchRole
          classes={classes}
          handleClose={handleClose}
          open={openModal}
        />
      ) : (
        <header className={"hrMobileHeader"}>
          <div className={"headerContainerHR"}>
            <div className={"profileContainerHR"}>
              <div
                className={"profileImageHR"}
                style={{ background: mobileTheme }}
              >
                <img alt="profile" src={ProfileLogo} className={"profileImg"} />
              </div>
              <div className={"userInfoContainerHR"}>
                <div className={"userNameHR"}>
                  Hi,{ovUserName}
                </div>
                <div className={"welcomeTextHR"}>Welcome back</div>
              </div>
            </div>
            <div
              className={"searchIconContainerHR"}
              style={{ background: `rgba(${hexToRgb(mobileTheme)}, 0.15)` }}
            >
              <IoMdSearch
                onClick={openSearchBar}
                className={"searchIcon"}
                style={{ color: mobileTheme }}
              />
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default HrMobileTopNav;
