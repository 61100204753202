//Dashboard
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import productsdata from '../../utils/productsdata';
import { ProductCardMini } from '../../components'
const styles = (theme) => ({
  root: {
    padding: theme.spacing(4, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 4),
    },
  }
});


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDashboard: false,
      products: productsdata
    }
  }

  render() {
    const { classes, productModalToggle } = this.props;
    return (
      <Grid
        container
        spacing={4}
        className={classes.root}
      >
        {this.state.products.map((item, index) => {

          return (
            <Grid
              item
              key={index}
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={12}
            >
              <ProductCardMini
                isActive={1}
                product={item}
                productModalToggle={productModalToggle}
              />
            </Grid>
          )
        })}
      </Grid>
    );
  }
}

export default withStyles(styles)(Dashboard);