import { Typography } from "@material-ui/core";
import VideocamIcon from "@mui/icons-material/Videocam";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export const LessonTab = ({ id, lessonNo, selected, data, val }) => {
  return (
    <div>
      <div
        style={{
          // background: this.state.SlideIndex === index ? "#4E78A0" : "#ADD8E6",
          width: "200px",
          cursor: "pointer",
          border:
            selected === id
              ? "3px solid #1950DE"
              : "3px solid rgba(25, 80, 222, 0.2)",
          height: "120px",
          textAlign: "center",
          // lineHeight: "100px",
          boxSizing: "border-box",
          visibility: "block",
          borderRadius: "10px",
          position: "relative",
          marginLeft: "10px",
          marginRight: "10px",
          marginBottom: "10px",
          overflow: "hidden",
          zIndex: 10,
        }}
        // onClick={() => {
        //   this.handleFrame({
        //     type: val.lesson_type,
        //     content: val.content,
        //     video: val.videoUrl,
        //     file: val.documenturl,
        //   });
        // }}
        // key={index}
      >
        {val.lesson_type === 1 ? (
          <Typography
            style={{
              padding: "5px",
              overflow: "hidden",
              fontSize: "5px",
              lineHeight: "5px",
              textAlign: "left",
            }}
            //   dangerouslySetInnerHTML={{
            //     __html:
            //       "React Native is one of the most popular cross-platform app development frameworks. Using JavaScript, you can develop native apps for both Android and iOS. One important part of creating apps is being able to navigate between screens. In this tutorial, you'll learn how to use React Navigation in your app to add navigation. This will include basic navigation from one screen to another, going back to the previous screen, passing parameters, listening to events, and more. This tutorial will not explain the basics of creating a React Native app. If you're interested in learning about that, you can check out my other tutorial React Native Tutorial: Create Your First App. ",
            //   }}
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          >
            {/* <h2
            style={{
              padding: "5px",
              overflow: "hidden",
              fontSize: "12px",
              lineHeight: "5px",
              textAlign: "center",
            }}
          >
            React Native
          </h2>
          React Native is one of the most popular cross-platform app development
          frameworks. Using JavaScript, you can develop native apps for both
          Android and iOS. One important part of creating apps is being able to
          navigate between screens. In this tutorial, you'll learn how to use
          React Navigation in your app to add navigation. This will include
          basic navigation from one screen to another, going back to the
          previous screen, passing parameters, listening to events, and more.
          This tutorial will not explain the basics of creating a React Native
          app. If you're interested in learning about that, you can check out my
          other tutorial React Native Tutorial: Create Your First App. */}
          </Typography>
        ) : // <AssignmentIcon
        //   style={{ fontSize: "25px", color: "gray" }}
        // />:
        val.lesson_type === 2 ? ( //{" "}
          <VideocamIcon
            style={{
              fontSize: "25px",
              color: "gray",
              position: "absolute",
              bottom: "5px",
              right: "5px",
            }}
          />
        ) : (
          //{" "}
          <FileCopyIcon
            style={{
              fontSize: "25px",
              color: "gray",
              position: "absolute",
              bottom: "5px",
              right: "5px",
            }}
          />
        )}
        <Typography
          style={{
            position: "absolute",
            bottom: "-3px",
            left: "-3px",
            background: "#135098",
            width: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "41px",
            color: "white",
            padding: "5px 15px",
            border: "2px solid #135098",
            borderRadius: "0px 20px 0px 10px",
            fontWeight: "700",
            fontSize: "20px",
            zIndex: 20,
          }}
        >
          {id}
        </Typography>
      </div>
    </div>
  );
};
