import {
  SET_LESSON_LOADING,
  SET_ALL_LESSON,
  ADD_LESSON,
  EDIT_LESSON,
  DELETE_LESSON,
  SET_FETCH_LESSON_LOADING,
  FETCH_LESSON_BY_ID,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_LOADING,
} from "./constants";

import { alert, invalidToken } from "../../../../utilities";
import { lessonServices } from "./services";

export function fetchAllLessons(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_LESSON_LOADING,
        payload: true,
      });
    }

    lessonServices.fetchAllLessons().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_ALL_LESSON,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchLessonByID(ID) {
  return function (dispatch) {
    dispatch({
      type: SET_FETCH_LESSON_LOADING,
      payload: true,
    });

    lessonServices.fetchLessonByID(ID).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: FETCH_LESSON_BY_ID,
          payload: response.data,
        });
      }
    });
  };
}

export function deleteData(_id) {
  // var data = {
  // _id
  // pushStatus: parseInt(pushStatus)
  // }
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_LOADING,
      payload: true,
    });
    lessonServices.deleteData(_id).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: DELETE_LESSON,
          payload: _id,
        });
      }
    });
  };
}

export function addData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_LOADING,
      payload: true,
    });
    lessonServices.addData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: ADD_LESSON,
          payload: response.data.post,
        });
      }
    });
  };
}

export function editData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_LOADING,
      payload: true,
    });
    lessonServices.editData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: EDIT_LESSON,
          payload: response.data.post,
        });
      }
    });
  };
}

export function allCategories() {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_CATEGORIES_LOADING,
      payload: true,
    });
    lessonServices.allCategories().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: response.data.categoryData,
        });
      }
    });
  };
}
