import * as React from "react";
import Button from "@mui/material/Button";

export const FilledButton = ({
  text,
  icon,
  bgColor,
  textColor,
  size,
  onClick,
  textSize,
}) => {
  const calculateFontSize = () => {
    const ratio = 5;
    const fontSize = Math.min(size.width * ratio, size.height * ratio);
    return `${fontSize}px`;
  };

  const buttonStyle = {
    backgroundColor: bgColor,
    color: textColor,
    textTransform: "capitalize",
    borderRadius: "7px",
    fontWeight: "700",
    width: size?.width ? size.width : "110px",
    height: size?.height ? size.height : "40px",
    fontSize: textSize || "15px",
  };

  return (
    <Button startIcon={icon ? icon : ""} style={buttonStyle} onClick={onClick}>
      {text}
    </Button>
  );
};
