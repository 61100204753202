

import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";
class Example extends React.Component {

  render() {
    var value = moment.range(this.props.startDate, this.props.endDate)
    return (
      <div style={{
        padding: "10px 0 0 0",
        display: "flex",
        justifyContent: "center"
      }}>
        <DateRangePicker
          locale="en-US"
          value={value}
          onSelect={this.props.onSelectStartEndDate}
          singleDateRange={true}
        />
      </div>
    );
  }
}

export default Example;
