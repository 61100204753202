import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  Card: {
    cursor: "pointer",
    height: '200px',
    margin: '10px',
    borderRadius: '5px',
    // boxShadow: "0px 12px 24px rgba(44, 39, 56, 0.1), 0px 24px 48px rgba(44, 39, 56, 0.12)",
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    }
  },
  media: {
    borderRadius: '5px',
    margin: "16px",
    backgroundSize: '100% 100%',
    height: 120
  },
  CardContent: {
    padding: "0 16px"
  },
  courseTitle: {
    textAlign: "center",
    color: "#2C2738",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "21px"
  }
}));

const LessonTypeCard = props => {
  const { data } = props;
  const classes = useStyles();
  return (
    <Card
    onClick={props.setSelectType}
    className={classes.Card}>
      <CardMedia
        className={classes.media}
        image={data.image}
      />

      <CardContent className={classes.CardContent} >
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Typography
              className={classes.courseTitle}
              component="p"
              gutterBottom
            >
              {data.title}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LessonTypeCard;