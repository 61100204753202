import {
  GET_LOCATION_DETAIL_DATA,
  GET_STATE_DATA,
  GET_CITY_DATA,
  GET_SCHEDULE_SETTING_DATA,
  UPDATE_SCHEDULE_SETTING_DATA,
  UPDATE_LOCATION_DETAIL_DATA,
  SCHEDULER_SETTINGS_LOADING,
  GET_BREAK_DATA,
  DELETE_BREAK_DATA,
  ADD_WORK_SCEDULER,
  GET_ROLE_DATA,
} from "./constants";
import { alert, invalidToken } from "../../../../utilities";
import { settingsServices } from "./services";

export function getLocationdata(lid) {
  return function (dispatch) {
    settingsServices.getLocationdata(lid).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_LOCATION_DETAIL_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function getStateData(data) {
  return function (dispatch) {
    settingsServices.getStatedata(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_STATE_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function getCityData(data) {
  return function (dispatch) {
    settingsServices.getCityData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_CITY_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function updateLocationdata(data) {
  return function (dispatch) {
    dispatch({
      type: SCHEDULER_SETTINGS_LOADING,
      payload: true,
    });
    settingsServices.updateLocationdata(data).then((response) => {
      alert.success(response.data.message);
      dispatch({
        type: UPDATE_LOCATION_DETAIL_DATA,
        payload: response.data,
      });
    });
  };
}

export function fetchRoleData() {
  return function (dispatch) {
    dispatch({
      type: GET_ROLE_DATA,
      payload: true,
    });
    settingsServices.fetchRoleData().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_ROLE_DATA,
          payload: response.data.RoleData,
        });
      }
    });
  };
}
export function getScheduleData(lid) {
  return function (dispatch) {
    settingsServices.getScheduleData(lid).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_SCHEDULE_SETTING_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function updateScheduleData(data) {
  return function (dispatch) {
    dispatch({
      type: SCHEDULER_SETTINGS_LOADING,
      payload: true,
    });
    settingsServices.updateScheduleData(data).then((response) => {
      alert.success(response.data.message);
      dispatch({
        type: UPDATE_SCHEDULE_SETTING_DATA,
        payload: response.data,
      });
    });
  };
}

export function getBreakData(data) {
  return function (dispatch) {
    settingsServices.getBreakData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_BREAK_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function deleteBreakData(data) {
  return function (dispatch) {
    dispatch({
      type: SCHEDULER_SETTINGS_LOADING,
      payload: true,
    });
    settingsServices.deleteBreakData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: DELETE_BREAK_DATA,
          payload: response,
        });
      }
    });
  };
}

export function addWorkScheduler(data) {
  return function (dispatch) {
    settingsServices.addWorkScheduler(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: ADD_WORK_SCEDULER,
          payload: response.data,
        });
      }
    });
  };
}
