// components
import Modal from "@mui/material/Modal";

// icons
import { Button, Grid, Typography } from "@mui/material";
import { BiArrowBack } from "react-icons/bi";
import { useEffect, useState } from "react";
// import { withStyles } from "@material-ui/core/styles";

import "./SlideQuiz.scss";

// const useStyles = makeStyles((theme) => styles(theme));

const SlideQuiz = ({
  open,
  handleClose,
  // classes,
  // isQuestionSubmit,
  // correct_answer,
  questionCompleted,
  user_correct_answer,
  handleUserAnswer,
  // active_lesson_details,
  // questionLoading,
  slide_questions,
  active_question_index,
  // closeQuestionModal,
  // totalAttempts,
  // quizSummary,
}) => {
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const shuffleOptions = (question) => {
    const optionsArray = [
      { value: 1, option: question.option1 },
      { value: 2, option: question.option2 },
      { value: 3, option: question.option3 },
      { value: 4, option: question.option4 },
    ];

    const shuffledArray = [...optionsArray].sort(() => Math.random() - 0.5);

    return shuffledArray;
  };

  useEffect(() => {
    // Check if slide_questions or active_question_index has been updated
    if (slide_questions) {
      const fetchDataAndShuffle = async () => {
        try {
          console.log(slide_questions, "Slide Questions...");
          setIsLoading(true);

          if (slide_questions) {
            let array = shuffleOptions(slide_questions[active_question_index]);

            setShuffledOptions(array);
            setIsLoading(false);
          } else {
            console.error(
              "slide_questions is undefined or does not have enough items."
            );
          }
        } catch (error) {
          console.error("Error in fetchDataAndShuffle:", error);
          setIsLoading(false);
        }
      };
      fetchDataAndShuffle();
    }
  }, [slide_questions, active_question_index]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"slideQuizMainContainer"}
      >
        <div className={"slideQuizContentWrapper"}>
          <div className={"slideQuizContentAlignmentContainer"}>
            <div className={"slideQuizBackArrowWrapper"}>
              <BiArrowBack
                size={20}
                className={"slideQuizMainBackIcon"}
                onClick={handleClose}
              />
            </div>

            {slide_questions &&
              slide_questions.map((items, index) => {
                return (
                  active_question_index === index && (
                    <Grid key={index} container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <div
                          className={
                            "d-flex align-items-center justify-content-center " +
                            "slideQuizTopRow"
                          }
                        ></div>
                        <div
                          // fullWidth
                          component="fieldset"
                        >
                          <div className={"slideQuizQuestionContainer"}>
                            <p className={"slideQuizQuestionHeading"}>
                              Question {index + 1}/{slide_questions.length}
                            </p>
                            <p className={"slideQuizQuestion"}>
                              {items.question}
                            </p>
                          </div>

                          <div className={"slideQuizOptionsContainer"}>
                            {shuffledOptions &&
                              shuffledOptions.map((option, index) => (
                                <div
                                  key={option.value}
                                  style={{
                                    background:
                                      option.value === user_correct_answer
                                        ? "#EDEDED"
                                        : "#FFFFFF",
                                  }}
                                  className={"slideQuizOptionWrapper"}
                                  onClick={() =>
                                    handleUserAnswer(
                                      option.value,
                                      items.correct_answer
                                    )
                                  }
                                >
                                  <div className={"slideQuizAlphabetChar"}>
                                    {String.fromCharCode(65 + index)}.
                                  </div>
                                  <div className={"slideQuizOptionText"}>
                                    {option.option}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </Grid>

                      <Grid item md={12} xs={12} mt={"10%"}>
                        {slide_questions.length - 1 ===
                        active_question_index ? (
                          <Button
                            variant="outlined"
                            className={"slideQuizBtn"}
                            color="primary"
                            size="medium"
                            disabled={user_correct_answer ? false : true}
                            onClick={() => {
                              // this.setState({ isLoading: true });
                              questionCompleted(
                                1,
                                items.if_wrong_answer,
                                items._id,
                                user_correct_answer,
                                index
                              );
                            }}
                          >
                            <Typography className={"slideQuizText"}>
                              Submit
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            className={"slideQuizBtn"}
                            // size="medium"
                            disabled={user_correct_answer ? false : true}
                            onClick={() => {
                              // this.setState({ isLoading: true });
                              questionCompleted(
                                0,
                                items.if_wrong_answer,
                                items._id,
                                user_correct_answer,
                                index
                              );
                            }}
                          >
                            <Typography className={"slideQuizText"}>
                              Next&nbsp;Question
                            </Typography>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )
                );
              })}
          </div>
        </div>
      </Modal>

      {/* <SlideDetailModal
        open={openSlideDetail}
        handleClose={handleCloseSlideDetail}
      /> */}
    </>
  );
};

export default SlideQuiz;
