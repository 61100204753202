import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { history } from "../../../../../history";
import { ProfileSample } from "../../../../../assets/images/mobileView/profile";
import { Typography } from "@material-ui/core";
import { mobileTheme } from "../../../constant";
import "./AddNoteHeader.scss";

const AddNoteHeader = ({ userName }) => {
  return (
    <div className={"AddNoteHeaderRoot"} style={{ background: mobileTheme }}>
      <div className={"AddNoteHeaderLeftSection"}>
        <div onClick={() => history.goBack()}>
          <FaChevronLeft className={"AddNoteHeaderArrowIcon"} />
        </div>
        <div className={"AddNoteHeaderProfileContainer"}>
          <div>
            <img
              alt=""
              src={ProfileSample}
              className={"AddNoteHeaderProfileImage"}
            />
          </div>
          <div className={"AddNoteHeaderUserDetails"}>
            <Typography className={"AddNoteHeaderUserName"}>
              Add notes
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoteHeader;
