import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { SlideTab } from "../../grading/SlideTab";
import { SlideDetailModal } from "./SlideDetailModal";
import { useState } from "react";
import "./SlidesModal.scss";

export const SlidesModal = ({ open, handleClose, LessonDetail }) => {
  const [openSlideDetail, setOpenSlideDetail] = useState(false);
  const [slideIndex, setSlideIndex] = useState();

  const handleSlideDetail = (index) => {
    setSlideIndex(index);
    setOpenSlideDetail(true);
  };

  const handleCloseSlideDetail = () => {
    setOpenSlideDetail(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"SlideModalRoot"}
      >
        <div className={"SlideModalModalContainer"}>
          <div className={"SlideModalHeaderContainer"}>
            <Typography
              className={"SlideModalSlideNumber"}
              fontSize={"16px"}
              fontWeight={700}
              color={"#1B3F8F"}
            >
              {LessonDetail?.lesson_name}
            </Typography>
            <CloseIcon
              className={"SlideModalCloseIcon"}
              onClick={handleClose}
            />
          </div>

          <div className={"SlideModalContentContainer"}>
            <div className={"SlideModalSlideTabsContainer"}>
              {LessonDetail
                ? LessonDetail?.lessonData?.map((slide, index) => (
                    <div
                      key={index}
                      className={"SlideModalSlideTabWrapper"}
                      onClick={() => handleSlideDetail(index)}
                    >
                      <SlideTab
                        data={slide}
                        lesson_type={
                          slide.lesson_type === 1
                            ? 1
                            : slide.lesson_type === 2
                            ? 2
                            : slide.lesson_type === 3 && 3
                        }
                        id={index + 1}
                      />
                    </div>
                  ))
                : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                    <div
                      key={index}
                      className={"SlideModalSlideTabWrapper"}
                      onClick={() => handleSlideDetail([])}
                    >
                      <SlideTab
                        data={
                          "React Native is one of the most popular cross-platform app development frameworks. Using JavaScript, you can develop native apps for both Android and iOS. One important part of creating apps is being able to navigate between screens. In this tutorial, you'll learn how to use React Navigation in your app to add navigation. This will include basic navigation from one screen to another, going back to the previous screen, passing parameters, listening to events, and more. This tutorial will not explain the basics of creating a React Native app. If you're interested in learning about that, you can check out my other tutorial React Native Tutorial: Create Your First App."
                        }
                        lesson_type={1}
                        id={index}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </Modal>

      {LessonDetail && (
        <SlideDetailModal
          open={openSlideDetail}
          handleClose={handleCloseSlideDetail}
          selectedSlide={slideIndex}
          LessonDetail={LessonDetail}
        />
      )}
    </>
  );
};
