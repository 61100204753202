import React, { createContext, useContext, useEffect, useState } from "react";

const gradingLessonContext = createContext();

export const GradingLessonContextProvider = ({ children }) => {
  const [userName, setUserNameState] = useState(() => {
    // Retrieve userName from localStorage, or set a default value
    return localStorage.getItem("userName") || "";
  });

  const [role, setRoleState] = useState(() => {
    // Retrieve role from localStorage, or set a default value
    return localStorage.getItem("role") || "";
  });

  const setUserName = (newUserName) => {
    setUserNameState(newUserName);
    localStorage.setItem("userName", newUserName);
  };

  const setRole = (newRole) => {
    setRoleState(newRole);
    localStorage.setItem("role", newRole);
  };

  return (
    <gradingLessonContext.Provider
      value={{ userName, setUserName, role, setRole }}
    >
      {children}
    </gradingLessonContext.Provider>
  );
};

export const useGradingLessonDetail = () => {
  const context = useContext(gradingLessonContext);
  if (!context) {
    throw new Error(
      "useGradingLessonDetail must be used within a GradingLessonContextProvider"
    );
  }
  return context;
};
