import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import _ from "lodash";
import './Hierarachy.css';
import AddIcon from '@mui/icons-material/Add';
import CommonModal from "./CommonModal";
import MoreMenuDropdown from "./MoreMenuDropdown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fetchDataRoles, fetchData, deleteHeirarachyChart, fetchHeirarachyChartData, editHeirarachyChart,
  addHeirarachyChart, fetchRoleData
} from "./modules/action";
import { connect } from "react-redux";
import AddLineModal from "./AddLineModal";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    width: 200,
    margin: 'auto',
    // display: "inline-block",
    borderRadius: 16,
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#977c4f"
  },
  titleModal: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: "space-between"
  },
  cardHeader: {
    cursor: 'pointer'
  },
  firstDiv: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  addIcon: {
    background: '#595fe7',
    color: "white",
    cursor: "pointer",
    borderRadius: "0 150px 150px 0",
    height: 29,

    '& svg': {
      marginTop: 4,
      fontSize: 20,
      marginLeft: -4,
    },
  },
  emaptyCard: {
    width: 100,
    height: 40
  }
}));

function SubTitleChart(props) {
  const { a, usersData, fetchData, heirarachyData, deleteHeirarachyChart,
    editHeirarachyChart, fetchHeirarachyChartData, addHeirarachyChart, RoleData, fetchRoleData } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editRoleModal, setEditRoleModal] = React.useState(false);
  const [deleteRoleModal, setDeleteRoleModal] = React.useState(false);
  const [assignHelpdeskModal, setAssignHelpdeskModal] = React.useState(false);
  const [valueTab, setValueTab] = React.useState('1');
  const [openAdd, setOpenAdd] = React.useState(null);
  const [openTopAdd, setOpenTopAdd] = React.useState(null);
  const [addLineModal, setAddLineModal] = React.useState(false);
  const [isAbove, setIsAbove] = React.useState("");
  const [styleAddIcon, setStyleAddIcon] = useState({ display: 'none' });
  const [userInputValue, setUserInputValue] = React.useState('');
  const [roleInputValue, setRoleInputValue] = React.useState('');
  const [cardStyleBorder, setCardStyleBorder] = useState({ border: '1px solid rgba(0, 0, 0, 0.12)' });
  const [inputsValue, setInputsValue] = useState({
    name: '',
    title: a ? a.title : '',
    id: a.id
  });

  let newArrays = {}
  heirarachyData && heirarachyData.map((item) => {
    let returmName = item.departments && item.departments.map((row) => {
      let accountArray = row.department_role && row.department_role.map((child) => {
        let productArray = child && child.department_role_assis.map((parentItem) => {
          let user_obj = {
            name: parentItem.user_id && parentItem.user_id.user_name,
            id: parentItem && parentItem._id,
            title: parentItem.role_id && parentItem.role_id.role_name,
          }
          return user_obj
        })
        let child_obj = {
          name: child.user_id && child.user_id.user_name,
          id: child && child._id,
          title: child.role_id && child.role_id.role_name,
          product: productArray
        }
        return child_obj
      })
      let reurn_obj = {
        name: row && row.department_name,
        colorcode: row.colorcode,
        id: row && row._id,
        account: accountArray,
      }
      return reurn_obj
    })

    let assistantArray = item.assiatant && item.assiatant.map((item) => {
      let assistant_obj = {
        name: item.user_id && item.user_id.user_name,
        id: item && item._id,
        title: item.role_id && item.role_id.role_name,
      }
      return assistant_obj
    })
    newArrays = {
      name: item.user_id && item.user_id.user_name,
      title: item.role_id && item.role_id.role_name,
      id: item.user_id && item._id,
      assiatant: assistantArray,
      organizationChildRelationship: returmName,
    }
  })

  useEffect(() => {
    setInputsValue(a);
  }, [a]);

  useEffect(() => {
    fetchData();
    fetchRoleData();
  }, [])

  //edit user onchange 
  const handleChange = (event) => {
    setUserInputValue(event.target.value);
  };

  //edit role onchange 
  const handleChangeRole = (event) => {
    setRoleInputValue(event.target.value);
  };

  //onChange Input Click
  const onChangeInput = (e) => {
    e.persist();
    setInputsValue(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
  }

  //add icon handle click
  const open = Boolean(openAdd);

  const handleCloseAddIcon = () => {
    setOpenAdd(null);
  };

  const handleAddRoles = (org) => {
    // newArrays.organizationChildRelationship.forEach(element => {
    //   element && element.account.forEach(item => {
    //     if (item.name === org) {
    // let newArray = [...item.product, {
    //   "name": "",
    //   "title": "",
    //   "id": ""
    // }]
    // item.product = newArray
    const data = {
      user_id: null,
      role_id: null,
      parent: [a.id],
      isroot: 0,
      isrole: 0,
      isassistant: 1,
      isdepartment: 0,
      isuser: 0
    }
    addHeirarachyChart(data);
    setTimeout(() => {
      fetchHeirarachyChartData();
    }, 1000)
    setOpenAdd(null);
    //     }
    //   })
    // })

  };

  const handleClickAddIcon = (event) => {
    setOpenAdd(event.currentTarget);
  };

  //Top Icon handle click
  const openTop = Boolean(openTopAdd);
  const handleCloseAddTopIcon = () => {
    setOpenTopAdd(null);
  };

  const handleClickAddTopIcon = (event) => {
    setOpenTopAdd(event.currentTarget);
  };

  //more menu handle click
  const handleClick = (event) => {
    let selectedUser;
    usersData && usersData.forEach(item => {
      if (item.user_name === inputsValue.name) {
        selectedUser = item
      }
    })

    let selectedRole;
    RoleData && RoleData.forEach(item => {
      if (item.role_name === inputsValue.title) {
        selectedRole = item
      }
    })
    setUserInputValue(selectedUser && selectedUser._id);
    setRoleInputValue(selectedRole && selectedRole._id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //add Dotted line modal onclick 
  const handleClickAddLineOpen = (title) => {
    setIsAbove(title);
    setAddLineModal(true);
  };
  const handleAddLineClose = () => {
    setAddLineModal(false);
  };

  //edit modal handle click
  const onHandleOpenEditModal = () => {
    setEditRoleModal(true);
    setAnchorEl(null);
  };

  const onHandleCloseEditModal = () => {
    setEditRoleModal(false);
  };

  //delete modal handle click
  const onHandleOpenDeleteModal = () => {
    setDeleteRoleModal(true);
    setAnchorEl(null);
  };

  const onHandleCloseDeleteModal = () => {
    setDeleteRoleModal(false);
  };

  //AssignHelpDesk modal handle click
  const onHandleOpenAssignHelpdesk = () => {
    setAssignHelpdeskModal(true);
    setAnchorEl(null);
  };

  const onHandleCloseAssignHelpdesk = () => {
    setAssignHelpdeskModal(false);
  };

  //Tab handle change
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  //onDelete function
  const onHandleDeleteClick = (id) => {
    deleteHeirarachyChart(id);
    setTimeout(() => {
      fetchHeirarachyChartData();
    }, 1000)
  }

  //onEdit Click function
  const onHandleEditClick = (editValue, userName, item, roleName) => {
    let firstUser = usersData[0] && usersData[0].user_name
    let firstUserIDs = usersData[0] && usersData[0]._id
    let firstRole = RoleData[0] && RoleData[0].role_name
    let firstRoleIDs = RoleData[0] && RoleData[0]._id
    const data = {
      role_name: roleName !== undefined ? roleName : editValue.title === "" ? firstRole : editValue.title,
      user_name: userName !== undefined ? userName : editValue.name === "" ? firstUser : editValue.name,
      user_id: userInputValue ? userInputValue : firstUserIDs ? firstUserIDs : null,
      role_id: roleInputValue ? roleInputValue : firstRoleIDs ? firstRoleIDs : null,
      _id: a.id
    }
    setInputsValue({
      name: userName !== undefined ? userName : editValue.name === "" ? firstUser : editValue.name,
      title: roleName !== undefined ? roleName : editValue.title === "" ? firstRole : editValue.title,
      id: a.id
    })
    editHeirarachyChart(data);
    setTimeout(() => {
      fetchHeirarachyChartData();
    }, 1000)
  }

  return (
    <div
      // className={classes.firstDiv}
      onMouseEnter={e => {
        setStyleAddIcon({ display: 'inline' });
        setCardStyleBorder({ border: '3px solid #595fe7' })
      }}
      onMouseLeave={e => {
        setStyleAddIcon({ display: 'none' });
        setCardStyleBorder({ border: '1px solid rgba(0, 0, 0, 0.12)' })
      }}>

      <div className={"topIcon"}>
        <AddIcon onClick={(event) => handleClickAddTopIcon(event)} style={styleAddIcon} />
        <Menu
          id="basic-menu"
          anchorEl={openTopAdd}
          open={openTop}
          onClose={handleCloseAddTopIcon}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => handleAddRoles(a.name)}>Add Assistant</MenuItem>
          {/* <MenuItem onClick={() => handleClickAddLineOpen("isAbove")}>Add Dotted Line</MenuItem> */}
        </Menu>
      </div>

      <Card
        variant="outlined"
        className={classes.root}
        style={cardStyleBorder}
      >
        <div className="cardHeaderTitle">
          <div className="titleName">{a.title}</div>
          <div className="">
            <IconButton size="small" onClick={(event) => { handleClick(event) }}>
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>

        {userInputValue || a.name ?
          <CardHeader
            avatar={
              <Avatar className={classes.avatar}
              // onClick={onHandleOpenEditModal}
              >
                <AccountCircleIcon />
              </Avatar>
            }
            title={
              <div className={classes.cardHeader}>
                <div
                  // onClick={(event) => { onHandleOpenEditModal(event) }}
                  className={classes.cardHeader}>
                  <div className="cardInnerTitle">
                    {a.name}
                  </div>
                </div>
              </div>
            }
          />
          : <CardHeader
            className={classes.emaptyCard}
          />}
        <MoreMenuDropdown anchorEl={anchorEl} handleClose={handleClose}
          onHandleOpenEditModal={onHandleOpenEditModal}
          onHandleOpenDeleteModal={onHandleOpenDeleteModal}
          onHandleOpenAssignHelpdesk={onHandleOpenAssignHelpdesk} />

        <CommonModal onHandleCloseEditModal={onHandleCloseEditModal} editRoleModal={editRoleModal}
          valueTab={valueTab} handleChange={handleChangeTab} onHandleCloseDeleteModal={onHandleCloseDeleteModal}
          deleteRoleModal={deleteRoleModal} assignHelpdeskModal={assignHelpdeskModal} oneItem={a}
          handleChanges={handleChange} userInputValue={userInputValue} usersData={usersData}
          RoleData={RoleData} handleChangeRole={handleChangeRole} roleInputValue={roleInputValue}
          onHandleEditClick={onHandleEditClick} onChangeInput={onChangeInput} inputsValue={inputsValue}
          onHandleCloseAssignHelpdesk={onHandleCloseAssignHelpdesk} onHandleDeleteClick={onHandleDeleteClick}
        />
      </Card>

      <AddLineModal handleAddLineClose={handleAddLineClose} addLineModal={addLineModal}
        handleChanges={handleChange} userInputValue={userInputValue} isAbove={isAbove}
      />

      <div>
        <div className={"bottomIcon"} style={styleAddIcon}>
          <AddIcon onClick={(event) => handleClickAddIcon(event)} />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={openAdd}
          open={open}
          onClose={handleCloseAddIcon}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => handleAddRoles(a.name)}>Add Assistant</MenuItem>
          {/* <MenuItem onClick={handleCloseAddIcon}>Add New Branch</MenuItem>
          <MenuItem onClick={handleClickAddLineOpen}>Add Dotted Line</MenuItem> */}
        </Menu>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  usersData: state.HierarachyChart.usersData,
  RoleData: state.HierarachyChart.RoleData,
  addRolesData: state.HierarachyChart.addRolesData,
  heirarachyData: state.HierarachyChart.heirarachyData,
});


const ConnectWith = connect(mapStateToProps, {
  addHeirarachyChart, fetchRoleData, fetchDataRoles, editHeirarachyChart, fetchData,
  fetchHeirarachyChartData, deleteHeirarachyChart
})(SubTitleChart);
export default (ConnectWith);
