import {
  GET_MY_EARNING_LOADING,
  GET_MY_EARNING_DATA,
  GET_TEAM_EARNING_LOADING,
  GET_TEAM_EARNING_DATA,
  GET_USER_EARNING_LOADING,
  GET_USER_EARNING_DATA,
  GET_USER_PAYMENT_LOGS_LOADING,
  GET_USER_PAYMENT_LOGS_DATA,
  GET_EARNING_COMMENTS_LOADING,
  GET_EARNING_COMMENTS_DATA,
  GET_ALL_EARNING_LESSONS_LOADING,
  GET_ALL_EARNING_LESSONS_DATA,
  PAY_USER_EARNING_LOADING,
  PAY_USER_EARNING,
  ADD_EARNING_COMMENT_LOADING,
  ADD_EARNING_COMMENT,
  GET_EARNING_USER_DATA,
  GET_EARNING_USER_LOADING,
} from "./constants";

const initialState = {
  myEarningLoading: false,
  myEarningData: {},
  teamEarningLoading: false,
  teamEarningData: {},
  userEarningLoading: false,
  userEarningData: {},
  userPaymentLogsLoading: false,
  userPaymentLogsData: {},
  earningCommentsLoading: false,
  earningCommentsData: {},
  allEarningLessonsLoading: false,
  allEarningLeassonsData: [],
  payUserEarningLoading: false,
  payUserEarningData: {},
  addEarningCommentLoading: false,
  addEarningCommentData: {},
  earningEmployeesData: [],
  earningEmployeesLoading: false,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MY_EARNING_LOADING:
      return {
        ...state,
        myEarningLoading: payload,
      };
    case GET_MY_EARNING_DATA:
      return {
        ...state,
        myEarningLoading: false,
        myEarningData: payload,
      };
    case GET_TEAM_EARNING_LOADING:
      return {
        ...state,
        teamEarningLoading: payload,
      };
    case GET_TEAM_EARNING_DATA:
      return {
        ...state,
        teamEarningLoading: false,
        teamEarningData: payload,
      };
    case GET_EARNING_USER_LOADING:
      return {
        ...state,
        earningEmployeesLoading: payload,
      };
    case GET_EARNING_USER_DATA:
      return {
        ...state,
        earningEmployeesLoading: false,
        earningEmployeesData: payload.result,
      };
    case GET_USER_EARNING_LOADING:
      return {
        ...state,
        userEarningLoading: payload,
      };
    case GET_USER_EARNING_DATA:
      return {
        ...state,
        userEarningLoading: false,
        userEarningData: payload,
      };
    case GET_USER_PAYMENT_LOGS_LOADING:
      return {
        ...state,
        userPaymentLogsLoading: payload,
      };
    case GET_USER_PAYMENT_LOGS_DATA:
      return {
        ...state,
        userPaymentLogsLoading: false,
        userPaymentLogsData: payload,
      };
    case GET_EARNING_COMMENTS_LOADING:
      return {
        ...state,
        earningCommentsLoading: payload,
      };
    case GET_EARNING_COMMENTS_DATA:
      return {
        ...state,
        earningCommentsLoading: false,
        earningCommentsData: payload,
      };
    case GET_ALL_EARNING_LESSONS_LOADING:
      return {
        ...state,
        allEarningLessonsLoading: payload,
      };
    case GET_ALL_EARNING_LESSONS_DATA:
      return {
        ...state,
        allEarningLessonsLoading: false,
        allEarningLeassonsData: payload,
      };
    case PAY_USER_EARNING_LOADING:
      return {
        ...state,
        payUserEarningLoading: payload,
      };
    case PAY_USER_EARNING:
      return {
        ...state,
        payUserEarningLoading: false,
        payUserEarningData: payload,
      };
    case ADD_EARNING_COMMENT_LOADING:
      return {
        ...state,
        addEarningCommentLoading: payload,
      };
    case ADD_EARNING_COMMENT:
      return {
        ...state,
        addEarningCommentLoading: false,
        addEarningCommentData: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
