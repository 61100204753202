import {
    SET_HOLIDAY_LOADING,
    SET_HOLIDAY,
    ADD_USERS,
    EDIT_USERS,
    DELETE_USERS,
    SET_OV_GET_LOCATIONS,
  } from "./constants";
  import { alert, invalidToken } from '../../../../utilities';
  import { usersServices } from './services'
  import { COOKIE_MAX_AGE } from '../../../../config'
  import Cookies from 'universal-cookie';
  import { history } from "../../../../history"
  const cookies = new Cookies();
  

  export function fetchData(isReload,page) {
    return function (dispatch) {
        dispatch({
          type: SET_HOLIDAY_LOADING,
          payload: true
        });
      usersServices.fetchData(page)
        .then(response => {
          if (response.data.success === 2) {
            invalidToken(response.data)
          } else {
            dispatch({
              type: SET_HOLIDAY,
              payload: response.data
            });
          }
        });
    };
  }
  
  export function deleteData(_id) {
    return function (dispatch) {
      usersServices.deleteData(_id)
        .then(response => {
          if (response.data.success === 2) {
            invalidToken(response.data.message)
          } else {
            alert.success(response.data.message)
            dispatch({
              type: DELETE_USERS,
              payload: _id
            });
          }
        });
    };
  }
  
  
  export function addData(data) {
    return function (dispatch) {
      usersServices.addData(data)
        .then(response => {
          if (response.data.success === 2) {
            invalidToken(response.data.message)
          }
          else {
            alert.success(response.data.message)
            dispatch({
              type: ADD_USERS,
              payload: response.data.post
            });
          }
        });
    };
  }
  export function fetchlocationData( isReload) {
    return function (dispatch) {
        if (isReload) {
            dispatch({
                type: SET_HOLIDAY_LOADING,
                payload: true
              });
          }
          usersServices.fetchlocationData().then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          dispatch({
            type: SET_OV_GET_LOCATIONS,
            payload: response.data.LocationData
          });
        }
      });
    };
  }

  export function editData(data, _id) {
    return function (dispatch) {
      usersServices.editData(data, _id)
        .then(response => {
          if (response.data.success === 2) {
            invalidToken(response.data.message)
          } else {
            alert.success(response.data.message)
            dispatch({
              type: EDIT_USERS,
              payload: response.data.post
            });
            // window.location.href = '/hr/users'
          }
        });
    };
  }