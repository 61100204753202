import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export function MobileDatePicker({ placeholder,onChange,onFocus,value }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={["DatePicker"]}> */}
      <DatePicker
        label={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        sx={{
          width: "100%",
          //   borderRadius: "8px",
          //   border: "1px solid #E3E3E3",
          color: "#00000080",
 
          background: "transparent",
          // "& .MuiTextField-root": {
          //   maxWidth: "320px",
          // },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            // border: "1px solid #E3E3E3",
            height: "40px",
          },
        }}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}
