import {
  SET_TIMECARD_LOADING,
  SET_TIMECARD
} from "./constants";

const initialState = {
  isLoading: true,
  usersData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMECARD_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TIMECARD:
      return {
        ...state,
        isLoading: false,
        usersData: payload.usersData,
      };
    default:
      return state;
  }
};

export default Reducer;