//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const styles = (theme) => ({
        label: {
                fontSize: '18px',
                fontWeight: 500
        },
        input: {
                //padding: '5px 14px !important',
                fontSize: '16px',
                fontWeight: 500,
                backgroundColor: '#f4f2f26b !important',
                borderRadius: 5,
                marginBottom: 0
        },
        labelColor: {
                // color: '#b6b5b5'
                color: 'gray'
        }
});

class MyDatePicker extends Component {
        render() {
                return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>

      <TimePicker
                                        fullWidth
                                        style={{ width: this.props.fixWidth ? "95px" : "100%" }}
                                        label={this.props.label}
                                        name={this.props.name}
                                        key={this.props.key}
                                        placeholder={this.props.placeholder}
                                        onChange={this.props.onChange}
                                        value={dayjs(this.props.value)}
                                        variant="inline"
                                        size="small"
                                        renderInput={this.props.renderInput}
                                        inputVariant="outlined"
                                        error={this.props.error}
                                        helperText={this.props.helperText}
                                // format={"hh:mm a"}
                                />

    </LocalizationProvider>
             
                )
        }
}

export default withStyles(styles)(MyDatePicker);