import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import {
    KeyboardArrowRight as RightIcon,
    KeyboardArrowDown as DownIcon
} from '@material-ui/icons';
import { FILE_URL, schedulerColor } from '../../../config';
import {
    Avatar,
    Collapse,
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Paper,
    Grid
}
    from '@material-ui/core';
import moment from "moment";
import { utcDateToLocal, utcToLocal } from '../../../utilities';

const styles = (theme) => ({
    root: {
        padding: 5
    },
    TableRowHover: {
        padding: 0
    },
    userCell: {
        padding: '5px 10px'
    },
    listCell: {
        padding: '10px',
        borderBottom: 'none'
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "40px",
        width: "40px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: schedulerColor.main,
        color: "white",
    },
    progress: {
        height: '7px',
        borderRadius: '50px',
        backgroundColor: '#ffffff'
    },
    barColorGreen: {
        backgroundColor: 'green'
    },
    barColorRed: {
        backgroundColor: 'red'
    },
    barColorOrange: {
        backgroundColor: 'orange'
    }
})

class ColleapseTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            headCells: [
                {
                    id: 'lesson_name',
                    label: "name",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'lesson_level',
                    label: "Difficult Level",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'created_at',
                    label: "Created at",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'updated_at',
                    label: "Updated at",
                    numeric: false,
                    align: "left"
                }
            ],
            usersData: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((JSON.stringify(prevProps.usersData) !== JSON.stringify(this.props.usersData))) {
            this.setState({
                usersData: this.props.usersData
            })
        }
    }

    render() {
        var { classes, usersData } = this.props;
        return (
            <Grid className={classes.root} container spacing={1}>
                <Paper style={{ width: "100%" }} className={classes.paper}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                {usersData.map((item, index) => {
                                    return (
                                        <>

                                            <TableRow key={index}
                                                hover>
                                                <TableCell
                                                    onClick={() => { this.props.handleClickViewDetailsSingle(index) }}
                                                    className={classes.userCell} align="left">
                                                    {item.user_image ?
                                                        <Avatar
                                                            src={FILE_URL + item.user_image}
                                                            className={classes.avatar}
                                                        />
                                                        :
                                                        <Avatar className={classes.avatar} >
                                                            {item.user_name.charAt(0)}
                                                        </Avatar>
                                                    }
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                            cursor: "pointer",
                                                            color: schedulerColor.main,
                                                            lineHeight: 2.5
                                                        }} variant="h5"
                                                    >
                                                        {item.user_name}
                                                    </Typography>
                                                </TableCell>


                                                <TableCell
                                                    className={classes.userCell}
                                                    align="right"
                                                    style={{
                                                        textAlign: 'end'
                                                    }}>
                                                    <IconButton
                                                        onClick={() => { this.props.handleClickViewDetailsSingle(index) }}
                                                    >
                                                        {item.is_expanded ?
                                                            <DownIcon style={{ color: schedulerColor.main }} />
                                                            : <RightIcon style={{ color: schedulerColor.main }} />
                                                        }
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>


                                            <TableRow
                                                style={{
                                                    backgroundColor: "lightgray",
                                                    display: item.is_expanded ? "table-row" : "none",
                                                    borderBottom: "0px solid #ffffff"
                                                }}
                                                className={classes.TableRowHover}
                                            >
                                                {item.myShifts.length > 0 ?
                                                    <TableCell
                                                        style={{ padding: 0 }}
                                                        colSpan={4}
                                                    >
                                                        <Collapse
                                                            style={{ borderBottom: '0px solid red' }}
                                                            in={item.is_expanded}
                                                            timeout="auto"
                                                            unmountOnExit>
                                                            <Box>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableCell
                                                                            style={{ width: '20%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Timecard
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Date
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Role
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Scheduled
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Actual
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Sch vs Paid
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Sch vs Actual
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Total paid
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            style={{ width: '10%' }}
                                                                            className={classes.listCell} align="left">
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    lineHeight: 2.5
                                                                                }} variant="h5"
                                                                            >
                                                                                Break
                                                                            </Typography>
                                                                        </TableCell>


                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    :
                                                    <TableCell
                                                        style={{ padding: 0 }}
                                                        colSpan={4}
                                                    >
                                                        <Typography
                                                            style={{
                                                                color: "red",
                                                                textAlign: "center",
                                                                padding: "20px"
                                                            }} variant="h4"
                                                        >
                                                            No Timecard found
                                                        </Typography>
                                                    </TableCell>
                                                }
                                            </TableRow>


                                            {item.myShifts.map((myShift) => {
                                                return (
                                                    <TableRow
                                                        style={{
                                                            display: item.is_expanded ? "table-row" : "none",
                                                            borderBottom: "0px solid #ffffff"
                                                        }}
                                                        className={classes.TableRowHover}
                                                    >
                                                        <TableCell
                                                            style={{ padding: 0 }}
                                                            colSpan={4}
                                                        >
                                                            <Collapse
                                                                style={{ borderBottom: '0px solid red' }}
                                                                in={item.is_expanded}
                                                                timeout="auto"
                                                                unmountOnExit>
                                                                <Box >
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow
                                                                                hover
                                                                            >
                                                                                <TableCell
                                                                                    style={{ width: '20%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography
                                                                                        style={{ color: schedulerColor.main, fontWeight: 800 }}
                                                                                        variant="body1">
                                                                                        {myShift.job_id}
                                                                                    </Typography>
                                                                                    <Typography variant="body1">
                                                                                        {moment(utcToLocal(myShift.clock_in ? myShift.clock_in : myShift.startTime)).format("hh:mm A")} - {moment(utcToLocal(myShift.clock_out ? myShift.clock_out : myShift.endTime)).format("hh:mm A")}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography
                                                                                        className={classes.subjectName}
                                                                                        variant="h6"
                                                                                        style={{ fontWeight: 800 }}
                                                                                    >

                                                                                        <Typography variant="body1">
                                                                                            {moment(utcDateToLocal(myShift.currentDate)).locale('en-US').format("DD MMM YYYY")}
                                                                                        </Typography>
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.role_name}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.total_scheduled_hrs}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.total_actual_hrs}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.sch_vs_paid_hrs}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.sch_vs_actual_hrs}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.total_paid_hrs}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    style={{ width: '10%' }}
                                                                                    className={classes.listCell} align="left">
                                                                                    <Typography variant="body1">
                                                                                        {myShift.total_break_hrs}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        )
    }
}

export default withStyles(styles)(ColleapseTable);
