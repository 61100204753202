import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Grid } from "@mui/material";
// import Webcam from "react-webcam";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getVideoURL } from "../modules/actions";
import { styles } from "../ThemeStyle";
import {
  // ReactMediaRecorder,
  useReactMediaRecorder,
} from "react-media-recorder";
import { useRef } from "react";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
};

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return (
    <video
      ref={videoRef}
      style={{
        width: "90%",
        minWidth: "270px",
        height: "350px",
      }}
      autoPlay
      controls
    />
  );
};

function liveStream(stream) {
  const previewStream = stream;
  if (previewStream != null) {
    return <VideoPreview stream={previewStream} />;
  }
}

function liveStreamWrapper(previewStream, fn, status) {
  //console.log(status)
  if (status !== "stopped") {
    return fn(previewStream);
  }
}

const VideoModal = ({ open, handleClose, getVideoURL, handleLoading }) => {
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  // const [error, setError] = useState(null);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
    // onError,
  } = useReactMediaRecorder({ video: true });

  const handleRecord = (blobUrl) => {
    setRecordedBlob(blobUrl);
  };

  // useEffect(() => {
  //   if (onError) {
  //     console.error("Webcam error", onError);
  //     setError("There was an error accessing the camera or microphone.");
  //   }
  // }, [onError]);

  const sendVideoToProp = async () => {
    try {
      if (status === "stopped" && (recordedBlob || mediaBlobUrl)) {
        const blobUrl = recordedBlob || mediaBlobUrl;
        const videoBlob = await fetch(blobUrl).then((r) => r.blob());
        const blob = new File([videoBlob], "video.webm", {
          type: "video/webm",
        });

        const data = new FormData();
        data.append("myfile", blob);
        data.append("product_name", "eLearning");
        console.log(data, "DATAAA");
        getVideoURL(data);
        console.log(blob, "blobbb");
        handleLoading();
        clearBlobUrl();
        setRecordedBlob(null);
        setIsRecording(false);
        handleClose();
      }
    } catch (error) {
      console.error("Error sending video to API:", error);
    }
  };

  function recordedVideo(mediaBlob, status) {
    //console.log(status)
    if (status === "stopped") {
      return (
        // <div>
        //   Recorded Video
        //   <p />
        //   <video width="390vw" src={mediaBlob} controls></video>
        // </div>
        <video
          style={{
            width: "90%",
            minWidth: "270px",
            height: "350px",
          }}
          src={mediaBlob}
          autoPlay
          loop
          controls
        />
      );
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{ width: "80%", minWidth: "300px", maxWidth: "380px" }}
        sx={style}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <>
            {liveStreamWrapper(previewStream, liveStream, status)}
            {recordedVideo(mediaBlobUrl, status)}
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {!isRecording && (
                <Button
                  style={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    height: "35px",
                    width: "115px",
                    backgroundColor: "#135098",
                    color: "white",
                    borderRadius: "29px",
                  }}
                  onClick={() => {
                    startRecording();
                    setIsRecording(true);
                  }}
                >
                  Start
                </Button>
              )}
              {isRecording && (
                <Button
                  style={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    height: "35px",
                    width: "115px",
                    backgroundColor: "#135098",
                    color: "white",
                    borderRadius: "29px",
                  }}
                  onClick={() => {
                    stopRecording(() => handleRecord);
                    setIsRecording(false);
                  }}
                >
                  Stop
                </Button>
              )}
            </div>
            {status === "stopped" && (recordedBlob || mediaBlobUrl) && (
              <Button
                style={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  height: "35px",
                  width: "115px",
                  backgroundColor: "#135098",
                  color: "white",
                  borderRadius: "29px",
                }}
                onClick={() => sendVideoToProp()}
              >
                Upload
              </Button>
            )}
          </>
        </Grid>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  videoURL: state.eLearningGrading.videoURL,
  isLoadingVideoURL: state.eLearningGrading.isLoadingVideoURL,
});

const ConnectWith = connect(mapStateToProps, {
  getVideoURL,
})(VideoModal);

export default withStyles(styles)(ConnectWith);
