import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { fetchData } from './modules/actions';
import { connect } from "react-redux";
import ColleapseTable from './ColleapseTable';
import { NoPermission, LoadingData } from '../../../components'
import {
    Grid,
    Typography
}
    from '@material-ui/core';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    }
});

export function fetchTimecardData() {
    window.Timecard.fetchData(true);
}

class Timecard extends Component {
    constructor(props) {
        super(props)
        window.Timecard = this;
        this.state = {
            usersData: []
        }
    }

    fetchData = (isReload) => {
        var lid = this.props.scheduler_lid
        this.props.fetchData(lid, isReload)
    }

    componentDidMount = () => {
        var isReload = this.props.usersData.length > 0 ? false : true
        this.fetchData(isReload)
        this.setState({
            usersData: this.props.usersData
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((JSON.stringify(prevProps.usersData) !== JSON.stringify(this.props.usersData))) {
            this.setState({
                usersData: this.props.usersData
            })
        }
    }

    handleClickViewDetailsSingle = (index) => {
        var tempData = this.state.usersData;
        var value = tempData[index].is_expanded ? false : true
        tempData[index].is_expanded = value
        this.setState({ usersData: tempData })
    }

    render() {
        const { classes, isLoading, permissionsData } = this.props;
        if (isLoading) return <LoadingData />
        return (
            permissionsData.scheduler_timecard_report ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid item sm={12} xs={12}>
                        <Typography
                            variant="h4"
                        >
                            Timecard
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <ColleapseTable
                            usersData={this.props.usersData}
                            handleClickViewDetailsSingle={this.handleClickViewDetailsSingle}
                        />
                    </Grid>
                </Grid>
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerTimecard.isLoading,
    usersData: state.schedulerTimecard.usersData
});

const ConnectWith = connect(mapStateToProps, { fetchData })(Timecard);
export default withStyles(styles)(ConnectWith);