import palette from '../palette';

export default {
  root: {
    //padding: '8px !important',
    color: palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      color: palette.primary.main
    }
  },

};
