import {
  SET_TRADE_LOADING,
  SET_TRADE,
  ADD_TRADE,
  EDIT_TRADE,
  DELETE_TRADE
} from "./constants";

const initialState = {
  isLoading: true,
  mytradeData: [],
  teamTradeData:[],
  totalpage:""
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TRADE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_TRADE:
      return {
        ...state,
        isLoading: false,
        mytradeData: payload.myTrade,
        totalpage:payload.totalpage
      };
    case ADD_TRADE:
      return {
        ...state,
        isLoading: false,
        tradeData: [...state.tradeData, payload]
      }
    case EDIT_TRADE:
      return {
        ...state,
        isLoading: false,
        tradeData: state.tradeData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    case DELETE_TRADE:
      return {
        ...state,
        isLoading: false,
        tradeData: state.tradeData.filter((item) => item.id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;