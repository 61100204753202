export const SET_TODO_DASHBOARD_LOADING = 'SET_TODO_DASHBOARD_LOADING'

export const TOGGLE_TODO = 'TOGGLE_TODO'
export const SET_TODO_DASHBOARD = 'SET_TODO_DASHBOARD'
export const ADD_TODO_DASHBOARD = 'ADD_TODO_DASHBOARD'
export const EDIT_TODO_DASHBOARD = 'EDIT_TODO_DASHBOARD'
export const DELETE_TODO_DASHBOARD = 'DELETE_TODO_DASHBOARD'

export const ADD_TODO_SUBTASKS = 'ADD_TODO_SUBTASKS'
export const DELETE_TODO_SUBTASKS = 'DELETE_TODO_SUBTASKS'
export const TOGGLE_TODO_SUBTASKS = 'TOGGLE_TODO_SUBTASKS'

export const SET_TODO_LIST = 'SET_TODO_LIST'
export const ADD_TODO_LIST = 'ADD_TODO_LIST'
export const EDIT_TODO_LIST = 'EDIT_TODO_LIST'
export const DELETE_TODO_LIST = 'DELETE_TODO_LIST'