import { API_URL, HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const payrollSummaryServices = {
    fetchData,
    addData,
    editData,
    deleteData,
    fetchDetails,
    getDetailsPayrollPE,
    getDetailsPayrollCash,
    posttDetailsPayrollCash,
    fetchApproveData,
    resetApprovalData,
    fetchPayrollComment,
    warningCorrectionRequest,
    rejectApprovalData,
    getTableAd,
    getTableAdData,
    getDownloadFilterData,
    getTableHeaderData,
    getSettingsData
    // getDetailsPayrollPi
}

function getDetailsPayrollPE(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/payrollcompany/pe`, { data }, HEADER_TOKEN).then(response => response)
};

// function getDetailsPayrollCash(data) {
//     return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/payrollcompany/cash`, {data}, HEADER_TOKEN).then(response => response)
// };

function getTableAd(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payrun/headers`, {data}, HEADER_TOKEN).then(response => response)
};

function getTableAdData(lid) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payrun/headers/${lid}`, HEADER_TOKEN).then(response => response)
};

function getSettingsData(lid) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/payrun/settings/${lid}`, HEADER_TOKEN).then(response => response)
};

function getTableHeaderData(lid) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/payrun/headers/${lid}`, HEADER_TOKEN).then(response => response)
};


function getDetailsPayrollCash() {
    return axios.get(`${MoNGO_DB_API_URL}/payroll_timecode/pi/connected`, HEADER_TOKEN).then(response => response)
};

function posttDetailsPayrollCash(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payrollcompany/pe`, { data }, HEADER_TOKEN).then(response => response)
};

function fetchData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/get`, { data }, HEADER_TOKEN).then(response => response)
};

function fetchApproveData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/v3/scheduler/payrun/payroll/approve`, { data }, HEADER_TOKEN).then(response => response)
};

function resetApprovalData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/v3/scheduler/payrun/payroll/reset`, { data }, HEADER_TOKEN).then(response => response)
};

function fetchDetails(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/details/payrollcompany`, { data }, HEADER_TOKEN).then(response => response)
};

// function fetchDetails(data) {
//     return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/details/payrollcompany/refresh`, { data }, HEADER_TOKEN).then(response => response)
// };

function fetchPayrollComment(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/comments`, { data }, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${API_URL}/scheduler/payroll_summary`, {
        data
    }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${API_URL}/scheduler/payroll_summary/${data.cid}`, {
        data
    }, HEADER_TOKEN).then(response => response)
};

function deleteData(cid) {
    return axios.delete(`${API_URL}/scheduler/payroll_summary/${cid}`, HEADER_TOKEN).then(response => response)
};

function warningCorrectionRequest(data) {
    return axios.post(`${API_URL}/onevision/scheduler/payroll_summary/v2/payroll/correction/user`, { data }, HEADER_TOKEN).then(response => response)
};

function rejectApprovalData(data) {
    return axios.post(`${API_URL}/onevision/scheduler/payroll_summary/v2/payroll/reject`, { data }, HEADER_TOKEN).then(response => response)
};


function getDownloadFilterData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v3/payrollcompany/pe`, { data }, HEADER_TOKEN).then(response => response)
};