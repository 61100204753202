import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  LoadingData,
  MyLeft50Modal,
  MyTextfield,
  MySearchRoleBar,
  MySearchBar,
} from "../../../components";
import {
  fetchData,
  addData,
  editData,
  deleteData,
  cloneRoles,
} from "../../../pages/common/Roles/modules/actions";
import { fetchData as fetchHelpdesk } from "../../../pages/eLearning/HelpDesk/modules/actions";
import { loginAsUser } from "../../../pages/common/UsersList/modules/actions";
import { fetchAllLessons } from "../../eLearning/Lessons/modules/actions";
import { fetchData as fetchCategory } from "../../eLearning/Category/modules/actions";
import { connect } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ovHrColor } from "../../../config";
import { rolesServices } from "../../../pages/common/Roles/modules/services";
import {
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  Grid,
  Tooltip,
  Button,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Cookies from "universal-cookie";
import { validationIsEmpty } from "../../../utilities";
const cookies = new Cookies();
var ovCid = cookies.get("ovCid");

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#ffffff",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px",
    // padding: theme.spacing(0, 2),
    justifyContent: "end",
  },
  title: {
    flex: "1 1 100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  ExpansionPanelMain: {
    "&:last-child": {
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
    },
  },
  ExpansionPanelSummary: {
    backgroundColor: ovHrColor.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#ffffff",
  },
  headingIcon: {
    color: "#ffffff",
  },
  ExpansionPanelDetailsSecond: {
    borderLeft: "5px solid #977c4f",
    borderRight: "5px solid #977c4f",
    borderBottom: "5px solid #977c4f",
    padding: "20px",
  },
  ExpansionPanel: {
    "&:last-child": {
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
    },
  },
  ExpansionPanelSummaryThird: {
    backgroundColor: "#f6f7f754",
    padding: "0px 10px",
    height: "30px",
  },
  ExpansionPanelDetailsThird: {
    padding: "10px 20px",
  },
  ExpansionPanelDetailsThirdCategory: {
    padding: "10px 20px",
    height: 20,
  },
  ExpansionPanelDetailsThirdRole: {
    padding: "10px 20px",
    marginLeft: "32px",
    display: "flex",
    flexDirection: "column",
  },
  ExpansionPanelDetailsThirdBase: {
    padding: "10px 20px",
    marginLeft: "32px",
  },
  FormControlLabelSecond: {
    padding: "6px",
  },
  pageTitle: {
    lineHeight: "40px",
    fontSize: "14px!important",
    color: "#977c4f",
  },
  addRoleButton: {
    backgroundColor: "#977c4f!important",
    color: "white!important",
    padding: 10,
  },
});

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAllData: "",
      buyItems: [],
      searchLevel: "",
      searchRole: "",
      assignUsersLoading: false,
      assignUsers: [],
      categoryData: [],
      categoryDataTemp: [],
      myItem: {},
      page: 1,
      order: "asc",
      orderBy: "_id",
      headCells: [
        {
          id: "role_name",
          label: "name",
          numeric: false,
          align: "left",
        },
        {
          id: "role_description",
          label: "Description",
          numeric: false,
          align: "left",
        },
        {
          id: "user_role_count",
          label: "Assigned Roles",
          numeric: false,
          align: "left",
        },
        {
          id: "created_at",
          label: "Created at",
          numeric: false,
          align: "left",
        },
        {
          id: "updated_at",
          label: "Updated at",
          numeric: false,
          align: "left",
        },
      ],
      common_Company_Data: [
        {
          Name: "common_company_edit",
          Label: "Edit Company",
        },
        {
          Name: "common_company_delete",
          Label: "Delete Company",
        },
      ],
      common_Location_Data: [
        {
          Name: "common_location_add",
          Label: "Add Location",
        },
        {
          Name: "common_location_edit",
          Label: "Edit Location",
        },
        {
          Name: "common_location_delete",
          Label: "Delete Location",
        },
      ],
      common_Users_Data: [
        {
          Name: "common_users_add",
          Label: "Add User",
        },
        {
          Name: "common_users_edit",
          Label: "Edit User",
        },
        {
          Name: "common_users_delete",
          Label: "Delete User",
        },
        {
          Name: "common_users_loginas",
          Label: "Login as User",
        },
        {
          Name: "common_users_active",
          Label: "Deactive User",
        },
      ],
      scheduler_correction_request_Data: [
        {
          Name: "correction_Level_1_Approval",
          Label: "Level 1 Approval",
        },
        {
          Name: "correction_Level_2_Approval",
          Label: "Level 2 Approval",
        },
        {
          Name: "correction_Level_3_Approval",
          Label: "Level 3 Approval",
        },
        {
          Name: "delete_correction_request",
          Label: "Delete Correction Request",
        },
      ],
      common_Roles_Data: [
        {
          Name: "common_roles_add",
          Label: "Add Roles",
        },
        {
          Name: "common_roles_edit",
          Label: "Edit Roles",
        },
        {
          Name: "common_roles_delete",
          Label: "Delete Roles",
        },
        {
          Name: "common_roles_view_assigned",
          Label: "View Assiged Roles",
        },
      ],
      common_Payroll_Data: [
        {
          Name: "common_payroll_add",
          Label: "Add Payroll",
        },
        {
          Name: "common_payroll_edit",
          Label: "Edit Payroll",
        },
        {
          Name: "common_payroll_delete",
          Label: "Delete Payroll",
        },
        {
          Name: "common_payroll_viewHistory",
          Label: "View History",
        },
      ],
      common_Ticket_Data: [
        {
          Name: "common_tickets_add",
          Label: "Add Ticket",
        },
        {
          Name: "common_tickets_edit",
          Label: "Edit Ticket",
        },
        {
          Name: "common_tickets_delete",
          Label: "Delete Ticket",
        },
      ],
      common_Ideas_Data: [
        {
          Name: "common_ideas_add",
          Label: "Add Ideas",
        },
        {
          Name: "common_ideas_edit",
          Label: "Edit Ideas",
        },
        {
          Name: "common_ideas_delete",
          Label: "Delete Ideas",
        },
      ],
      feedback_Followups_Data: [
        {
          Name: "feedback_followups_approve",
          Label: "Approve Followups",
        },
        {
          Name: "feedback_followups_view_detail",
          Label: "View Detail Followups",
        },
        {
          Name: "feedback_followups_delete",
          Label: "Delete Followups",
        },
      ],
      feedback_Campaigns_Data: [
        {
          Name: "feedback_campaign_add",
          Label: "Add Campaign",
        },
        {
          Name: "feedback_campaign_edit",
          Label: "Edit Campaign",
        },
        {
          Name: "feedback_campaign_delete",
          Label: "Delete Campaign",
        },
        {
          Name: "feedback_campaign_assign_location",
          Label: "Assign Locations",
        },
      ],
      feedback_QR_Locations_Data: [
        {
          Name: "feedback_qr_locations_devices",
          Label: "Device Management",
        },
        {
          Name: "feedback_qr_locations_view_history",
          Label: "View History",
        },
        {
          Name: "feedback_qr_locations_edit",
          Label: "Edit Location",
        },
      ],
      feedback_Settings_Data: [
        {
          Name: "feedback_settings_sms",
          Label: "SMS",
        },
        {
          Name: "feedback_settings_percentage",
          Label: "Percentage",
        },
        {
          Name: "feedback_settings_timezone",
          Label: "Timezone",
        },
        {
          Name: "feedback_settings_notification_dashboard",
          Label: "Dashboard Notification",
        },
        {
          Name: "feedback_settings_notification_email",
          Label: "Email Notification",
        },
        {
          Name: "feedback_settings_notification_push",
          Label: "Push Notification",
        },
        {
          Name: "feedback_settings_notification_mobile_push",
          Label: "Mobile Push Notification",
        },
      ],
      elearning_Library_Data: [
        {
          Name: "elearning_library_category_add",
          Label: "Add Category",
        },
        {
          Name: "elearning_library_category_edit",
          Label: "Edit Category",
        },
        {
          Name: "elearning_library_category_delete",
          Label: "Delete Category",
        },
        {
          Name: "elearning_library_lessons_add",
          Label: "Add Lesson",
        },
        {
          Name: "elearning_library_lessons_edit",
          Label: "Edit Lesson",
        },
        {
          Name: "elearning_library_lessons_delete",
          Label: "Delete Lesson",
        },
        {
          Name: "elearning_library_lessons_view_link_courses",
          Label: "View Linked Course",
        },
      ],
      elearning_Course_Data: [
        {
          Name: "elearning_course_add",
          Label: "Add Course",
        },
        {
          Name: "elearning_course_edit",
          Label: "Edit Course",
        },
        {
          Name: "elearning_course_delete",
          Label: "Delete Course",
        },
        {
          Name: "elearning_course_assign",
          Label: "Assign Course",
        },
      ],
      elearning_Helpdesk_Data: [
        {
          Name: "elearning_helpdesk_add",
          Label: "Add helpdesk",
        },
        {
          Name: "elearning_helpdesk_edit",
          Label: "Edit helpdesk",
        },
        {
          Name: "elearning_helpdesk_delete",
          Label: "Delete helpdesk",
        },
        {
          Name: "elearning_helpdesk_devices",
          Label: "Device Management",
        },
        {
          Name: "elearning_helpdesk_view_lessons",
          Label: "View helpdesk lessons",
        },
      ],
      // elearning_Helpdesk_All_Data: [
      //   {
      //     Name: "elearning_helpdesk_all_add",
      //     Label: "Add helpdesk"
      //   },
      //   {
      //     Name: "elearning_helpdesk_all_edit",
      //     Label: "Edit helpdesk"
      //   },
      //   {
      //     Name: "elearning_helpdesk_all_delete",
      //     Label: "Delete helpdesk"
      //   },
      //   {
      //     Name: "elearning_helpdesk_all_devices",
      //     Label: "Device Management"
      //   },
      //   {
      //     Name: "elearning_helpdesk_view_lessons_all",
      //     Label: "View helpdesk lessons"
      //   }
      // ],
      scheduler_Schedule_Data: [
        {
          Name: "scheduler_schedule_view_others_schedule",
          Label: "View Team Schedule",
        },
        {
          Name: "scheduler_schedule_add_shifts",
          Label: "Add Shifts",
        },
        {
          Name: "scheduler_schedule_edit_shifts",
          Label: "Edit Shifts",
        },
        {
          Name: "scheduler_schedule_delete_shifts",
          Label: "Delete Shifts",
        },
        {
          Name: "scheduler_schedule_drag",
          Label: "Drag Drop Shifts",
        },
        {
          Name: "scheduler_schedule_view_events",
          Label: "View Events",
        },
        {
          Name: "scheduler_schedule_add_events",
          Label: "Add Events",
        },
        {
          Name: "scheduler_schedule_edit_events",
          Label: "Edit Events",
        },
        {
          Name: "scheduler_schedule_delete_events",
          Label: "Delete Events",
        },
        {
          Name: "scheduler_schedule_print",
          Label: "Print Schedule",
        },
        {
          Name: "scheduler_schedule_copy",
          Label: "Copy Schedule",
        },
        {
          Name: "scheduler_schedule_publish",
          Label: "Publish Schedule",
        },
        // {
        //     Name: "scheduler_schedule_add_employee",
        //     Label: "Add Employee"
        // }
      ],
      scheduler_MyAvailability_Data: [
        {
          Name: "scheduler_myavailability_add",
          Label: "Add Availability",
        },
        {
          Name: "scheduler_myavailability_edit",
          Label: "Edit Availability",
        },
        {
          Name: "scheduler_myavailability_delete",
          Label: "Delete Availability",
        },
      ],
      scheduler_TeamAvailability_Data: [
        {
          Name: "scheduler_teamavailability_add",
          Label: "Add Availability",
        },
        {
          Name: "scheduler_teamavailability_edit",
          Label: "Edit Availability",
        },
        {
          Name: "scheduler_teamavailability_delete",
          Label: "Delete Availability",
        },
        {
          Name: "scheduler_teamavailability_approve",
          Label: "Approve & Decline",
        },
      ],
      scheduler_TimeOffRequests_Data: [
        {
          Name: "scheduler_timeoffrequests_add",
          Label: "Add Time-Off",
        },
        {
          Name: "scheduler_timeoffrequests_edit",
          Label: "Edit Time-Off",
        },
        {
          Name: "scheduler_timeoffrequests_delete",
          Label: "Delete Time-Off",
        },
        {
          Name: "scheduler_timeoffrequests_approve",
          Label: "Approve Time-Off",
        },
      ],
      scheduler_TradeShifts_Data: [
        {
          Name: "scheduler_tradeshifts_add",
          Label: "Add Trade",
        },
        {
          Name: "scheduler_tradeshifts_edit",
          Label: "Edit Trade",
        },
        {
          Name: "scheduler_tradeshifts_delete",
          Label: "Delete Trade",
        },
        {
          Name: "scheduler_tradeshifts_approve",
          Label: "Approve & Decline",
        },
      ],
      scheduler_CoverShifts_Data: [
        {
          Name: "scheduler_covershifts_add",
          Label: "Add Cover",
        },
        {
          Name: "scheduler_covershifts_edit",
          Label: "Edit Cover",
        },
        {
          Name: "scheduler_covershifts_delete",
          Label: "Delete Cover",
        },
        {
          Name: "scheduler_covershifts_approve",
          Label: "Approve & Decline",
        },
      ],
      scheduler_payroll_run_Data: [
        {
          Name: "level_1_approval",
          Label: "Level 1 Approval",
        },
        {
          Name: "level_2_approval",
          Label: "Level 2 Approval",
        },
        {
          Name: "level_3_approval",
          Label: "Level 3 Approval",
        },
      ],
      // scheduler_payroll_run_Data: [
      //     {
      //       Name: "level_1_approval",
      //       Label: "Level 1 Approval"
      //     },
      //     {
      //       Name: "level_2_approval",
      //       Label: "Level 2 Approval"
      //     },
      //     {
      //       Name: "level_3_approval",
      //       Label: "Level 3 Approval"
      //     }
      //   ],
      scheduler_Timesheet_Data: [
        {
          Name: "scheduler_timesheet_add",
          Label: "Add Timecard",
        },
        {
          Name: "scheduler_timesheet_edit",
          Label: "Edit Timecard",
        },
        {
          Name: "scheduler_timesheet_view",
          Label: "View Timecard",
        },
        {
          Name: "scheduler_timesheet_view_timecard_notes",
          Label: "Add/View Timecard notes",
        },
        {
          Name: "scheduler_timesheet_fix_issue",
          Label: "Fix Timecard issues",
        },
        {
          Name: "scheduler_timesheet_view_edit_history",
          Label: "View Edit History",
        },
        {
          Name: "scheduler_timesheet_lock",
          Label: "Lock Unlock Timecard",
        },
        {
          Name: "scheduler_timesheet_filter",
          Label: "Filter Timesheet",
        },
        {
          Name: "scheduler_timesheet_download",
          Label: "Download Timesheet",
        },
        {
          Name: "scheduler_timesheet_view_others_timesheet",
          Label: "View Team Timesheet",
        },
      ],
      scheduler_TimecardReport_Data: [
        {
          Name: "scheduler_timecardreport_view_detail",
          Label: "View Detail Timecard",
        },
      ],
      scheduler_Payroll_Data: [
        {
          Name: "scheduler_payroll_add",
          Label: "Add Payroll",
        },
        {
          Name: "scheduler_payroll_edit",
          Label: "Edit Payroll",
        },
        {
          Name: "scheduler_payroll_delete",
          Label: "Delete Payroll",
        },
        {
          Name: "scheduler_payroll_assign",
          Label: "Assign Payroll",
        },
        {
          Name: "scheduler_payroll_view_assign",
          Label: "View Assign Payroll",
        },
        {
          Name: "scheduler_payroll_code_add",
          Label: "Add Timecode",
        },
        {
          Name: "scheduler_payroll_code_edit",
          Label: "Edit Timecode",
        },
        {
          Name: "scheduler_payroll_code_delete",
          Label: "Delete Timecode",
        },
      ],
      scheduler_Payroll_Summary_Data: [
        {
          Name: "scheduler_payroll_view_other_payroll",
          Label: "View Team Payroll",
        },
        {
          Name: "scheduler_payroll_id",
          Label: "Payroll Id",
        },
      ],
      scheduler_Roles_Data: [
        {
          Name: "scheduler_roles_add",
          Label: "Add Role",
        },
        {
          Name: "scheduler_roles_edit",
          Label: "Edit Role",
        },
        {
          Name: "scheduler_roles_delete",
          Label: "Delete Role",
        },
      ],
      rating_form_Data: [
        {
          Name: "scheduler_myavailability_add",
          Label: "Add form",
        },
        {
          Name: "scheduler_myavailability_edit",
          Label: "Edit form",
        },
        {
          Name: "scheduler_myavailability_delete",
          Label: "Delete form",
        },
      ],
      helpdeskDataa: [],
      errors: {},
      errorsHelper: {},
    };
  }

  componentDidMount = () => {
    var isReload = this.props.rolesData.length > 0 ? false : true;
    let data = {
      pageNumber: 1,
      nPerPage: 10,
      isfeedback: 2,
      isLessonQuiz: 2,
      isviedioQuiz: 2,
      ispracticalQuiz: 2,
      ismangerQuiz: 2,
      slidetype: 3,
    };
    this.props.fetchData(
      isReload,
      data.pageNumber === null ? this.state.page : data.pageNumber
    );
    this.props.fetchCategory(data, false);
    this.props.fetchAllLessons();
    this.setCategory();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.allLessonData) !==
        JSON.stringify(this.props.allLessonData) ||
      JSON.stringify(this.props.categoryData) !==
        JSON.stringify(prevProps.categoryData)
    ) {
      this.setCategory();
    }
  }

  //   componentDidUpdate(prevProps, prevState) {
  //     if (JSON.stringify(prevProps.helpdeskData) !== JSON.stringify(this.props.helpdeskData)) {
  //       var helpdeskDataaaa = []
  //       this.props.helpdeskData?.map((item) => {
  //         var obj = {
  //           id:item._id,
  //           label:item.desk_name,
  //           status:false
  //         }
  //         helpdeskDataaaa.push(obj)
  //       })
  //       this.setState({
  //         helpdeskDataa:helpdeskDataaaa
  //       })
  //     }
  // }

  setCategory = (value) => {
    this.setState({
      isLoading: true,
    });
    var newCategory = [];

    this.props.categoryData.map((category) => {
      var myLessons = [];
      this.props.allLessonData.map((lesson) => {
        if (category._id === lesson.ecid) {
          var lessonObj = {
            _id: lesson.cid,
            lesson_id: lesson._id,
            lesson_name: lesson.lesson_name,
            author: lesson.author,
            lesson_image: lesson.lesson_image,
            lesson_tags: lesson.lesson_tags,
            lesson_level: lesson.lesson_level,
            created_at: lesson.createdAt,
            updated_at: lesson.updatedAt,
            mySlide: lesson.mySlide,
            selected: 0,
            ecid: lesson.ecid,
          };
          myLessons.push(lessonObj);
        }
      });
      var categoryObj = {
        _id: category._id,
        category_name: category.category_name,
        selected: 0,
        category_lesson: myLessons.length,
        image: category.image,
        date: category.createdAt,
        myLessons: myLessons,
      };
      newCategory.push(categoryObj);
    });
    this.setState({
      categoryData: newCategory,
      categoryDataTemp: newCategory,
    });
  };

  handleCategory = (e, value, index) => {
    let check = e.target.checked;
    var items = value;
    var newItem = this.state.categoryData.find((cat) => items === cat._id);
    newItem["selected"] = check ? 1 : 0;
    var tempLesson = newItem.myLessons;
    tempLesson.map((item) => (item.selected = check ? 1 : 0));
    newItem["myLessons"] = tempLesson;
    var categoryData = this.state.categoryData.map((myITems) =>
      myITems._id === newItem.ecid ? { ...newItem } : myITems
    );
    this.setState((prevState) => ({
      categoryData: categoryData,
    }));
  };

  handleUsers = (e, value, items, index) => {
    let check = e.target.checked;
    var items = items;
    var newItem = this.state.categoryData.find((cat) => value === cat._id);
    var tempLessons = newItem.myLessons;
    tempLessons[index]["selected"] = check ? 1 : 0;
    newItem["myLessons"] = tempLessons;
    var countUsers = tempLessons.filter((uss) => uss.selected === 1);
    newItem["selected"] = countUsers.length === tempLessons.length ? 1 : 0;
    var categoryData = this.state.categoryData.map((myITems) =>
      myITems._id === newItem.ecid ? { ...newItem } : myITems
    );
    this.setState((prevState) => ({
      categoryData: categoryData,
    }));
  };

  handleSearch = (target) => {
    var updatedList = this.state.categoryDataTemp;
    if (target) {
      updatedList = updatedList.filter(function (item) {
        return (
          item.category_name.toLowerCase().search(target.toLowerCase()) !==
            -1 ||
          item.myLessons.lesson_name
            .toLowerCase()
            .search(target.toLowerCase()) !== -1
        );
      });
    }
    var categoryData = updatedList.filter((item) => item.category_name);
    this.setState({
      searchUsers: target,
      categoryData,
    });
  };

  clearSearch = () => {
    var categoryData = this.state.categoryDataTemp.filter(
      (item) => item.category_name
    );
    this.setState({
      searchLevel: "",
      searchUsers: "",
      searchRole: "",
      categoryData,
    });
  };

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    this.props.deleteData(this.state.myItem._id);
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  addEditModalClose = () => {
    this.setState({
      addEditModal: false,
    });
  };

  moreMenuClick = (item, value) => {
    var myItem = {
      role_name: "",
      role_description: "",

      //common initial count
      common_Company_Data_Count: 0,
      common_Location_Data_Count: 0,
      common_Users_Data_Count: 0,
      common_Roles_Data_Count: 0,
      common_Payroll_Data_Count: 0,
      common_Ticket_Data_Count: 0,
      common_Ideas_Data_Count: 0,

      //feedback initial count
      feedback_Followups_Data_Count: 0,
      feedback_Campaigns_Data_Count: 0,
      feedback_QR_Locations_Data_Count: 0,
      feedback_Settings_Data_Count: 0,

      //elearnig initial count
      elearning_Library_Data_Count: 0,
      elearning_Course_Data_Count: 0,
      elearning_Helpdesk_Data_Count: 0,
      elearning_Helpdesk_Data_Helpdesk_Count: 0,
      elearning_helpdesk_assign: 0,

      //scheduler initial count
      scheduler_Schedule_Data_Count: 0,
      scheduler_MyAvailability_Data_Count: 0,
      scheduler_TeamAvailability_Data_Count: 0,
      scheduler_TimeOffRequests_Data_Count: 0,
      scheduler_TradeShifts_Data_Count: 0,
      scheduler_CoverShifts_Data_Count: 0,
      scheduler_Timesheet_Data_Count: 0,
      scheduler_TimecardReport_Data_Count: 0,
      scheduler_Payroll_Data_Count: 0,
      scheduler_payroll_run_Data_Count: 0,
      scheduler_correction_request_Data_Count: 0,
      scheduler_Payroll_Summary_Data_Count: 0,
      scheduler_Roles_Data_Count: 0,

      //Rating
      rating_form_Data_Count: 0,

      common_billings: 0,
      common_coupons: 0,
      common_products: 0,

      common_company_edit: 0,
      common_company_delete: 0,
      common_location_add: 0,
      common_location_edit: 0,
      common_location_delete: 0,
      common_users_add: 0,
      common_users_edit: 0,
      common_users_delete: 0,
      common_users_loginas: 0,
      common_users_active: 0,
      common_payroll_add: 0,
      common_payroll_delete: 0,
      common_payroll_edit: 0,
      common_payroll_viewHistory: 0,
      common_roles_add: 0,
      common_roles_edit: 0,
      common_roles_delete: 0,
      common_roles_view_assigned: 0,
      common_tickets_add: 0,
      common_tickets_edit: 0,
      common_tickets_delete: 0,
      common_ideas_add: 0,
      common_ideas_edit: 0,
      common_ideas_delete: 0,

      feedback_dashboard: 0,
      feedback_team: 0,
      feedback_reports: 0,

      feedback_followups_approve: 0,
      feedback_followups_view_detail: 0,
      feedback_followups_delete: 0,
      feedback_campaign_add: 0,
      feedback_campaign_edit: 0,
      feedback_campaign_delete: 0,
      feedback_campaign_assign_location: 0,
      feedback_qr_locations_devices: 0,
      feedback_qr_locations_view_history: 0,
      feedback_qr_locations_edit: 0,
      feedback_settings_sms: 0,
      feedback_settings_percentage: 0,
      feedback_settings_timezone: 0,
      feedback_settings_notification_dashboard: 0,
      feedback_settings_notification_email: 0,
      feedback_settings_notification_push: 0,
      feedback_settings_notification_mobile_push: 0,

      elearning_dashboard: 0,
      elearning_myhelpdesk: 0,
      assignHelpdesk: [],
      elearning_mycourse: 0,
      elearning_reports: 0,
      elearning_team: 0,
      elearning_library_category_add: 0,
      elearning_library_category_edit: 0,
      elearning_library_category_delete: 0,
      elearning_library_lessons_add: 0,
      elearning_library_lessons_edit: 0,
      elearning_library_lessons_delete: 0,
      elearning_library_lessons_view_link_courses: 0,
      elearning_course_add: 0,
      elearning_course_edit: 0,
      elearning_course_delete: 0,
      elearning_course_assign: 0,
      elearning_helpdesk_add: 0,
      elearning_helpdesk_edit: 0,
      elearning_helpdesk_delete: 0,
      elearning_helpdesk_devices: 0,
      elearning_helpdesk_view_lessons: 0,
      scheduler_access_level: 0,
      scheduler_dashboard: 0,
      scheduler_schedule_add_shifts: 0,
      scheduler_schedule_view_others_schedule: 0,
      scheduler_schedule_edit_shifts: 0,
      scheduler_schedule_delete_shifts: 0,
      scheduler_schedule_drag: 0,
      scheduler_schedule_view_events: 0,
      scheduler_schedule_add_events: 0,
      scheduler_schedule_edit_events: 0,
      scheduler_schedule_delete_events: 0,
      scheduler_schedule_print: 0,
      scheduler_schedule_copy: 0,
      scheduler_schedule_publish: 0,
      // scheduler_schedule_add_employee: 0,
      scheduler_myavailability_add: 0,
      scheduler_myavailability_edit: 0,
      scheduler_myavailability_delete: 0,
      scheduler_teamavailability_add: 0,
      scheduler_teamavailability_edit: 0,
      scheduler_teamavailability_delete: 0,
      scheduler_teamavailability_approve: 0,
      scheduler_timeoffrequests_add: 0,
      scheduler_timeoffrequests_edit: 0,
      scheduler_timeoffrequests_delete: 0,
      scheduler_timeoffrequests_filter: 0,
      scheduler_timeoffrequests_approve: 0,
      scheduler_tradeshifts_add: 0,
      scheduler_tradeshifts_edit: 0,
      scheduler_tradeshifts_delete: 0,
      scheduler_tradeshifts_approve: 0,
      scheduler_covershifts_add: 0,
      scheduler_covershifts_edit: 0,
      scheduler_covershifts_delete: 0,
      scheduler_covershifts_approve: 0,
      scheduler_timesheet_add: 0,
      scheduler_timesheet_edit: 0,
      scheduler_timesheet_view_timecard_notes: 0,
      scheduler_timesheet_fix_issue: 0,
      scheduler_timesheet_view: 0,
      scheduler_timesheet_view_edit_history: 0,
      scheduler_timesheet_lock: 0,
      scheduler_timesheet_filter: 0,
      scheduler_timesheet_download: 0,
      scheduler_timesheet_view_others_timesheet: 0,
      scheduler_timecardreport_view_detail: 0,
      scheduler_payroll_id: 0,
      scheduler_payroll_change_export: 0,
      scheduler_payroll_view_export: 0,
      scheduler_payroll_code_add: 0,
      scheduler_payroll_code_edit: 0,
      scheduler_payroll_code_delete: 0,
      scheduler_payroll_add: 0,
      scheduler_payroll_edit: 0,
      scheduler_payroll_delete: 0,
      scheduler_payroll_assign: 0,
      scheduler_payroll_view_assign: 0,
      scheduler_payroll_view_other_payroll: 0,
      scheduler_roles_add: 0,
      scheduler_roles_edit: 0,
      scheduler_roles_delete: 0,
      scheduler_settings: 0,
    };

    if (item) {
      myItem = item;
      //common check intermediate value of checkbox
      let common_Company_Data_Count = 0;
      let common_Location_Data_Count = 0;
      let common_Users_Data_Count = 0;
      let common_Roles_Data_Count = 0;
      let common_Payroll_Data_Count = 0;
      let common_Ticket_Data_Count = 0;
      let common_Ideas_Data_Count = 0;

      //feedback check intermediate value of checkbox
      let feedback_Followups_Data_Count = 0;
      let feedback_Campaigns_Data_Count = 0;
      let feedback_QR_Locations_Data_Count = 0;
      let feedback_Settings_Data_Count = 0;

      //elearning check intermediate value of checkbox
      let elearning_Library_Data_Count = 0;
      let elearning_Course_Data_Count = 0;
      let elearning_Helpdesk_Data_Count = 0;

      //scheduler check intermediate value of checkbox
      let scheduler_Schedule_Data_Count = 0;
      let scheduler_MyAvailability_Data_Count = 0;
      let scheduler_TeamAvailability_Data_Count = 0;
      let scheduler_TimeOffRequests_Data_Count = 0;
      let scheduler_TradeShifts_Data_Count = 0;
      let scheduler_CoverShifts_Data_Count = 0;
      let scheduler_Timesheet_Data_Count = 0;
      let scheduler_payroll_run_Data_Count = 0;
      let scheduler_correction_request_Data_Count = 0;
      let scheduler_TimecardReport_Data_Count = 0;
      let scheduler_Payroll_Summary_Data_Count = 0;
      let scheduler_Payroll_Data_Count = 0;
      let scheduler_Roles_Data_Count = 0;

      //Rating
      let rating_form_Data_Count = 0;

      //common set intermediate value of checkbox
      this.state.common_Company_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Company_Data_Count = common_Company_Data_Count + 1;
        }
      });
      this.state.common_Location_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Location_Data_Count = common_Location_Data_Count + 1;
        }
      });
      this.state.common_Users_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Users_Data_Count = common_Users_Data_Count + 1;
        }
      });
      this.state.common_Roles_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Roles_Data_Count = common_Roles_Data_Count + 1;
        }
      });
      this.state.common_Payroll_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Payroll_Data_Count = common_Payroll_Data_Count + 1;
        }
      });
      this.state.common_Ticket_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Ticket_Data_Count = common_Ticket_Data_Count + 1;
        }
      });
      this.state.common_Ideas_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          common_Ideas_Data_Count = common_Ideas_Data_Count + 1;
        }
      });

      //feedback set intermediate value of checkbox
      this.state.feedback_Followups_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          feedback_Followups_Data_Count = feedback_Followups_Data_Count + 1;
        }
      });
      this.state.feedback_Campaigns_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          feedback_Campaigns_Data_Count = feedback_Campaigns_Data_Count + 1;
        }
      });
      this.state.feedback_QR_Locations_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          feedback_QR_Locations_Data_Count =
            feedback_QR_Locations_Data_Count + 1;
        }
      });
      this.state.feedback_Settings_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          feedback_Settings_Data_Count = feedback_Settings_Data_Count + 1;
        }
      });

      //elearning set intermediate value of checkbox
      this.state.elearning_Library_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          elearning_Library_Data_Count = elearning_Library_Data_Count + 1;
        }
      });
      this.state.elearning_Course_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          elearning_Course_Data_Count = elearning_Course_Data_Count + 1;
        }
      });
      this.state.elearning_Helpdesk_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          elearning_Helpdesk_Data_Count = elearning_Helpdesk_Data_Count + 1;
        }
      });

      //scheduler set intermediate value of checkbox
      this.state.scheduler_Schedule_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_Schedule_Data_Count = scheduler_Schedule_Data_Count + 1;
        }
      });
      this.state.scheduler_MyAvailability_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_MyAvailability_Data_Count =
            scheduler_MyAvailability_Data_Count + 1;
        }
      });
      this.state.scheduler_TeamAvailability_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_TeamAvailability_Data_Count =
            scheduler_TeamAvailability_Data_Count + 1;
        }
      });
      this.state.scheduler_TimeOffRequests_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_TimeOffRequests_Data_Count =
            scheduler_TimeOffRequests_Data_Count + 1;
        }
      });
      this.state.scheduler_TradeShifts_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_TradeShifts_Data_Count =
            scheduler_TradeShifts_Data_Count + 1;
        }
      });
      this.state.scheduler_CoverShifts_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_CoverShifts_Data_Count =
            scheduler_CoverShifts_Data_Count + 1;
        }
      });
      this.state.scheduler_payroll_run_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_payroll_run_Data_Count =
            scheduler_payroll_run_Data_Count + 1;
        }
      });
      this.state.scheduler_correction_request_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_correction_request_Data_Count =
            scheduler_correction_request_Data_Count + 1;
        }
      });
      this.state.scheduler_Timesheet_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_Timesheet_Data_Count = scheduler_Timesheet_Data_Count + 1;
        }
      });
      this.state.scheduler_TimecardReport_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_TimecardReport_Data_Count =
            scheduler_TimecardReport_Data_Count + 1;
        }
      });
      this.state.scheduler_Payroll_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_Payroll_Data_Count = scheduler_Payroll_Data_Count + 1;
        }
      });
      this.state.scheduler_Payroll_Summary_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_Payroll_Summary_Data_Count =
            scheduler_Payroll_Summary_Data_Count + 1;
        }
      });
      this.state.scheduler_Roles_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          scheduler_Roles_Data_Count = scheduler_Roles_Data_Count + 1;
        }
      });

      this.state.rating_form_Data.map((item) => {
        if (myItem[item.Name] === 1) {
          rating_form_Data_Count = rating_form_Data_Count + 1;
        }
      });

      myItem["common_Company_Data_Count"] = common_Company_Data_Count;
      myItem["common_Location_Data_Count"] = common_Location_Data_Count;
      myItem["common_Users_Data_Count"] = common_Users_Data_Count;
      myItem["common_Roles_Data_Count"] = common_Roles_Data_Count;
      myItem["common_Payroll_Data_Count"] = common_Payroll_Data_Count;
      myItem["common_Ticket_Data_Count"] = common_Ticket_Data_Count;
      myItem["common_Ideas_Data_Count"] = common_Ideas_Data_Count;

      myItem["feedback_Followups_Data_Count"] = feedback_Followups_Data_Count;
      myItem["feedback_Campaigns_Data_Count"] = feedback_Campaigns_Data_Count;
      myItem["feedback_QR_Locations_Data_Count"] =
        feedback_QR_Locations_Data_Count;
      myItem["feedback_Settings_Data_Count"] = feedback_Settings_Data_Count;

      myItem["elearning_Library_Data_Count"] = elearning_Library_Data_Count;
      myItem["elearning_Course_Data_Count"] = elearning_Course_Data_Count;
      myItem["elearning_Helpdesk_Data_Count"] = elearning_Helpdesk_Data_Count;
      myItem["scheduler_Schedule_Data_Count"] = scheduler_Schedule_Data_Count;
      myItem["scheduler_MyAvailability_Data_Count"] =
        scheduler_MyAvailability_Data_Count;
      myItem["scheduler_TeamAvailability_Data_Count"] =
        scheduler_TeamAvailability_Data_Count;
      myItem["scheduler_TimeOffRequests_Data_Count"] =
        scheduler_TimeOffRequests_Data_Count;
      myItem["scheduler_TradeShifts_Data_Count"] =
        scheduler_TradeShifts_Data_Count;
      myItem["scheduler_CoverShifts_Data_Count"] =
        scheduler_CoverShifts_Data_Count;
      myItem["scheduler_Timesheet_Data_Count"] = scheduler_Timesheet_Data_Count;
      myItem["scheduler_payroll_run_Data_Count"] =
        scheduler_payroll_run_Data_Count;
      myItem["scheduler_correction_request_Data_Count"] =
        scheduler_correction_request_Data_Count;
      myItem["scheduler_TimecardReport_Data_Count"] =
        scheduler_TimecardReport_Data_Count;
      myItem["scheduler_Payroll_Data_Count"] = scheduler_Payroll_Data_Count;
      myItem["scheduler_Payroll_Summary_Data_Count"] =
        scheduler_Payroll_Summary_Data_Count;
      myItem["scheduler_Roles_Data_Count"] = scheduler_Roles_Data_Count;

      myItem["rating_form_Data_Count"] = rating_form_Data_Count;

      // let payload = this.state.helpdeskDataa;
      // payload && payload.map((item1) => {
      //   item && item.assignHelpdesk.map((items) => {
      //     if (item1.id === items) {
      //             item1.status = true
      //         }
      //     })
      //     this.setState({
      //       helpdeskDataa:payload
      //     })
      // })
    }
    this.setCategory();
    this.setState({
      myItem,
      errors: {},
      errorsHelper: {},
      isEdit: 0,
      // helpdeskDataa:this.state.helpdeskDataa,
      addEditModal: value === "add" ? true : false,
    });
  };

  handleSubmit = async () => {
    var userItem = this.state.myItem;
    var valid_role_description = await validationIsEmpty(
      userItem.role_description
    );
    var valid_role_name = await validationIsEmpty(userItem.role_name);
    var myItem = this.state.myItem;
    if (valid_role_name) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          role_name: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          role_name: "Please enter name",
        },
      }));
    }

    if (valid_role_description) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          role_description: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          role_description: "Please enter description",
        },
      }));
    }

    if (valid_role_description || valid_role_name) {
      return;
    }

    myItem.common_company = myItem.common_Company_Data_Count > 0 ? 1 : 0;
    myItem.common_location = myItem.common_Location_Data_Count > 0 ? 1 : 0;
    myItem.common_users = myItem.common_Users_Data_Count > 0 ? 1 : 0;
    myItem.common_roles = myItem.common_Roles_Data_Count > 0 ? 1 : 0;
    myItem.common_payroll = myItem.common_Payroll_Data_Count > 0 ? 1 : 0;
    myItem.common_tickets = myItem.common_Ticket_Data_Count > 0 ? 1 : 0;
    myItem.common_ideas = myItem.common_Ideas_Data_Count > 0 ? 1 : 0;

    myItem.feedback_followups =
      myItem.feedback_Followups_Data_Count > 0 ? 1 : 0;
    myItem.feedback_campaign = myItem.feedback_Campaigns_Data_Count > 0 ? 1 : 0;
    myItem.feedback_qr_locations =
      myItem.feedback_QR_Locations_Data_Count > 0 ? 1 : 0;
    myItem.feedback_settings = myItem.feedback_Settings_Data_Count > 0 ? 1 : 0;
    myItem.elearning_library = myItem.elearning_Library_Data_Count > 0 ? 1 : 0;
    myItem.elearning_course = myItem.elearning_Course_Data_Count > 0 ? 1 : 0;
    myItem.elearning_helpdesk =
      myItem.elearning_Helpdesk_Data_Count > 0 ? 1 : 0;
    myItem.elearning_helpdesk_assign = myItem.assignHelpdesk.length > 0 ? 1 : 0;
    myItem.scheduler_schedule =
      myItem.scheduler_Schedule_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_availability =
      myItem.scheduler_MyAvailability_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_team_availability =
      myItem.scheduler_TeamAvailability_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_timeoff_request =
      myItem.scheduler_TimeOffRequests_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_trade_shift =
      myItem.scheduler_TradeShifts_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_cover_shift =
      myItem.scheduler_CoverShifts_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_timesheet =
      myItem.scheduler_Timesheet_Data_Count > 0 ? 1 : 0;
    myItem.payroll_run = myItem.scheduler_payroll_run_Data_Count > 0 ? 1 : 0;
    myItem.correction_request =
      myItem.scheduler_correction_request_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_payroll_summary =
      myItem.scheduler_Payroll_Summary_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_timecard_report =
      myItem.scheduler_TimecardReport_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_payroll_template =
      myItem.scheduler_Payroll_Data_Count > 0 ? 1 : 0;
    myItem.scheduler_roles = myItem.scheduler_Roles_Data_Count > 0 ? 1 : 0;

    myItem.rating_form = myItem.rating_form_Data_Count > 0 ? 1 : 0;
    this.setState({ addEditModal: false });
    if (this.state.isEdit) {
      this.props.editData(userItem);
    } else {
      this.props.addData(userItem);
    }
  };

  modifyData = (value) => {
    if (value === "clone") {
      this.cloneRoles(this.state.myItem);
    }
    if (value === "assign") {
      this.setAssignUsers(this.state.myItem._id);
    }
    this.setState({
      assignModal: value === "assign" ? true : false,
      isEdit: value === "edit" ? 1 : 0,
      addEditModal: value === "edit" ? true : false,
      deleteModal: value === "delete" ? true : false,
    });
  };

  cloneRoles = (data) => {
    var mydata = {
      role_name: data.role_name,
      role_description: data.role_description,
      common_company: data.common_company,
      common_location: data.common_location,
      common_users: data.common_users,
      common_roles: data.common_roles,
      common_tickets: data.common_tickets,
      common_ideas: data.common_ideas,
      common_billings: data.common_billings,
      common_coupons: data.common_coupons,
      common_products: data.common_products,
      common_company_edit: data.common_company_edit,
      common_company_delete: data.common_company_delete,
      common_location_add: data.common_location_add,
      common_location_edit: data.common_location_edit,
      common_location_delete: data.common_location_delete,
      common_users_add: data.common_users_add,
      common_users_edit: data.common_users_edit,
      common_users_delete: data.common_users_delete,
      common_users_loginas: data.common_users_loginas,
      common_users_active: data.common_users_active,
      common_roles_add: data.common_roles_add,
      common_roles_edit: data.common_roles_edit,
      common_roles_delete: data.common_roles_delete,
      common_roles_view_assigned: data.common_roles_view_assigned,
      common_tickets_add: data.common_tickets_add,
      common_tickets_edit: data.common_tickets_edit,
      common_tickets_delete: data.common_tickets_delete,
      common_ideas_add: data.common_ideas_add,
      common_ideas_edit: data.common_ideas_edit,
      common_ideas_delete: data.common_ideas_delete,
      feedback_dashboard: data.feedback_dashboard,
      feedback_followups: data.feedback_followups,
      feedback_campaign: data.feedback_campaign,
      feedback_qr_locations: data.feedback_qr_locations,
      feedback_reports: data.feedback_reports,
      feedback_settings: data.feedback_settings,
      feedback_team: data.feedback_team,
      feedback_followups_approve: data.feedback_followups_approve,
      feedback_followups_view_detail: data.feedback_followups_view_detail,
      feedback_followups_delete: data.feedback_followups_delete,
      feedback_campaign_add: data.feedback_campaign_add,
      feedback_campaign_edit: data.feedback_campaign_edit,
      feedback_campaign_delete: data.feedback_campaign_delete,
      feedback_campaign_assign_location: data.feedback_campaign_assign_location,
      feedback_qr_locations_devices: data.feedback_qr_locations_devices,
      feedback_qr_locations_view_history:
        data.feedback_qr_locations_view_history,
      feedback_qr_locations_edit: data.feedback_qr_locations_edit,
      feedback_settings_sms: data.feedback_settings_sms,
      feedback_settings_percentage: data.feedback_settings_percentage,
      feedback_settings_timezone: data.feedback_settings_timezone,
      feedback_settings_notification_dashboard:
        data.feedback_settings_notification_dashboard,
      feedback_settings_notification_email:
        data.feedback_settings_notification_email,
      feedback_settings_notification_push:
        data.feedback_settings_notification_push,
      feedback_settings_notification_mobile_push:
        data.feedback_settings_notification_mobile_push,
      elearning_dashboard: data.elearning_dashboard,
      elearning_mycourse: data.elearning_mycourse,
      elearning_myhelpdesk: data.elearning_myhelpdesk,
      elearning_library: data.elearning_library,
      elearning_course: data.elearning_course,
      elearning_helpdesk: data.elearning_helpdesk,
      elearning_reports: data.elearning_reports,
      elearning_team: data.elearning_team,
      elearning_library_category_add: data.elearning_library_category_add,
      elearning_library_category_edit: data.elearning_library_category_edit,
      elearning_library_category_delete: data.elearning_library_category_delete,
      elearning_library_lessons_add: data.elearning_library_lessons_add,
      elearning_library_lessons_edit: data.elearning_library_lessons_edit,
      elearning_library_lessons_delete: data.elearning_library_lessons_delete,
      elearning_library_lessons_view_link_courses:
        data.elearning_library_lessons_view_link_courses,
      elearning_course_add: data.elearning_course_add,
      elearning_course_edit: data.elearning_course_edit,
      elearning_course_delete: data.elearning_course_delete,
      elearning_course_assign: data.elearning_course_assign,
      elearning_helpdesk_add: data.elearning_helpdesk_add,
      elearning_helpdesk_edit: data.elearning_helpdesk_edit,
      elearning_helpdesk_delete: data.elearning_helpdesk_delete,
      elearning_helpdesk_devices: data.elearning_helpdesk_devices,
      elearning_helpdesk_view_lessons: data.elearning_helpdesk_view_lessons,
      scheduler_access_level: data.scheduler_access_level,
      scheduler_dashboard: data.scheduler_dashboard,
      scheduler_schedule: data.scheduler_schedule,
      scheduler_availability: data.scheduler_availability,
      scheduler_team_availability: data.scheduler_team_availability,
      scheduler_timeoff_request: data.scheduler_timeoff_request,
      scheduler_trade_shift: data.scheduler_trade_shift,
      scheduler_cover_shift: data.scheduler_cover_shift,
      scheduler_timesheet: data.scheduler_timesheet,
      scheduler_payroll_summary: data.scheduler_payroll_summary,
      scheduler_timecard_report: data.scheduler_timecard_report,
      scheduler_payroll_template: data.scheduler_payroll_template,
      scheduler_roles: data.scheduler_roles,
      scheduler_schedule_add_shifts: data.scheduler_schedule_add_shifts,
      scheduler_schedule_drag: data.scheduler_schedule_drag,
      scheduler_schedule_view_others_schedule:
        data.scheduler_schedule_view_others_schedule,
      scheduler_schedule_edit_shifts: data.scheduler_schedule_edit_shifts,
      scheduler_schedule_delete_shifts: data.scheduler_schedule_delete_shifts,
      scheduler_schedule_view_events: data.scheduler_schedule_view_events,
      scheduler_schedule_add_events: data.scheduler_schedule_add_events,
      scheduler_schedule_edit_events: data.scheduler_schedule_edit_events,
      scheduler_schedule_delete_events: data.scheduler_schedule_delete_events,
      scheduler_schedule_print: data.scheduler_schedule_print,
      scheduler_schedule_copy: data.scheduler_schedule_copy,
      scheduler_schedule_publish: data.scheduler_schedule_publish,
      //scheduler_schedule_add_employee: data.scheduler_schedule_add_employee,
      scheduler_myavailability_add: data.scheduler_myavailability_add,
      scheduler_myavailability_edit: data.scheduler_myavailability_edit,
      scheduler_myavailability_delete: data.scheduler_myavailability_delete,
      scheduler_teamavailability_add: data.scheduler_teamavailability_add,
      scheduler_teamavailability_edit: data.scheduler_teamavailability_edit,
      scheduler_teamavailability_delete: data.scheduler_teamavailability_delete,
      scheduler_teamavailability_approve:
        data.scheduler_teamavailability_approve,
      scheduler_timeoffrequests_add: data.scheduler_timeoffrequests_add,
      scheduler_timeoffrequests_edit: data.scheduler_timeoffrequests_edit,
      scheduler_timeoffrequests_delete: data.scheduler_timeoffrequests_delete,
      scheduler_timeoffrequests_filter: data.scheduler_timeoffrequests_filter,
      scheduler_timeoffrequests_approve: data.scheduler_timeoffrequests_approve,
      scheduler_tradeshifts_add: data.scheduler_tradeshifts_add,
      scheduler_tradeshifts_edit: data.scheduler_tradeshifts_edit,
      scheduler_tradeshifts_delete: data.scheduler_tradeshifts_delete,
      scheduler_tradeshifts_approve: data.scheduler_tradeshifts_approve,
      scheduler_covershifts_add: data.scheduler_covershifts_add,
      scheduler_covershifts_edit: data.scheduler_covershifts_edit,
      scheduler_covershifts_delete: data.scheduler_covershifts_delete,
      scheduler_covershifts_approve: data.scheduler_covershifts_approve,
      scheduler_timesheet_add: data.scheduler_timesheet_add,
      scheduler_timesheet_edit: data.scheduler_timesheet_edit,
      scheduler_timesheet_view_timecard_notes:
        data.scheduler_timesheet_view_timecard_notes,
      scheduler_timesheet_fix_issue: data.scheduler_timesheet_fix_issue,
      scheduler_timesheet_view: data.scheduler_timesheet_view,
      scheduler_timesheet_view_edit_history:
        data.scheduler_timesheet_view_edit_history,
      scheduler_timesheet_lock: data.scheduler_timesheet_lock,
      scheduler_timesheet_filter: data.scheduler_timesheet_filter,
      scheduler_timesheet_download: data.scheduler_timesheet_download,
      scheduler_timesheet_view_others_timesheet:
        data.scheduler_timesheet_view_others_timesheet,
      scheduler_timecardreport_view_detail:
        data.scheduler_timecardreport_view_detail,
      scheduler_payroll_view_other_payroll:
        data.scheduler_payroll_view_other_payroll,
      scheduler_payroll_id: data.scheduler_payroll_id,
      scheduler_payroll_add: data.scheduler_payroll_add,
      scheduler_payroll_edit: data.scheduler_payroll_edit,
      scheduler_payroll_delete: data.scheduler_payroll_delete,
      scheduler_payroll_assign: data.scheduler_payroll_assign,
      scheduler_payroll_view_assign: data.scheduler_payroll_view_assign,
      scheduler_payroll_change_export: data.scheduler_payroll_change_export,
      scheduler_payroll_view_export: data.scheduler_payroll_view_export,
      scheduler_payroll_code_add: data.scheduler_payroll_code_add,
      scheduler_payroll_code_edit: data.scheduler_payroll_code_edit,
      scheduler_payroll_code_delete: data.scheduler_payroll_code_delete,
      scheduler_roles_add: data.scheduler_roles_add,
      scheduler_roles_edit: data.scheduler_roles_edit,
      scheduler_roles_delete: data.scheduler_roles_delete,
      scheduler_settings: data.scheduler_settings,
      assignHelpdesk: data.assignHelpdesk,
    };
    this.props.cloneRoles(mydata);
  };

  setAssignUsers = (_id) => {
    this.setState({
      assignUsersLoading: true,
    });

    rolesServices.fetchUsersByRole(_id).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        this.setState({
          assignUsers: response.data.assignUsers,
          assignUsersLoading: false,
        });
      }
    });
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  handleSettings = (e) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [e.target.name]: e.target.checked ? 1 : 0,
      },
    }));
  };

  onChangeSelectall = (e) => {
    var state = [e.target.name];
    let totalCount =
      e.target.checked === true ? 0 : this.state.myItem[e.target.value];
    const newState = this.state[state].map((item) => {
      if (e.target.checked === true) {
        totalCount = totalCount + 1;
        this.setState((prevState) => ({
          myItem: {
            ...prevState.myItem,
            [item.Name]: 1,
          },
        }));
      } else {
        totalCount = totalCount - 1;
        this.setState((prevState) => ({
          myItem: {
            ...prevState.myItem,
            [item.Name]: 0,
          },
        }));
      }
    });

    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [e.target.value]: totalCount,
      },
    }));
  };

  onChangeHelpDeskSelectall = (e) => {
    const allID =
      this.state.helpdeskDataa && this.state.helpdeskDataa.map((id) => id.id);
    let payload = this.state.helpdeskDataa;
    payload.map((item) => {
      item.status = e.target.checked;
    });
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        myItem: {
          ...prevState.myItem,
          assignHelpdesk: allID,
        },
        helpdeskDataa: payload,
      }));
    }

    if (e.target.checked === false) {
      this.setState((prevState) => ({
        myItem: {
          ...prevState.myItem,
          assignHelpdesk: [],
        },
        helpdeskDataa: payload,
      }));
    }
  };

  onChangeAssignLibrary = (e) => {
    const allID =
      this.state.categoryData && this.state.categoryData.map((id) => id._id);
    let payload = this.state.categoryData;
    payload.map((item) => {
      item.myLessons.map((value) => {
        item.selected = e.target.checked === true ? 1 : 0;
        value.selected = e.target.checked === true ? 1 : 0;
      });
    });
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        myItem: {
          ...prevState.myItem,
          assignLibrary: allID,
        },
        categoryData: payload,
      }));
    }

    if (e.target.checked === false) {
      this.setState((prevState) => ({
        myItem: {
          ...prevState.myItem,
          assignLibrary: [],
        },
        categoryData: payload,
      }));
    }
  };

  onChangeHelpdesk = (e, items) => {
    let payload = this.state.helpdeskDataa;
    payload.map((item) => {
      if (item.id === items.id) {
        item.status = e.target.checked;
      }
    });
    var daa = [];
    payload.map((item) => {
      if (item.status === true) {
        daa.push(item.id);
      }
    });
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        assignHelpdesk: daa,
      },
      helpdeskDataa: payload,
    }));
  };

  onChange = (e) => {
    let totalCount = this.state.myItem[e.target.value];
    if (e.target.checked === true) {
      totalCount = totalCount + 1;
    } else {
      totalCount = totalCount - 1;
    }

    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [e.target.name]: e.target.checked ? 1 : 0,
        [e.target.value]: totalCount,
      },
    }));
  };

  assignModalClose = () => {
    this.setState({
      assignModal: false,
    });
  };

  handleSearchLevel = (target) => {
    this.setState({
      searchLevel: target,
    });
  };

  handleSearchRole = (target) => {
    this.setState({
      searchRole: target,
    });
  };

  render() {
    const {
      classes,
      rolesData,
      isLoading,
      pageNo,
      rowsPerPage,
      permissionsData,
    } = this.props;

    const {
      common_Company_Data,
      common_Location_Data,
      common_Users_Data,
      common_Roles_Data,
      common_Payroll_Data,
      common_Ticket_Data,
      common_Ideas_Data,

      feedback_Followups_Data,
      feedback_Campaigns_Data,
      feedback_QR_Locations_Data,
      feedback_Settings_Data,
      elearning_Library_Data,
      elearning_Course_Data,
      elearning_Helpdesk_Data,
      scheduler_Schedule_Data,
      scheduler_payroll_run_Data,
      scheduler_MyAvailability_Data,
      rating_form_Data,
      scheduler_TeamAvailability_Data,
      scheduler_TimeOffRequests_Data,
      scheduler_TradeShifts_Data,
      scheduler_CoverShifts_Data,
      scheduler_Timesheet_Data,
      scheduler_correction_request_Data,
      scheduler_TimecardReport_Data,
      scheduler_Payroll_Data,
      scheduler_Payroll_Summary_Data,
      scheduler_Roles_Data,
      assignUsers,
      assignUsersLoading,
    } = this.state;

    var show_common_company = 0;
    var show_common_location = 0;
    var show_common_users = 0;
    var show_common_roles = 0;
    var show_common_payroll = 0;
    var show_common_tickets = 0;
    var show_common_ideas = 0;
    var show_common_billings = permissionsData.common_billings ? true : false;
    var show_common_coupons = permissionsData.common_coupons ? true : false;
    var show_common_products = permissionsData.common_products ? true : false;

    var show_feedback_dashboard = permissionsData.feedback_dashboard
      ? true
      : false;
    var show_feedback_followups = 0;
    var show_feedback_campaign = 0;
    var show_feedback_qr = 0;
    var show_feedback_reports = permissionsData.feedback_reports ? true : false;
    var show_feedback_settings = 0;
    var show_feedback_team = permissionsData.feedback_team ? true : false;

    var show_elearning_dashboard = permissionsData.elearning_dashboard
      ? true
      : false;
    var show_elearning_mycourse = permissionsData.elearning_mycourse
      ? true
      : false;
    var show_elearning_myhelpdesk = 1;
    var show_elearning_library = 0;
    var show_elearning_course = 0;
    var show_elearning_helpdesk = permissionsData.elearning_helpdesk
      ? true
      : false;
    var show_elearning_all_helpdesk = 0;
    //var show_elearning_reports = permissionsData.elearning_reports ? true : false
    //var show_elearning_team = permissionsData.elearning_team ? true : false

    var show_scheduler_dashboard = permissionsData.scheduler_dashboard
      ? true
      : false;
    var show_scheduler_scheduler = 0;
    var show_scheduler_my_availability = 0;
    var show_scheduler_team_availability = 0;
    var show_scheduler_timeoff_requests = 0;
    var show_scheduler_trade_shifts = 0;
    var show_scheduler_cover_shifts = 0;
    var show_scheduler_payroll_run = 0;
    var show_scheduler_correction_request = 0;
    var show_scheduler_timesheets = 0;
    var show_scheduler_timecard_reports = 0;
    var show_scheduler_payroll_templates = 0;
    var show_scheduler_payroll_summary = 0;
    var show_scheduler_roles = 0;
    var show_scheduler_settings = permissionsData.scheduler_settings
      ? true
      : false;
    var show_receiver_manager_alert = permissionsData.receive_manager_alert
      ? true
      : false;

    var show_rating_form = 0;

    this.state.common_Company_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_company = true;
      }
    });
    this.state.common_Location_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_location = true;
      }
    });
    this.state.common_Users_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_users = true;
      }
    });
    this.state.common_Roles_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_roles = true;
      }
    });
    this.state.common_Payroll_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_payroll = true;
      }
    });
    this.state.common_Ticket_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_tickets = true;
      }
    });
    this.state.common_Ideas_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_common_ideas = true;
      }
    });

    this.state.feedback_Followups_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_feedback_followups = true;
      }
    });
    this.state.feedback_Campaigns_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_feedback_campaign = true;
      }
    });
    this.state.feedback_QR_Locations_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_feedback_qr = true;
      }
    });
    this.state.feedback_Settings_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_feedback_settings = true;
      }
    });
    this.state.elearning_Library_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_elearning_library = true;
      }
    });
    this.state.elearning_Course_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_elearning_course = true;
      }
    });
    this.state.elearning_Helpdesk_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_elearning_helpdesk = true;
      }
    });
    this.state.scheduler_Schedule_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_scheduler = true;
      }
    });
    this.state.scheduler_MyAvailability_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_my_availability = true;
      }
    });
    this.state.scheduler_TeamAvailability_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_team_availability = true;
      }
    });
    this.state.scheduler_TimeOffRequests_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_timeoff_requests = true;
      }
    });
    this.state.scheduler_TradeShifts_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_trade_shifts = true;
      }
    });
    this.state.scheduler_CoverShifts_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_cover_shifts = true;
      }
    });
    this.state.scheduler_Timesheet_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_timesheets = true;
      }
    });
    this.state.scheduler_TimecardReport_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_timecard_reports = true;
      }
    });
    this.state.scheduler_Payroll_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_payroll_templates = true;
      }
    });
    this.state.scheduler_Payroll_Summary_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_payroll_summary = true;
      }
    });
    this.state.scheduler_Roles_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_roles = true;
      }
    });
    this.state.scheduler_payroll_run_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_payroll_run = true;
      }
    });
    this.state.scheduler_correction_request_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_scheduler_correction_request = true;
      }
    });

    var show_common =
      show_common_company ||
      show_common_location ||
      show_common_users ||
      show_common_roles ||
      show_common_payroll ||
      show_common_tickets ||
      show_common_ideas ||
      show_common_billings ||
      show_common_coupons ||
      show_common_products
        ? 1
        : 0;
    var show_elearning =
      show_elearning_dashboard ||
      show_elearning_mycourse ||
      show_elearning_library ||
      show_elearning_helpdesk ||
      show_elearning_all_helpdesk ||
      show_elearning_myhelpdesk
        ? 1
        : 0;
    var show_feedback =
      show_feedback_dashboard ||
      show_feedback_followups ||
      show_feedback_campaign ||
      show_feedback_qr ||
      show_feedback_reports ||
      show_feedback_settings ||
      show_feedback_team
        ? 1
        : 0;
    var show_scheduler =
      show_scheduler_dashboard ||
      show_scheduler_scheduler ||
      show_scheduler_payroll_run ||
      show_scheduler_correction_request ||
      show_scheduler_my_availability ||
      show_scheduler_team_availability ||
      show_scheduler_timeoff_requests ||
      show_scheduler_trade_shifts ||
      show_scheduler_cover_shifts ||
      show_scheduler_timesheets ||
      show_scheduler_timecard_reports ||
      show_scheduler_payroll_templates ||
      show_scheduler_payroll_summary ||
      show_scheduler_roles ||
      show_scheduler_settings ||
      show_receiver_manager_alert
        ? 1
        : 0;

    this.state.rating_form_Data.map((items) => {
      if (permissionsData[items.Name]) {
        show_rating_form = true;
      }
    });
    if (isLoading) return <LoadingData />;

    return permissionsData.common_roles ? (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <MyLeft50Modal
            bgColor={ovHrColor.main}
            title={this.state.isEdit ? "Edit Roles" : "Add Roles"}
            open={this.state.addEditModal}
            onClose={this.addEditModalClose}
            handleSubmit={this.handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MyTextfield
                  name="role_name"
                  label="Name"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.role_name}
                  type="text"
                  error={this.state.errors.role_name}
                  helperText={
                    this.state.errors.role_name
                      ? this.state.errorsHelper.role_name
                      : ""
                  }
                  placeholder="Enter role name"
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="role_description"
                  label="Description"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.role_description}
                  type="text"
                  error={this.state.errors.role_description}
                  helperText={
                    this.state.errors.role_description
                      ? this.state.errorsHelper.role_description
                      : ""
                  }
                  placeholder="Enter description"
                />
              </Grid>

              {/* feedback main start */}
              {show_feedback ? (
                <Grid item md={12} xs={12}>
                  <ExpansionPanel className={classes.ExpansionPanelMain}>
                    <ExpansionPanelSummary
                      className={classes.ExpansionPanelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.headingIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Feedback
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      className={classes.ExpansionPanelDetailsSecond}
                    >
                      <Grid container>
                        {/* feedback dashboard start */}
                        {show_feedback_dashboard ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="feedback_dashboard"
                                  checked={
                                    this.state.myItem.feedback_dashboard
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Dashboard
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}

                        {/* feedback dashboard end */}

                        {/* feedback followups start */}
                        {show_feedback_followups ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .feedback_Followups_Data_Count > 0 &&
                                    this.state.myItem
                                      .feedback_Followups_Data_Count <
                                      feedback_Followups_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="feedback_Followups_Data"
                                  value="feedback_Followups_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .feedback_Followups_Data_Count ===
                                    feedback_Followups_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Followups
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {feedback_Followups_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="feedback_Followups_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback campaign start */}
                        {show_feedback_campaign ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .feedback_Campaigns_Data_Count > 0 &&
                                    this.state.myItem
                                      .feedback_Campaigns_Data_Count <
                                      feedback_Campaigns_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="feedback_Campaigns_Data"
                                  value="feedback_Campaigns_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .feedback_Campaigns_Data_Count ===
                                    feedback_Campaigns_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Campaign
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {feedback_Campaigns_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="feedback_Campaigns_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback campaign end */}

                        {/* feedback qr locations start */}
                        {show_feedback_qr ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .feedback_QR_Locations_Data_Count > 0 &&
                                    this.state.myItem
                                      .feedback_QR_Locations_Data_Count <
                                      feedback_QR_Locations_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="feedback_QR_Locations_Data"
                                  value="feedback_QR_Locations_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .feedback_QR_Locations_Data_Count ===
                                    feedback_QR_Locations_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Locations
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {feedback_QR_Locations_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="feedback_QR_Locations_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback qr locations end */}

                        {/* feedback dashboard start */}
                        {show_feedback_dashboard ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="feedback_reports"
                                  checked={
                                    this.state.myItem.feedback_reports
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Reports
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback dashboard end */}

                        {/* feedback qr locations start */}
                        {show_feedback_settings ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .feedback_Settings_Data_Count > 0 &&
                                    this.state.myItem
                                      .feedback_Settings_Data_Count <
                                      feedback_Settings_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="feedback_Settings_Data"
                                  value="feedback_Settings_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .feedback_Settings_Data_Count ===
                                    feedback_Settings_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Settings
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {feedback_Settings_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="feedback_Settings_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback qr locations end */}

                        {/* feedback team start */}
                        {show_feedback_team ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="feedback_team"
                                  checked={
                                    this.state.myItem.feedback_team
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Team
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback team end */}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : (
                ""
              )}
              {/* feedback main end */}

              {/* common main start */}
              {show_common ? (
                <Grid item md={12} xs={12}>
                  <ExpansionPanel className={classes.ExpansionPanelMain}>
                    <ExpansionPanelSummary
                      className={classes.ExpansionPanelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.headingIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Common
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      className={classes.ExpansionPanelDetailsSecond}
                    >
                      <Grid container>
                        {/* feedback followups start */}
                        {show_common_company ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .common_Company_Data_Count > 0 &&
                                    this.state.myItem
                                      .common_Company_Data_Count <
                                      common_Company_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Company_Data"
                                  value="common_Company_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Company_Data_Count ===
                                    common_Company_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Company
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Company_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Company_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback followups start */}
                        {show_common_location ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .common_Location_Data_Count > 0 &&
                                    this.state.myItem
                                      .common_Location_Data_Count <
                                      common_Location_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Location_Data"
                                  value="common_Location_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Location_Data_Count ===
                                    common_Location_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Locations
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Location_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Location_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback followups start */}
                        {show_common_users ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem.common_Users_Data_Count >
                                      0 &&
                                    this.state.myItem.common_Users_Data_Count <
                                      common_Users_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Users_Data"
                                  value="common_Users_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Users_Data_Count ===
                                    common_Users_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Users
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Users_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Users_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback followups start */}
                        {show_common_roles ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem.common_Roles_Data_Count >
                                      0 &&
                                    this.state.myItem.common_Roles_Data_Count <
                                      common_Roles_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Roles_Data"
                                  value="common_Roles_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Roles_Data_Count ===
                                    common_Roles_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Roles
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Roles_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Roles_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback followups start */}
                        {show_common_payroll ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .common_Payroll_Data_Count > 0 &&
                                    this.state.myItem
                                      .common_Payroll_Data_Count <
                                      common_Payroll_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Payroll_Data"
                                  value="common_Payroll_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Payroll_Data_Count ===
                                    common_Payroll_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Payroll Management
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Payroll_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Payroll_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback followups start */}
                        {show_common_tickets ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem.common_Ticket_Data_Count >
                                      0 &&
                                    this.state.myItem.common_Ticket_Data_Count <
                                      common_Ticket_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Ticket_Data"
                                  value="common_Ticket_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Ticket_Data_Count ===
                                    common_Ticket_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Ticket
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Ticket_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Ticket_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* feedback followups start */}
                        {show_common_ideas ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem.common_Ideas_Data_Count >
                                      0 &&
                                    this.state.myItem.common_Ideas_Data_Count <
                                      common_Ideas_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="common_Ideas_Data"
                                  value="common_Ideas_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .common_Ideas_Data_Count ===
                                    common_Ideas_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Ideas
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {common_Ideas_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="common_Ideas_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* feedback followups end */}

                        {/* common billing start */}
                        {show_common_billings ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="common_billings"
                                  checked={
                                    this.state.myItem.common_billings
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Billings
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* common billing end */}

                        {/* common company start */}
                        {show_common_coupons ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="common_coupons"
                                  checked={
                                    this.state.myItem.common_coupons
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Coupons
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* common billing end */}

                        {/* common company start */}
                        {show_common_products ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="common_products"
                                  checked={
                                    this.state.myItem.common_products
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Products
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* common billing end */}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : (
                ""
              )}
              {/* feedback main end */}

              {/* elearning main start */}
              {show_elearning ? (
                <Grid item md={12} xs={12}>
                  <ExpansionPanel className={classes.ExpansionPanelMain}>
                    <ExpansionPanelSummary
                      className={classes.ExpansionPanelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.headingIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Elearning
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      className={classes.ExpansionPanelDetailsSecond}
                    >
                      <Grid container>
                        {/* elearning dashboard start */}
                        {show_elearning_dashboard ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="elearning_dashboard"
                                  checked={
                                    this.state.myItem.elearning_dashboard
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Dashboard
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* elearning dashboard end */}

                        {/* elearning team start */}
                        {show_elearning_mycourse ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="elearning_mycourse"
                                  checked={
                                    this.state.myItem.elearning_mycourse
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  My Course
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* elearning team end */}

                        {/* elearning library start */}
                        {show_elearning_library ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .elearning_Library_Data_Count > 0 &&
                                    this.state.myItem
                                      .elearning_Library_Data_Count <
                                      elearning_Library_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="elearning_Library_Data"
                                  value="elearning_Library_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .elearning_Library_Data_Count ===
                                    elearning_Library_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Library
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {elearning_Library_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="elearning_Library_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* elearning library end */}

                        {/* elearning library start */}
                        {show_elearning_helpdesk ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem.assignLibrary &&
                                    this.state.myItem.assignLibrary.length === 0
                                      ? false
                                      : this.state.myItem.assignLibrary &&
                                        this.state.myItem.assignLibrary.length >
                                          0 &&
                                        this.state.myItem.assignLibrary
                                          .length !==
                                          this.state.categoryData.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeAssignLibrary}
                                  name="assignLibrary"
                                  value="assignLibrary"
                                  checked={
                                    this.state.myItem.assignLibrary &&
                                    this.state.myItem.assignLibrary.length ===
                                      this.state.categoryData.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Assign Library
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={
                                  classes.ExpansionPanelDetailsThirdRole
                                }
                              >
                                <MySearchRoleBar
                                  clearSearch={this.clearSearch}
                                  handleSearch={this.handleSearch}
                                  value={this.state.searchUsers}
                                  placeholder="Search"
                                />
                                {this.state.categoryData.map((value, index) => {
                                  return (
                                    <Grid item md={12} xs={12}>
                                      <ExpansionPanel
                                        className={classes.ExpansionPanel}
                                      >
                                        <ExpansionPanelSummary
                                          className={
                                            classes.ExpansionPanelDetailsThirdCategory
                                          }
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-label="Expand"
                                          aria-controls="additional-actions3-content"
                                          id="additional-actions3-header"
                                        >
                                          <Grid
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                            }}
                                          >
                                            <Grid item md={8}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    className={
                                                      classes.FormControlLabelSecond
                                                    }
                                                    //disabled={value.assigned_from_role === 1 ? true : false}
                                                    onChange={(e) =>
                                                      this.handleCategory(
                                                        e,
                                                        value._id,
                                                        index
                                                      )
                                                    }
                                                    checked={
                                                      value.selected === 1
                                                        ? true
                                                        : false
                                                    }
                                                    color="primary"
                                                    name={value.category_name}
                                                  />
                                                }
                                                label={value.category_name}
                                              />
                                            </Grid>
                                            <Grid item md={4}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    className={
                                                      classes.FormControlLabelSecond
                                                    }
                                                    // disabled={value.assigned_from_role === 1 ? true : false}
                                                    // checked={items.selected === 1 ? true : false}
                                                    // onChange={(e) =>
                                                    //     this.props.handleUsers(
                                                    //       e,
                                                    //       value._id,
                                                    //       items._id,
                                                    //       index
                                                    //     )
                                                    //   }
                                                    //value="feedback_QR_Locations_Data_Count"
                                                    color="primary"
                                                    name={value.category_name}
                                                  />
                                                }
                                                label="Auto assign"
                                              />
                                            </Grid>
                                            <Tooltip
                                              arrow
                                              title="Auto assign means all current snd future lessons"
                                            >
                                              <IconButton
                                                aria-label="More"
                                                aria-haspopup="true"
                                              >
                                                <InfoOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        </ExpansionPanelSummary>

                                        <ExpansionPanelDetails
                                          className={
                                            classes.ExpansionPanelDetailsThird
                                          }
                                        >
                                          <Grid container>
                                            {value.myLessons.map(
                                              (items, index) => {
                                                return (
                                                  <Grid item md={4} xs={12}>
                                                    {/* <Typography> {items.lesson_name} </Typography> */}
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          className={
                                                            classes.FormControlLabelSecond
                                                          }
                                                          //disabled={value.assigned_from_role === 1 ? true : false}
                                                          checked={
                                                            items.selected === 1
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            this.handleUsers(
                                                              e,
                                                              value._id,
                                                              items._id,
                                                              index
                                                            )
                                                          }
                                                          name={
                                                            items.lesson_name
                                                          }
                                                          //value="feedback_QR_Locations_Data_Count"
                                                          color="primary"
                                                        />
                                                      }
                                                      label={items.lesson_name}
                                                    />
                                                  </Grid>
                                                );
                                              }
                                            )}
                                          </Grid>
                                        </ExpansionPanelDetails>
                                      </ExpansionPanel>
                                    </Grid>
                                  );
                                })}
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* elearning library end */}

                        {/* elearning team start */}
                        {/* {show_elearning_team ?
                            <Grid
                              item
                              md={12}
                              xs={12}
                            >
                              <ExpansionPanel className={classes.ExpansionPanel} >
                                <ExpansionPanelSummary
                                  className={classes.ExpansionPanelSummaryThird}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions3-content"
                                  id="additional-actions3-header"
                                >
                                  <Checkbox
                                    className={classes.FormControlLabelSecond}
                                    onChange={this.handleSettings}
                                    name="elearning_team"
                                    checked={this.state.myItem.elearning_team ? true : false}
                                    color="primary"
                                  />
                                  <Typography className={classes.pageTitle} variant="h6" color="primary" >
                                    Team
                                  </Typography>
                                </ExpansionPanelSummary>
                              </ExpansionPanel>
                            </Grid>
                            : ""} */}
                        {/* elearning team end */}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : (
                ""
              )}
              {/* elearning main end */}

              {/* scheduler main start */}
              {show_scheduler ? (
                <Grid item md={12} xs={12}>
                  <ExpansionPanel className={classes.ExpansionPanelMain}>
                    <ExpansionPanelSummary
                      className={classes.ExpansionPanelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.headingIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Scheduler
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      className={classes.ExpansionPanelDetailsSecond}
                    >
                      <Grid container>
                        {/* scheduler dashboard start */}
                        {show_scheduler_dashboard ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="scheduler_dashboard"
                                  checked={
                                    this.state.myItem.scheduler_dashboard
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Scheduler Dashboard
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler dashboard end */}

                        {/* scheduler Scheduler start */}
                        {show_scheduler_scheduler ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_Schedule_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_Schedule_Data_Count <
                                      scheduler_Schedule_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_Schedule_Data"
                                  value="scheduler_Schedule_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_Schedule_Data_Count ===
                                    scheduler_Schedule_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Scheduler
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_Schedule_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_Schedule_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* schedule scheduler end */}

                        {/* myavailability start */}
                        {show_scheduler_my_availability ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_MyAvailability_Data_Count >
                                      0 &&
                                    this.state.myItem
                                      .scheduler_MyAvailability_Data_Count <
                                      scheduler_MyAvailability_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_MyAvailability_Data"
                                  value="scheduler_MyAvailability_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_MyAvailability_Data_Count ===
                                    scheduler_MyAvailability_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  My Availability
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_MyAvailability_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_MyAvailability_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* myavailability end */}

                        {/* TeamAvailability start */}
                        {show_scheduler_team_availability ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_TeamAvailability_Data_Count >
                                      0 &&
                                    this.state.myItem
                                      .scheduler_TeamAvailability_Data_Count <
                                      scheduler_TeamAvailability_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_TeamAvailability_Data"
                                  value="scheduler_TeamAvailability_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_TeamAvailability_Data_Count ===
                                    scheduler_TeamAvailability_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Team Availability
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_TeamAvailability_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_TeamAvailability_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* teamAvailability end */}

                        {/* scheduler_TimeOffRequests start */}
                        {show_scheduler_timeoff_requests ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_TimeOffRequests_Data_Count >
                                      0 &&
                                    this.state.myItem
                                      .scheduler_TimeOffRequests_Data_Count <
                                      scheduler_TimeOffRequests_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_TimeOffRequests_Data"
                                  value="scheduler_TimeOffRequests_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_TimeOffRequests_Data_Count ===
                                    scheduler_TimeOffRequests_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Time-Off Requests
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_TimeOffRequests_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_TimeOffRequests_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_TimeOffRequests end */}

                        {/* scheduler_TradeShifts start */}
                        {show_scheduler_trade_shifts ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_TradeShifts_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_TradeShifts_Data_Count <
                                      scheduler_TradeShifts_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_TradeShifts_Data"
                                  value="scheduler_TradeShifts_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_TradeShifts_Data_Count ===
                                    scheduler_TradeShifts_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Trade Shifts
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_TradeShifts_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_TradeShifts_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_TradeShifts end */}

                        {/* scheduler_covershift start */}
                        {show_scheduler_cover_shifts ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_CoverShifts_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_CoverShifts_Data_Count <
                                      scheduler_CoverShifts_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_CoverShifts_Data"
                                  value="scheduler_CoverShifts_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_CoverShifts_Data_Count ===
                                    scheduler_CoverShifts_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Cover Shifts
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_CoverShifts_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_CoverShifts_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_coverShifts end */}

                        {/* scheduler_Timesheet start */}
                        {show_scheduler_timesheets ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_Timesheet_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_Timesheet_Data_Count <
                                      scheduler_Timesheet_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_Timesheet_Data"
                                  value="scheduler_Timesheet_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_Timesheet_Data_Count ===
                                    scheduler_Timesheet_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Timesheets
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_Timesheet_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_Timesheet_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}

                        {/* scheduler_payroll start */}
                        {show_scheduler_payroll_templates ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_Payroll_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_Payroll_Data_Count <
                                      scheduler_Payroll_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_Payroll_Data"
                                  value="scheduler_Payroll_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_Payroll_Data_Count ===
                                    scheduler_Payroll_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Payroll Template
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_Payroll_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_Payroll_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_payroll end */}

                        {/* payroll run start */}
                        {show_scheduler_payroll_run ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_payroll_run_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_payroll_run_Data_Count <
                                      scheduler_payroll_run_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_payroll_run_Data"
                                  value="scheduler_payroll_run_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_payroll_run_Data_Count ===
                                    scheduler_payroll_run_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Payroll Run
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_payroll_run_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_payroll_run_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* payroll run end */}

                        {/* correction request start */}
                        {show_scheduler_correction_request ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_correction_request_Data_Count >
                                      0 &&
                                    this.state.myItem
                                      .scheduler_correction_request_Data_Count <
                                      scheduler_correction_request_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_correction_request_Data"
                                  value="scheduler_correction_request_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_correction_request_Data_Count ===
                                    scheduler_correction_request_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Correction Request
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_correction_request_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_correction_request_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* correction request end */}

                        {/* scheduler_payroll_summary start */}
                        {show_scheduler_payroll_summary ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_Payroll_Summary_Data_Count >
                                      0 &&
                                    this.state.myItem
                                      .scheduler_Payroll_Summary_Data_Count <
                                      scheduler_Payroll_Summary_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_Payroll_Summary_Data"
                                  value="scheduler_Payroll_Summary_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_Payroll_Summary_Data_Count ===
                                    scheduler_Payroll_Summary_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Payroll Summary
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_Payroll_Summary_Data.map(
                                    (items, index) => {
                                      if (permissionsData[items.Name])
                                        return (
                                          <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className={
                                                    classes.FormControlLabelSecond
                                                  }
                                                  checked={
                                                    this.state.myItem[
                                                      items.Name
                                                    ]
                                                  }
                                                  onChange={this.onChange}
                                                  name={items.Name}
                                                  value="scheduler_Payroll_Summary_Data_Count"
                                                  color="primary"
                                                />
                                              }
                                              label={items.Label}
                                            />
                                          </Grid>
                                        );
                                    }
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_payroll_summary end */}

                        {/* scheduler_Roles start */}
                        {show_scheduler_roles ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem
                                      .scheduler_Roles_Data_Count > 0 &&
                                    this.state.myItem
                                      .scheduler_Roles_Data_Count <
                                      scheduler_Roles_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="scheduler_Roles_Data"
                                  value="scheduler_Roles_Data_Count"
                                  checked={
                                    this.state.myItem
                                      .scheduler_Roles_Data_Count ===
                                    scheduler_Roles_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Scheduler Roles
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {scheduler_Roles_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="scheduler_Roles_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_Roles end */}

                        {/* scheduler_Roles start */}
                        {show_scheduler_settings ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="scheduler_settings"
                                  checked={
                                    this.state.myItem.scheduler_settings
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Scheduler Settings
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* scheduler_Roles end */}

                        {/* Manager alert start */}
                        {show_receiver_manager_alert ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  onChange={this.handleSettings}
                                  name="receive_manager_alert"
                                  checked={
                                    this.state.myItem.receive_manager_alert
                                      ? true
                                      : false
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Receive Manager Alerts
                                </Typography>
                              </ExpansionPanelSummary>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* Manager alert end  */}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : (
                ""
              )}
              {/* scheduler main end */}

              {/* 360 rating main start */}
              {show_scheduler ? (
                <Grid item md={12} xs={12}>
                  <ExpansionPanel className={classes.ExpansionPanelMain}>
                    <ExpansionPanelSummary
                      className={classes.ExpansionPanelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.headingIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        360 Rating
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      className={classes.ExpansionPanelDetailsSecond}
                    >
                      <Grid container>
                        {/* myavailability start */}
                        {show_scheduler_my_availability ? (
                          <Grid item md={12} xs={12}>
                            <ExpansionPanel className={classes.ExpansionPanel}>
                              <ExpansionPanelSummary
                                className={classes.ExpansionPanelSummaryThird}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions3-header"
                              >
                                <Checkbox
                                  className={classes.FormControlLabelSecond}
                                  indeterminate={
                                    this.state.myItem.rating_form_Data_Count >
                                      0 &&
                                    this.state.myItem.rating_form_Data_Count <
                                      rating_form_Data.length
                                      ? true
                                      : false
                                  }
                                  onChange={this.onChangeSelectall}
                                  name="rating_form_Data"
                                  value="rating_form_Data_Count"
                                  checked={
                                    this.state.myItem.rating_form_Data_Count ===
                                    rating_form_Data.length
                                  }
                                  color="primary"
                                />
                                <Typography
                                  className={classes.pageTitle}
                                  variant="h6"
                                  color="primary"
                                >
                                  Rating form
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails
                                className={classes.ExpansionPanelDetailsThird}
                              >
                                <Grid container>
                                  {rating_form_Data.map((items, index) => {
                                    if (permissionsData[items.Name])
                                      return (
                                        <Grid item md={4} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.FormControlLabelSecond
                                                }
                                                checked={
                                                  this.state.myItem[items.Name]
                                                }
                                                onChange={this.onChange}
                                                name={items.Name}
                                                value="rating_form_Data_Count"
                                                color="primary"
                                              />
                                            }
                                            label={items.Label}
                                          />
                                        </Grid>
                                      );
                                  })}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {/* myavailability end */}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : (
                ""
              )}
              {/* rating main end */}
            </Grid>
          </MyLeft50Modal>

          <Grid item style={{ display: "flex" }} md={12} sm={12}>
            <Grid item xs={3} sm={3}>
              <div
                style={{
                  width: "fit-content",
                  height: "29px",
                  padding: "0px 4px",
                }}
                className="searchBarHeight"
              >
                <MySearchBar
                  clearSearch={this.clearSearch}
                  handleSearch={this.handleSearchLevel}
                  value={this.state.searchLevel}
                  placeholder="Search Location"
                />
              </div>
            </Grid>

            <Grid item xs={7} sm={7}>
              <div
                style={{
                  width: "fit-content",
                  height: "29px",
                  padding: "0px 4px",
                }}
                className="searchBarHeight"
              >
                <MySearchBar
                  clearSearch={this.clearSearch}
                  handleSearch={this.handleSearchRole}
                  value={this.state.searchRole}
                  placeholder="Search Role"
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={2}>
              <div variant="regular" className={classes.rootToolbar}>
                <Button
                  className={classes.addRoleButton}
                  disabled={permissionsData.common_roles_add ? false : true}
                  style={{ float: "right" }}
                  size="small"
                  onClick={() => {
                    this.moreMenuClick(null, "add");
                  }}
                >
                  Add Role
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.settingsRoles.isLoading,
  rolesData: state.settingsRoles.rolesData,
  helpdeskData: state.eLearningHelpDesk.helpdeskData,
  categoryData: state.eLearningCategory.categoryData,
  allLessonData: state.eLearningLessons.allLessonData,
  permissionsData: state.settingsRoles.permissionsData,
});

const ConnectWith = connect(mapStateToProps, {
  cloneRoles,
  fetchData,
  addData,
  editData,
  deleteData,
  loginAsUser,
  fetchHelpdesk,
  fetchCategory,
  fetchAllLessons,
})(Roles);
export default withStyles(styles)(ConnectWith);
