
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, TableMyPagination, TableRecordNotFound, LoadingData, DeleteModal, MySearchBar, MyLeftSchedulerModal } from '../../../components'
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { fetchData, addData, editData, deleteData } from './modules/actions';
import { connect } from "react-redux";
import { TextField } from '@material-ui/core';

import {
    Delete as DeleteIcon
} from '@material-ui/icons';
import moment from "moment";
import { utcToLocal, invalidToken, alert } from '../../../utilities';
import { FILE_URL, schedulerColor } from '../../../config';
import { coverServices } from "./modules/services";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SearchBar from "material-ui-search-bar";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { history } from "../../../history";

import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    Avatar,
    Button
}
    from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    TableCell: {
        padding: '10px'
    },
    headCell: {
        padding: '0.3rem 0rem',
        color: '#aa076b',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    cardDate: {
        backgroundImage: schedulerColor.main,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        flex: '1 1 100%',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '27px',
        color: '#1E2134'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    userName: {
        maxWidth: "250px",
        // lineHeight: 3,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        color: '#1E2134',
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "35px",
        width: "35px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundImage: theme.palette.primary.main,
        color: "white",
    },
    button: {
        color: '#ffffff',
        padding: '6px 10px',
        width: "80px",
        fontWeight: '400',
        textTransform: "Capitalize",
    },
    SearchBar: {
        fontSize: "14px !important",
        color: "#7D7D7D !important",
        width: "455px !important",
        height: "46px !important",
        border: "none !important",
        fontFamily: "century_gothic !important",
        borderRadius: "22px !important",
        paddingLeft: "20px !important",
        paddingTop: "9px !important",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25) !important",
    },
    icon: {
        position: "absolute",
        // right: "32px",
        left: "10px",
        fontSize: "23px",
        top: "50%",
        color: "#7D7D7D",
        transform: "translate(0%, -50%)",
    }

});

export function fetchCoverShiftsData() {
    window.CoverShift.fetchData(true);
}

class TeamCoverShift extends Component {
    constructor(props) {
        super(props)
        window.CoverShift = this;
        this.state = {
            myItem: {},
            order: 'desc',
            orderBy: "id",
            searchUsers: "",
            teamTradeData: [],
            page: 1,
            headCells: [
                { id: 'user_name', label: "Requested To", numeric: false },
                { id: 'requestedBy', label: "Requested By", numeric: false },
                { id: 'created_At', label: "Shift To Cover", numeric: false },
                { id: 'created_At', label: "Created At", numeric: false },
                { id: 'location_name', label: "Location Name", numeric: false },
                { id: 'user_status', label: "Status", numeric: false },
            ]
        }
    }

    componentDidMount = () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
        this.fetchData(value)  
    }


    handleChange = (event, value) => {
        var url = `/scheduler/covershift/team?page=` + value ;
        this.setState({
            page:value
        },() => {
            this.fetchData(value);
            history.push(url);
        })
    }

    fetchData = (value) => {
        this.setState({
            page:value,
            isLoading:true
    })
        var data = {
            rowsPerPage:10,
            pageNo: value,
            lid: this.props.scheduler_lid
        }
        coverServices.fetchTeamData(data).then(response => {
            this.setState({
                teamCoverData: response.data.Tmp_newteamcover,
                totalpage:response.data.totalpage,
                isLoading:false
            })

        })
    }

    // onChangeRowsPerPage = (event) => {
    //     this.setState({
    //       rowsPerPage: parseInt(event.target.value, 10)
    //     },() => {
    //         this.fetchData(true)
    //     })
    //   }


    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    deleteData = () => {
        this.setState({ deleteModal: false })
        this.props.deleteData(this.state.myItem.id)
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false
        })
    };

    modifyData = (value, item) => {
        this.setState({
            myItem: item,
            deleteModal: value === "delete" ? true : false
        })
    }

    handleSearch = (e) => {

        this.setState({
            searchUsers:e.target.value
        })
        // var updatedList = this.state.teamCoverDataTemp;
        // if (target) {
        //     updatedList = updatedList.filter(function (item) {
        //         return (
        //             item.user_name.toLowerCase().search(target.toLowerCase()) !== -1
        //         );
        //     });
        // }
        //     var teamCoverData = updatedList.filter((item) => item.user_name)
        //     this.setState({
        //         searchUsers: target,
        //         teamCoverData,
        //     });

    };

    
    approveCover = (row) => {
        this.setState({
            approveCoverModal: true,
            tradeUserData: row
        })
    }

    closeApproveModal = () => {
        this.setState({
            approveCoverModal: false
        })
    }

    searchdara = () => {
        this.setState({
            isLoading:true
        })
        var data = {
            rowsPerPage: 10,
            pageNo: this.state.page,
            lid: this.props.scheduler_lid,
            filter:this.state.searchUsers
        }
        coverServices.fetchTeamData(data).then(response => {
            this.setState({
                teamCoverData: response.data.Tmp_newteamcover,
                totalpage:response.data.totalpage,
                isLoading:false
            })

        })
    }

    clearSearch = () => {
        // var teamCoverData = this.state.teamCoverDataTemp.filter((item) => item.user_name)
        // this.setState({
        //     searchUsers: "",
        //     teamCoverData,
        // });
    }

    openDetailModal = (row) => {
        this.setState({
            detailModal: true,
            CoverUserData: row,
            isUserLoading: true
        })
    }

    closeDetalModal = () => {
        this.setState({
            detailModal: false
        })
    }

    // openDetailModal = (row) => {
    //     this.setState({
    //         detailModal: true,
    //         userData: row,
    //         isUserLoading: true
    //     })
    //     coverServices.approveRejectTeamCover(row.id).then(response => {
    //         if (response.data.success === 2) {
    //             invalidToken(response.data.message)
    //         } else {
    //             this.setState({
    //                 tradeUserData: response.data.tradeUsers ? response.data.tradeUsers[0] : "",
    //                 isUserLoading: false
    //             })
    //         }
    //     })
    // }


    handleApprove = (row) => {
        var data = {
            status: row.manager_status === 0 ?  1 : 0,
            uid: row.requested_to_uid
        }
        coverServices.approveRejectTeamCover(row.id, data).then(response => {
            if (response.data.success === 2) {
                invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.closeDetalModal()
                const queryParameters = new URLSearchParams(window.location.search)
                const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
                this.fetchData(value)  
            }

        })
    }


    handleReject = (row) => {
        var data = {
            status: 2,
            job_id: this.state.CoverUserData.job_id
        }
        coverServices.approveRejectTeamCover(this.state.CoverUserData.id, data).then(response => {
            if (response.data.success === 2) {
                invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.closeDetalModal()
            }
        })
    }

    render() {
        const { classes, isLoading, pageNo, permissionsData, rowsPerPage } = this.props;
        const { teamCoverData ,totalpage} = this.state;
        var nextDayDate = false
        var currentDate = new Date(this.state.CoverUserData?.created_at)
        var startTime = new Date(this.state.CoverUserData?.startTime)
        var endTime = new Date(this.state.CoverUserData?.endTime)
        var currentDateNew = currentDate.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
        var startTimeNew = startTime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
        var endTimeNew = endTime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
        if (moment(endTimeNew, "DD/MM/YYYY,hh:mm:ss A").format("HH:mm:ss A") < moment(startTimeNew, "DD/MM/YYYY,hh:mm:ss A").format("HH:mm:ss A")) {
            nextDayDate = true
        }
        return (
            permissionsData.scheduler_cover_shift ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Paper>
                            <Toolbar variant="regular" className={classes.rootToolbar}>
                                <Typography
                                    display="inline"
                                    className={classes.title}
                                    variant="h6" >
                                    <b style={{
                                        color: "#AA076B",
                                        fontWeight: "500",
                                        fontSize: "17px",
                                        marginLeft: "15px",
                                    }}>Team Cover </b>
                                </Typography>
                                <TextField
                                    className={classes.SearchBar}
                                
                                    onChange={(e) => { this.handleSearch(e) }}
                                   placeholder="Search User"
                                    InputProps={{
                                        disableUnderline: true ,
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <SearchIcon onClick={this.searchdara} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                {/* <MySearchBar
                               
                                    clearSearch={this.clearSearch}
                                    handleSearch={this.handleSearch}
                                    value={this.state.searchUsers}
                                    placeholder="Search user"
                                /> */}
                            </Toolbar>

                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    size='medium'
                                >
                                    <TableHead style={{ backgroundColor: 'rgba(170, 7, 107, 0.125)' }}>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                                >
                                                    <TableSortLabel
                                                        className={classes.headCell}
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler(headCell.id)}
                                                    >
                                                        {headCell.label}
                                                        {this.state.orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {this.state.isLoading ? 
                                    <TableBody>
                                        <LoadingData/>
                                    </TableBody>:
                                   <TableBody>
                                   {stableSort(teamCoverData, getComparator(this.state.order, this.state.orderBy))
                                       .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                                       .map((row, index) => {
                                        var currentDate = new Date(row.createdAt)
                                        var currentDateNew = currentDate.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                        var startTime = new Date(row?.shift_to_cover_startTime)
                                        var endTime = new Date(row?.shift_to_cover_endTime)
                                        var startTimeNew = startTime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                        var endTimeNew = endTime.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                        // var startTimeReplaceWith = new Date(row?.shift_to_trade_startTime)
                                        // var endTimeReplaceWith = new Date(row?.shift_to_trade_endTime)
                                        // var startTimeNewReplaceWith = startTimeReplaceWith.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                        // var endTimeNewReplaceWith = endTimeReplaceWith.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                                           return (
                                               <TableRow
                                                   hover
                                                   tabIndex={-1}
                                                   key={row.id}
                                               >
                                                   <TableCell
                                                       style={{ verticalAlign: "middle" }}
                                                       className={classes.TableCell}>
                                                       {row.user_image ?
                                                           <Avatar
                                                               src={FILE_URL + row.req_user_image}
                                                               className={classes.avatar}
                                                           />
                                                           :
                                                           <Avatar className={classes.avatar} >
                                                               {row.requested_to.charAt(0)}
                                                           </Avatar>
                                                       }
                                                       <Typography
                                                           className={classes.userName}
                                                           style={{ marginTop: '5px' }}
                                                       >
                                                           {row.requested_to}
                                                       </Typography>
                                                   </TableCell>
                                                   <TableCell
                                                       className={classes.TableCell}
                                                   >
                                                       <Typography
                                                           className={classes.userName}
                                                       >
                                                           {row.requested_by}
                                                       </Typography>
                                                   </TableCell>

                                                   <TableCell
                                                       className={classes.TableCell}
                                                   >
                                                       <Typography
                                                           className={classes.userName}
                                                       >
                                                              {moment(startTimeNew).locale('en-US').format("DD MMM YYYY hh:mm A")} - {moment(endTimeNew).locale('en-US').format("DD MMM YYYY hh:mm A")}
                                                       </Typography>
                                                   </TableCell>



                                                   <TableCell
                                                       className={classes.TableCell}
                                                   >
                                                       <Typography
                                                           className={classes.userName}
                                                       >
                                                           {row.createdAt ? moment(currentDateNew).locale('en-US').format("DD MMM YYYY") : ""} 
                                                       </Typography>
                                                   </TableCell>

                                                   <TableCell
                                                       className={classes.TableCell}
                                                   >
                                                       <Typography
                                                           className={classes.userName}
                                                       >
                                                           {row.location_name}
                                                       </Typography>
                                                   </TableCell>



                                                   <TableCell
                                                       className={classes.TableCell}>
                                                       {parseInt(row.manager_status) === 1 ?
                                                           <Typography  onClick={() => this.handleApprove(row)} style={{ color: "#5DBD55", fontWeight: 800}}>Approved</Typography>
                                                           :
                                                           parseInt(row.manager_status) === 0 ?
                                                               <Button
                                                                   style={{ backgroundImage: schedulerColor.main }}
                                                                   className={classes.button}
                                                                   onClick={() => this.handleApprove(row)}
                                                                   variant="contained"
                                                               >
                                                                   Pending
                                                               </Button>
                                                               :
                                                               <Button
                                                                   style={{ backgroundColor: "#B71C1C" }}
                                                                   className={classes.button}
                                                                   onClick={() => this.handleApprove(row)}
                                                                   variant="contained"
                                                               >
                                                                   Rejected
                                                               </Button>
                                                       }
                                                       {/* <Button
                                                           style={{ backgroundColor: parseInt(row.user_status) === 0 ? schedulerColor.main : parseInt(row.user_status) === 1 ? "#5DBD55" : "#B71C1C" }}
                                                           className={classes.button}
                                                           onClick={() =>  this.openDetailModal(row)}
                                                           variant="contained"
                                                       >
                                                           {parseInt(row.user_status) === 0 ? "Pending" : parseInt(row.user_status) === 1 ? "Approved" : "Rejected"}
                                                       </Button> */}
                                                   </TableCell>

                                                


                                               </TableRow>
                                           );
                                       })}

                                   {teamCoverData && teamCoverData.length < 1 ?
                                       <TableRecordNotFound
                                           colSpan={8}
                                           label="No Data Found.."
                                       />
                                       : ""
                                   }

                               </TableBody>}
                                </Table>
                                <Stack spacing={2}>
                                    <Pagination color="primary" 
                                    count={totalpage === 0 ? 1 : totalpage} variant="outlined" page={parseInt(this.state.page)} onChange={this.handleChange}
                                        className={classes.pagination}
                                    />
                                </Stack>
                            </TableContainer>


                            {/* <TableMyPagination
                            rowsPerPage={rowsPerPage}
                            pageNo={pageNo}
                            onChangeRowsPerPage={this.onChangeRowsPerPage}
                            onChangePage={this.props.onChangePage}
                            count={teamCoverData ? teamCoverData.length : 0}
                            /> */}
                        </Paper>

                        <DeleteModal
                            bgColor={schedulerColor.main}
                            desc="Are you sure you want to delete?"
                            open={this.state.deleteModal}
                            onClose={this.deleteModalClose}
                            handleDelete={this.deleteData}
                        />

                        <MyLeftSchedulerModal
                            bgColor={schedulerColor.main}
                            title={"Trade Shift"}
                            open={this.state.detailModal}
                            onClose={this.closeDetalModal}
                            handleApprove={this.handleApprove}
                            handleReject={this.handleReject}
                        >
                                       <Grid container spacing={1}>
                                <Grid>
                                    <Card >
                                        <CardContent style={{ padding: "20px" }}>
                                            <Grid container spacing={2} style={{ display: "flex" }}>
                                                <Grid item md={4} className={classes.cardDate}>
                                                    <div style={{ color: "white" }}>
                                                        {currentDateNew ? moment(currentDateNew).locale('en-US').format("DD") : ""}
                                                    </div>
                                                    <div style={{ color: "white" }}>
                                                        {currentDateNew ? moment(currentDateNew).locale('en-US').format("MMM") : ""}
                                                    </div>
                                                    <div style={{ color: "white" }}>
                                                        {currentDateNew ? moment(currentDateNew).locale('en-US').format("YYYY") : ""}
                                                    </div>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <div>
                                                        {moment(startTimeNew).locale('en-US').format(" hh:mm A")} -  {moment(endTimeNew).locale('en-US').format("hh:mm A")}{nextDayDate === true ? <span style={{ color: "red" }}>(Next day)</span> : ""}
                                                    </div>
                                                    <div>
                                                        <span style={{ fontWeight: 700 }}>Total Hours : </span>  {this.state.CoverUserData?.total_scheduled_hrs}hour
                                                    </div>

                                                    <div>
                                                        {this.state.CoverUserData?.location_name}
                                                    </div>
                                                    <div>
                                                        Requested on {currentDateNew ? moment(currentDateNew).locale('en-US').format("DD MMM YYYY") : ""}
                                                    </div>
                                                    <div>
                                                        <span style={{ fontWeight: 700 }}>  {this.state.CoverUserData?.requestedBy}</span>  request <span style={{ fontWeight: 700 }}>  {this.state.CoverUserData?.user_name}</span> to cover shift
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </MyLeftSchedulerModal>
                    </Grid>
                </Grid>
                :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerTradeShift.isLoading,
});

const ConnectWith = connect(mapStateToProps, { fetchData, addData, editData, deleteData })(TeamCoverShift);
export default withStyles(styles)(ConnectWith);

