import React, { Component } from 'react';
import {
        Button,
        Grid,
        Popover,
        Typography,
        MenuItem,
        Checkbox,
        ListItemIcon
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config';
import { MySelectField } from '../../../components'
import { connect } from "react-redux";

const styles = (theme) => ({
        PopoverClass: {
                overflowY: "hidden",
                padding: "15px",
                width: '700px'
        },
        MenuItem: {
                padding: "0px 0px"
        },
        ListItemIcon: {
                minWidth: "25px"
        },
        mYicon: {
                padding: 0,
                height: 24,
                width: 24
        },
        Typography: {
                fontSize: '14px',
                lineHeight: '1rem'
        },
        myButton: {
                backgroundImage: schedulerColor.main,
                boxShadow: 'none',
                fontWeight: 600,
                color: '#ffffff',
                borderRadius: "1px",
                border: `1px solid ${schedulerColor.main}`,
                padding: '5px 20px',
                '&:hover': {
                        backgroundImage: schedulerColor.main,
                }
        }
});

class PopoverDemo extends Component {
        constructor(props) {
                super(props)
                this.state = {
                        filter_employee_uid: "",
                        filter_group_by:"",
                        filter_sort_by:"",
                        status_is_approved:4
                }
        }

        componentDidMount = () => {
               this.setState({
                filter_employee_uid: this.props.filteremployee_uid,
                filter_group_by: this.props.filtergroup_by,
                filter_sort_by:this.props.filtersort_by,
                status_is_approved:this.props.status_is_approved
               })
        }

        // componentDidUpdate(prevProps, prevState) {     
        //         if ((JSON.stringify(prevProps.usersData) !== JSON.stringify(this.props.usersData))) {
        //             this.setState({
        //                 filter_employee_uid: this.props.filteremployee_uid,
        //                 filter_group_by: this.props.filtergroup_by,
        //                 filter_sort_by: this.props.filtersort_by
        //             })
        //         }
        // }
        resetFilter = () => {
                this.props.resetFilter()
        }

        applyFilter = () => {
                this.props.applyFilter(this.state.filter_employee_uid, this.state.filter_group_by, this.state.filter_sort_by,this.props.hideshowfilters,this.state.status_is_approved)
        }

        handleChange = (e) => {
                let state = e.target.name;
                let value = e.target.value;
                this.setState({
                        [state]: value
                })
        }

        render() {
                const { classes, anchorEl, toolsData, toolbarSettings,hideshowfilters } = this.props;
                const open = Boolean(anchorEl);
                const id = open ? 'simple-popover' : undefined;
                return (
                        <Popover
                                className={classes.Popover}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.props.handleFilterPopoverClose}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                }}
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                }}
                        >
                                <Grid spacing={2} container className={classes.PopoverClass}>
                                        <Grid
                                                item
                                                sm={12}
                                                xs={12}
                                        >
                                                <Grid container>

                                                        {toolsData.map((value) => {
                                                                return (
                                                                        <Grid
                                                                                item
                                                                                sm={6}
                                                                                xs={12}
                                                                        >
                                                                                <MenuItem
                                                                                        //onClick={() => { this.props.handleToolsClose(value.sortName) }}
                                                                                        className={classes.MenuItem}
                                                                                >
                                                                                        <ListItemIcon
                                                                                                className={classes.ListItemIcon}>
                                                                                                <Checkbox
                                                                                                        style={{ color: schedulerColor.main }}
                                                                                                        className={classes.mYicon}
                                                                                                        name={value.sortName}
                                                                                                        // checked={toolbarSettings[value.sortName] ? true : false}
                                                                                                        onClick={this.props.handleChangeFilterCheckbox}
                                                                                                        checked={hideshowfilters[value.sortName] === 1}
                                                                                                />
                                                                                        </ListItemIcon>

                                                                                        <Typography
                                                                                                className={classes.Typography}
                                                                                                color="textPrimary"
                                                                                                variant="inherit"
                                                                                        >
                                                                                                {value.title}
                                                                                        </Typography>
                                                                                </MenuItem>
                                                                        </Grid>
                                                                )
                                                        })}
                                                </Grid>
                                        </Grid>

                                        <Grid style={{ marginTop: ".5rem" }} item sm={12} xs={12}>
                                                <Typography
                                                        variant="h4"
                                                        style={{ color: schedulerColor.main }}
                                                >
                                                        Filters, Groups, & Sorting:
                                                </Typography>
                                        </Grid>

                                        <Grid item sm={12} md={12} xs={12} style={{display:"flex",justifyContent:"space-between"}}>

                                        <Grid item sm={4} xs={12}>
                                                <MySelectField
                                                        label="Filter By"
                                                        name="filter_employee_uid"
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        value={this.state.filter_employee_uid ? this.state.filter_employee_uid : this.props.filteremployee_uid}
                                                        error={null}
                                                        helperText={null}
                                                        placeholder="Please Select Timecode"
                                                >
                                                        <option value={0}>
                                                                All Employee
                                                        </option>
                                                        {this.props.teamUsers && this.props.teamUsers.map((item) => {
                                                                return (
                                                                        <option key={item.uid} value={item.uid} >
                                                                                {item.user_name}
                                                                        </option>
                                                                )
                                                        })}
                                                </MySelectField>
                                        </Grid>

                                        <Grid item sm={4} xs={12} style={{marginLeft:"2px"}}>
                                                <MySelectField
                                                        label="Sort Employees By"
                                                        name="filter_sort_by"
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        value={this.state.filter_sort_by ?this.state.filter_sort_by : this.props.filtersort_by}
                                                        error={null}
                                                        helperText={null}
                                                        placeholder="Please Select Timecode"
                                                >
                                                        <option value="name">
                                                                Name
                                                        </option>
                                                        <option value="email">
                                                                Email
                                                        </option>
                                                </MySelectField>
                                        </Grid>
                                        <Grid item sm={4} xs={12}  style={{marginLeft:"2px"}}>
                                                <MySelectField
                                                        label="Group By"
                                                        name="filter_group_by"
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        value={this.state.filter_group_by ? this.state.filter_group_by : this.props.filtergroup_by}
                                                        error={null}
                                                        helperText={null}
                                                        placeholder="Please Select Timecode"
                                                >
                                                        <option value="employee">
                                                                Employee
                                                        </option>
                                                        <option value="date">
                                                                Date
                                                        </option>
                                                        <option value="role">
                                                                Role
                                                        </option>
                                                </MySelectField>
                                        </Grid>                                    
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                        <Grid item sm={4} xs={12}>
                                                <MySelectField
                                                        label="Status"
                                                        name="status_is_approved"
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        value={this.state.status_is_approved ? this.state.status_is_approved : this.props.status_is_approved}
                                                        error={null}
                                                        helperText={null}
                                                        placeholder="Please select status"
                                                >
                                                        <option value="4">
                                                                All
                                                        </option>
                                                        <option value="1">
                                                                Approved
                                                        </option>
                                                        <option value="0">
                                                                Pending
                                                        </option>
                                                        <option value="2">
                                                                Upcoming
                                                        </option>
                                                        <option value="3">
                                                                Deleted
                                                        </option>
                                                </MySelectField>
                                        </Grid>
                                        </Grid>
                                     
                                        <Grid item sm={6} xs={12}>
                                                <Button
                                                        onClick={this.resetFilter}
                                                        size="small"
                                                        variant="contained"
                                                        className={classes.myButton}
                                                >
                                                        Reset All
                                                </Button>
                                        </Grid>
                                        <Grid style={{ display: "flex", justifyContent: "space-evenly" }} item sm={6} xs={12}>
                                                <Button
                                                        onClick={this.props.handleFilterPopoverClose}
                                                        size="small"
                                                        variant="contained"
                                                        style={{backgroundColor: "black",
                                                                boxShadow: 'none',
                                                                fontWeight: 600,
                                                                color: '#ffffff',
                                                                borderRadius: "1px",
                                                                border: `1px solid black`,
                                                                padding: '5px 20px',}}
                                                        //className={classes.myButton}
                                                >
                                                        Cancel
                                                </Button>
                                                <Button
                                                        onClick={this.applyFilter}
                                                        size="small"
                                                        variant="contained"
                                                        className={classes.myButton}
                                                >
                                                        Apply
                                                </Button>
                                        </Grid>

                                       

                                </Grid>
                        </Popover>
                )
        }
}
const mapStateToProps = (state) => ({
        usersData: state.schedulerTimsheets.usersData,
    });
const ConnectWith = connect(mapStateToProps)(PopoverDemo);
export default withStyles(styles)(ConnectWith);