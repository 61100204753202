import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import { alert, localTimeToUtc, utcDateToLocal, utcToLocal } from "../../../../utilities";
import axios from 'axios';
import moment from "moment";
export const timesheetServices = {
    fetchData,
    addTimecard,
    editTimecard,
    viewTimecard,
    approveRevisedHours,
    approveBreaks,
    deleteTimecard,
    toggleTimesheetIssue,
    fetchPayrollTimecode,
    approveWholetimecard
}

function fetchData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timesheet/new/filter`, { data }, HEADER_TOKEN).then(response => response)
};

function toggleTimesheetIssue(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timesheet/toogle_issue`, { data }, HEADER_TOKEN).then(response => response)
};

function addTimecard(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timecard/add/newtimecard`, { data }, HEADER_TOKEN).then(response => response)
};

function approveWholetimecard(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/approve/shift/break`, { data }, HEADER_TOKEN).then(response => response)
};

function editTimecard(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/edit/newtimecard`, { data }, HEADER_TOKEN).then(response => response)
};

function approveRevisedHours(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/approve/revised`, { data }, HEADER_TOKEN).then(response => response)
};

function approveBreaks(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/approve/breaks`, { data }, HEADER_TOKEN).then(response => response)
};

function viewTimecard(myItem) {
    var date = moment(utcToLocal(myItem.currentDate)).format(
        "YYYY-MM-DD" )
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/timecard/viewpayroll/${myItem.job_id}/${date}`, HEADER_TOKEN).then(response => response)
};

function fetchPayrollTimecode() {
    return axios.get(`${MoNGO_DB_API_URL}/payroll_timecode`, HEADER_TOKEN).then(response => response)
};


function deleteTimecard(value) {
    var data = {
        job_id:value.job_id,
        currentDate:value.currentDate
    }
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/deletepayroll`,{data}, HEADER_TOKEN).then(response => response)
};
