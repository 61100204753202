import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import FeedbackIcon from "@mui/icons-material/Feedback";
import "./hrstyles.scss";

// import { mobileTheme } from "../../constant";
import { history } from "../../../../history";
// import { useSearch } from "../../context/SearchContext";

// const bottomNavStyle = {
//   position: "fixed",
//   bottom: 0,
//   left: 0,
//   width: "100%",
//   background: mobileTheme,
//   zIndex: 999999,
//   fontSize: "14px",
// };

// const bottomNavActionStyle = { color: "#ffffff" };

const tabs = [
  {
    label: "Directory",
    icon: <LibraryBooksIcon />,
    path: [
      "/hr/users",
      "/hr/roles",
      "/hr/users/edituser",
      "/hr/roles/editRole",
    ],
  },
  {
    label: "Events",
    icon: <HomeIcon />,
    path: [
      "/hr/holiday",
      "/hr/events/publicholiday",
      "/hr/events/edit/publicholiday",
      "/hr/events/add/publicholiday",
    ],
  },
  // { label: "Grading", icon: <HomeIcon />, path: "/elearning/grading" },
  // { label: "Feedback", icon: <FeedbackIcon />, path: "/elearning/feedbacks" },
];

export function HrBottomNav() {
  const [value, setValue] = useState(0);
  const location = useLocation();

  // const { resetSearchQuery } = useSearch();

  useEffect(() => {
    const selectedIndex = tabs.findIndex((tab) =>
      tab.path.some((path) => location.pathname.includes(path))
    );
    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }
  }, [location.pathname]);

  return (
    <div className="bottom-appbar" style={{ width: "100%" }}>
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            className={`tab ${
              value === index
                ? "tab--fab"
                : value === index - 1
                ? "tab--right"
                : value === index + 1
                ? "tab--left"
                : ""
            }`}
            onClick={() => history.push(tab.path[0])}
          >
            {value === index ? (
              <div className="top">
                <div className="fab">{tab.icon}</div>
                {/* <span style={{ color: "#ffffff" }}>{tab.label}</span> */}
              </div>
            ) : (
              <>
                <div style={{ color: "#00000080" }}>{tab.icon}</div>
                <span style={{ color: "#00000080" }}>{tab.label}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
