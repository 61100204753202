import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const GradientButton = styled(Button)`
  background: linear-gradient(to right, #1b3f8f, #635bff);
  border-radius: 23px;
  border: 0;
  color: white;
  height: 17px;
  padding: 0 30px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 400;
  width: 51px;
  &:hover {
    background: linear-gradient(
      to right,
      #1b3f8f,
      #635bff
    ); /* Change the background on hover */
  }
`;

export const PendingButton = styled(Button)`
  background: #f6b000;
  border-radius: 23px;
  border: 0;
  color: white;
  height: 17px;
  padding: 0 30px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 400;
  width: 51px;
  &:hover {
    background: #f6b000;
  }
`;

export const PassButton = styled(Button)`
  background: #2f8803;
  border-radius: 23px;
  border: 0;
  color: white;
  height: 17px;
  padding: 0 30px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 400;
  width: 51px;
  &:hover {
    background: #2f8803;
  }
`;

export const FailButton = styled(Button)`
  background: #c24545;
  border-radius: 23px;
  border: 0;
  color: white;
  height: 17px;
  padding: 0 30px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 400;
  width: 51px;
  &:hover {
    background: #c24545;
  }
`;

export const DisabledButton = styled(Button)`
  background: #ccc;
  border-radius: 23px;
  border: 0;
  color: white;
  height: 17px;
  padding: 0 30px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 400;
  width: 51px;
  &:hover {
    background: #ccc;
    cursor: not-allowed;
    scale: 1;
    boxshadow: none;
    /* Change the background on hover */
  }
`;
