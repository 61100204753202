import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InfoIcon from '@mui/icons-material/Info';

const styles = (theme) => ({
  formControl: {
    width: "100%"
  },
  input: {
    //padding: '5px 14px !important',
    fontSize: '16px',
    fontWeight: 500,
    backgroundColor: '#f4f2f26b !important',
    borderRadius: 5,
    marginBottom: 0
  },
  labelColor: {
    // color: '#b6b5b5'
   
  }
});

const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5,
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

class MySelect extends Component {
  render() {
    const { classes, mapId, mapName, options, selectedIds, label  } = this.props;
    return (
      <FormControl
        className={classes.formControl}
        variant="outlined"
        size="small"
      >
        <InputLabel id="mutiple-select-label">{this.props.label}</InputLabel>
        <Select
          classes={{
            root: classes.input
          }}
          multiple
          value={selectedIds}
          onFocus={this.props.onFocus}
          onChange={this.props.handleChange}
          label={this.props.label}
          input={<OutlinedInput />}
          error={this.props.error}
          helperText={this.props.helperText}
          renderValue={(data) => {
            var newData = []
            options.map((items) => {
              if (selectedIds.indexOf(items[mapId]) > -1) {
                newData.push(items[mapName])
              }
            })
            return newData.join(",")
          }}
          MenuProps={MenuProps}
        >
          {options && options.map((items) => (
            <MenuItem
              key={items[mapId]}
              value={items[mapId]}
            >
              <InfoIcon
                // checked={selectedIds.indexOf(items[mapId]) > -1}
              />
              <ListItemText
                primary={items[mapName]}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl >
    )
  }
}

export default withStyles(styles)(MySelect);