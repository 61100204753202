import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  MyPlain25Modal,
  TableMyPagination,
  TableRecordNotFound,
  LoadingData,
  DeleteModal,
  MyTextfield,
  MoreMenu,
  MySelectField,
  MyMultiSelect

} from "../../../components";
import UserCannotDelete from "../../../components/UserCannotDelete";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import { payrollServices } from "./modules/services";
import { fetchData, addData, editData, deleteData } from "./modules/actions";
import { fetchData as fetchlocationData } from "../../common/Location/modules/actions";
import { onChangePage } from "../../../components/modules/actions";
import { connect } from "react-redux";
import PayrollUsers from "./PayrollUsers";
import NoPayrollUsers from "./NoPayrollUsers";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  People as PeopleAltIcon,
} from "@material-ui/icons";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { data_not_found } from "../../../icons";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Select,
  Paper,
  Grid,
  Tooltip,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import { schedulerColor } from "../../../config";
import moment from "moment";
import { alert, utcToLocal, validationIsEmpty } from "../../../utilities";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#7c7c7c",
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: "18px",
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  title: {
    flex: "1 1 100%",
    fontSize: "16px",
    fontWeight: "400",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  button: {
    backgroundColor: schedulerColor.main,
    color: "#FFFFFF",
  },
  rateField: {
    width: "10",
    height: "20",
  },
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "55%",
    },
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "1rem 1.5rem",
  },
  text: {
    color: schedulerColor.main,
    fontWeight: "600",
    lineHeight: '3'
  },
  saveButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
  closeButton: {
    color: "#ffffff",
    padding: "10px 22px",
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: schedulerColor.main,
    color: "white",
  },
  usersCard: {
    marginBottom: "10px",
    height: "100px",
  },
  userName: {
    marginRight: "10px",
    float: "left",
    height: "40px",
    width: "150px",
  },
  userData: {
    marginRight: "5px",
    // float: "left",
    height: "20px",
    width: "150px",
  },
  searchField: {
    paddingBottom: "5px",
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    lineHeight: "2.5rem",
    float: "left",
    color: "#ffffff",
    fontWeight: 600,
  },
  closeIcon: {
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
  },
  radioRows: {
    paddingBottom: "0.5rem",
  },
  TableCell: {
    fontSize: "14px",
    fontWeight: "400",
  },
  stepper: {
    padding: 0,
  },
  StepContent: {},
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#2e5bff",
    display: "flex",
    height: 28,
    marginLeft: 8,
    alignItems: "center",
  },
  active: {
    color: "#2e5bff",
  },
  circle: {
    zIndex: 2,
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: schedulerColor.main,
  },
  completed: {
    color: "#2e5bff",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();

  return (
    <div className={classes.root}>
      <div style={{ color: props.color }} className={classes.circle} />
    </div>
  );
}

class PayrollTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualData: {},
      myItem: {},
      order: "asc",
      orderBy: "_id",
      headCells: [
        { id: "templates", label: "Templates", numeric: false },
        { id: "payrollRule", label: "Payroll Rule", numeric: false },
        { id: "totalUser", label: "Total User", numeric: false },
      ],
      errors: {},
      errorsHelper: {},
      moreData: [
        {
          icon: EditIcon,
          title: "Edit",
          sortName: "edit",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_payroll_edit,
        },
        {
          icon: DeleteIcon,
          title: "Delete",
          sortName: "delete",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_payroll_delete,
        },
        {
          icon: VisibilityIcon,
          title: "View",
          sortName: "view",
          onClick: this.modifyData,
        },
        {
          icon: PeopleAltIcon,
          title: "Assign Payroll",
          sortName: "people",
          onClick: this.modifyData,
          permission: props.permissionsData.scheduler_payroll_view_assign,
        },
      ],
      payrollHistoryData: [],
      isSelected: "",
      noPayrollUsers: [],
      payrollAssignUsers: [],
      selectedOption: "",
      isEdit: 1,
      selectedLocationIds: [],
      locationData: []
    };
  }

  componentDidMount = () => {
    this.props.onChangePage();
    var isReload = this.props.payrollData.length > 0 ? true : false;
    this.props.fetchData(isReload, this.props.scheduler_lid);
  };

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  handleSubmit = async () => {
    var userItem = this.state.myItem;
    var valid_template_name = await validationIsEmpty(userItem.template_name);
    var valid_payroll_summary_type1 = await validationIsEmpty(userItem.payroll_summary_type1);
    var valid_payroll_summary_type2 = await validationIsEmpty(userItem.payroll_summary_type2);
    var valid_location_id = this.state.selectedLocationIds.length === 0 ? true : false;

    if (valid_template_name) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          template_name: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          template_name: "Please enter template_name",
        },
      }));
    }

    if (valid_location_id) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          selectedLocationIds: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          selectedLocationIds: "Please select location",
        },
      }));
    }

    if (userItem.rate === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          rate: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          rate: "Please enter rate",
        },
      }));
    }

    if (valid_payroll_summary_type1) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          payroll_summary_type1: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          payroll_summary_type1: "Please enter",
        },
      }));
    }
    if (valid_payroll_summary_type2) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          payroll_summary_type2: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          payroll_summary_type2: "Please enter",
        },
      }));
    }
    if (userItem.overlimit_rate === "") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          overlimit_rate: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          overlimit_rate: "Please enter",
        },
      }));
    }
    if (userItem.hours_limit === "") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          hours_limit: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          hours_limit: "Please enter",
        },
      }));
    }

    if (valid_template_name || valid_location_id) {
      return;
    }

    if (
      parseInt(userItem.payroll_type) === 1 &&
        (userItem.rate === 0 ||
          valid_payroll_summary_type1)
    ) {
      return;
    }

    if (
      parseInt(userItem.payroll_type) === 2 &&
        (userItem.hours_limit === "" ||
          valid_payroll_summary_type1 ||
          valid_payroll_summary_type2 ||
          userItem.overlimit_rate === "")
    ) {
      return;
    }

    if (
      parseInt(userItem.payroll_type) === 3 &&
        (userItem.rate === 0 ||
          valid_payroll_summary_type1 === "" ||
          userItem.overlimit_rate === "")
    ) {
      return;
    }
    var data = {
      lid: this.state.myItem.lid,
      rate: this.state.myItem.rate,
      template_name: this.state.myItem.template_name,
      payroll_type: this.state.myItem.payroll_type,
      hours_limit: this.state.myItem.hours_limit,
      overlimit_rate: this.state.myItem.overlimit_rate,
      payroll_summary_type1: this.state.myItem.payroll_summary_type1,
      payroll_summary_type2: this.state.myItem.payroll_summary_type2,
      payroll_type: this.state.myItem.payroll_type,
      selectedLocationIds: this.state.selectedLocationIds
    };
    this.setState({ addEditModal: false });
    if (this.state.isEdit) {
      var editMessage = [];
      if (
        this.state.actualData.template_name !== this.state.myItem.template_name
      ) {
        editMessage.push(
          `Name changed from ${this.state.actualData.template_name} to ${this.state.myItem.template_name}`
        );
      }
      if (
        this.state.actualData.payroll_type !== this.state.myItem.payroll_type
      ) {
        editMessage.push(
          `Type changed from ${this.state.actualData.payroll_type} to ${this.state.myItem.payroll_type}`
        );
      }
      if (this.state.actualData.hours_limit !== this.state.myItem.hours_limit) {
        editMessage.push(
          `Hours limit changed from ${this.state.actualData.hours_limit} to ${this.state.myItem.hours_limit}`
        );
      }
      if (this.state.actualData.rate !== this.state.myItem.rate) {
        editMessage.push(
          `Rate changed from ${this.state.actualData.rate} to ${this.state.myItem.rate}`
        );
      }
      if (
        this.state.actualData.overlimit_rate !== this.state.myItem.overlimit_rate
      ) {
        editMessage.push(
          `Overlimit rate changed from ${this.state.actualData.overlimit_rate} to ${this.state.myItem.overlimit_rate}`
        );
      }
      if (
        this.state.actualData.payroll_summary_type1 !==
        this.state.myItem.payroll_summary_type1
      ) {
        editMessage.push(
          `Export type1 changed from ${this.state.actualData.payroll_summary_type1} to ${this.state.myItem.payroll_summary_type1}`
        );
      }
      if (
        this.state.actualData.payroll_summary_type2 !==
        this.state.myItem.payroll_summary_type2
      ) {
        editMessage.push(
          `Export type2 changed from ${this.state.actualData.payroll_summary_type2} to ${this.state.myItem.payroll_summary_type2}`
        );
      }
      data.id = this.state.myItem._id;
      data.message = editMessage.join();

      this.props.editData(data, data.id);
    } else {
      this.props.addData(data);
    }
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    this.props.deleteData(this.state.myItem._id);
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  deleteModalUserClose = () => {
    this.setState({
      UserCannotDeleteModal: false
    })
  }

  viewModalClose = () => {
    this.setState({
      viewModal: false,
    });
  };

  fetchPayrollHistoryData = (id) => {
    this.setState({
      historyLoading: true,
    });
    payrollServices.fetchPayrollHistoryData(id).then((response) => {
      this.setState({
        historyLoading: false,
        payrollHistoryData: response.data.payrollHistoryData,
      });
    });
  };

  fetchNoPayrollUsers = () => {
    var lid = this.props.scheduler_lid;
    this.setState({
      noPayrollModal: true,
      noPayrollUsersLoading: true,
    });

    payrollServices.fetchNoPayrollUsers(lid).then((response) => {
      this.setState({
        noPayrollUsersLoading: false,
        noPayrollUsers: response.data.noPayrollUsers,
      });
    });
  };

  closeNoPayrollModal = () => {
    this.setState({
      noPayrollModal: false,
    });
  };

  fetchPayrollAssignUsers = (id) => {
    this.setState({
      payrollAssignUsersLoading: true,
    });
    payrollServices.fetchPayrollAssignUsers(id).then((response) => {
      this.setState({
        payrollAssignUsersLoading: false,
        payrollAssignUsers: response.data.payrollUsersData,
        UserCannotDeleteModal: false
      });
    });

  };

  peopleModalClose = () => {
    this.setState({
      peopleModal: false,
    });
  };
  assigneeModalClose = () => {
    this.setState({
      assigneeModal: false,
    });
  };

  addEditModalClose = () => {
    this.setState({
      addEditModal: false,
    });
  };

  modifyData = (value, item) => {
    if (value === "view") {
      this.fetchPayrollHistoryData(this.state.myItem._id);
    }
    if (value === "people") {
      this.fetchPayrollAssignUsers(this.state.myItem._id);
    }


    this.setState({
      isEdit: value === "edit" ? 1 : 0,
      addEditModal: value === "add" || value === "edit" ? true : false,
      deleteModal: this.state.myItem.total_payroll_assign === 0 && value === "delete" ? true : false,
      viewModal: value === "view" ? true : false,
      peopleModal: value === "people" ? true : false,
      assigneeModal: value === "assignee" ? true : false,
      UserCannotDeleteModal: this.state.myItem.total_payroll_assign > 0 && value === "delete" ? true : false
    });
  };

  handleFocus = (e) => {
    let state = e.target.id;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = parseInt(e.target.value);
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleChangeTemplate = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleChangeFields = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  moreMenuClick = (item, value) => {

    var myItem = {
      lid: this.props.scheduler_lid,
      payroll_type: this.state.myItem.payroll_type,
      template_name: "",
      hours_limit: 0,
      rate: 0,
      overlimit_rate: 0,
      payroll_summary_type1: "",
      payroll_summary_type2: "",
    };
    if (item) {
      myItem = item;
    }

    if (value === "add") {
      this.props.fetchlocationData();
    }

    if (value === "people") {
      this.fetchPayrollAssignUsers(myItem._id);
    }
    this.setState({
      isEdit: 0,
      peopleModal: value === "people" ? true : false,
      myItem,
      actualData: myItem,
      addEditModal: value === "add",
      assigneeModal: value === "assignee",
      locationData: this.props.locationData
    });
  };

  toggleAssignUser = (e, uid) => {
    this.setState({
      payrollAssignUsersLoading: true,
    });
    var data = {
      payroll_id: this.state.myItem._id,
      uid: uid,
      lid: this.state.myItem.lid,
      isSelected: e.target.checked ? 1 : 0,
    };

    payrollServices.togglePayrollAssignUsers(data).then((response) => {
      alert.success(response.data.message);
      var payrollAssignUsers = this.state.payrollAssignUsers.map((user) => {
        var cloneItem = user;
        cloneItem["isSelected"] = user._id === uid ? data.isSelected : user.isSelected;
        return cloneItem;
      });
      this.fetchPayrollAssignUsers(this.state.myItem._id)
      this.setState({
        payrollAssignUsers: payrollAssignUsers,
        payrollAssignUsersLoading: false,
      });
    });
    var isReload = this.props.payrollData.length > 0 ? true : false;
    this.props.fetchData(isReload, this.props.scheduler_lid);
    this.assigneeModalClose();
  };

  handleChangeCheckbox = (event) => {
    const value = event.target.value;
    this.setState({
      selectedLocationIds: value
    })
  }

  render() {
    const {
      classes,
      payrollData,
      isLoading,
      pageNo,
      rowsPerPage,
      permissionsData,
      // locationData
    } = this.props;
    const {
      noPayrollUsers,
      payrollAssignUsers,
      payrollAssignUsersLoading,
      payrollHistoryData,
      selectedLocationIds,
      locationData
    } = this.state;

    // const isAllSelected =
    //   locationData.length > 0 && selectedLocations.length === locationData.length;

    if (isLoading) return <LoadingData />;

    return permissionsData.scheduler_payroll_template ? (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullScreen
            TransitionComponent={Transition}
            open={this.state.addEditModal}
            onClose={this.addEditModalClose}
            handleSubmit={this.handleSubmit}
          >
            <DialogTitle
              style={{ backgroundColor: schedulerColor.main }}
              className={classes.DialogTitle}
            >
              {" "}
              <Typography className={classes.myTitle} variant="h4">
                {this.state.isEdit ? "Edit Template" : "Add Template"}
              </Typography>
              <Tooltip arrow title="close">
                <IconButton
                  className={classes.closeIcon}
                  size="small"
                  onClick={this.addEditModalClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </DialogTitle>
            <DialogContent className={classes.dialogcontent}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MyTextfield
                    name="template_name"
                    label="Template"
                    onChange={this.handleChangeTemplate}
                    onFocus={this.handleFocus}
                    id="template"
                    value={this.state.myItem.template_name}
                    type="text"
                    error={this.state.errors.template_name}
                    helperText={
                      this.state.errors.template_name
                        ? this.state.errorsHelper.template_name
                        : ""
                    }
                    placeholder="Template"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyMultiSelect
                    options={this.props.locationData}
                    mapId="_id"
                    mapName="name"
                    name="selectedLocationIds"
                    selectedIds={this.state.selectedLocationIds}
                    onFocus={this.handleFocus}
                    handleChange={this.handleChangeCheckbox}
                    label="Select Locations"
                    error={this.state.errors.selectedLocationIds}
                    helperText={
                      this.state.errors.selectedLocationIds
                        ? this.state.errorsHelper.selectedLocationIds
                        : ""
                    }
                    placeholder="Select Locations"
                  />
                  {this.state.errors.selectedLocationIds ?
                    <Typography
                      style={{ color: '#e53935', marginLeft:'1rem'}}
                      variant="body2"
                    >
                      {this.state.errorsHelper.selectedLocationIds}
                    </Typography>
                    : ""}

                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      defaultValue="1"
                      name="radio-buttons-group"
                      value={this.state.myItem.payroll_type}
                      checked

                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        name="payroll_type"
                        onChange={this.handleChange}
                        checked={this.state.myItem.payroll_type === 1}
                        label=
                        {
                          < span >
                            <Typography className={classes.text}>
                              Fixed hourly Rate $ &ensp;
                              <TextField
                                size="small"
                                variant="outlined"
                                // style={{ width: "50px", height: "27px" }}
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.payroll_type === 1 ? this.state.myItem.rate : ""}
                                name="rate"
                                // name="fixedRate"
                                id="fixedRate"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 1 ? this.state.errors.rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 1 &&
                                  this.state.errors.rate
                                    ? this.state.errorsHelper.rate
                                    : ""
                                }
                              // disabled={true}

                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px", height: "37px" }}
                                name="payroll_summary_type1"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_type === 1 ? this.state.myItem.payroll_summary_type1 : ""}
                                error={this.state.myItem.payroll_type === 1 ? this.state.errors.payroll_summary_type1 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 1 &&
                                  this.state.errors.payroll_summary_type1
                                    ? this.state.errorsHelper
                                      .payroll_summary_type1
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                                autoFocus={false}
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>
                            </Typography>
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        name="payroll_type"
                        checked={this.state.myItem.payroll_type === 2}
                        onChange={this.handleChange}
                        label={
                          <span>
                            <Typography className={classes.text}>
                              First &ensp;
                              {/* <OutlinedInput */}
                              <TextField
                                size="small"
                                variant="outlined"
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.payroll_type === 2 ? this.state.myItem.hours_limit : ""}
                                // name="hours_limit"
                                name="hours_limit"
                                id="firstHour"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.hours_limit : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.hours_limit
                                    ? this.state.errorsHelper.hours_limit
                                    : ""
                                }
                              />
                              &ensp; hours @ $ &ensp;
                              <TextField
                                size="small"
                                variant="outlined"
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.payroll_type === 2 ? this.state.myItem.rate : ""}
                                name="rate"
                                // name="firstRate"
                                id="firstRate"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.rate
                                    ? this.state.errorsHelper.rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px" }}
                                name="payroll_summary_type1"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_type === 2 ? this.state.myItem.payroll_summary_type1 : ""}
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.payroll_summary_type1 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.payroll_summary_type1
                                    ? this.state.errorsHelper
                                      .payroll_summary_type1
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>{" "}
                              &ensp; and then $ &ensp;
                              <TextField
                                size="small"
                                variant="outlined"
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.payroll_type === 2 ? this.state.myItem.overlimit_rate : ""}
                                name="overlimit_rate"
                                id="overlimit_rate"
                                // name="overlimit_rate"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.overlimit_rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.overlimit_rate
                                    ? this.state.errorsHelper.overlimit_rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px" }}
                                name="payroll_summary_type2"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_type === 2 ?
                                  this.state.myItem.payroll_summary_type2
                                    ? this.state.myItem.payroll_summary_type2
                                    : "" : ""
                                }

                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.payroll_summary_type2 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.payroll_summary_type2
                                    ? this.state.errorsHelper
                                      .payroll_summary_type2
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>
                            </Typography>
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        onChange={this.handleChange}
                        name="payroll_type"
                        checked={this.state.myItem.payroll_type === 3}
                        label={
                          <span>
                            <Typography className={classes.text}>
                              Always pay minimum &ensp;
                              <TextField
                                size="small"
                                variant="outlined"
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.payroll_type === 3 ? this.state.myItem.hours_limit : ""}
                                name="hours_limit"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                id="minRate"
                                // name="minRate"
                                type="text"
                                error={this.state.myItem.payroll_type === 3  ? this.state.errors.minRate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.minRate
                                    ? this.state.errorsHelper.minRate
                                    : ""
                                }
                              />
                              &ensp; hours @ $ &ensp;
                              <TextField
                                size="small"
                                variant="outlined"
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.payroll_type === 3 ? this.state.myItem.rate : ""}
                                name="rate"
                                id="minHour"
                                // name="minHour"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 3 ? this.state.errors.rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 3 &&
                                  this.state.errors.rate
                                    ? this.state.errorsHelper.rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px" }}
                                name="payroll_summary_type1"
                                onChange={this.handleChangeFields}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_type === 3 ? this.state.myItem.payroll_summary_type1 : ""}
                                error={this.state.myItem.payroll_type === 3 ? this.state.errors.payroll_summary_type1 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 3 &&
                                  this.state.errors.payroll_summary_type1
                                    ? this.state.errorsHelper
                                      .payroll_summary_type1
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>
                              &ensp;
                            </Typography>
                          </span>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                onClick={this.addEditModalClose}
                startIcon={<CloseIcon />}
                className={classes.closeButton}
                style={{ backgroundColor: "black" }}
              >
                Close
              </Button>

              <Button
                style={{
                  backgroundColor: this.props.bgColor
                    ? this.props.bgColor
                    : schedulerColor.main,
                }}
                variant="outlined"
                size="medium"
                className={classes.saveButton}
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullScreen
            TransitionComponent={Transition}
            // open={this.state.addEditModal}
            open={this.state.assigneeModal}
            onClose={this.assigneeModalClose}
            // onClose={this.addEditModalClose}
            handleSubmit={this.handleSubmit}
          >
            <DialogTitle
              style={{ backgroundColor: schedulerColor.main }}
              className={classes.DialogTitle}
            >
              {" "}
              <Typography className={classes.myTitle} variant="h4">
                No Payroll Assign
              </Typography>
              <Tooltip arrow title="Add">
                <IconButton
                  className={classes.closeIcon}
                  size="small"
                  onClick={this.addEditModalClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </DialogTitle>
            <DialogContent className={classes.dialogcontent}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MyTextfield
                    name="template_name"
                    label="Template"
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    value={this.state.myItem.template_name}
                    type="text"
                    error={this.state.errors.template_name}
                    helperText={
                      this.state.errors.template_name
                        ? this.state.errorsHelper.template_name
                        : ""
                    }
                    placeholder="Search Name, Email, Role..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      // aria-label="gender"
                      defaultValue={1}
                      name="radio-buttons-group"
                    // value={this.state.myItem.payroll_type}
                    >
                      <FormControlLabel
                        value={this.state.myItem.payroll_type}
                        control={<Radio />}
                        name="payroll_type"
                        onChange={this.handleChange}
                        className={classes.radioRows}
                        label={
                          <span>
                            <Typography className={classes.text}>
                              Fixed hourly Rate $ &ensp;
                              <TextField
                                style={{ width: "100px", height: "37px" }}
                                value={this.state.myItem.rate}
                                name="rate"
                                // name="fixedRate"
                                id="fixedRate"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                type="text"

                                error={this.state.myItem.payroll_type === 1 ? this.state.errors.rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 1 &&
                                  this.state.errors.rate
                                    ? this.state.errorsHelper.rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px", height: "37px" }}
                                name="payroll_summary_type1"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_summary_type1}
                                error={this.state.myItem.payroll_type === 1 ? this.state.errors.payroll_summary_type1: ""}
                                helperText={
                                  this.state.myItem.payroll_type === 1 &&
                                  this.state.errors.payroll_summary_type1
                                    ? this.state.errorsHelper
                                      .payroll_summary_type1
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>
                            </Typography>
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        name="payroll_type"
                        onChange={this.handleChange}
                        className={classes.radioRows}
                        label={
                          <span>
                            <Typography className={classes.text}>
                              First &ensp;
                              <TextField
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.hours_limit}
                                name="hours_limit"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.hours_limit : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.hours_limit
                                    ? this.state.errorsHelper.hours_limit
                                    : ""
                                }
                              />
                              &ensp; hours @ $ &ensp;
                              <TextField
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.rate}
                                name="rate"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.rate
                                    ? this.state.errorsHelper.rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px" }}
                                name="payroll_summary_type1"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_summary_type1}
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.payroll_summary_type1 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.payroll_summary_type1
                                    ? this.state.errorsHelper
                                      .payroll_summary_type1
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>{" "}
                              &ensp; and then $ &ensp;
                              <TextField
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.overlimit_rate}
                                name="overlimit_rate"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.overlimit_rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.overlimit_rate
                                    ? this.state.errorsHelper.overlimit_rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px" }}
                                name="payroll_summary_type2"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                // value={
                                //   this.state.myItem.payroll_summary_type2
                                //     ? this.state.myItem.payroll_summary_type2
                                //     : ""
                                // }
                                // error={this.state.errors.payroll_summary_type2}
                                // helperText={
                                //   this.state.errors.payroll_summary_type2
                                //     ? this.state.errorsHelper
                                //       .payroll_summary_type2
                                //     : ""
                                // }
                                value={this.state.myItem.payroll_summary_type2}
                                error={this.state.myItem.payroll_type === 2 ? this.state.errors.payroll_summary_type2 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 2 &&
                                  this.state.errors.payroll_summary_type2
                                    ? this.state.errorsHelper
                                      .payroll_summary_type2
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>
                            </Typography>
                          </span>
                        }
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        onChange={this.handleChange}
                        name="payroll_type"
                        className={classes.radioRows}
                        label={
                          <span>
                            <Typography className={classes.text}>
                              Always pay minimum &ensp;
                              <TextField
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.hours_limit}
                                name="hours_limit"
                                id="hours_limit"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 3 ? this.state.errors.hours_limit : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 3 &&
                                  this.state.errors.hours_limit
                                    ? this.state.errorsHelper.hours_limit
                                    : ""
                                }
                              />
                              &ensp; hours @ $ &ensp;
                              <TextField
                                style={{ width: "50px", height: "37px" }}
                                value={this.state.myItem.rate}
                                name="rate"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                type="text"
                                error={this.state.myItem.payroll_type === 3 ? this.state.errors.rate : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 3 &&
                                  this.state.errors.rate
                                    ? this.state.errorsHelper.rate
                                    : ""
                                }
                              />
                              &ensp;
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                required={
                                  this.props.required
                                    ? this.props.required
                                    : false
                                }
                                label="select"
                                style={{ width: "100px" }}
                                name="payroll_summary_type1"
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                value={this.state.myItem.payroll_summary_type1}
                                error={this.state.myItem.payroll_type === 3 ? this.state.errors.payroll_summary_type1 : ""}
                                helperText={
                                  this.state.myItem.payroll_type === 3 &&
                                  this.state.errors.payroll_summary_type1
                                    ? this.state.errorsHelper
                                      .payroll_summary_type1
                                    : ""
                                }
                                placeholder="select"
                                variant="outlined"
                                size="small"
                                fullWidth
                              >
                                <option value="select">select</option>
                                <option value="pe">PE</option>
                                <option value="cash">Cash</option>
                              </TextField>
                              &ensp;
                            </Typography>
                          </span>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                onClick={this.addEditModalClose}
                startIcon={<CloseIcon />}
                className={classes.closeButton}
                style={{ backgroundColor: "black" }}
              >
                Close
              </Button>

              <Button
                style={{
                  backgroundColor: this.props.bgColor
                    ? this.props.bgColor
                    : "gray",
                }}
                variant="outlined"
                size="medium"
                className={classes.saveButton}
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Paper>
            <Toolbar variant="regular" className={classes.rootToolbar}>
              <Typography
                display="inline"
                className={classes.title}
                variant="h6"
              >
                Payroll Template
              </Typography>
              <Tooltip arrow title="View No payroll assignee">
                <IconButton
                  // disabled={permissionsData.scheduler_payroll_add ? false : true}
                  style={{ float: "right", marginRight: "1rem" }}
                  size="small"
                  onClick={this.fetchNoPayrollUsers}
                >
                  <AssignmentTurnedInIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Add">
                <IconButton
                  disabled={
                    permissionsData.scheduler_payroll_add ? false : true
                  }
                  style={{ float: "right" }}
                  size="small"
                  onClick={() => {
                    this.moreMenuClick(null, "add");
                  }}
                >
                  <AddIcon
                    style={{
                      border: "3px",
                      borderRadius: "50%",
                      backgroundColor: schedulerColor.main,
                      color: "white",
                      fontWeight: "500",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Toolbar>

            <TableContainer>
              <Table className={classes.table} size="medium">
                <TableHead>
                  <TableRow>
                    {this.state.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          this.state.orderBy === headCell.id
                            ? this.state.order
                            : false
                        }
                      >
                        <TableSortLabel
                          className={classes.headCell}
                          active={this.state.orderBy === headCell.id}
                          direction={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : "asc"
                          }
                          onClick={this.createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {this.state.orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {this.state.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell
                      style={{ paddingRight: "20px" }}
                      className={classes.headCell}
                      align="right"
                      colSpan={2}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stableSort(
                    payrollData,
                    getComparator(this.state.order, this.state.orderBy)
                  )
                    .slice(
                      pageNo * rowsPerPage,
                      pageNo * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row._id}>
                          <TableCell className={classes.TableCell}>
                            {row.template_name}
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {row.payroll_type === 1 ? (
                              <Typography className={classes.TableCell}>
                                {" "}
                                Fixed hourly rate ${row.rate}
                              </Typography>
                            ) : row.payroll_type === 2 ? (
                              <Typography className={classes.TableCell}>
                                First {row.hours_limit} Hours @ ${row.rate} and
                                then ${row.overlimit_rate}
                              </Typography>
                            ) : (
                              <Typography className={classes.TableCell}>
                                Always pay minimum {row.hours_limit} hours @ $
                                {row.rate}{" "}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            <Button
                              onClick={() => {
                                this.moreMenuClick(row, "people");
                              }}
                              className={classes.button}>
                              {row.total_payroll_assign}
                            </Button>
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.TableCell}
                          >
                            <MoreMenu
                              moreMenuClick={() => {
                                this.moreMenuClick(row);
                              }}
                              moreData={this.state.moreData}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {payrollData && payrollData.length < 1 ? (
                    <TableRecordNotFound colSpan={8} label="No Data Found.." />
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TableMyPagination count={payrollData ? payrollData.length : 0} />
          </Paper>

          <DeleteModal
            bgColor={schedulerColor.main}
            desc="Are you sure you want to delete?"
            open={this.state.deleteModal}
            onClose={this.deleteModalClose}
            handleDelete={this.deleteData}
          />

          <UserCannotDelete
            bgColor={schedulerColor.main}
            desc="Can not delete! Please unassign users before deleting payroll template."
            open={this.state.UserCannotDeleteModal}
            onClose={this.deleteModalUserClose}
            handleDelete={() => this.modifyData("people")}
          />

          <MyPlain25Modal
            bgColor={schedulerColor.main}
            title="View History"
            open={this.state.viewModal}
            onClose={this.viewModalClose}
          >
            <Grid container spacing={0}>
              {this.state.historyLoading ? (
                <Grid item sm={12}>
                  <LoadingData />
                </Grid>
              ) : payrollHistoryData.length > 0 ? (
                <Grid item sm={12} >
                  <Stepper
                    className={classes.stepper}
                    orientation="vertical"
                    active={true}
                  >
                    {payrollHistoryData.map((data, index) => {
                      var newMessages = data.message.split(",");
                      return (
                        <Step active={true} key={index}>
                          <StepLabel StepIconComponent={QontoStepIcon}>
                            <Typography>
                              <b>
                                {data.createdAt
                                  ? moment(utcToLocal(data.createdAt)).format(
                                    "DD MMM YYYY,hh:mm A"
                                  )
                                  : ""}
                              </b>
                              <br />
                            </Typography>
                          </StepLabel>
                          <StepContent className={classes.StepContent}>
                            <Typography variant="h5" color="textPrimary">
                              {data.user_name ? (
                                <span>{data.user_name}</span>
                              ) : (
                                <span style={{ color: "#C66060" }}>
                                  {" "}
                                  {data.message}
                                </span>
                              )}
                              <br />
                            </Typography>

                            {newMessages.map((message) => {
                              return (
                                <Typography
                                  display="block"
                                  variant="h6"
                                  color="textPrimary"
                                >
                                  {message}
                                </Typography>
                              );
                            })}
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
              ) : (
                <Grid
                  item
                  sm={12}
                  style={{
                    height: "75vh",
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    verticalAlign: "center",
                  }}
                >
                  <img
                    alt="dsa"
                    style={{
                      height: "200px",
                    }}
                    src={data_not_found}
                  />
                </Grid>
              )}
            </Grid>
          </MyPlain25Modal>

          <MyPlain25Modal
            bgColor={schedulerColor.main}
            title="Payroll Not Assign"
            open={this.state.noPayrollModal}
            onClose={this.closeNoPayrollModal}
          >
            <Grid container spacing={0}>
              <NoPayrollUsers
                noPayrollUsersLoading={this.state.noPayrollUsersLoading}
                noPayrollUsers={noPayrollUsers}
              />
            </Grid>
          </MyPlain25Modal>

          <MyPlain25Modal
            bgColor={schedulerColor.main}
            title="Assign Payroll"
            open={this.state.peopleModal}
            onClose={this.peopleModalClose}
          >
            <PayrollUsers
              toggleAssignUser={this.toggleAssignUser}
              myItem={this.state.myItem}
              payrollAssignUsersLoading={payrollAssignUsersLoading}
              payrollAssignUsers={payrollAssignUsers}
            />
          </MyPlain25Modal>
        </Grid>
      </Grid >
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.schedulerPayrollTemplate.isLoading,
  payrollData: state.schedulerPayrollTemplate.payrollData,
  pageNo: state.localData.pageNo,
  rowsPerPage: state.localData.rowsPerPage,
  locationData: state.settingsLocation.locationData,
});

const ConnectWith = connect(mapStateToProps, {
  fetchData,
  addData,
  editData,
  deleteData,
  onChangePage,
  fetchlocationData
})(PayrollTemplate);
export default withStyles(styles)(ConnectWith);
