import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import Checkbox from '@material-ui/core/Checkbox';
import { schedulerColor } from "../../../config";
const styles = (theme) => ({
  menu: {
    minWidth: "193px",
    borderRadius: "7px",
    backgroundColor: "#fafafa",
    marginLeft: "-5.7%",
    border: "1px solid #DFDFDF",
  },
  MenuItem: {
    borderBottom: "1px solid #DFDFDF",
    padding: "0px 10px",
    "&:hover": {
      boxShadow:
        "4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  ListItemIcon: {
    minWidth: "45px",
  },
  mYicon: {
    height: 24,
    width: 24,
  },
  Typography: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "2rem",
    color: "rgba(100, 106, 112, 0.6)",
  },
});

class AddContent extends Component {
  render() {
    const { classes, toolsAnchor, addContentDropdown, showHideSettings } =
      this.props;
    const open = Boolean(toolsAnchor);
    return (
      <Menu
        MenuListProps={{ disablePadding: true }}
        anchorEl={toolsAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={Boolean(toolsAnchor)}
        onClose={(e) => {
          this.props.addContentClose("");
        }}
        PopoverClasses={{
          paper: classes.menu,
        }}
      >
        {addContentDropdown.map((value, key) => {
          return (
            <MenuItem
              onClick={() => {
                this.props.setSelectType(value.type, this.props.slides.length);
              }}
              // onClick={() => this.props.addContentClose(value) }
              className={classes.MenuItem}
            >
              <Typography
                className={classes.Typography}
                //onClick = {this.props.handleChangeDropdown(value.sortName)}
                color="textPrimary"
                variant="inherit"
              >
                {value.title}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    );
  }
}

export default withStyles(styles)(AddContent);
