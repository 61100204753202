//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { ovHrV5Color } from "../config";
const styles = (theme) => ({
  dialog: {
    margin: '0px 0px 0px auto',
    right: '0px',
    left: "auto",
    backgroundColor:"white",
    [theme.breakpoints.up('md')]: {
      width: "50%"
    }
  },
  DialogTitle: {
    padding: "10px 24px",
  },
  myTitle: {
    lineHeight: '2.5rem',
    float: "left",
    color: "#ffffff",
    fontWeight: 600
  },
  closeIcon: {
    padding: "5px",
    color: "#ffffff",
    cursor: 'pointer',
    float: 'right',
    "&:hover": {
      color: "#ffffff",
    },
  },
  dialogcontent: {
    backgroundColor: '#fcfcfc',
    padding: '1rem 1.5rem'
  },
  saveButton: {
    color: '#ffffff',
    padding: '10px 22px'
  },
  closeButton: {
    color: 'white',
    padding: '10px 22px'
  }
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class MyRightModal extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open || false}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{ backgroundColor: this.props.bgColor ? this.props.bgColor : "gray" }}
          className={classes.DialogTitle}
        >
          <Typography
            className={classes.myTitle}
            variant="h6" >
            {this.props.title}
          </Typography>

          <Tooltip arrow title="Add">
            <IconButton
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>

        </DialogTitle>

        <DialogContent
          className={classes.dialogcontent}
          dividers>
          {children}
        </DialogContent>
        <DialogActions style={{ backgroundColor: 'white' }}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={this.props.onClose}
            startIcon={<CloseIcon />}
            style={{ backgroundColor: this.props.bgColor }}
            className={classes.closeButton}
          >
            Close
          </Button>

          <Button
            style={{ backgroundColor: this.props.bgColor ? this.props.bgColor : "gray" }}
            variant="outlined"
            size="medium"
            className={classes.saveButton}
            startIcon={<SaveIcon />}
            onClick={this.props.handleSubmit}
          >
            Save
          </Button>

        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(MyRightModal);
