import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid
}
    from '@material-ui/core';
import { schedulerColor } from "../../../config";

const styles = (theme) => ({
    saveButton: {
        border: "1px solid lightgray",
        background: schedulerColor.main,
        color: "#FFFFFF",
        textTransform: 'capitalize',
        padding: "6px 20px",
        margin: "0px 7px",
        "&:hover": {
            background: schedulerColor.main,
            color: "#FFFFFF",
        }
    },
    cancelButton: {
        border: "1px solid lightgray",
        background: "#000000",
        color: "#FFFFFF",
        textTransform: 'capitalize',
        padding: "6px 20px",
        margin: "0px 7px",
        "&:hover": {
            background: "#000000",
            color: "#FFFFFF",
        }
    },
});

class SaveSection extends Component {
    constructor(props) {
        super(props)
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2} justifyContent="center">
                <Button className={classes.saveButton} onClick={this.props.handleSubmit}>
                    Save
                </Button>
                <Button className={classes.cancelButton} onClick={this.props.handleCancel}>
                    Cancel
                </Button>
            </Grid>
        )
    }
}

export default withStyles(styles)(SaveSection);
