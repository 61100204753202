import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const coverServices = {
    fetchData,
    addData,
    editData,
    deleteData,
    approveRejectTeamCover,
    fetchTeamDetailsById,
    approveRejectMyCover,
    fetchTeamData
}

function fetchData(data) {
    return axios.get(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/mycover/${data.lid}?nPerPage=${data.rowsPerPage}&pageNumber=${data.pageNo}`, HEADER_TOKEN).then(response => response)
};

function fetchTeamData(data) {
    return axios.get(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/teamcover/${data.lid}?nPerPage=${data.rowsPerPage}&pageNumber=${data.pageNo}&filter=${data.filter ? data.filter  : ""}`, HEADER_TOKEN).then(response => response)
};

function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/cover`, { data }, HEADER_TOKEN).then(response => response)
};

function editData(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/cover/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/new/${id}`, HEADER_TOKEN).then(response => response)
};

function approveRejectTeamCover(id, data) {
    return axios.put(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/team/${id}`, data , HEADER_TOKEN).then(response => response)
};

function approveRejectMyCover(id, data) {
    return axios.put(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/my/${id}`,  data , HEADER_TOKEN).then(response => response)
};

function fetchTeamDetailsById(id) {
    return axios.get(`${MoNGO_DB_API_URL}/v2/web/scheduler/cover/${id}`, HEADER_TOKEN).then(response => response)
};
