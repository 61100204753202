import {
  SET_TIMECODE_LOADING,
  SET_TIMECODE,
  ADD_TIMECODE,
  EDIT_TIMECODE,
  DELETE_TIMECODE,
  DEFAULT_TIMECODE
} from "./constants";
import { alert } from '../../../../utilities';
import { timecodeServices } from './services';

export function fetchData(isReload,page) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_TIMECODE_LOADING,
        payload: true
      });
    }
    
    timecodeServices.fetchData(page).then(response => {
      dispatch({
        type: SET_TIMECODE,
        payload: response.data
      });
    },
      error => {
        dispatch({
          type: SET_TIMECODE,
          payload: false
        });
      });
  };
}


export function deleteData(_id) {
  return function (dispatch) {
    dispatch({
      type: SET_TIMECODE_LOADING,
      payload: true
    });
    timecodeServices.deleteData(_id).then(response => {
      // if(response.data.success === 0){
      //   alert.error("Timecode Can Not Delete")
      // }
      // else {
      alert.success(response.data.message)
      dispatch({
        type: DELETE_TIMECODE,
        payload: _id
      });
    // }
    });
  };
}


export function addData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_TIMECODE_LOADING,
      payload: true
    });
    timecodeServices.addData(data).then(response => {
      alert.success(response.data.message)
      dispatch({
        type: ADD_TIMECODE,
        payload: response.data.post
      });
    });
  };
}

export function defaultTimecode(data) {
  return function (dispatch) {
    timecodeServices.defaultTimecode(data).then(response => {
      alert.success(response.data.message)
      dispatch({
        type: DEFAULT_TIMECODE,
        payload: data
      });
    });
  };
}

export function editData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_TIMECODE_LOADING,
      payload: true
    });
    timecodeServices.editData(data).then(response => {
      alert.success(response.data.message)
      dispatch({
        type: EDIT_TIMECODE,
        payload: response.data.post
      });
    });
  };
}