import {  HEADER_TOKEN,MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const usersServices = {
    fetchData,
    addCorrection,
    deleteData,
    editCorrection
}

// function fetchData(id) {
//     return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/get/mycorrection/${id}`, HEADER_TOKEN).then(response => response)
// };
function fetchData(data) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/get/mycorrection//${data.lid}?nPerPage=${data.rowsPerPage}&pageNumber=${data.pageNo}`, HEADER_TOKEN).then(response => response)
};
function addCorrection(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/add/mycorrection`,{data}, HEADER_TOKEN).then(response => response)
};

function editCorrection(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/edit/mycorrection`,{data}, HEADER_TOKEN).then(response => response)
};

function deleteData(uid) {
    var data = {
        id:uid
    }
    return axios
      .put(
        `${MoNGO_DB_API_URL}/scheduler/payroll_summary/v2/payroll/delete/correction`, {data},
        HEADER_TOKEN
      )
      .then((response) => response);
  }

