import React from "react";
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
} from "@material-ui/core";
import { useStyles } from "./style";
import { TableHeaderComponent } from "../../../components/TableheaderComponent";
import ReactSelect from "react-select";

const generatedHours = [];
for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60 / 15; j++) {
        const leftTimer = i.toString().length === 1 ? `${"0" + i}` : i;
        const rightTimer = (j * 15).toString().length === 1 ? `${j + "0"}` : (j * 15);
        let hourData = { value: `${leftTimer + ":" + rightTimer}`, label: `${leftTimer + ":" + rightTimer}` }
        generatedHours.push(hourData)
    }
}

function TimeZone(props) {
    const classes = useStyles()
    const hoursHeader = ["Day", "Opens At", "Closes At", ""];

    return (
        <div>
            <TableContainer
                className={classes.tableContainer1}
            >
                <Table style={{ width: '100%' }}>
                    <TableHeaderComponent theadData={hoursHeader} />
                    <TableBody>
                        {props.daysData.map((detail, index) => {

                            return (
                                <>
                                    <TableRow key={detail.day}>
                                        <TableCell className={classes.tableCellBody1}>
                                            <Typography variant="h6">
                                                {detail.schedule_start_day === 0 ? "Monday" : 
                                                    detail.schedule_start_day === 1 ? "Tuesday" : 
                                                    detail.schedule_start_day === 2 ? "Wednesday" :
                                                    detail.schedule_start_day === 3 ? "Thursday" :
                                                    detail.schedule_start_day === 4 ? "Friday" :
                                                    detail.schedule_start_day === 5 ? "Saturday" :
                                                    detail.schedule_start_day === 6 ? "Sunday" : ""
                                                }
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody2}>
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                //isDisabled={props.isWeek && index >= 1}
                                                value={{
                                                    value: detail?.schedule_day_start_time !== undefined ? detail?.schedule_day_start_time : "",
                                                    label: detail?.schedule_day_start_time !== undefined ? detail?.schedule_day_start_time : "",
                                                }}
                                                options={generatedHours}
                                                onChange={(e) => props.handleChangeWeekTiming(e, 'schedule_day_start_time', index)}
                                                name="schedule_day_start_time"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody2}>
                                            {/* {detail.close} */}
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                //isDisabled={props.isWeek && index >= 1}
                                                options={generatedHours}
                                                value={{
                                                    value: detail?.schedule_day_end_time !== undefined ? detail?.schedule_day_end_time : "",
                                                    label: detail?.schedule_day_end_time !== undefined ? detail?.schedule_day_end_time : "",
                                                }}
                                                onChange={(e) => props.handleChangeWeekTiming(e, 'schedule_day_end_time', index)}
                                                name="schedule_day_end_time"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody1}>
                                            {
                                                index === 0 && (
                                                    <FormControlLabel
                                                        control={<Checkbox name="checkedC" size='small' />}
                                                        label="Copy to All"
                                                        onChange={props.onChangeSetAsAllWeeks}
                                                    />
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                <Button className={classes.saveBtn} onClick={() => {
                    props.handlePopupModal()
                    // updatedMyAccountSchedule()
                    props.handleSubmit()
                }} >Save</Button>
            </div>
            {/* </CardContent> */}
        </div>
    )
}

export default TimeZone