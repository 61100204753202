import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import { ProgressBar } from "../../../../utilities/v5/common";
import {
  pptIcon,
  txtIcon,
  videoFileIcon,
} from "../../../../assets/images/mobileView/icons";
import { mobileTheme } from "../../constant";

const styles = {
  CourseCardLink: {
    color: "#212121",
    textDecoration: "none !important",
    width: "100%",
    maxWidth: "350px",
  },
  CourseCard: {
    width: "100%",
    height: "109px",
    borderRadius: "20px",
    boxShadow: "5px 5px 21px 4px #00000014",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    alignItems: "center",
  },
  CourseCardImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    width: "100%",
  },
  CourseCardImageWrapper: {
    width: "80px",
    height: "80px",
    borderRadius: "5px",
    backgroundColor: "rgba(116, 85, 246, 0.2)",
  },
  CourseCardImage: {
    width: "80px",
    height: "80px",
    borderRadius: "5px",
    objectFit: "contain",
  },
  CourseCardTextContainer: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    justifyContent: "space-between",
  },
  CourseCardDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    height: "70px",
  },
  CourseCardDetailsText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "0px",
    margin: "0px",
  },
  lessonEarningContainer: {
    display: "flex",
    justifyContent: "start",
    gap: "4px",
  },
  CourseCardCourseName: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "15px",
    color: "#212121",
  },
  CourseCardCategoryNameText: {
    fontSize: "12px",
    color: "rgba(33, 33, 33, 0.7)",
  },
  CourseCardDescriptionText: {
    fontSize: "12px",
    color: "rgba(33, 33, 33, 0.7)",
  },
  CourseCardIconContainer: {
    display: "flex",
    gap: "2px",
    alignItems: "start",
    justifyContent: "start",
  },
  CourseCardProgressBarContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  CourseCardIconStyle: {
    width: "16px !important",
    aspectRatio: "auto !important",
  },
};

const CourseCard = ({
  classes,
  progress,
  name,
  description,
  image,
  slides,
  categoryName,
  earningPoints,
  earningAmounts,
  ecid,
  id,
}) => {
  return (
    <Link
      to={`/elearning/mycourses/slider/${ecid}/${id}`}
      className={classes.CourseCardLink}
    >
      <Card className={classes.CourseCard}>
        <Box className={classes.CourseCardImageContainer}>
          <Box className={classes.CourseCardImageWrapper}>
            <CardMedia
              component="img"
              src={image}
              alt="lesson"
              className={classes.CourseCardImage}
            />
          </Box>
          <Box className={classes.CourseCardTextContainer}>
            <Box className={classes.CourseCardDetailsContainer}>
              <Box className={classes.CourseCardDetailsText}>
                <Typography
                  variant="h6"
                  className={classes.CourseCardCourseName}
                >
                  {name.slice(0, 40)}
                  {name.length > 40 ? "..." : ""}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.CourseCardCategoryNameText}
                >
                  {categoryName}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.CourseCardDescriptionText}
                >
                  {description.slice(0, 20)}
                  {description.length > 20 ? "..." : ""}
                </Typography>
                <Box className={classes.lessonEarningContainer}>
                  <Typography
                    variant="body2"
                    className={classes.CourseCardDescriptionText}
                  >
                    Points: {earningPoints || 0}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.CourseCardDescriptionText}
                  >
                    Amount: {earningAmounts || 0}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.CourseCardIconContainer}>
                {slides.find((slide) => slide.lesson_type === 3) && (
                  <img
                    alt="ppt doc icon"
                    src={pptIcon}
                    className={classes.CourseCardIconStyle}
                  />
                )}
                {slides.find((slide) => slide.lesson_type === 1) && (
                  <img
                    alt="txt doc icon"
                    src={txtIcon}
                    className={classes.CourseCardIconStyle}
                  />
                )}
                {slides.find((slide) => slide.lesson_type === 2) && (
                  <img
                    alt="video file icon"
                    src={videoFileIcon}
                    className={classes.CourseCardIconStyle}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.CourseCardProgressBarContainer}>
              <ProgressBar
                themeColor={mobileTheme}
                progressValue={progress}
                height={12}
                fontSize={"7px"}
                bgColor={"#635BFF1A"}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Link>
  );
};

export default withStyles(styles)(CourseCard);
