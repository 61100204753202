import {
  SET_SC_ROLES_LOADING,
  SET_SC_ROLES,
  ADD_SC_ROLES,
  EDIT_SC_ROLES,
  DELETE_SC_ROLES
} from "./constants";

const initialState = {
  isLoading: true,
  rolesData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SC_ROLES_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SC_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: payload.rolesData
      };
    case ADD_SC_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: [...state.rolesData, payload]
      }
    case EDIT_SC_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: state.rolesData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_SC_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: state.rolesData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;