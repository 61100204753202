import { SET_ELE_CATEGORY1, SET_GALLERY_IMAGE } from "./constants";

const initialState = {
  lessonData: [],
  galleryData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ELE_CATEGORY1:
      return {
        ...state,
        //isLoading: false,
        lessonData: payload
      };
    case SET_GALLERY_IMAGE:
      return {
        ...state,
        galleryData: payload
      }
    default:
      return state;
  }
};

export default Reducer;