export const SET_ELE_CATEGORY_LOADING = 'SET_ELE_CATEGORY_LOADING'
export const SET_ELE_CATEGORY_LOADING_Data = 'SET_ELE_CATEGORY_LOADING_Data'
export const SET_ELE_CATEGORY = 'SET_ELE_CATEGORY'
export const ADD_ELE_CATEGORY = 'ADD_ELE_CATEGORY'
export const EDIT_ELE_CATEGORY = 'EDIT_ELE_CATEGORY'
export const DELETE_ELE_CATEGORY = 'DELETE_ELE_CATEGORY'
export const SET_ELE_CATEGORY1 = 'SET_ELE_CATEGORY1'
export const SET_LESSON_CATEGORY = 'SET_LESSON_CATEGORY'
export const SET_LESSON_CATEGORYDATA = 'SET_LESSON_CATEGORYDATA'
export const SET_LESSON_NEWDATA = 'SET_LESSON_NEWDATA'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'