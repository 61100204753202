import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardArrowRight as RightIcon,
  KeyboardArrowDown as DownIcon,
} from "@material-ui/icons";
import { StickyContainer, Sticky } from "react-sticky";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { FILE_URL, schedulerColor } from "../../../config";
import { sc_issue_image } from "../../../icons";
import {
  Avatar,
  Collapse,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import { utcDateToLocal, utcToLocal, localTimeToUtc } from "../../../utilities";
import { utcToTimeZone } from "../../../utilities/utcToTimeZone";

const styles = (theme) => ({
  root: {
    padding: 2,
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  TableRowHover: {
    padding: 0,
  },
  userCell: {
    padding: "5px 5px",
  },
  listCell: {
    padding: "10px 5px",
    // paddingLeft:'5px',
    borderBottom: "none",
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "35px",
    width: "35px",
    fontWeight: "600",
    fontSize: "14px",
    color: "#AA076B",
    backgroundColor: 'rgba(170, 7, 107, 0.125)',
    position: "inherit"
  },
  headTitle: {
    color: "#AA076B",
    fontWeight: 500,
    lineHeight: 1,
    fontSize: 14,
  },
  subTitle: {
    fontWeight: 500,
    lineHeight: 2.5,
  },
  rowTitle: {
    color: schedulerColor.main,
    fontWeight: 500,
    lineHeight: 2.5,
  },
  user_name: {
    fontWeight: 600,
    cursor: "pointer",
    color: schedulerColor.main,
    lineHeight: 3,
  },
});

class ColleapseTable extends Component {
  render() {
    var { classes, usersData, showHideSettings, timezone } = this.props;
    var total_timecard = 0;
    var total_scheduled_hrs = 0;
    var total_actual_hrs = 0;
    var total_sch_vs_paid_hrs = 0;
    var total_sch_vs_actual_hrs = 0;
    var total_paid_hrs = 0;
    var total_break_hrs = 0;
    var total_issues = 0;
    var total_resolve_issues = 0;
    return (
      <Grid className={classes.root} style={{
        padding: "1px",
      }}>
        <Paper
          style={{ width: "100%", zIndex: "99", borderRadius: "0" }}
          className={classes.paper}
        >
          <StickyContainer className="app">
            <Sticky zIndex={"99"} topOffset={0}>
              {({ style }) => (
                <div
                  className="create-task"
                  style={{
                    ...style,
                    top: 61,
                  }}
                >
                  <TableContainer component={Paper} style={{ borderRadius: 0 }}>
                    <Table stickyHeader style={{ marginLeft: "0%" }}>
                      <TableHead style={{ backgroundImage: schedulerColor.main, color: "white" }}>
                        <TableRow
                          style={{
                            display: "table-row",
                            borderRadius: 0,
                          }}
                          className={classes.TableRowHover}
                        >
                          <TableCell style={{ padding: 0 }} colSpan={9}>
                            <Table>
                              <TableBody style={{
                                backgroundColor: "#AA076B20",
                              }}>
                                {showHideSettings.name ? (
                                  <TableCell
                                    style={{
                                      width: "16.75%",
                                      paddingLeft: "3%",
                                      height: "45px",
                                    }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      {this.props.filter_group_by === "date"
                                        ? "Date"
                                        : this.props.filter_group_by === "role"
                                          ? "Role"
                                          : "Name"}
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {showHideSettings.name ? (
                                  <TableCell
                                    style={{ width: "10.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      {this.props.filter_group_by === "role"
                                        ? "Date"
                                        : "Role"}
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {showHideSettings.timecard ? (
                                  <TableCell
                                    style={{ width: "12.55%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Timecard
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {showHideSettings.scheduled ? (
                                  <TableCell
                                    style={{ width: "12.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Scheduled
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {showHideSettings.actual ? (
                                  <TableCell
                                    style={{ width: "8.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Actual
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {/* {showHideSettings.sch_vs_paid_diff ? (
                                  <TableCell
                                    style={{ width: "9.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Sch vs Approved
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {showHideSettings.sch_vs_actual_diff ? (
                                  <TableCell
                                    style={{ width: "9.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Sch vs Actual
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )} */}

                                {showHideSettings.total_paid ? (
                                  <TableCell
                                    style={{ width: "10.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Total Paid Hrs.
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                {showHideSettings.unpaid_breaks ? (
                                  <TableCell
                                    style={{ width: "9.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Total Break
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}

                                <TableCell
                                  style={{ width: "9.75%" }}
                                  className={classes.listCell}
                                  align="left"
                                >
                                  <Typography className={classes.headTitle}>
                                    Type
                                  </Typography>
                                </TableCell>

                                {showHideSettings.issues ? (
                                  <TableCell
                                    style={{ width: "9.75%" }}
                                    className={classes.listCell}
                                    align="left"
                                  >
                                    <Typography className={classes.headTitle}>
                                      Issue
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                                <TableCell
                                  style={{ width: "9.75%" }}
                                  className={classes.listCell}
                                  align="left"
                                >
                                  <Typography className={classes.headTitle}>
                                    &ensp;&ensp;&ensp;
                                  </Typography>
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Sticky>
            <TableContainer style={{ maxHeight: "auto" }} component={Paper}>
              <Table aria-label="collapsible table">
                <TableBody>
                  {usersData.map((item, index) => {
                    total_timecard = total_timecard + item.total_timecard;
                    total_scheduled_hrs =
                      parseFloat(total_scheduled_hrs) +
                      parseFloat(item.total_scheduled_hrs);
                    total_actual_hrs =
                      parseFloat(total_actual_hrs) +
                      parseFloat(item.total_actual_hrs);
                    total_sch_vs_paid_hrs =
                      parseFloat(total_sch_vs_paid_hrs) +
                      parseFloat(item.total_sch_vs_paid_hrs);
                    total_sch_vs_actual_hrs =
                      parseFloat(total_sch_vs_actual_hrs) +
                      parseFloat(item.total_sch_vs_actual_hrs);
                    total_paid_hrs =
                      parseFloat(total_paid_hrs) +
                      parseFloat(item.total_paid_hrs);
                    total_break_hrs =
                      parseFloat(total_break_hrs) +
                      parseFloat(item.total_break_hrs);
                    total_issues = total_issues + item.total_issues;
                    total_resolve_issues =
                      total_resolve_issues + item.resolve_issues;
                    return (
                      <>
                        <TableRow key={index} hover>
                          {showHideSettings.name ? (
                            <TableCell
                              style={{ width: "24.75%" }}
                              onClick={() => {
                                this.props.handleClickViewDetailsSingle(index);
                              }}
                              className={classes.userCell}
                              align="left"
                            >
                              {this.props.filter_group_by === "employee" ? (
                                item.user_image ? (
                                  <Typography >
                                    <Avatar
                                      src={FILE_URL + item.user_image}
                                      className={classes.avatar}
                                    />
                                  </Typography>
                                ) : (
                                  <Typography >
                                    <Avatar
                                      style={{
                                        backgroundColor: schedulerColor.main,
                                      }}
                                      className={classes.avatar}
                                    >
                                      {item.user_name && item.user_name.charAt(0)}
                                    </Avatar>
                                  </Typography>
                                )
                              ) : (
                                ""
                              )}
                              <Typography className={classes.user_name}>
                                {this.props.filter_group_by === "date"
                                  ? new Date(
                                    utcDateToLocal(item.currentDate)
                                  ).toDateString()
                                  : this.props.filter_group_by === "role"
                                    ? item.role_name
                                    : item.user_name}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          <TableCell
                            style={{ width: "1%" }}
                            className={classes.userCell}
                            align="left"
                          >
                            <Typography
                              className={classes.rowTitle}
                              style={{
                                fontWeight: 600,
                                color: schedulerColor.main,
                              }}
                            >
                              &nbsp;&nbsp;
                            </Typography>
                          </TableCell>

                          {showHideSettings.timecard ? (
                            <TableCell
                              style={{ width: "14.25%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography
                                className={classes.rowTitle}
                                style={{
                                  fontWeight: 600,
                                  color: schedulerColor.main,
                                }}
                              >
                                {item.total_timecard}&nbsp;
                                {item.total_timecard > 1
                                  ? "Timecards"
                                  : "Timecard"}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {showHideSettings.scheduled ? (
                            <TableCell
                              style={{ width: "11.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                {item.total_scheduled_hrs}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {showHideSettings.actual ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                {item.total_actual_hrs}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {/* {showHideSettings.sch_vs_paid_diff ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                {item.total_sch_vs_paid_hrs}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {showHideSettings.sch_vs_actual_diff ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                {item.total_sch_vs_actual_hrs}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )} */}

                          {showHideSettings.total_paid ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                {item.total_paid_hrs}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {showHideSettings.unpaid_breaks ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                {item.total_break_hrs}
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell
                            style={{ width: "9.75%" }}
                            className={classes.userCell}
                            align="left"
                          >
                            <Typography className={classes.rowTitle}>

                            </Typography>
                          </TableCell>
                          {showHideSettings.issues ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.userCell}
                              align="left"
                            >
                              {item.total_issues > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginRight: "10px",
                                  }}
                                >
                                  {total_resolve_issues > 0 ? (
                                    <Typography
                                      display="inline"
                                      style={{
                                        lineHeight: 3.5,
                                        color: "red",
                                        fontWeight: 700,
                                        marginLeft: 2,
                                      }}
                                      className={classes.rowTitle}
                                    >
                                      {total_resolve_issues}&nbsp;{"Resolved"}
                                    </Typography>
                                  ) : (
                                    <>
                                      <Avatar
                                        style={{
                                          width: 15,
                                          height: 15,
                                          marginTop: 15,
                                        }}
                                        src={sc_issue_image}
                                      />

                                      <Typography
                                        display="inline"
                                        style={{
                                          lineHeight: 3.5,
                                          color: "red",
                                          fontWeight: 700,
                                          marginLeft: 2,
                                        }}
                                        className={classes.rowTitle}
                                      >
                                        {item.total_issues}&nbsp;
                                        {item.total_issues > 1
                                          ? "Issues"
                                          : "Issue"}
                                      </Typography>
                                    </>
                                  )}
                                </div>
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}

                          <TableCell
                            style={{ width: "9.75%" }}
                            className={classes.userCell}
                            align="right"
                          >
                            <IconButton
                              style={{
                                margin: "12px 0px",
                                float: "right",
                                padding: "0",
                              }}
                              onClick={() => {
                                this.props.handleClickViewDetailsSingle(index);
                              }}
                            >
                              {item.is_expanded ? (
                                <DownIcon
                                  style={{ color: schedulerColor.main }}
                                />
                              ) : (
                                <RightIcon
                                  style={{ color: schedulerColor.main }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>

                        {item.myShifts.map((myShift) => {
                          var date = moment(utcToLocal(new Date())).format(
                            "YYYY-MM-DD"
                          );
                          var startTiime = new Date(
                            myShift.isScheduled === 1
                              ? myShift.startTime
                              : myShift.revised_clock_in
                          );
                          var endTiime = new Date(
                            myShift.isScheduled === 1
                              ? myShift.endTime
                              : myShift.revised_clock_out
                          );
                          var clock_in = startTiime.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });
                          var clock_out = endTiime.toLocaleString("en-US", {
                            timeZone: this.props.timezone[0].location_timezone,
                          });
                          return (
                            <TableRow
                              onClick={() => {
                                this.props.openViewTimecardModal(myShift);
                              }}
                              style={{
                                marginRight: "-50px",
                                cursor: "pointer",
                                display: item.is_expanded
                                  ? "table-row"
                                  : "none",
                                borderBottom: "0px solid #ffffff",
                              }}
                              className={classes.TableRowHover}
                            >
                              <TableCell style={{ padding: 0 }} colSpan={10}>
                                <Collapse
                                  style={{ borderBottom: "0px solid red" }}
                                  in={item.is_expanded}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box>
                                    <Table>
                                      <TableBody>
                                        <TableRow hover>
                                          {showHideSettings.name ? (
                                            <TableCell
                                              style={{ width: "13.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography
                                                className={classes.subjectName}
                                                variant="h6"
                                                style={{ fontWeight: 800 }}
                                              >
                                                <Typography variant="body1">
                                                  {this.props
                                                    .filter_group_by ===
                                                    "date" ||
                                                    this.props.filter_group_by ===
                                                    "role"
                                                    ? myShift.user_name
                                                    : new Date(
                                                      utcToTimeZone(
                                                        myShift.currentDate,
                                                        this.props.timezone[0]
                                                          .location_timezone
                                                      )
                                                    ).toDateString()}
                                                </Typography>
                                                {myShift.clock_out && (
                                                  <Typography
                                                    style={{
                                                      fontSize: 10,
                                                      color: "gray",
                                                    }}
                                                  >
                                                    Clock out at{" "}
                                                    {moment(clock_out).format(
                                                      "hh:mm A"
                                                    )}
                                                  </Typography>
                                                )}
                                                {myShift.clock_in &&
                                                  !myShift.clock_out && (
                                                    <Typography
                                                      style={{
                                                        fontSize: 10,
                                                        color: "green",
                                                      }}
                                                    >
                                                      Clock in at{" "}
                                                      {moment(clock_in).format(
                                                        "hh:mm A"
                                                      )}
                                                    </Typography>
                                                  )}
                                                {!myShift.clock_in &&
                                                  !myShift.clock_out && (
                                                    <Typography
                                                      style={{
                                                        fontSize: 10,
                                                        color: "black",
                                                      }}
                                                    >
                                                      Scheduled
                                                    </Typography>
                                                  )}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                          <TableCell
                                            style={{ width: "12.75%" }}
                                            className={classes.listCell}
                                            align="left"
                                          >
                                            <Typography variant="body1">
                                              {this.props.filter_group_by ===
                                                "role"
                                                ? moment(
                                                  utcDateToLocal(
                                                    myShift.currentDate
                                                  )
                                                ).format("DD MMM YYYY")
                                                : myShift.role_name}
                                            </Typography>
                                          </TableCell>

                                          {showHideSettings.timecard ? (
                                            <TableCell
                                              style={{ width: "14.55%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography
                                                style={{
                                                  color: myShift.is_approved
                                                    ? "green"
                                                    : !myShift.clock_in &&
                                                      !myShift.clock_out &&
                                                      moment(
                                                        utcToLocal(
                                                          myShift.currentDate
                                                        )
                                                      ).format("YYYY-MM-DD") >
                                                      date
                                                      ? "orange"
                                                      : "red",
                                                  fontWeight: 800,
                                                }}
                                                variant="body1"
                                              >
                                                {
                                                  myShift.is_approved
                                                    ?
                                                    "Approved"
                                                    :
                                                    !myShift.clock_in && !myShift.clock_out &&
                                                      moment(
                                                        utcToLocal(
                                                          myShift.currentDate
                                                        )
                                                      ).format("YYYY-MM-DD") > date
                                                      ?
                                                      "Upcoming"
                                                      :
                                                      myShift.isDeleted === 1 ? "Deleted" :
                                                      "Pending"
                                                }
                                              </Typography>
                                              <Typography variant="body2">
                                                {/* {new Date(utcToTimeZone(myShift.clock_in ? myShift.clock_in : myShift.startTime,this.props.timezone[0].location_timezone)).toLocaleTimeString()} - {new Date(utcToTimeZone(myShift.clock_out ? myShift.clock_out : myShift.endTime,this.props.timezone[0].location_timezone)).toLocaleTimeString()} */}
                                                {moment(clock_in).format(
                                                  "hh:mm A"
                                                )}{" "}
                                                -{" "}
                                                {moment(clock_out).format(
                                                  "hh:mm A"
                                                )}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}

                                          {showHideSettings.scheduled ? (
                                            <TableCell
                                              style={{ width: "12.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography variant="body1">
                                                {myShift.total_scheduled_hrs}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}

                                          {showHideSettings.actual ? (
                                            <TableCell
                                              style={{ width: "8.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography variant="body1">
                                                {myShift.total_actual_hrs}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                          {/* {showHideSettings.sch_vs_paid_diff ? (
                                            <TableCell
                                              style={{ width: "9.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography variant="body1">
                                                {myShift.sch_vs_paid_hrs}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                          {showHideSettings.sch_vs_actual_diff ? (
                                            <TableCell
                                              style={{ width: "9.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography variant="body1">
                                                {myShift.sch_vs_actual_hrs}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )} */}
                                          {showHideSettings.total_paid ? (
                                            <TableCell
                                              style={{ width: "10.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography variant="body1">
                                                {myShift.total_paid_hrs}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                          {showHideSettings.unpaid_breaks ? (
                                            <TableCell
                                              style={{ width: "9.75%" }}
                                              className={classes.listCell}
                                              align="left"
                                            >
                                              <Typography variant="body1">
                                                {myShift.total_break_hrs}
                                              </Typography>
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}

                                          <TableCell
                                            style={{ width: "9.75%" }}
                                            className={classes.listCell}
                                            align="left"
                                          >
                                            <Typography variant="body1">
                                              {myShift.isScheduled ? "Scheduled" : "Manual"}
                                            </Typography>
                                          </TableCell>
                                          {showHideSettings.issues ? (
                                            myShift.total_issues > 0 ? (
                                              <TableCell
                                                style={{ width: "9.75%" }}
                                                className={classes.listCell}
                                                align="left"
                                              >
                                                <span
                                                  style={{ display: "flex" }}
                                                >
                                                  {myShift.resolve_issues >
                                                    0 ? (
                                                    <>
                                                      <Typography
                                                        variant="body1"
                                                        display="inline"
                                                        style={{
                                                          lineHeight: 3.5,
                                                          color: "red",
                                                          fontWeight: 700,
                                                          marginLeft: 2,
                                                        }}
                                                        className={
                                                          classes.rowTitle
                                                        }
                                                      >
                                                        {myShift.resolve_issues}
                                                        &nbsp;{"Resolved"}
                                                      </Typography>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Avatar
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                          marginTop: 15,
                                                        }}
                                                        src={sc_issue_image}
                                                      />
                                                      <Typography
                                                        variant="body1"
                                                        display="inline"
                                                        style={{
                                                          lineHeight: 3.5,
                                                          color: "red",
                                                          fontWeight: 700,
                                                          marginLeft: 2,
                                                        }}
                                                        className={
                                                          classes.rowTitle
                                                        }
                                                      >
                                                        {myShift.total_issues}
                                                        &nbsp;
                                                        {myShift.total_issues >
                                                          1
                                                          ? "Issues"
                                                          : "Issue"}
                                                      </Typography>
                                                    </>
                                                  )}
                                                </span>
                                              </TableCell>
                                            ) : (
                                              <TableCell
                                                style={{
                                                  width: "9.75%",
                                                  color: "white",
                                                }}
                                                className={classes.listCell}
                                                align="left"
                                              >
                                                <span
                                                  style={{ display: "flex" }}
                                                >
                                                  <Typography
                                                    variant="body1"
                                                    display="inline"
                                                    style={{
                                                      lineHeight: 3.5,
                                                      color: "red",
                                                      fontWeight: 700,
                                                      marginLeft: 2,
                                                    }}
                                                    className={classes.rowTitle}
                                                  >
                                                    {myShift.total_issues}&nbsp;
                                                    {myShift.total_issues > 1
                                                      ? "Issues"
                                                      : "Issue"}
                                                  </Typography>
                                                </span>
                                              </TableCell>
                                            )
                                          ) : (
                                            ""
                                          )}

                                          <TableCell
                                          // onClick={this.props.deleteTimecard(myShift)}
                                          className={classes.listCell}
                                            align="left"
                                          >
                                            
                                            &ensp;&ensp;
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} style={{ borderRadius: 0 }}>
              <Table>
                <TableBody>
                  <TableRow
                    style={{
                      backgroundColor: "rgba(170, 7, 107, 0.125)",
                      color: "#AA076B",
                      display: "table-row",
                    }}
                    className={classes.TableRowHover}
                  >
                    <TableCell style={{ padding: 0 }} colSpan={9}>
                      <Table>
                        <TableBody>
                          <TableCell
                            style={{ width: "16.75%", paddingLeft: "2%" }}
                            className={classes.listCell}
                            align="left"
                          >
                            <Typography className={classes.rowTitle}
                              style={{ color: "#AA076B" }}
                            >
                              <b>Total</b>
                            </Typography>
                          </TableCell>

                          <TableCell
                            style={{ width: "10.75%" }}
                            className={classes.listCell}
                            align="left"
                          >
                            <Typography className={classes.headTitle}>

                            </Typography>
                          </TableCell>
                          {showHideSettings.timecard ? (
                            <TableCell
                              style={{ width: "12.55%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}
                                style={{ color: "#AA076B" }}
                              >
                                <b>
                                  {total_timecard}&nbsp;
                                  {total_timecard > 1
                                    ? "Timecards"
                                    : "Timecard"}
                                </b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {showHideSettings.scheduled ? (
                            <TableCell
                              style={{ width: "12.75%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}
                                style={{ color: "#AA076B" }}>

                                <b>
                                  {parseFloat(total_scheduled_hrs).toFixed(2)}
                                </b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {showHideSettings.actual ? (
                            <TableCell
                              style={{ width: "8.75%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                <b>{parseFloat(total_actual_hrs).toFixed(2)}</b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {/* {showHideSettings.sch_vs_paid_diff ? (
                            <TableCell
                              style={{ width: "10%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                <b>
                                  {parseFloat(total_sch_vs_paid_hrs).toFixed(2)}
                                </b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {showHideSettings.sch_vs_actual_diff ? (
                            <TableCell
                              style={{ width: "8.95%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                <b>
                                  {parseFloat(total_sch_vs_actual_hrs).toFixed(
                                    2
                                  )}
                                </b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )} */}

                          {showHideSettings.total_paid ? (
                            <TableCell
                              style={{ width: "12.75%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                <b>{parseFloat(total_paid_hrs).toFixed(2)}</b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {showHideSettings.unpaid_breaks ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              <Typography className={classes.rowTitle}>
                                <b>{parseFloat(total_break_hrs).toFixed(2)}</b>
                              </Typography>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell
                            style={{ width: "9.75%" }}
                            className={classes.listCell}
                            align="left"
                          >
                            <Typography className={classes.rowTitle}>
                              &ensp;
                            </Typography>
                          </TableCell>
                          {showHideSettings.issues ? (
                            <TableCell
                              style={{ width: "9.75%" }}
                              className={classes.listCell}
                              align="left"
                            >
                              {
                                <Typography className={classes.rowTitle}>
                                  <b>{total_issues}&nbsp;Issues</b>
                                </Typography>
                              }
                            </TableCell>
                          ) : (
                            ""
                          )}

                          <TableCell
                            style={{ width: "9.75%" }}
                            className={classes.listCell}
                            align="left"
                          >
                            <Typography className={classes.rowTitle}>
                              &ensp;
                            </Typography>
                          </TableCell>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </StickyContainer>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(ColleapseTable);

