//DownloadModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    Slide,
    Grid,
    Button,
    Typography
} from '@material-ui/core';

const styles = (theme) => ({
    avatar: {
        margin: '0px auto',
        // backgroundColor : theme.palette.primary.main,
        backgroundColor: '#ffffff',
        height: '60px',
        width: '60px'
    },
    avatarIcon: {
        fontSize: '4rem',
    },
    title: {
        textAlign: 'left'
    },
    dialog: {
        borderRadius: 0,
    },
    dialogcontent: {
        backgroundColor: '#fcfcfc',
        padding: '2rem',
        borderRadius: 0,
        overflow: 'hidden',
        "&:last-child": {
            borderRadius: 0,
            padding: '2rem'
        },
    },
    saveButton: {
        boxShadow: 'none',
        fontWeight: 600,
        color: '#ffffff',
        borderRadius: 0,
        padding: '10px 22px'
    },
    closeButton: {
        boxShadow: 'none',
        fontWeight: 600,
        color: '#ffffff',
        borderRadius: 0,
        padding: '10px 22px'
    }
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DownloadModal extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                maxWidth="xs"
                fullWidth
                className={classes.dialog}
                open={this.props.open}
                onClose={this.props.onClose}
                TransitionComponent={Transition}
            >
                <DialogContent
                    className={classes.dialogcontent}
                    dividers>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography
                                className={classes.title}
                                variant="h3"
                            >
                                Download Timesheets
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography
                                className={classes.title}
                                variant="h4"
                                gutterBottom
                            >
                                How would you like to format your download?
                            </Typography>
                        </Grid>


                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    md={6}
                                    xs={6}
                                >
                                    <Button
                                        onClick={this.props.onClose}
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        className={classes.closeButton}
                                        style={{ backgroundColor: 'gray' }}
                                    >
                                        cancel
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={6}
                                >
                                    <Button
                                        style={{ backgroundImage: this.props.bgColor ? this.props.bgColor : "gray" }}
                                        onClick={this.props.handleSubmit}
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        className={classes.saveButton}
                                    >
                                        Next
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(DownloadModal);