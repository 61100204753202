import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Paper,
  Grid,
  TextareaAutosize,
  Typography,
  Stack,
} from "@mui/material";
import { connect } from "react-redux";
import { history } from "../../../../history";
import { withStyles } from "@material-ui/core/styles";
import {
  getLessonData,
  fetchAssignLesson,
  setManagerGrading,
} from "../modules/actions";
import Cookies from "universal-cookie";
import { LoadingData, TableRecordNotFound } from "../../../../components";
import LessonSlides from "../LessonSlides";
import { styles } from "../ThemeStyle";

// toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GradingLoading } from "../utils/GradingLoading";
import styled from "styled-components";
import { MobileGradingVideoQuiz } from "../../../../mobileView/eLearning/pages/Grading/MobileGradingVideoQuiz";
import { withWindowSize } from "../../../../mobileView/hoc/withWindowSize";
toast.configure();

const cookies = new Cookies();
var ovUid = cookies.get("ovUid");

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const GradingContainer = styled.div`
  background-color: rgba(247, 248, 252, 1);
  height: 100%;
  width: 35%;
  padding: 5% 40px 40px 40px;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const GradingVideoQuiz = (props) => {
  const [showList, setShowList] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [Description, setDescription] = useState(
    "React Native is one of the most popular cross-platform app development frameworks. Using JavaScript, you can develop native apps for both Android and iOS..."
  );
  const [status, setStatus] = useState("Pass");
  const [note, setNote] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [data, setData] = useState({});

  const [videoLoadingFlag, setVideoLoadingFlag] = useState(false);

  function normalizeVideoURL(url) {
    if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  }

  useEffect(() => {
    setData(props.LessonDetail);
    setVideoLoadingFlag(true);
    let data = {
      ecid: props.match.params.ecid,
      lesson_id: props.match.params.lesson_id,
      uid: props.match.params.user_id,
    };
    props.getLessonData(data);

    console.log(ovUid, "USer ID");
    let user_id = props.match.params.user_id;
    let lessonData = { _id: props.match.params.lesson_id };
    props.fetchAssignLesson(user_id, lessonData);

    if (props.LessonDetail) {
      setVideoLoadingFlag(false);
      console.log(props.LessonDetail?.lesson_result?.video_url, "Video Url");
      let normalizedVideoURL = normalizeVideoURL(
        props.LessonDetail?.lesson_result?.video_url
      );
      setVideoURL(`${normalizedVideoURL}?${new Date().getTime()}`);
    }
  }, [props.LessonDetail?.lesson_result?.video_url]);

  const backPage = () => {
    history.push("/elearning/grading");
  };

  const handleStatus = (value) => {
    setStatus(value);
  };

  const handleNote = (value) => {
    setNote(value);
  };

  const [submitted, setSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = useCallback(() => {
    let data = {
      result_id: props.match.params.result_id,
      status: status,
      grading_type: "Video",
      note: note,
    };

    props.setManagerGrading(data);
    setSubmitted(true);

    console.log(note, status, "Hello World");
  }, [props, status, note]);

  useEffect(() => {
    if (submitted && !showToast && !props.isStatusLoading) {
      let message = props.statusPayload?.message;

      toast.success(message, {
        containerId: "TOP_RIGHT",
        autoClose: 2000,
        onClose: () => {
          setShowToast(true);
          backPage();
        },
      });
    }
  }, [props.isStatusLoading, submitted, showToast]);

  localStorage.setItem("gradingToggelTabState", JSON.stringify("team"));

  const {
    LessonDetail,
    isLoadingLesson,
    LessonPayload,
    isLessonPayloadLoading,
    classes,
  } = props;

  if (isLoadingLesson && isLessonPayloadLoading) return <LoadingData />;

  const { width } = props.windowSize;
  const isDesktop = width > 768;

  return isDesktop ? (
    <Grid container className={classes.root}>
      {props.LessonDetail?.lessonData?.length > 0 ? (
        <Paper className={classes.paper}>
          <Container>
            <LessonSlides classes={classes} LessonDetail={LessonDetail} />
            <GradingContainer>
              <div
                className="align-items-center"
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingTop: "1%",
                  textAlign: "center",
                }}
              >
                <div
                  className={"d-flex justifyContent center"}
                  style={{ justifyContent: "center" }}
                >
                  <p className={"mb-0 " + classes.lessonTitleS}>
                    Uploaded video
                  </p>
                </div>
                <div
                  className={"d-flex justifyContent center"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "3%",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "390px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!videoLoadingFlag ? (
                      <video
                        style={{ width: "90%", height: "360px" }}
                        controls
                        onLoadedData={() =>
                          console.log("Video loaded successfully")
                        }
                      >
                        <source src={videoURL} type="video/mp4" />
                        <source src={videoURL} type="video/webm" />
                      </video>
                    ) : (
                      <Stack spacing={2} m={"1rem"}>
                        <GradingLoading />
                      </Stack>
                    )}
                  </Paper>
                </div>
                <>
                  <Box
                    m={"1rem"}
                    alignItems={"center"}
                    display={"flex"}
                    gap={"1.5rem"}
                    flexWrap={"wrap"}
                    paddingLeft={"15px"}
                    minWidth={"350px"}
                  >
                    <Typography fontSize={"22px"} fontWeight={700}>
                      Status:
                    </Typography>
                    <Box m={"1rem"} gap={"1rem"} display={"flex"}>
                      <div style={{ display: "flex" }}>
                        <Button
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "16px",
                            height: "35px",
                            width: "115px",
                            backgroundColor:
                              status === "Pass"
                                ? `#135098`
                                : "rgba(27, 63, 143, 0.3)",
                            color: status === "Pass" ? "white" : "#1B3F8F",
                            borderRadius: "29px",
                          }}
                          value="Pass"
                          onClick={(e) => {
                            handleStatus(e.target.value);
                          }}
                        >
                          Pass
                        </Button>
                        <Button
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            marginLeft: "10px",
                            fontSize: "16px",
                            width: "115px",
                            height: "35px",
                            backgroundColor:
                              status === "Reassigned"
                                ? `#135098`
                                : "rgba(27, 63, 143, 0.3)",
                            color:
                              status === "Reassigned" ? "white" : "#1B3F8F",
                            borderRadius: "29px",
                          }}
                          value="Reassigned"
                          onClick={(e) => {
                            handleStatus(e.target.value);
                          }}
                        >
                          Reassign
                        </Button>
                      </div>
                    </Box>
                  </Box>
                  <Box>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Add notes"
                      value={note}
                      onChange={(e) => handleNote(e.target.value)}
                      style={{
                        width: "100%",
                        height: "129px",
                        outline: "none",
                        border: "none",
                        borderRadius: "5px",
                        padding: "20px",
                      }}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                    />
                  </Box>
                </>
                <Box m={"1rem"}>
                  <Button
                    style={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      height: "35px",
                      width: "115px",
                      backgroundColor: "#135098",
                      color: "white",
                      borderRadius: "29px",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </GradingContainer>
          </Container>
        </Paper>
      ) : (
        <TableRecordNotFound />
      )}
    </Grid>
  ) : (
    <MobileGradingVideoQuiz
      status={status}
      handleStatus={handleStatus}
      handleSubmit={handleSubmit}
      note={note}
      handleNote={handleNote}
      videoURL={videoURL}
      videoLoadingFlag={videoLoadingFlag}
      LessonDetail={props.LessonDetail}
    />
  );
};

const mapStateToProps = (state) => ({
  LessonDetail: state.eLearningGrading.LessonDetail,
  LessonArray: state.eLearningGrading.LessonArray,
  isLoadingLesson: state.eLearningGrading.isLoadingLesson,
  isLessonPayloadLoading: state.eLearningGrading.isLessonPayloadLoading,
  LessonPayload: state.eLearningGrading.LessonPayload,
  isStatusLoading: state.eLearningGrading.isStatusLoading,
  statusPayload: state.eLearningGrading.statusPayload,
  role: state.eLearningGrading.role,
});

const ConnectWith = connect(mapStateToProps, {
  setManagerGrading,
  fetchAssignLesson,
  getLessonData,
})(GradingVideoQuiz);

const ConnectedWithWindowSize = withWindowSize(ConnectWith);

export default withStyles(styles)(ConnectedWithWindowSize);
