import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  lessonSlideDetailRoot: {
    borderRadius: "5px",
    boxShadow: "0px 0px 4px 0px #00000040",
    maxWidth: "100%",
    padding: "10px",
    margin: "10px 20px",
    marginTop: window.location.href.includes("myhelpdesk/slider")
      ? "10px"
      : "150px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    overflowY: "auto",
    background: "white",
  },
  lessonSlideDetailVideoContainer: {
    display: (props) => (props.description.videoUrl ? "flex" : "none"),
    width: "100%",
    justifyContent: "center",
    alignItems: "start",
  },
  lessonSlideDetailVideo: {
    width: "100%",
    maxHeight: "340px",
  },
  lessonSlideDetailIframe: {
    width: "100%",
    height: "300px",
  },
}));

const SlideDetail = ({ description }) => {
  const classes = useStyles({ description });

  const renderContent = () => {
    return { __html: description.content || "" };
  };

  return (
    <Box className={classes.lessonSlideDetailRoot} component="article">
      {description.lesson_type === 1 ? (
        <Typography component="div" dangerouslySetInnerHTML={renderContent()} />
      ) : description.lesson_type === 2 ? (
        <Box className={classes.lessonSlideDetailVideoContainer}>
          <video
            className={classes.lessonSlideDetailVideo}
            id="prevideo"
            controls={true}
          >
            <source
              className="preview_video_src"
              src={description.videoUrl}
              type="video/mp4"
            />
          </video>
        </Box>
      ) : (
        <iframe
          className={classes.lessonSlideDetailIframe}
          id="myIframe"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${description?.documenturl}`}
          title="description"
        />
      )}
    </Box>
  );
};
export default SlideDetail;
