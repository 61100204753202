import { configOptions } from "final-form";
import moment from "moment";
import {
  SET_USERS_NOTIFICATIONS_LOADING,
  SET_USERS_LOADING,
  SET_USERS,
  ADD_USERS,
  EDIT_USERS,
  DELETE_USERS,
  LOGIN_USER,
  LOGOUT_USER,
  SET_COUNT,
  TOGGLE_VERIFY_USERS,
  SET_USERS_NOTIFICATIONS,
  USER_RESEND_INVITE
} from "./constants";

const initialState = {
  isLoading: true,
  locationData: [],
  notificationLoading: true,
  totalPage:1,
  countData: {},
  notificationsData: [],
  usersData: [],
  currentUser: {},
  isLoggedIn: false
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USERS_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case LOGOUT_USER:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: payload,
        currentUser: {}
      };
    case SET_USERS_NOTIFICATIONS_LOADING:
      return {
        ...state,
        isLoading: false,
        notificationLoading: true
      };
    case SET_USERS_NOTIFICATIONS:
      return {
        ...state,
        isLoading: false,
        notificationsData: payload.notificationsData,
        notificationLoading: false
      };
    case SET_COUNT:
      return {
        ...state,
        countData: payload.countData
      };
    case LOGIN_USER:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: true,
        currentUser: payload
      };
    case SET_USERS:
      return {
        ...state,
        isLoading: false,
        totalPage:payload.totalPages,
        usersData: payload.result,
        users_count: payload.count,
        locationData: payload.locationData
      };
    case ADD_USERS:
      var myPayload = payload
      // if (payload.assignLocations && payload.assignLocations.length > 0) {
      //   var assignLocations = []
      //   state.locationData.map((location) => {
      //     if (payload.includes(location.lid)) {
      //       assignLocations.push(location.location_name)
      //     }
      //   })
      //   myPayload.assignLocations = assignLocations
      // }

      return {
        ...state,
        isLoading: false,
        usersData: [...state.usersData, myPayload]
      }
    case USER_RESEND_INVITE:
      var newUser = state.usersData.find((user) => payload._id === user._id);
      newUser.invitation_date = payload.invitation_date
      return {
        ...state,
        isLoading: false,
        usersData: state.usersData.map((item) => item._id === payload.uid ? { ...newUser } : item)
      }
    case TOGGLE_VERIFY_USERS:
      var newUser = state.usersData.find((user) => payload.uid === user._id);
      newUser.user_status = payload.user_status
      return {
        ...state,
        isLoading: false,
        usersData: state.usersData.map((item) => item._id === payload.uid ? { ...newUser } : item)
      }
    case EDIT_USERS:
      return {
        ...state,
        isLoading: false,
        usersData: state.usersData.map((item) => item._id === payload.uid ? { ...payload } : item)
      }
    case DELETE_USERS:
      return {
        ...state,
        isLoading: false,
        usersData: state.usersData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;