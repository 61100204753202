import {
  SET_FEEDBACK_REPORT_LOADING,
  SET_FEEDBACK_CATEGORY_REPORT,
  SET_FEEDBACK_REPORT,
  DELETE_CUSTOM_REPORT,
  DELETE_SCHEDULE_REPORT,
  ADD_CUSTOM_REPORT,
  ADD_SCHEDULE_REPORT,
  EDIT_CUSTOM_REPORT,
  EDIT_SCHEDULE_REPORT,
  FETCH_ALL_CUSTOM_REPORT,
  FETCH_CAMPAIGN_DATA,
  SET_SCHEDULE_REPORT,
} from "./constants";

const initialState = {
  isLoading: true,
  categoryData: [],
  customReportData: [],
  scheduleReportData: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEEDBACK_REPORT_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FEEDBACK_CATEGORY_REPORT:
      return {
        ...state,
        isLoading: false,
        categoryData: payload.categoryData,
      };
    case SET_FEEDBACK_REPORT:
      return {
        ...state,
        isLoading: false,
        all_Feedbacks_Category_Count: payload.categoryData,
        category: payload.categoryDrop,
        campeign: payload.campeignDrop,
      };
    case FETCH_CAMPAIGN_DATA:
      return {
        ...state,
        isLoading: false,
        allcampaignDrop: payload,
      };

    case SET_SCHEDULE_REPORT:
      return {
        ...state,
        isLoading: false,
        scheduleReportData: payload,
      };

    case FETCH_ALL_CUSTOM_REPORT:
      return {
        ...state,
        isLoading: false,
        customReportData: payload,
      };

    case ADD_CUSTOM_REPORT:
      return {
        ...state,
        isLoading: false,
        customReportData: [...state.customReportData, payload],
      };
    case ADD_SCHEDULE_REPORT:
      return {
        ...state,
        isLoading: false,
        scheduleReportData: [...state.scheduleReportData, payload],
      };

    case EDIT_CUSTOM_REPORT:
      return {
        ...state,
        isLoading: false,
        customReportData: state.customReportData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };

    case EDIT_SCHEDULE_REPORT:
      return {
        ...state,
        isLoading: false,
        scheduleReportData: state.scheduleReportData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };

    case DELETE_CUSTOM_REPORT:
      return {
        ...state,
        isLoading: false,
        customReportData: state.customReportData.filter(
          (item) => item.id !== payload
        ),
      };
    case DELETE_SCHEDULE_REPORT:
      return {
        ...state,
        isLoading: false,
        scheduleReportData: state.scheduleReportData.filter(
          (item) => item._id !== payload
        ),
      };
    default:
      return state;
  }
};

export default Reducer;
