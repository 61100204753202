import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { FILE_URL } from '../../../config';
import {
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Paper,
    Grid
}
    from '@material-ui/core';
import { schedulerColor } from '../../../config';
const styles = (theme) => ({
    root: {
        padding: 5
    },
    TableRowHover: {
        padding: 0
    },
    userCell: {
        padding: '10px'
    },
    listCell: {
        padding: '10px',
        borderBottom: 'none'
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "40px",
        width: "40px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: schedulerColor.main,
        color: "white",
    },
    progress: {
        height: '7px',
        borderRadius: '50px',
        backgroundColor: '#ffffff'
    },
    barColorGreen: {
        backgroundColor: 'green'
    },
    barColorRed: {
        backgroundColor: 'red'
    },
    barColorOrange: {
        backgroundColor: 'orange'
    }
})

class ColleapseTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            headCells: [
                {
                    id: 'lesson_name',
                    label: "name",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'lesson_level',
                    label: "Difficult Level",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'created_at',
                    label: "Created at",
                    numeric: false,
                    align: "left"
                },
                {
                    id: 'updated_at',
                    label: "Updated at",
                    numeric: false,
                    align: "left"
                }
            ],
        }
    }

    render() {
        var { classes, usersData } = this.props
        return (
            <Grid className={classes.root} container spacing={1}>
                <Paper style={{ width: "100%" }} className={classes.paper}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                {usersData.map((item, index) => {
                                    return (
                                        <>
                                            <TableRow
                                                hover>
                                                <TableCell
                                                    onClick={() => { this.props.handleClickViewDetailsSingle(index) }}
                                                    className={classes.userCell} align="left">
                                                    {item.user_image ?
                                                        <Avatar
                                                            src={FILE_URL + item.user_image}
                                                            className={classes.avatar}
                                                        />
                                                        :
                                                        <Avatar className={classes.avatar} >
                                                            {item.user_name.charAt(0)}
                                                        </Avatar>
                                                    }
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                            color: schedulerColor.main,
                                                            lineHeight: 2.5
                                                        }} variant="h5"
                                                    >
                                                        {item.user_name}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell
                                                    className={classes.userCell}
                                                    align="right"
                                                    style={{
                                                        textAlign: 'end'
                                                    }}>
                                                    {/* <IconButton
                                                        onClick={() => { this.props.handleClickViewDetailsSingle(index) }}
                                                    >
                                                        {item.is_expanded ?
                                                            <DownIcon style={{ color: schedulerColor.main }} />
                                                            : <RightIcon style={{ color: schedulerColor.main }} />
                                                        }
                                                    </IconButton> */}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        )
    }
}

export default withStyles(styles)(ColleapseTable);
