import {
  SET_TIMESHEET_LOADING,
  SET_TIMESHEET,
  SET_PAYROLL_TIMECODE
} from "./constants";

const initialState = {
  isLoading: true,
  usersData: [],
  timecodeData: [],
  toolbarSettings: {},
  current_startDate: null,
  current_endDate: null,
  previous_startDate: null,
  previous_endDate: null,
  custom_startDate:null,
  custom_startDate:null,
  todaydate:null,
  Display_Edited_Timecard_Only: 0,
  Display_Unscheduled_Shifts_Only: 0,
  Hide_Employees_With_No_Hours: 0,
  Hide_Employees_With_No_Timecard: 0,
  Display_Scheduled_Shifts_Only:0,
  filter_employee_uid: "",
  filter_group_by: "",
  filter_sort_by: "",
  status_is_approved:2,
  payPeriod:""

}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMESHEET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_PAYROLL_TIMECODE:
      return{
        ...state,
        timecodeData: payload.TimecodeData,
      }
    case SET_TIMESHEET:
      return {
        ...state,
        isLoading: false,
        usersData: payload.usersData,
        toolbarSettings: payload.toolbarSettings,
        current_startDate: payload.current_startDate,
        current_endDate: payload.current_endDate,
        previous_startDate: payload.previous_startDate,
        previous_endDate: payload.previous_endDate,
        Display_Edited_Timecard_Only: payload.Display_Edited_Timecard_Only,
        Display_Unscheduled_Shifts_Only: payload.Display_Unscheduled_Shifts_Only,
        Display_Scheduled_Shifts_Only:payload.Display_Scheduled_Shifts_Only,
        Hide_Employees_With_No_Hours: payload.Hide_Employees_With_No_Hours,
        Hide_Employees_With_No_Timecard: payload.Hide_Employees_With_No_Timecard,
        filter_employee_uid: payload.filter_employee_uid,
        filter_group_by: payload.filter_group_by,
        filter_sort_by: payload.filter_sort_by,
        payPeriod:payload.payPeriod,
        status_is_approved:payload.status_is_approved,
        custom_startDate:payload.custom_startDate,
        custom_endDate:payload.custom_endDate,
        todaydate:payload.todaydate
      };

    default:
      return state;
  }
};

export default Reducer;