import {
  SET_FEEDBACK_WORKFLOW_AUTOMATION_LOADING,
  SET_FEEDBACK_WORKFLOW_AUTOMATION,
  ADD_FEEDBACK_WORKFLOW_AUTOMATION,
  EDIT_FEEDBACK_WORKFLOW_AUTOMATION
} from "./constants";

const initialState = {
  isLoading: true,
  workflowData: [],
  locationDropdown: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FEEDBACK_WORKFLOW_AUTOMATION_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FEEDBACK_WORKFLOW_AUTOMATION:
    return {
        ...state,
        isLoading: false,
        workflowData: payload.workflowData,
      };
    // case ADD_FEEDBACK_LOCATION:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     workflowData: [...state.workflowData, payload],
    //   };
    // case EDIT_FEEDBACK_LOCATION:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     workflowData: state.workflowData.map((item) =>
    //       item._id === payload._id ? { ...payload } : item
    //     ),
    //   };
    default:
      return state;
  }
};

export default Reducer;
