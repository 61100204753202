import React from "react";

import { GradingLessonContextProvider } from "../eLearning/context/gradingContext";
import { SearchProvider } from "../eLearning/context/SearchContext";
import { SearchProviderHR } from "../HR/context/MobileHRSearchContext";

import { NewsContextProvider } from "../news/context";
import { NewsMobileSearchProvider } from "../news/context/MobileNewsSearchContext";
import { TeamFeedbackMobileSearchProvider } from "../TeamFeedback/context/MobileTeamSearchContext";
const CombinedContextProvider = ({ children }) => (
  <GradingLessonContextProvider>
    <SearchProvider>
      <NewsContextProvider>
        <TeamFeedbackMobileSearchProvider>
        <NewsMobileSearchProvider>
          <SearchProviderHR>{children}</SearchProviderHR>       
        </NewsMobileSearchProvider>
        </TeamFeedbackMobileSearchProvider>
      </NewsContextProvider>
    </SearchProvider>
  </GradingLessonContextProvider>
);

export default CombinedContextProvider;
