import SlideQuiz from "./SlideQuiz";
import { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { FeedbackModal, MobileLoader } from "../../utilities";
import { BiArrowBack } from "react-icons/bi";
import { history } from "../../../../history";
import { SlideDetail, SlidesBox } from "../../components";
import { connect } from "react-redux";
import {
  getLessonDetailsById,
  getLessonByCourses,
} from "../../../../pages/eLearning/MyCourses/modules/actions";
import SummaryModal from "../../../../components/SummaryModal";
import QuizSummaryModalMobile from "../../../../pages/eLearning/MyCourses/QuizSummaryModalMobile";
import { Box, Typography } from "@material-ui/core";

import "./MobileCourseDetail.scss";
import EarningLessonPopup from "../../../../pages/eLearning/MyCourses/EarningLessonPopup";

const Button = ({ text, handleClick }) => (
  <button
    className={"courseDetailMobileBtnStyle"}
    onClick={handleClick}
    disableScrollLock={true}
  >
    {text}
  </button>
);

const CourseDetail = ({
  getLessonDetailsById,
  lessonSlider,
  lessonName,
  lessonDetailsData,
  slideIndex,
  setLessondata,
  updateSlide,
  startQuiz,
  resumeQuiz,
  isLoading,
  handleFeedbackChange,
  handleFeedback,
  handleRating,
  rating,
  feedbackModalOpen,
  handleCloseFeedbackModal,
  handleFeedbackSubmitted,
  feedback,
  questionModal,
  closeModal,
  getLessonDetails,
  isQuestionSubmit,
  active_question_index,
  correct_answer,
  user_correct_answer,
  slide_questions,
  handleUserAnswer,
  questionLoading,
  totalAttempts,
  questionCompleted,
  quizSummary,
  closeSummaryModal,
  quizSummaryModal,
  summary_questions,
  quiz_score,
  summary_status,
  slideId,
  SlideIndex,
  summary_loading,
  permissionsData,
  // earning
  isEarningSetting,
  showPopup,
  earningModuleType,
  earningModalOpen,
  closeEarningModal,
  completedLessonEarningAmount,
  completedLessonEarningPoint,
}) => {
  const { id, cid } = useParams();

  useEffect(() => {
    console.log(id, cid, "id CIDDDx");
    let data = {
      lesson_id: id,
      ecid: cid,
    };

    getLessonDetailsById(data);
  }, []);

  useEffect(() => {
    console.log(id, cid, "id CIDDDx");
    let data = {
      lesson_id: id,
      ecid: cid,
    };

    getLessonDetailsById(data);
  }, [id, cid, getLessonDetailsById]);

  const EarningPointsAndAmount = (data) => {
    return !!isEarningSetting ? (
      <p style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
        {`(${
          earningModuleType === 1 || earningModuleType === 3
            ? `Points: ${data.earningPoints || 0} | `
            : ""
        } ${
          earningModuleType === 2 || earningModuleType === 3
            ? `Amount: ${data.earningAmounts || 0}$`
            : ""
        })`}
      </p>
    ) : (
      ""
    );
  };

  return (
    <>
      <section className={"courseDetailMobileRoot"}>
        <article className={"courseDetailMobileArticle"}>
          <Box className={"courseDetailMobileBackButton"}>
            <BiArrowBack
              size={20}
              onClick={() => {
                history.push("/elearning/mycourses");
              }}
            />
          </Box>

          <Box className={"courseDetailMobileTitleContainer"}>
            <Typography variant="h2" className={"courseDetailMobileTitle"}>
              {" "}
              {lessonName}
            </Typography>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "10px",
              }}
            >
              {EarningPointsAndAmount(lessonDetailsData)}
            </p>
            {lessonSlider &&
              lessonSlider?.map(
                (item, index) =>
                  slideIndex === index && (
                    <p
                      key={slideIndex}
                      className={"courseDetailMobileSlideTitle"}
                    >
                      {item.slide_title}
                    </p>
                  )
              )}
          </Box>

          <Box className={"courseDetailMobileFeedbackBtnContainer"}>
            {permissionsData?.elearning_submit_feedback ? (
              <button
                className={"courseDetailMobileFeedbackButton"}
                onClick={handleFeedback}
              >
                Feedback
              </button>
            ) : (
              <div style={{ width: "45px" }}></div>
            )}
          </Box>
        </article>

        {isLoading ? (
          <Box className={"courseDetailMobileLoaderContainer"}>
            <MobileLoader />
          </Box>
        ) : (
          <>
            {lessonSlider &&
              lessonSlider?.map(
                (item, index) =>
                  slideIndex === index && (
                    <Fragment key={item._id}>
                      {" "}
                      <SlideDetail description={item} />
                      <Box className={"courseDetailMobileButtonContainer"}>
                        {!item.isCompleted ? (
                          item.slide_quiz_completed === 1 ? (
                            <Button
                              text={
                                index + 1 < lessonSlider?.length
                                  ? "Acknowledge & move to next slide"
                                  : "Acknowledge"
                              }
                              handleClick={() => updateSlide(item._id, index)}
                            />
                          ) : item.last_attempt?.quiz_status === "Failed" ? (
                            <Button
                              text={"Start Quiz Again"}
                              handleClick={() => startQuiz(item._id)}
                            />
                          ) : item.last_attempt?.quiz_status === "Pending" ? (
                            <Button
                              text={"Resume Quiz"}
                              handleClick={() => resumeQuiz(item._id)}
                            />
                          ) : item.slide_questions.length > 0 ? (
                            <Button
                              text={"Start Quiz"}
                              handleClick={() => startQuiz(item._id)}
                            />
                          ) : (
                            <Button
                              text={
                                index + 1 < lessonSlider?.length
                                  ? "Acknowledge & move to next slide"
                                  : "Acknowledge"
                              }
                              handleClick={() => updateSlide(item._id, index)}
                            />
                          )
                        ) : (
                          " "
                        )}
                      </Box>
                    </Fragment>
                  )
              )}

            {lessonSlider && lessonSlider.length > 0 && (
              <div className={"courseDetailslidesBox"}>
                <SlidesBox
                  slides={lessonSlider}
                  handleSlide={setLessondata}
                  slideIndex={slideIndex}
                  slideAccessFlag={true}
                />
              </div>
            )}
          </>
        )}

        {!!isEarningSetting
          ? showPopup && (
              <EarningLessonPopup
                open={earningModalOpen}
                onClose={() => closeEarningModal()}
                points={
                  earningModuleType === 1 || earningModuleType === 3
                    ? completedLessonEarningPoint
                    : false
                }
                amount={
                  earningModuleType === 2 || earningModuleType === 3
                    ? completedLessonEarningAmount
                    : false
                }
              />
            )
          : ""}

        <SlideQuiz
          open={questionModal}
          handleClose={() => {
            closeModal();
            getLessonDetails();
          }}
          isQuestionSubmit={isQuestionSubmit}
          active_question_index={active_question_index}
          correct_answer={correct_answer}
          questionCompleted={questionCompleted}
          user_correct_answer={user_correct_answer}
          slide_questions={slide_questions}
          handleUserAnswer={handleUserAnswer}
          questionLoading={questionLoading}
          totalAttempts={totalAttempts}
          quizSummary={quizSummary}
        />
        <FeedbackModal
          open={feedbackModalOpen}
          handleClose={handleCloseFeedbackModal}
          handleFeedbackSubmitted={handleFeedbackSubmitted}
          handleFeedback={handleFeedbackChange}
          handleRating={handleRating}
          rating={rating}
          feedback={feedback}
        />
        <SummaryModal
          onClose={() => {
            closeSummaryModal();
            getLessonDetails();
          }}
          open={quizSummaryModal}
        >
          <QuizSummaryModalMobile
            questions={summary_questions}
            quizScore={quiz_score}
            summary_status={summary_status}
            slide_id={slideId}
            SlideIndex={SlideIndex}
            startQuiz={startQuiz}
            updateQuiz={updateSlide}
            closeSummaryModal={() => {
              closeSummaryModal();
              getLessonDetails();
            }}
            loading={summary_loading}
            quizSummary={quizSummary}
          />
        </SummaryModal>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  lessonSlider: state.eLearningMyCourses.lessonSlider,
  totalAttempts: state.eLearningMyCourses.totalAttempts,
  isLoading: state.eLearningMyCourses.isLoading,
  lessonName: state.eLearningMyCourses.lessonName,
  // lessonDetailsData: state.elearningMyCourses.lessonDetailsData,
  lessonId: state.eLearningMyCourses.lessonId,
  courseLessonsData: state.eLearningMyCourses.courseLessonsData,
  myCourse_settings: state.eLearningMyCourses.myCourse_settings,
  myHelpdesk_setting: state.eLearningMyCourses.myHelpdesk_setting,
  permissionsData: state.settingsRoles.permissionsData,
});

const ConnectWith = connect(mapStateToProps, {
  getLessonDetailsById,
  getLessonByCourses,
})(CourseDetail);
export default ConnectWith;
