import React, { Component } from "react";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { withStyles } from "@material-ui/core/styles";
import { LoadingData, MyDatePicker } from "../../../components";
import { connect } from "react-redux";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    ArrowBack as BackIcon,
} from "@material-ui/icons";
import Cookies from "universal-cookie";
import {
    IconButton,
    Typography,
    Checkbox,
    MenuItem,
    TextField,
    Select,
    Paper,
    Grid,
    Tooltip,
    Button,
    FormControl,
} from "@material-ui/core";
import { schedulerColor } from "../../../config";
import { fetchData as fetchlocationData } from "../../common/Location/modules/actions";
import { fetchData, addData, editData, deleteData } from "../../common/PublicHoliday/modules/actions";
import moment from "moment";
import { alert, utcToLocal, validationIsEmpty } from "../../../utilities";
import './correction.css';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    title: {
        flex: "1 1 100%",
        fontSize: "16px",
        fontWeight: "400",
    },
    button: {
        backgroundImage: schedulerColor.main,
        color: "#FFFFFF",
    },
    paperMain: {
        padding: 10,
        height: "100%",
        minHeight: '100vh'
    },
    dialog: {
        margin: "0px 0px 0px auto",
        right: "0px",
        left: "auto",
        [theme.breakpoints.up("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "55%",
        },
    },
    dialogcontent: {
        backgroundColor: "#fcfcfc",
        padding: "1rem 1.5rem",
    },
    text: {
        color: schedulerColor.main,
        fontWeight: "600",
        lineHeight: '3'
    },
    closeButton: {
        backgroundImage: schedulerColor.main,
        color: "white",
        marginRight: "5%",
        '&:hover': {
            border: '1px solid white',
            color: 'white',
            backgroundImage: schedulerColor.main,
        }
    },
    saveButton: {
        backgroundImage: schedulerColor.main,
        color: "white",
        '&:hover': {
            border: '1px solid white',
            color: 'white',
            backgroundImage: schedulerColor.main,
        }
    },
    DialogTitle: {
        padding: "10px 24px",
    },
    closeIcon: {
        color: "black",
        cursor: "pointer",
        float: "right",
    },
    TableCell: {
        fontSize: "14px",
        fontWeight: "400",
    },
    newsSection: {
        display: 'flex',
        padding: 14,
        width: 'inherit',
    },
    columnFlex: {
        display: 'block',
        width: 'auto'
    },
    newsTitle: {
        fontSize: 14,
        color: '#646A70A6',
        width: 'auto'
    },
});

const cookies = new Cookies();
const format = 'h:mm a';

class addCorrectionRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualData: {},
            isEdit: 1,
            locationData: []
        };
    }

    addEditModalClose = () => {
        this.setState({
            addEditModal: false
        })
    };


    render() {
        const { classes, onClose,isEdit,comments } = this.props;

        return (
            <>
                <Paper className={classes.paperMain}>
                    <Grid container className={classes.root}>
                        <Grid item sm={12} xs={12} style={{ display: "flex" }}>
                            <Tooltip arrow title="Back">
                                <IconButton
                                    className={classes.closeIcon}
                                    size="small"
                                    onClick={onClose}
                                >
                                    <BackIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography style={{ padding: '5px 7px', fontSize: '17px' }}>{isEdit ? "Edit New Request" : "Add New Request"}</Typography>
                        </Grid>

                        <Grid className={classes.newsSection}>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                    Select Date :
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.columnFlex}>
                                <div className={classes.newsTitle}>
                                    <div style={{ paddingTop: 15 }}>
                                        <MyDatePicker
                                            name="occurance_date"
                                            label="Date of Holiday"
                                            onChange={(date) => {this.props.handleChangeOccuranceDate(date)}}
                                            onFocus={this.props.handleFocus}
                                            value={this.props.occurance_date}
                                            placeholder="Enter Date"
                                            error={this.props.errors.title}
                                            helperText={this.props.errors.title ? this.props.errorsHelper.title : ""}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                    Choose User :
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.columnFlex}>
                                <div className={classes.newsTitle}>
                                    <div style={{ paddingTop: 15 }}>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="User" 
                                        variant="outlined" 
                                        value={this.props.UserName}
                                        disabled
                                        size="small"
                                    />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        {/* <Grid item sm={12} xs={12} style={{ display: "flex" }}>
                            <Grid md={8} sm={6} xs={5}
                                className={classes.titlePage}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Typography>Correction Type</Typography>
                                </div>
                                <div class="line" />
                            </Grid>
                        </Grid> */}

                        <Grid container style={{ display: 'flex' }}>
                            <h5 style={{ margin: 15 }}>Correction Type</h5>
                            <div className="line" />

                        </Grid>
                        <Grid className={classes.newsSection}>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: 8 }}>
                                        <Checkbox
                                            checked={this.props.isShiftStartChecked}
                                            onChange={this.props.handleShiftStartCheckbox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                        Shift Start :
                                    </div>
                                </div>
                            </Grid>
                            {this.props.isShiftStartChecked === 1 ? 
                            <>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                    Actual Shirt Start Time :
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.columnFlex}>
                                <div className={classes.newsTitle}>
                                    <div style={{ paddingTop: 15 }}>
                                        <TimePicker
                                            showSecond={false}
                                            value={moment(this.props.startTime, "hh:mm A")}
                                            className="xxx"
                                            onChange={this.props.onChangeStartTime}
                                            format={format}
                                            use12Hours
                                            inputReadOnly
                                            disabled={this.props.isShiftStartChecked === 0}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            </>  : "" }
                        </Grid>
                        {this.props.isShiftStartChecked === 1 ? 
                        <Grid className={classes.newsSection}>
                            <Grid item md={2} lg={2} sm={2} xs={2}>
                                <div style={{ paddingTop: 15, textAlign: 'left' }}>
                                    Comment :
                                </div>
                            </Grid>
                            <Grid item md={10} lg={10} sm={10} xs={10}>
                                <div>
                                    <textarea 
                                    value={comments.shirt_start_comment}
                                    name="shirt_start_comment"
                                    style={{ width: '-webkit-fill-available' }} disabled={this.props.isShiftStartChecked === 0}
                                    onChange={this.props.handleChangeComment} />
                                </div>
                            </Grid>
                        </Grid>:""}

                        <Grid className={classes.newsSection}>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: 8 }}>
                                        <Checkbox
                                            checked={this.props.isBreakChecked}
                                            onChange={this.props.handleBreakCheckbox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                       Start Break :
                                    </div>
                                </div>
                            </Grid>
                            {this.props.isBreakChecked === 1 ? 
                            <>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                    Break Start Time :
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.columnFlex}>
                                <div className={classes.newsTitle}>
                                    <div style={{ paddingTop: 15 }}>
                                        <TimePicker
                                            showSecond={false}
                                            value={moment(this.props.BreakEndTime, "hh:mm A")}
                                            className="xxx"
                                            onChange={this.props.onChangeBreakEndTime}
                                            format={format}
                                            use12Hours
                                            inputReadOnly
                                            disabled={this.props.isBreakChecked === 0}
                                        />
                                    </div>
                                </div>
                            </Grid></>:""}
                        </Grid>
                        {this.props.isBreakChecked === 1 ? 
                        <Grid className={classes.newsSection}>
                            <Grid item md={2} lg={2} sm={2} xs={2}>
                                <div style={{ paddingTop: 15, textAlign: 'left' }}>
                                    Comment :
                                </div>
                            </Grid>
                            <Grid item md={10} lg={10} sm={10} xs={10}>
                                <div>
                                    <textarea 
                                    name="break_comment"
                                    value={comments.break_comment}
                                    style={{ width: '-webkit-fill-available' }} 
                                    onChange={this.props.handleChangeComment}
                                    disabled={this.props.isBreakChecked === 0} />
                                </div>
                            </Grid>
                        </Grid>:""}

                        <Grid className={classes.newsSection}>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: 8 }}>
                                        <Checkbox
                                            checked={this.props.isNoBreakChecked}
                                            onChange={this.props.handleNoBreakCheckbox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                        End Break :
                                    </div>
                                </div>
                            </Grid>
                            {this.props.isNoBreakChecked === 1 ? 
                            <>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                    Break End Time :
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.columnFlex}>
                                <div className={classes.newsTitle}>
                                    <div style={{ paddingTop: 15 }}>
                                        <TimePicker
                                            showSecond={false}
                                            value={moment(this.props.BreakTime, "hh:mm A")}
                                            className="xxx"
                                            onChange={this.props.onChangeBreakTime}
                                            format={format}
                                            use12Hours
                                            inputReadOnly
                                            disabled={this.props.isNoBreakChecked === 0}
                                        />
                                    </div>
                                </div>
                            </Grid></>: ""}
                        </Grid>

                        {this.props.isNoBreakChecked === 1 ? 
                        <Grid className={classes.newsSection}>
                            <Grid item md={2} lg={2} sm={2} xs={2}>
                                <div style={{ paddingTop: 6, textAlign: 'left' }}>
                                    Comment :
                                </div>
                            </Grid>
                            <Grid item md={10} lg={10} sm={10} xs={10}>
                                <div>
                                    <textarea 
                                    name="no_break_comment"
                                    value={comments.no_break_comment}
                                    onChange={this.props.handleChangeComment}
                                    style={{ width: '-webkit-fill-available' }} 
                                    disabled={this.props.isNoBreakChecked === 0} />
                                </div>
                            </Grid>
                        </Grid>: ""}

                        <Grid className={classes.newsSection}>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: 8 }}>
                                        <Checkbox
                                            checked={this.props.isShiftEndChecked}
                                            onChange={this.props.handleShiftEndCheckbox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                        Shift End :
                                    </div>
                                </div>
                            </Grid>
                            {this.props.isShiftEndChecked === 1 ? 
                            <>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.titleTable}>
                                <div style={{ paddingTop: 15, textAlign: 'center' }}>
                                    Actual Shirt End Time :
                                </div>
                            </Grid>
                            <Grid item md={3} lg={3} sm={3} xs={3} className={classes.columnFlex}>
                                <div className={classes.newsTitle}>
                                    <div style={{ paddingTop: 15 }}>
                                        <TimePicker
                                            showSecond={false}
                                            value={moment(this.props.shiftEndTime, "hh:mm A")}
                                            className="xxx"
                                            onChange={this.props.onChangeShiftEndTime}
                                            format={format}
                                            use12Hours
                                            inputReadOnly
                                            disabled={this.props.isShiftEndChecked === 0}
                                        />
                                    </div>
                                </div>
                            </Grid> </>:""}
                        </Grid>
                        {this.props.isShiftEndChecked === 1 ? 
                        <Grid className={classes.newsSection}>
                            <Grid item md={2} lg={2} sm={2} xs={2}>
                                <div style={{ paddingTop: 15, textAlign: 'left' }}>
                                    Comment :
                                </div>
                            </Grid>
                            <Grid item md={10} lg={10} sm={10} xs={10}>
                                <div>
                                    <textarea
                            
                                    name="shift_end_comment"
                                    value={comments.shift_end_comment}
                                    onChange={this.props.handleChangeComment}
                                     style={{ width: '-webkit-fill-available' }} disabled={this.props.isShiftEndChecked === 0} />
                                </div>
                            </Grid>
                        </Grid>:""}

                        <Grid item sm={12} xs={12} style={{ textAlign: 'center' }}>
                            <div >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    className={classes.closeButton}
                                    style={{ backgroundImage: schedulerColor.main }}
                                    onClick={this.props.onClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="outlined"
                                    size="medium"
                                    className={classes.saveButton}
                                    style={{ backgroundImage: schedulerColor.main }}
                                    onClick={this.props.handleSubmit}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.holidayData.isLoading,
});

const ConnectWith = connect(mapStateToProps, null)(addCorrectionRequest);
export default withStyles(styles)(ConnectWith);
