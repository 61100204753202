import { Typography } from "@material-ui/core";
import VideocamIcon from "@mui/icons-material/Videocam";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import "./SlideTab.scss";

export const SlideTab = ({ id, lesson_type, data }) => {
  return (
    <div>
      <div className={"slideTabContainer"}>
        {lesson_type === 1 ? (
          <>
            <Typography
              className={"slideContent"}
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            ></Typography>
            <AssignmentIcon className={"slideIcon"} />
          </>
        ) : lesson_type === 2 ? (
          <VideocamIcon className={"slideIcon"} />
        ) : (
          <FileCopyIcon className={"slideIcon"} />
        )}
        <Typography className={"slideNumber"} color={"#ffffff"}>
          {id}
        </Typography>
      </div>
    </div>
  );
};
