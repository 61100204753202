import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, SvgIcon } from "@material-ui/core";
import CircularProgress from "../../../utilities/common/ProgressBar/CircularProgress";
import { MobileLinearLoader } from "../../../utilities";
import { MdFilterList } from "react-icons/md";

import "../../../pages/style.css";

const useStyles = makeStyles((theme) => ({
  categoriesBoxRoot: {
    width: "100%",
    height: "74px",
    boxShadow: "5px 5px 21px 4px #00000014",
    background: "white",
    display: "flex",
    gap: "10px",
    padding: "0px 10px",
    position: "relative",
    alignItems: "start",
  },
  categoriesBoxIcon: {
    fontSize: "20px",
    color: "#1B3F8F",
    paddingTop: "7px",
  },
  categoriesBoxLoaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "80vw",
  },
  categoriesBoxCategoryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "23px",
    height: "74px",
    overflowX: "auto",
    margin: "auto",
  },
  categoriesBoxCategory: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  categoriesBoxCategoryName: {
    fontSize: "14px",
    lineHeight: "17.17px",
    whiteSpace: "nowrap",
    color: "#21212199",
    "&.selected": {
      color: "#135098",
    },
  },
  categoriesBoxSelectedCategory: {
    fontWeight: "700",
    color: "#1B3F8F",
  },
  categoriesBoxUnselectedCategory: {
    fontWeight: "400",
    color: "rgba(33, 33, 33, 0.6)",
  },
}));

const CategoriesBox = ({
  selectedCategory,
  categoryLoading,
  handleCategoryChange,
  totalLessonsProgress,
  lessonCategories,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.categoriesBoxRoot}>
      <Box className={classes.categoriesBoxIcon}>
        <MdFilterList />
      </Box>
      {categoryLoading ? (
        <Box className={classes.categoriesBoxLoaderContainer}>
          <MobileLinearLoader />
        </Box>
      ) : (
        <Box className={`${classes.categoriesBoxCategoryContainer} scroll`}>
          <Box
            className={`${classes.categoriesBoxCategory} ${
              selectedCategory === "all"
                ? classes.categoriesBoxSelectedCategory
                : classes.categoriesBoxUnselectedCategory
            }`}
            onClick={() => handleCategoryChange("all")}
          >
            <Typography
              className={`${classes.categoriesBoxCategoryName} ${
                selectedCategory === "all"
                  ? classes.categoriesBoxSelectedCategory
                  : classes.categoriesBoxUnselectedCategory
              }`}
            >
              All
            </Typography>
            <Box className={classes.categoriesBoxCategory}>
              <CircularProgress
                value={totalLessonsProgress}
                size={22}
                progressColor={
                  selectedCategory === "all" ? "#1B3F8F" : "#5A5A5AC2"
                }
              />
            </Box>
          </Box>
          {lessonCategories &&
            lessonCategories.map((tab, index) => (
              <Box
                key={index}
                className={`${classes.categoriesBoxCategory} ${
                  selectedCategory === tab.ecid?._id
                    ? classes.categoriesBoxSelectedCategory
                    : classes.categoriesBoxUnselectedCategory
                }`}
                onClick={() => handleCategoryChange(tab.ecid?._id)}
              >
                <Typography
                  className={`${classes.categoriesBoxCategoryName} ${
                    selectedCategory === tab.ecid?._id
                      ? classes.categoriesBoxSelectedCategory
                      : classes.categoriesBoxUnselectedCategory
                  }`}
                >
                  {tab.ecid?.category_name}
                </Typography>
                <Box className={classes.categoriesBoxCategory}>
                  <CircularProgress
                    value={tab?.cat_per}
                    size={22}
                    progressColor={
                      selectedCategory === tab.ecid?._id
                        ? "#1B3F8F"
                        : "#5A5A5AC2"
                    }
                  />
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default CategoriesBox;
