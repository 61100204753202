import {
  GET_ELEARNING_SETTINGS_LOADING,
  GET_ELEARNING_SETTINGS_DATA,
  UPDATE_ELEARNING_SETTINGS_LOADING,
  UPDATE_ELEARNING_SETTINGS_DATA,
} from "./constants";

const initialState = {
  elearningSettingsLoading: false,
  elearningSettingsData: {},
  updatedElearningSettingsLoading: false,
  updatedElearningSettingsData: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ELEARNING_SETTINGS_LOADING:
      return {
        ...state,
        elearningSettingsLoading: payload,
      };
    case GET_ELEARNING_SETTINGS_DATA:
      return {
        ...state,
        elearningSettingsLoading: false,
        elearningSettingsData: payload,
      };
    case UPDATE_ELEARNING_SETTINGS_LOADING:
      return {
        ...state,
        updatedElearningSettingsLoading: payload,
      };
    case UPDATE_ELEARNING_SETTINGS_DATA:
      return {
        ...state,
        updatedElearningSettingsLoading: false,
        updatedElearningSettingsData: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
