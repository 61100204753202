import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const GradingLoading = () => {
  //   render() {
  return (
    // <div className="LoadingData"></div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        // zIndex: '-1',
        width: "100%",
        backgroundColor: "transparent",
      }}
    >
      <CircularProgress />
    </div>
  );
  //   }
};
