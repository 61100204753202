import {
  SET_COUPONS_LOADING,
  SET_COUPONS,
  ADD_COUPONS,
  EDIT_COUPONS,
  DELETE_COUPONS
} from "./constants";

const initialState = {
  isLoading: true,
  couponsData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COUPONS_LOADING:
      return {
        ...state,
        isLoading: payload,
        couponsData: []        
      };
    case SET_COUPONS:
      return {
        ...state,
        isLoading: false,
        couponsData: payload.couponsData
      };
    case ADD_COUPONS:
      return {
        ...state,
        isLoading: false,
        couponsData: [...state.couponsData, payload]
      }
    case EDIT_COUPONS:
      return {
        ...state,
        isLoading: false,
        couponsData: state.couponsData.map((item) => item.id === payload.id ? { ...payload } : item)
      }
    case DELETE_COUPONS:
      return {
        ...state,
        isLoading: false,
        couponsData: state.couponsData.filter((item) => item.id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;