import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  MySelectField,
  MoreMenu,
  TableMyPagination,
  TableRecordNotFound,
  LoadingData,
  DeleteModal,
  MyLeftModal,
  MyTextfield,
  MyTimePicker,
} from "../../../components";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close,
} from "@material-ui/icons";

import moment from "moment";
import { utcToLocal, validationIsEmpty } from "../../../utilities";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Tooltip,
  Modal,
  Box,
} from "@material-ui/core";
import { eLearningColor } from "../../../config";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    marginTop: "2%",
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#ffffff",
    fontWeight: "700",
    fontSize: "16px",
    textTransform: "capitalize",
    "&:hover": {
      padding: "0.5rem 0rem",
      color: "#ffffff",
      fontWeight: "700",
      fontSize: "16px",
      textTransform: "capitalize",
    },
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
  },
  // title: {
  //     flex: '1 1 100%',
  // },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  addQuizBtn: {
    borderRadius: "4px",
    height: "40px",
    width: "137px",
    backgroundColor: "#135098",
    border: "1px solid #135098",
    color: "white",
    fontSize: "14px",
    fontWeight: "700",
  },
  addQuizModal: {
    width: "600px",
    background: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    overflow: "hidden",
  },
  addQuizModalHeader: {
    background: "#135098",
    color: "white",
    display: "flex",
    alignItems: "center",
    padding: "15px 20px",
  },
  addQuizModalHeaderTitle: {
    fontSize: "16px",
    fontWeight: "700",
  },
  addQuizModalContent: {
    padding: "20px",
  },
  optionsTitle: {
    fontSize: "16px",
    fontWeight: "700",
    padding: "7px 4px",
    margin: "0px",
  },
  answersTitle: {
    fontSize: "14px",
    fontWeight: "700",
    padding: "7px 4px",
    margin: "0px",
  },
  quizLabel: {
    fontSize: "#646464",
    fontSize: "14px",
  },
  cancelQuizBtn: {
    background: "#858585",
    border: "1px solid #858585",
    borderRadius: "4px",
    width: "110px",
    height: "48px",
    color: "white",
    fontSize: "16px",
    fontWeight: "400",
    marginRight: "5px",
  },
  saveQuizBtn: {
    background: "#135098",
    border: "1px solid #135098",
    borderRadius: "4px",
    width: "110px",
    height: "48px",
    color: "white",
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: "5px",
  },
});

class BookMark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myItem: {},
      order: "asc",
      orderBy: "seconds",
      headCells: [
        { id: "no", label: "No", numeric: true },
        { id: "title", label: "Title", numeric: true },
        { id: "created_at", label: "Created at", numeric: true },
        { id: "seconds", label: "Time", numeric: true },
        // { id: 'updated_at', label: "Updated at", numeric: false }
      ],
      moreData: [
        {
          icon: EditIcon,
          title: "Edit Bookmark",
          sortName: "edit",
          onClick: this.modifyData,
        },
        {
          icon: DeleteIcon,
          title: "Delete Bookmark",
          sortName: "delete",
          onClick: this.modifyData,
        },
      ],
      errors: {},
      videoDurations: {
        second: [],
        minutes: [],
        hours: [],
      },
      errorsHelper: {},
    };
  }

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  componentDidMount = () => {
    if (this.props.videoUrl) {
      var url = this.props.videoUrl;
      // var videoDurations = [];
      var media = new Audio(url);
      media.onloadedmetadata = () => {
        var duration = parseInt(media.duration);
        const sec = parseInt(media.duration);
        const secArray = [];
        const minArray = [];
        const hoursArray = [];
        var myTime = 0;
        if (61 > sec) {
          let hours = Math.floor(sec / 3600);
          let minutes = Math.floor((sec - hours * 3600) / 60);
          let seconds = sec - hours * 3600 - minutes * 60;
          for (var i = 0; i <= duration; i++) {
            secArray.push(i);
          }
          myTime = hours + minutes + seconds;
        } else if (3600 > sec) {
          let hours = Math.floor(sec / 3600);
          let minutes = Math.floor((sec - hours * 3600) / 60);
          let seconds = sec - hours * 3600 - minutes * 60;
          for (var i = 0; i <= 60; i++) {
            secArray.push(i);
          }
          for (var i = 0; i <= minutes; i++) {
            minArray.push(i);
          }
          myTime = hours + minutes + seconds;
        } else {
          let hours = Math.floor(sec / 3600);
          let minutes = Math.floor((sec - hours * 3600) / 60);
          let seconds = sec - hours * 3600 - minutes * 60;
          for (var i = 0; i <= 60; i++) {
            secArray.push(i);
          }
          for (var i = 0; i <= minutes; i++) {
            minArray.push(i);
          }
          for (var i = 0; i <= hours; i++) {
            hoursArray.push(i);
          }
          myTime = hours + minutes + seconds;
        }
        var object = {
          // id: i,
          second: secArray,
          minutes: minArray,
          hours: hoursArray,
        };
        this.setState({ videoDurations: object });
      };
    }
  };

  handleSubmit = async () => {
    var userItem = this.state.myItem;
    var valid_title = await validationIsEmpty(userItem.bookmark_name);

    if (valid_title) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bookmark_name: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          bookmark_name: "Please enter bookmark name",
        },
      }));
    }

    // if (this.state.myItem.seconds < 1) {
    //     this.setState(prevState => ({
    //         errors: {
    //             ...prevState.errors,
    //             seconds: true
    //         },
    //         errorsHelper: {
    //             ...prevState.errorsHelper,
    //             seconds: "Please enter seconds"
    //         }
    //     }))
    // }

    if (valid_title) {
      return;
    }

    var data = {
      bookmark_name: userItem.bookmark_name,
      time:
        (userItem.hours ? userItem.hours : "00") +
        " : " +
        (userItem.min ? userItem.min : "00") +
        " : " +
        userItem.sec,
      slide_id: this.props.slideid,
    };

    this.setState({ addEditModal: false });
    if (this.state.isEdit) {
      this.props.editBookmark(data);
    } else {
      this.props.addBookmark(data);
    }
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    this.props.deleteBookmark(this.state.myItem._id);
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  addEditModalClose = () => {
    this.setState({
      addEditModal: false,
    });
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  handleChangeDateTime = (date, state) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: date,
      },
    }));
  };

  moreMenuClick = (item, value) => {
    var myItem = {
      bookmark_name: "",
      time: "",
      slide_id: this.props.slideid,
      hours: "",
      min: "",
      sec: "",
    };
    if (item) {
      myItem = item;
    }
    this.setState({
      myItem,
      errors: {},
      errorsHelper: {},
      isEdit: 0,
      addEditModal: value === "add" ? true : false,
    });
  };

  modifyData = (value) => {
    this.setState({
      isEdit: value === "edit" ? 1 : 0,
      addEditModal: value === "add" || value === "edit" ? true : false,
      deleteModal: value === "delete" ? true : false,
    });
  };

  render() {
    const { bookmarksData, isLoading, classes, pageNo, rowsPerPage } =
      this.props;
    if (isLoading) return <LoadingData />;

    return (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>
          <Modal
            open={this.state.addEditModal}
            onClose={this.addEditModalClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              className={classes.addQuizModal}
              style={{
                width: window.innerWidth >= 610 ? "600px" : "90%",
              }}
            >
              <div className={classes.addQuizModalHeader}>
                <p className={"mb-0 " + classes.addQuizModalHeaderTitle}>
                  {this.state.isEdit ? "Edit Bookmark" : "Add Bookmark"}
                </p>
                <Close
                  className="ml-auto"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={this.addEditModalClose}
                />
              </div>
              <Grid
                container
                spacing={1}
                className={classes.addQuizModalContent}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MyTextfield
                      name="bookmark_name"
                      label="Bookmark name"
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                      value={this.state.myItem.bookmark_name}
                      type="text"
                      error={this.state.errors.bookmark_name}
                      helperText={
                        this.state.errors.bookmark_name
                          ? this.state.errorsHelper.bookmark_name
                          : ""
                      }
                      placeholder="Enter bookmark_name"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Grid item xs={4}>
                      <MySelectField
                        name="hours"
                        label="Hours"
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        value={this.state.myItem.hours}
                        error={false}
                        helperText=""
                        placeholder="Please Select Time"
                      >
                        {this.state.videoDurations.hours.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </MySelectField>
                    </Grid>

                    <Grid item xs={4}>
                      <MySelectField
                        name="min"
                        label="Min"
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        value={this.state.myItem.min}
                        error={false}
                        helperText=""
                        placeholder="Please Select Time"
                      >
                        {this.state.videoDurations.minutes.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </MySelectField>
                    </Grid>
                    <Grid item xs={4}>
                      <MySelectField
                        name="sec"
                        label="Sec"
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        value={this.state.myItem.sec}
                        error={false}
                        helperText=""
                        placeholder="Please Select Time"
                      >
                        {/* {videoDurations.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id} >
                                                {item.time}
                                            </option>
                                        )
                                    })} */}
                        {this.state.videoDurations.second.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </MySelectField>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="mt-4 mb-2 w-100 d-flex justify-content-end">
                  <input
                    type="button"
                    value="Cancel"
                    className={classes.cancelQuizBtn}
                    onClick={this.addEditModalClose}
                  />
                  <input
                    type="button"
                    value="Save"
                    className={classes.saveQuizBtn}
                    onClick={this.handleSubmit}
                  />
                </div>
              </Grid>
            </Box>
          </Modal>
          <MyLeftModal
            bgColor={eLearningColor.main}
            title={this.state.isEdit ? "Edit Bookmark" : "Add Bookmark"}
            // open={this.state.addEditModal}
            onClose={this.addEditModalClose}
            handleSubmit={this.handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MyTextfield
                  name="bookmark_name"
                  label="Bookmark name"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.bookmark_name}
                  type="text"
                  error={this.state.errors.bookmark_name}
                  helperText={
                    this.state.errors.bookmark_name
                      ? this.state.errorsHelper.bookmark_name
                      : ""
                  }
                  placeholder="Enter bookmark_name"
                />
              </Grid>

              <Grid item xs={12} style={{ display: "flex" }}>
                {/* <MyTimePicker
                                label="Time"
                                name="time"
                                onChange={(date) => {
                                this.handleChangeDateTime(date, "time");
                                }}
                                onFocus={this.handleFocus}
                                error={this.state.errors.time}
                                helperText={this.state.errors.time ? this.state.errorsHelper.time : ""}
                                value={this.state.myItem.time}
                                placeholder="Please select bookmark Time"
                            /> */}
                <Grid item xs={4}>
                  <MySelectField
                    name="hours"
                    label="Hours"
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    value={this.state.myItem.hours}
                    error={false}
                    helperText=""
                    placeholder="Please Select Time"
                  >
                    {this.state.videoDurations.hours.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </MySelectField>
                </Grid>

                <Grid item xs={4}>
                  <MySelectField
                    name="min"
                    label="Min"
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    value={this.state.myItem.min}
                    error={false}
                    helperText=""
                    placeholder="Please Select Time"
                  >
                    {this.state.videoDurations.minutes.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </MySelectField>
                </Grid>
                <Grid item xs={4}>
                  <MySelectField
                    name="sec"
                    label="Sec"
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    value={this.state.myItem.sec}
                    error={false}
                    helperText=""
                    placeholder="Please Select Time"
                  >
                    {/* {videoDurations.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id} >
                                                {item.time}
                                            </option>
                                        )
                                    })} */}
                    {this.state.videoDurations.second.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </MySelectField>
                </Grid>
              </Grid>
            </Grid>
          </MyLeftModal>

          <DeleteModal
            bgColor={eLearningColor.main}
            desc="Are you sure you want to delete?"
            open={this.state.deleteModal}
            onClose={this.deleteModalClose}
            handleDelete={this.deleteData}
          />

          <div className="mb-3 text-right">
            <input
              type="button"
              value="Add Bookmarks"
              className={classes.addQuizBtn}
              onClick={() => {
                this.moreMenuClick(null, "add");
              }}
            />
          </div>

          <Paper
            style={{
              overflow: "hidden",
            }}
          >
            {/* <Toolbar variant="regular" className={classes.rootToolbar}>
                            <Typography
                                display="inline"
                                className={classes.title}
                                variant="h6" >
                                Bookmarks
                            </Typography>
                            <Tooltip arrow title="Add">
                                <IconButton style={{ float: 'right', marginLeft: "90%" }} size="small"
                                    onClick={() => { this.moreMenuClick(null, "add") }}
                                >
                                    <AddIcon
                                    />
                                </IconButton>
                            </Tooltip>
                        </Toolbar> */}

            <TableContainer>
              <Table className={classes.table} size="medium">
                <TableHead style={{ backgroundColor: eLearningColor.main }}>
                  <TableRow>
                    {this.state.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        style={{ background: "#135098", color: "white" }}
                        sortDirection={
                          this.state.orderBy === headCell.id
                            ? this.state.order
                            : false
                        }
                      >
                        <TableSortLabel
                          className={classes.headCell}
                          // active={this.state.orderBy === headCell.id}
                          direction={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : "asc"
                          }
                          onClick={this.createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {this.state.orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {this.state.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell
                      style={{
                        paddingRight: "20px",
                        background: "#135098",
                        color: "white",
                      }}
                      className={classes.headCell}
                      align="right"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {bookmarksData?.map((row, index) => {
                    const sec = parseInt(row.seconds, 10);
                    let hours = Math.floor(sec / 3600);
                    let minutes = Math.floor((sec - hours * 3600) / 60);
                    let seconds = sec - hours * 3600 - minutes * 60;
                    hours = hours > 0 ? hours + " hrs " : "";
                    minutes = minutes > 0 ? minutes + " min " : "";
                    seconds = seconds > 0 ? seconds + " sec" : "";
                    var myTime = hours + minutes + seconds;
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell className={classes.TableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {row.bookmark_name}
                        </TableCell>
                        <TableCell align="left" className={classes.TableCell}>
                          {moment(utcToLocal(row.createdAt))
                            .locale("en-US")
                            .format("DD MMM YYYY,hh:mm A")}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {row.time}
                        </TableCell>
                        {/* <TableCell align="left" className={classes.TableCell}>
                                                    {row.updated_at ?
                                                        moment(utcToLocal(row.updatedAt)).locale('en-US').format("DD MMM YYYY,hh:mm A")
                                                        : ""}
                                                </TableCell> */}
                        <TableCell align="right" className={classes.TableCell}>
                          <MoreMenu
                            moreMenuClick={() => {
                              this.moreMenuClick(row);
                            }}
                            moreData={this.state.moreData}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {bookmarksData && bookmarksData.length < 1 ? (
                    <TableRecordNotFound colSpan={8} label="No Data Found.." />
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TableMyPagination
              count={bookmarksData ? bookmarksData.length : 0}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BookMark);
