import React from "react";
import { makeStyles } from '@material-ui/styles';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Grid } from "@material-ui/core";
import { utcToLocal } from '../utilities';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  Card: {
    borderRadius: "16px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  CardMedia: {
    height: 180
  },
  CardContent: {
    padding: "10px 16px 0px 16px",
    "&:last-child": {
      paddingBottom: '5px'
    },
  },
  lessonTitle: {
    minHeight: "50px",
    lineHeight: '20px',
    fontSize: '18px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  chip: {
    borderRadius: '10px',
    backgroundColor: "#E5E5E5",
    textTransform: 'capitalize',
    marginRight: '3px',
    marginBottom: '3px',
    color: '#000000',
    fontSize: '11px',
    fontWeight: '400'
  }
}));

function MyCourseCard(props) {
  const classes = useStyles();
  const { data } = props;
  var newTags = data.lesson_tags ? data.lesson_tags.split(',') : []

  return (
    <div className="App">
      <Card className={classes.Card}>
        <CardMedia
          className={classes.CardMedia}
          image={data.lesson_image}
        />
        <CardContent className={classes.CardContent}>
          <Typography
            className={classes.lessonTitle}
            variant="h6"
          >
            {data.lesson_name}
          </Typography>

          <Grid style={{
            minHeight: "55px",
            maxHeight: "55px",
            overflow: "hidden"
          }}>
            {newTags.map((itemName) => {
              return (
                <Chip
                  size="small"
                  // onClick={() => { this.props.chipSearch(value) }}
                  key={itemName}
                  label={itemName}
                  className={classes.chip}
                />
              )
            })}
          </Grid>

          <Typography
            variant="body2"
            color="textSecondary"
          >
            By&nbsp;{data.author}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
          >
            updated at : {data.updated_at ? moment(utcToLocal(data.updated_at)).format("DD MMM YYYY,hh:mm A") : ""}
          </Typography>

        </CardContent>
      </Card>
    </div>
  );
}

export default MyCourseCard;