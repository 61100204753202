import {
  SET_COURSE_LOADING,
  SET_COURSE,
  ADD_COURSE,
  EDIT_COURSE,
  DELETE_COURSE,
  TOGGLE_ASSIGN_USERS
} from "./constants";

const initialState = {
  isLoading: true,
  courseData: [],
  allLessonData : []
}


const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COURSE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case TOGGLE_ASSIGN_USERS:
      let existCourse = state.courseData.find(item => payload.course_id === item._id)
      existCourse.course_user_count = payload.isSelected ? existCourse.course_user_count + 1 : existCourse.course_user_count - 1
      var myUsers = existCourse.course_user_list ? existCourse.course_user_list.split(',').map(Number) : []
      if (payload.isSelected) {
        myUsers.push(payload.uid)
      } else {
        myUsers.splice(myUsers.indexOf(payload.uid), 1);
      }
      existCourse.course_user_list = myUsers.join()
      return {
        ...state,
        courseData: state.courseData.map((item) => item._id === existCourse._id ? { ...existCourse } : item)
      }
    case SET_COURSE:
      return {
        ...state,
        isLoading: false,
        courseData: payload.courseData,
        allLessonData : payload.allLessonData
      };
    case ADD_COURSE:
      return {
        ...state,
        isLoading: false,
        courseData: [...state.courseData, payload]
      }
    case EDIT_COURSE:
      return {
        ...state,
        isLoading: false,
        courseData: state.courseData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_COURSE:
      return {
        ...state,
        isLoading: false,
        courseData: state.courseData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;