import {
  products,
  profile,
  profile2,
  company,
  locations,
  locations2,
  users,
  roles,
  payrollImage,
  tickets,
  ideas,
  ideas2,
  billings,
  billings2,
  coupons,
  device_management,
  device_management2,
  Coloricon,
  Wish
} from '../icons';

export default [
  // {
  //   title: 'Products',
  //   href: '/settings/products',
  //   imageUrl: products,
  //   permission: "common_products"
  // },
  {
    title: 'Profile',
    href: '/settings/profile',
    imageUrl: profile2,
    permission : 1
  },
  {
    title: 'Company',
    href: '/settings/company',
    imageUrl: company,
    permission: 1
  },
  {
    title: 'Locations',
    href: '/settings/locations',
    imageUrl: locations2,
    permission: "common_location"
  },
  {
    title: 'Device Management',
    href: '/settings/device',
    imageUrl: device_management2,
    permission: "common_tickets"
  },
  // {
  //   title: 'Tickets',
  //   href: '/settings/tickets',
  //   imageUrl: tickets,
  //   permission: "common_tickets"
  // },
  {
    title: 'Ideas',
    href: '/settings/ideas',
    imageUrl: ideas2,
    permission: "common_ideas"
  },
  {
    title: 'Billing',
    href: '/settings/billings',
    imageUrl: billings2,
    permission: "common_billings"
  },
  // {
  //   title: 'Coupons',
  //   href: '/settings/coupons',
  //   imageUrl: coupons,
  //   permission: "common_coupons"
  // },
  // {
  //   title: 'Product Color',
  //   href: '/settings/color',
  //   imageUrl: Coloricon,
  //   permission: "common_color"
  // },
  // {
  //   title: 'Product List',
  //   href: '/settings/productList',
  //   imageUrl: Wish,
  //   permission: "common_color"
  // },
];
