import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import HomeIcon from "@mui/icons-material/Home";
// import FeedbackIcon from "@mui/icons-material/Feedback";
import "./styles.scss";

import { history } from "../../../../history";
// import { usePreviewNewsDetails } from "../../context";

import {
  MyNewsIcon,
  NewsBuilderIcon,
  ReportsIcon,
} from "../../../../assets/images/mobileView/icons/svgs";
import { useSearch } from "../../context/MobileNewsSearchContext";

const tabs = [
  { label: "My News", icon: <MyNewsIcon />, path: "/newsandupdate/mynews" },
  {
    label: "News Builder",
    icon: <NewsBuilderIcon />,
    path: "/newsandupdate/news",
  },
  { label: "Reports", icon: <ReportsIcon />, path: "/newsandupdate/reports" },
];

export function NewsBottomNav() {
  const [value, setValue] = useState(0);
  const location = useLocation();
  console.log("location", location);

  const { resetSearchQuery } = useSearch();

  useEffect(() => {
    // Compare the current pathname with the paths of the tabs and set the selected tab
    const selectedIndex = tabs.findIndex((tab) =>
      location.pathname.startsWith(tab.path)
    );
    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }

    resetSearchQuery();
  }, [location.pathname]);

  return /^\/newsandupdate\/mynews\/preview\/\w+$/.test(location.pathname) ||
    /^\/newsandupdate\/news\/preview\/\w+$/.test(location.pathname) ||
    location.pathname === "/newsandupdate/news/add" ? (
    ""
  ) : (
    <div className="news-bottom-appbar">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            className={`tab ${
              value === index
                ? "tab--fab"
                : value === index - 1
                ? "tab--right"
                : value === index + 1
                ? "tab--left"
                : ""
            }`}
            onClick={() => history.push(tab.path)}
          >
            {value === index ? (
              <div className="top">
                <div className="fab">
                  {React.cloneElement(tab.icon, {
                    fill: "#b80000",
                    width: "22px",
                    height: "22px",
                    color: "#ffffff",
                  })}
                </div>
              </div>
            ) : (
              <>
                <div>
                  {React.cloneElement(tab.icon, {
                    fill: "#ffffff",
                    width: "22px",
                    height: "22px",
                    color: "#ffffff",
                  })}
                </div>
                <span style={{ color: "#ffffff" }}>{tab.label}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
