import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { schedulerColor } from '../../config'
import {
  Divider
} from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const useStyles = makeStyles(theme => ({
  // asad sidebar link padding
  link: {
    padding: '6px 8px',
    borderRadius: '.375rem',
    textDecoration: "none",
    marginBottom: '1rem',
    boxShadow: "0.5px 0.5ppx 0.5ppx rgba(0, 0, 0, 0.25)",
  },
  linkActive: {
    backgroundColor: schedulerColor.main,
    boxShadow: "4px 2px 4px rgba(0, 0, 0, 0.25)",
    textDecoration: 'none',
  },
  schedulerActive: {
    backgroundImage: 'linear-gradient(45deg, #AA076B, #9F0D9C)',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: schedulerColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff"
      },
      "& $linkIcon": {
        color: "#ffffff"
      },
      "& $linkTextCount": {
        color: "#ffffff"
      }
    }
  },
  avatar: {
    height: 25,
    width: 25,
    marginRight: '10px',
   
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#0000008a',
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    textDecoration: 'none',
  },
  linkTextActive: {
    // fontWeight: 600,
    color: "#ffffff",
  },
  linkTextHidden: {
    opacity: 0,
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color")
  },
}));


export default function SidebarLink({countData,handleToggleRoutes, type, isOpen, childData, clickLink, path, icon, image, active_image, label, count, location, isSidebarOpen, activeProduct, todoId, tooltip }) {
  var classes = useStyles();
  var isLinkActive = path && (location.pathname === path);
  const query = new URLSearchParams(location.search);
  var newId = query.get('id')
  var [toolTip, setToolTip] = useState(false);
  const openTooltip = () => {
    if (!isSidebarOpen) {
      setToolTip(true)
    }
  }

  const closeTooltip = () => {
    setToolTip(false)
  }

  return (
    <>
      {type === "divider" ?
       ""
        :
        <>
          <Tooltip
            arrow
            placement="right"
            open={toolTip}
            onClose={closeTooltip}
            onOpen={openTooltip}
            title={<p className={classes.popoverTitle}>{label}</p>}
          >
            {childData && childData.length > 0 ?
              <ListItem
                onClick={handleToggleRoutes}
                button
                classes={{
                  root: classnames(classes.link),
                }}
                disableRipple
              >
                <ListItemText
                  classes={{
                    primary: classnames(classes.linkText, {
                      [classes.linkTextActive]: isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  primary={label}
                />
                <ListItemText
                  classes={{
                    primary: classnames(classes.linkText, {
                      [classes.linkTextActive]: isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  style={{fontWeight: 900, display: 'contents'}}
                  // primary={label}
                >{label === "Availability" ? countData.total_avaliblity : 
                  label === "Request" ? countData.total_request_count : ""
                }</ListItemText>
                <ListItemIcon style={{
                  minWidth: "20px",
                }}>
                  {isOpen ?
                    <ExpandLessIcon sx={{ fontSize: "30px", minWidth: "30px", disArrowDropDown: "flex !important", justifyContent: "end !important" }} />
                    :
                    <ExpandMoreIcon sx={{ fontSize: "30px", minWidth: "30px", disArrowDropDown: "flex !important", justifyContent: "end !important" }} />
                  }
                </ListItemIcon>
              </ListItem>
              :
              <ListItem
                onClick={clickLink}
                button
                component={path && Link}
                to={path}
                classes={{
                  root: classnames(classes.link, {
                    [classes.schedulerActive]: isLinkActive ? true : false,
                  }),
                }}
                disableRipple
              >
                <ListItemIcon
                  className={classnames(classes.linkIcon, {
                    [classes.linkIconActive]: isLinkActive
                  })}
                >
                  {image ?
                    <Avatar
                      variant="square"
                      className={classes.avatar}
                      src={isLinkActive ? active_image : image} />
                    : icon}
                </ListItemIcon>

                <ListItemText
                  classes={{
                    primary: classnames(classes.linkText, {
                      [classes.linkTextActive]: isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  primary={label}
                />
              </ListItem>
            }
          </Tooltip>
          {childData && isOpen ?
            childData.map((item) => (
              <Tooltip
                arrow
                placement="right"
                open={toolTip}
                onClose={closeTooltip}
                onOpen={openTooltip}
                title={<p className={classes.popoverTitle}>{item.label}</p>}
              >
                <ListItem
                  onClick={clickLink}
                  button
                  component={item.path && Link}
                  to={item.path}
                  classes={{
                    root: classnames(classes.link, {
                      [classes.schedulerActive]: location.pathname === item.path ? true : false,
                    }),
                  }}
                  disableRipple
                >
                  <ListItemIcon
                    className={classnames(classes.linkIcon, {
                      [classes.linkIconActive]: location.pathname === item.path
                    })}
                  >
                    {item.image ?
                      <Avatar
                        variant="square"
                        className={classes.avatar}
                        style={{
                          height: 20,
                          width: 20,
                          marginLeft: '20px',
                          marginRight: '5px'
                        }}
                        src={location.pathname === item.path ? item.active_image : item.image} />
                      : icon}
                  </ListItemIcon>

                  <ListItemText
                    classes={{
                      primary: classnames(classes.linkText, {
                        [classes.linkTextActive]: location.pathname === item.path,
                        [classes.linkTextHidden]: !isSidebarOpen,
                      }),
                    }}
                    primary={item.label}
                  ></ListItemText>

                  <ListItemText
                    classes={{
                      primary: classnames(classes.linkText, {
                        [classes.linkTextActive]: location.pathname === item.path,
                        [classes.linkTextHidden]: !isSidebarOpen,
                      }),
                    }}
                    style={{fontWeight: 900, display: 'contents'}}
                    // primary={item.label}
                  > {item.label === "My Availability" ? countData.scheduler_availability 
                    : item.label === "Team Availability" ? countData.scheduler_team_availability 
                    : item.label === "Trade shift" ? countData.scheduler_trade_shifts 
                    : item.label === "Cover shift" ? countData.scheduler_cover_shifts 
                    : item.label === "Time off" ? countData.scheduler_timeoff_requests 
                    : item.label === "Correction request" ? countData.schedular_request : ""
                    }</ListItemText>
                </ListItem>

                
                {/* {count !== undefined ?
                <ListItemText
                  classes={{
                    primary: classnames(classes.linkTextCount, {
                      // [classes.linkTextActive]: todoId ? todoIdLinkActive : isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  primary={countData && countData[count]}
                />
                : ""
              } */}
              </Tooltip>
            ))
            : null}
        </>
      }
    </>
  );
}
