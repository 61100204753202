import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const styles = (theme) => ({
  menu: {
    minWidth: "250px",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
  },
  MenuItem: {
    border: "1px solid #f2f2f2",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#FFF0F0",
      "& $mYicon": {
        color: "#D72C0D",
      },
      "& $Typography": {
        fontWeight: "500",
        color: "#D72C0D",
      },
    },
  },
  mYicon: {
    color: "#7C7C7C",
    height: 24,
    width: 24,
  },
  ListItemIcon: {
    minWidth: "45px",
  },
  Typography: {
    color: "#202223",
    fontSize: "15px",
    lineHeight: "2rem",
  },
});

class MoreMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortAnchorEl: null,
    };
  }

  handleOpen = (event) => {
    // this.props.moreMenuClick();
    this.setState({ sortAnchorEl: event.currentTarget });
  };

  handleClose = (value) => {
    // if (value) {
    //   value.onClick(value.sortName);
    // }
    this.setState({ sortAnchorEl: null });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Tooltip arrow title="More">
          <IconButton
            aria-label="More"
            aria-haspopup="true"
            onClick={this.handleOpen}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="customized-menu"
          MenuListProps={{ disablePadding: true }}
          anchorEl={this.state.sortAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={Boolean(this.state.sortAnchorEl)}
          onClose={(e) => {
            this.handleClose("");
          }}
          PopoverClasses={{
            paper: classes.menu,
          }}
        >
          {this.props.moreData.map((value, index) => {
            return (
              <MenuItem
                key={index}
                disabled={true}
                onClick={(e) => {
                  this.handleClose(value);
                }}
                className={classes.MenuItem}
              >
                <ListItemIcon className={classes.ListItemIcon}>
                  <value.icon className={classes.mYicon} />
                </ListItemIcon>

                <Typography
                  className={classes.Typography}
                  color="textPrimary"
                  variant="inherit"
                >
                  {value.title}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(MoreMenu);
