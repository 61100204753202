import { useWindowSize } from "../hooks/useWindowSize";

import React from "react";

const withWindowSize = (WrappedComponent) => {
  return (props) => {
    const windowSize = useWindowSize();
    return <WrappedComponent windowSize={windowSize} {...props} />;
  };
};

export { withWindowSize };
