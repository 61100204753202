import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },
  input: {
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: 5,
    marginBottom: 0,
    color: "#000000B2",
  },
  labelColor: {
    color: "#000000B2",
  },
});

class MyNewDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, // Initially closed
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ open: false }); // Close the date picker dropdown on scroll
  };

  handleDatePickerOpen = () => {
    this.setState({ open: true }); // Open the date picker dropdown when it's clicked
  };

  handleDatePickerClose = () => {
    this.setState({ open: false }); // Close the date picker dropdown when it's closed
  };

  handleDateChange = (date) => {
    // Call the onChange prop if it's provided
    if (this.props.onChange) {
      this.props.onChange(date);
    }
    // Close the date picker
    this.handleDatePickerClose();
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            keyboardIcon={
              <CalendarMonthIcon style={{ color: "red !important" }} />
            }
            fullWidth
            disablePast={
              this.props.disablePast === undefined
                ? false
                : this.props.disablePast
            }
            disableFuture={
              this.props.disableFuture === undefined
                ? false
                : this.props.disableFuture
            }
            label={this.props.label}
            name={this.props.name}
            open={open}
            onOpen={this.handleDatePickerOpen}
            onClose={this.handleDatePickerClose}
            placeholder={this.props.placeholder}
            onChange={this.handleDateChange}
            value={this.props.value}
            variant="inline"
            size="small"
            inputVariant="outlined"
            format="dd MMM yyyy"
            InputLabelProps={{ classes: { root: classes.labelColor } }}
            inputProps={{ className: classes.input }}
            PopoverProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withStyles(styles)(MyNewDatePicker);
