import { HEADER_TOKEN, MY_TOKEN } from "../../../../config";
import axios from "axios";

export const earningServices = {
  fetchMyEarningData,
  fetchTeamEarningData,
  fetchUserEarningData,
  fetchUserPaymentLogsData,
  fetchEarningCommentsData,
  fetchAllEarningLessonsData,
  payUserEarning,
  addEarningComment,
  fetchEarningEmployeesData,
};

function fetchMyEarningData(data, page, limit) {
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/my`
  );
  url.searchParams.append("page", page);
  url.searchParams.append("limit", limit);

  return axios
    .post(url.toString(), { data }, HEADER_TOKEN)
    .then((response) => response);
}

function fetchTeamEarningData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/team`,
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchEarningEmployeesData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/employees`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchUserEarningData(data, page, limit) {
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/by/user`
  );
  url.searchParams.append("page", page);
  url.searchParams.append("limit", limit);

  return axios
    .post(url.toString(), { data }, HEADER_TOKEN)
    .then((response) => response);
}

function fetchUserPaymentLogsData(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/payment/logs/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchEarningCommentsData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/get/comments`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchAllEarningLessonsData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/lesson/names`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function payUserEarning(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/pay`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addEarningComment(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/earnings/add/comments`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}
