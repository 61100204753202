import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  formControl: {
    width: "100%",
  },
  input: {
    //padding: '5px 14px !important',
    fontSize: "14px",
    fontWeight: 500,
    // backgroundColor: '#f4f2f26b !important',
    borderRadius: 5,
    marginBottom: 0,
  },
  menuIte: {
    height: 32,
    marginLeft: -12,
  },
  labelColor: {
    // color: '#b6b5b5'
  },
});

const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5,
      width: 200,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

class MyMultiSelectNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const shouldHide = window.scrollY > 100;
    this.setState({ hidden: shouldHide });
  }
  render() {
    const {
      classes,
      mapId,
      mapName,
      options,
      selectedIds,
      label,
      applyCategory,
      onClose,
    } = this.props;

    if (this.state.hidden) {
      return null; // Render nothing if hidden
    }
    return (
      <FormControl
        className={classes.formControl}
        variant="outlined"
        size="small"
      >
        <InputLabel id="mutiple-select-label">{this.props.label}</InputLabel>
        <Select
          classes={{
            root: classes.input,
          }}
          multiple
          value={selectedIds}
          onFocus={this.props.onFocus}
          onChange={this.props.handleChange}
          label={this.props.label}
          input={<OutlinedInput />}
          error={this.props.error}
          helperText={this.props.helperText}
          renderValue={(data) => {
            var newData = [];
            options &&
              options.map((items) => {
                if (selectedIds.indexOf(items[mapId]) > -1) {
                  newData.push(items[mapName]);
                }
              });
            return newData.join(",");
          }}
          style={{ position: "relative" }}
          MenuProps={MenuProps}
        >
          {options &&
            options.map((items) => (
              <MenuItem
                className={classes.menuIte}
                key={items[mapId]}
                value={items[mapId]}
              >
                <Checkbox
                  sx={{
                    color: "#8E0C0C",
                    "&.Mui-checked": {
                      color: "#8E0C0C",
                    },
                  }}
                  md={{
                    color: "#8E0C0C",
                    "&.Mui-checked": {
                      color: "#8E0C0C",
                    },
                  }}
                  checked={selectedIds.indexOf(items[mapId]) > -1}
                />
                <ListItemText primary={items[mapName]} />
              </MenuItem>
            ))}
          <div style={{ display: "flex", paddingLeft: "35%" }}>
            <Button variant="text" style={{ color: "gray" }}>
              Cancel
            </Button>
            <Button
              variant="text"
              style={{ color: "#8E0C0C" }}
              onClick={applyCategory}
            >
              Apply
            </Button>
          </div>
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(MyMultiSelectNews);
