import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box
} from '@material-ui/core';
import { ovHrColor } from '../../../config';
import { TableRecordNotFound } from "../../../components";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = (theme) => ({
    headCell: {
        padding: '0.5rem 0rem',
        color: 'white!important',
        fontWeight: 500,
        fontSize: '16px',
        textTransform: 'capitalize'
    },
    TableCell: {
        fontWeight: '400',
        fontSize: '14px',
    },
});

export default function SchedulerModal(props) {
    const { openSchedulerModal, handleCloseSchedulerModal, permissionList, onChangeSchedulerIsCreate,
        onChangeFullAcces, onChangeSchedulerIsEdit, onChangeSchedulerIsDelete, onChangeSchedulerIsAssign } = props;
    const classes = useStyles();
    
    return (
        <div>
            <BootstrapDialog
                onClose={handleCloseSchedulerModal}
                aria-labelledby="customized-dialog-title"
                open={openSchedulerModal.isModalOpen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseSchedulerModal}>
                    {openSchedulerModal.isType} Permissions
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size='medium'
                            >
                                <TableHead style={{ backgroundColor: ovHrColor.main }}>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            style={{ paddingRight: '20px', paddingLeft: '20px', width: 300, color: 'white' }}
                                            className={classes.headCell}
                                        >
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ padding: '10px', paddingRight: '20px', width: 250, color: 'white' }}
                                            className={classes.headCell}
                                        >
                                            Full Access
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ paddingRight: '20px', paddingLeft: '10px', color: 'white' }}
                                            className={classes.headCell}
                                        >
                                            Create
                                        </TableCell>
                                        <TableCell
                                            style={{ paddingRight: '20px', padding: '10px', color: 'white' }}
                                            className={classes.headCell}
                                            align="left"
                                        >
                                            Edit
                                        </TableCell>
                                        <TableCell
                                            style={{ paddingRight: '20px', padding: '10px', color: 'white' }}
                                            className={classes.headCell}
                                            align="left"
                                        >
                                            Delete
                                        </TableCell>
                                        <TableCell
                                            style={{ paddingRight: '20px', padding: '10px', color: 'white' }}
                                            className={classes.headCell}
                                            align="left"
                                        >
                                            Assign
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {permissionList.length > 0 && permissionList.map((row, index) => {

                                        return (
                                            <TableRow
                                                key={index}
                                                hover
                                                tabIndex={-1}
                                            >
                                                <TableCell
                                                    align="left" className={classes.TableCell}>
                                                    {row.title}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={classes.TableCell}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                // indeterminate={permissionList.length > 0 && permissionList.length < row.IsCreate || row.IsEdit || row.IsAssign || row.IsDelete}
                                                                name="IsFullAccess"
                                                                value={row.IsFullAccess}
                                                                checked={row.IsFullAccess ? true : false}
                                                                onChange={() => onChangeFullAcces(row.title, { IsFullAccess: !row.IsFullAccess })}
                                                            />}
                                                            label="" />
                                                    </FormGroup>

                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    className={classes.TableCell}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name="IsCreate"
                                                                value={row.IsCreate}
                                                                checked={row.IsCreate ? true : false}
                                                                onChange={() => onChangeSchedulerIsCreate(row.title, { IsCreate: !row.IsCreate })}
                                                            />}
                                                            label="" />
                                                    </FormGroup>

                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    className={classes.TableCell}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name="IsEdit"
                                                                value={row.IsEdit}
                                                                checked={row.IsEdit ? true : false}
                                                                onChange={() => onChangeSchedulerIsEdit(row.title, { IsEdit: !row.IsEdit })}
                                                            />}
                                                            label="" />
                                                    </FormGroup>
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    className={classes.TableCell}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                value={row.IsDelete}
                                                                checked={row.IsDelete ? true : false}
                                                                onChange={() => onChangeSchedulerIsDelete(row.title, { IsDelete: !row.IsDelete })}
                                                                name="IsDelete"
                                                            />}
                                                            label="" />
                                                    </FormGroup>
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    className={classes.TableCell}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                value={row.IsAssign}
                                                                checked={row.IsAssign ? true : false}
                                                                onChange={() => onChangeSchedulerIsAssign(row.title, { IsAssign: !row.IsAssign })}
                                                                name="IsAssign"
                                                            />}
                                                            label="" />
                                                    </FormGroup>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {permissionList && permissionList < 1 ?
                                        <TableRecordNotFound
                                            colSpan={8}
                                            label="No Data Found.."
                                        />
                                        : ""
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* } */}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseSchedulerModal}>
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}