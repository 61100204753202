import {
  MoNGO_DB_API_URL,
  // API_URL,
  HEADER_TOKEN,
  MY_TOKEN,
} from "../../../../config";
import axios from "axios";

export const gradingServices = {
  fetchData,
  fetchAssignLesson,
  setManagerGrading,
  getLessonData,
  setLessonVideo,
  getVideoURL,
  fetchlocationData,
  fetchRoleData,
  fetchTeamGradingData,
  getLessonNames,
  getUsers,
  getQuizCount,
};

function getUsers() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/users/data`,
      {
        headers: { Authorization: MY_TOKEN },
      }
    )
    .then((response) => response);
}

function getLessonNames() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/company`,
      {
        headers: { Authorization: MY_TOKEN },
      }
    )
    .then((response) => response);
}

function fetchTeamGradingData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/team`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function getQuizCount(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/quiz/count`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/company`,
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchAssignLesson(uid, data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/user/${uid}`,
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        params: data,
      }
    )
    .then((response) => response);
}

function setManagerGrading(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getLessonData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/mycourse/lesson_details`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function setLessonVideo(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/video`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function getVideoURL(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchlocationData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/locations`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/grading/roles`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
