import React from "react";
// import DateRangePicker from "react-daterange-picker";
// import "react-daterange-picker/dist/css/react-calendar.css";
import DatePicker, {
  Calendar,
  getAllDatesInRange
} from "react-multi-date-picker";
import moment from "moment";
class Example extends React.Component {

  render() {
    var value = [this.props.currentDate, this.props.currentDate]
    return (
      <div style={{
        pointerEvents: "none",
        padding: "10px 0 0 0",
        display: "flex",
        justifyContent: "center"
      }}>
        <Calendar
          style={{backgroundColor: "red"}}
          onlyShowInRangeDates={true}
          value={value}
          // range
          minDate={this.props.currentDate}
          maxDate={this.props.currentDate}
          // singleDateRange={true}
        />
      </div>
    );
  }
}

export default Example;