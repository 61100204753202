import React, { createContext, useContext, useState } from "react";

const MobileNewsSearchContext = createContext();

export const NewsMobileSearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [newsTitle, setNewsTitle] = useState("");

  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const resetSearchQuery = () => {
    setSearchQuery("");
  };

  const updateNewsTitle = (value) => {
    setNewsTitle(value);
  };

  return (
    <MobileNewsSearchContext.Provider
      value={{
        searchQuery,
        updateSearchQuery,
        resetSearchQuery,
        newsTitle,
        updateNewsTitle,
      }}
    >
      {children}
    </MobileNewsSearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(MobileNewsSearchContext);
};
