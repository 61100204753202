import { MdOutlineSearch } from "react-icons/md";
import { mobileTheme } from "../../constant";

import "./EventSearchMobile.scss";

export const EventSearchMobile = ({ placeholder, value, handleSearch }) => {
  return (
    <div className="eventsMobileSearchRoot">
      <MdOutlineSearch className="searchIcon" style={{ color: mobileTheme }} />
      <input value={value} onChange={handleSearch} placeholder={placeholder} />
    </div>
  );
};
