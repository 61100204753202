//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
        Typography
} from '@material-ui/core';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import EmailIcon from '@material-ui/icons/Email';

const styles = (theme) => ({
        textField: {
                // backgroundColor: '#F3F3F3 !important',
                width: '100%',
                marginBottom: '5px'
        },
        label: {
                fontSize: '16px',
                fontWeight: 600
        },
        input: {
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: 5,
                marginBottom: 0,
                padding: '10.5px 14px',
        },
        labelColor: {
                color: 'gray'
        }
});

class MyTextField extends Component {
        render() {
                const { classes } = this.props;

                return (
                        <div>
                                {this.props.label ?
                                        <Typography
                                                gutterBottom
                                                className={classes.label}
                                        >
                                                {this.props.label}
                                        </Typography> : ""}
                                <FormControl
                                        className={classes.textField}
                                        variant="outlined"
                                >
                                        <OutlinedInput
                                                disabled={this.props.disabled === undefined ? false : this.props.disabled}
                                                placeholder={this.props.placeholder}
                                                type='text'
                                                name={this.props.name}
                                                onChange={this.props.onChange}
                                                onFocus={this.props.onFocus}
                                                value={this.props.value}
                                                inputlabelprops={{ classes: { root: classes.labelColor } }}
                                                inputProps={{
                                                        className: classes.input
                                                }}
                                                // endAdornment={
                                                //         <InputAdornment position="end">
                                                //                 <EmailIcon />
                                                //         </InputAdornment>
                                                // }
                                        />
                                        {this.props.error ?
                                                <FormHelperText
                                                        style={{ color: "red" }}
                                                >
                                                        {this.props.helperText}
                                                </FormHelperText> : ""}
                                </FormControl>
                        </div >
                )
        }
}

export default withStyles(styles)(MyTextField);