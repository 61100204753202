import {
  SET_PROFILE_LOADING,
  SET_PROFILE,
  DELETE_PROFILE,
  SET_PROFILE_DATA,
  EDIT_PROFILE_DATA
} from "./constants";

const initialState = {
  isLoading: true,
  tokenData: [],
  profileData: [],
  stateData: {},
  cityData: {},
  countryData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROFILE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_PROFILE:
      return {
        ...state,
        isLoading: false,
        tokenData: payload.tokenData
      };
    case DELETE_PROFILE:
      return {
        ...state,
        isLoading: false,
        tokenData: state.tokenData.filter((item) => item._id !== payload)
      }
    case SET_PROFILE_DATA:
      return {
        ...state,
        isLoading: false,
        profileData: payload.userData,
        countryData: payload.countryData
      }
    case EDIT_PROFILE_DATA:
      return {
        ...state,
        profileData: payload,
        isLoading: false,
      }
    default:
      return state;
  }
};

export default Reducer;