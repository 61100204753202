import {
  SET_ELE_REPORT_LOADING,
  SET_ELE_REPORT
} from "./constants";

const initialState = {
  isLoading: true,
  companyReports: [],
  userReports: [],
  courseReports: [],
  lessonReports: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ELE_REPORT_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_ELE_REPORT:
      return {
        ...state,
        isLoading: false,
        companyReports: payload.companyReports,
        userReports: payload.userReports,
        courseReports: payload.courseReports,
        lessonReports: payload.lessonReports
      };
    default:
      return state;
  }
};

export default Reducer;