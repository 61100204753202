import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { IconButton } from '@material-ui/core';

const styles = (theme) => ({
        menu: {
                minWidth: '270px',
                borderRadius: '5px',
                backgroundColor: "#fafafa"
        },
        MenuItem: {
                border: "1px solid #f2f2f2",
                padding: "0px 10px",
                '&:hover': {
                        boxShadow: '4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                        backgroundColor: theme.palette.primary.secondary,
                }
        },
        ListItemIcon: {
                minWidth: "45px"
        },
        mYicon: {
                height: 24,
                width: 24
        },
        Typography: {
                fontSize: '15px',
                lineHeight: '2rem'
        }
})

class ToolsMenus extends Component {
        render() {
                const { classes, isFeatureWeek, toolsAnchor, toolsData, toolbarSettings } = this.props;
                return (
                        <>
                                <Menu
                                        MenuListProps={{ disablePadding: true }}
                                        anchorEl={toolsAnchor}
                                        anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                        }}
                                        getContentAnchorEl={null}
                                        open={Boolean(toolsAnchor)}
                                        onClose={(e) => { this.props.handleToolsClose("") }}
                                        PopoverClasses={{
                                                paper: classes.menu
                                        }}
                                >
                                        {toolsData.map((value) => {
                                                return (
                                                        <MenuItem
                                                                disabled={value.sortName === "clear_entire_schedule" || value.sortName === "clear_unpublished_shifts" ?
                                                                        isFeatureWeek ? false : true
                                                                        : false}
                                                                onClick={() => { this.props.handleToolsClose(value.sortName) }}
                                                                className={classes.MenuItem}
                                                        >
                                                                <ListItemIcon className={classes.ListItemIcon}>
                                                                        {value.isCheckbox ?
                                                                                <Checkbox
                                                                                        className={classes.mYicon}
                                                                                        color="primary"
                                                                                        checked={toolbarSettings[value.sortName] ? true : false}
                                                                                /> :
                                                                                <IconButton style={{ padding: '8px' }}>
                                                                                        <value.icon
                                                                                                className={classes.mYicon}
                                                                                        />
                                                                                </IconButton>
                                                                        }
                                                                </ListItemIcon>

                                                                <Typography
                                                                        className={classes.Typography}
                                                                        color="textPrimary"
                                                                        variant="inherit"
                                                                >
                                                                        {value.title}
                                                                        
                                                                </Typography>
                                                        </MenuItem>
                                                )
                                        })}
                                </Menu>
                        </>
                )
        }
}

export default withStyles(styles)(ToolsMenus);