// import * as React from "react";
// import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { TextField } from "@material-ui/core";

// export default function BasicTimePicker(props) {

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <TimePicker
//         label={props.label}
//         key={props.key}
//         value={props.value}
//         variant="inline"
//         onFocus={props.onFocus}
//         error={props.error}
//         helperText={props.helperText}
//         onChange={props.onChange}
//         renderInput={props.renderInput}
//         //onChange={(newValue) => setValue(newValue)}
//       />
//     </LocalizationProvider>
//   );
// }

//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@material-ui/core";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },
  input: {
    //padding: '5px 14px !important',
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor: "#f4f2f26b !important",
    borderRadius: 5,
    marginBottom: 0,
  },
  labelColor: {
    // color: '#b6b5b5'
    color: "gray",
  },
});

class BasicTimePicker extends Component {
  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker
          label={this.props.label}
          key={this.props.key}
          value={this.props.value}
          variant="inline"
          onFocus={this.props.onFocus}
          error={this.props.error}
          helperText={this.props.helperText}
          onChange={this.props.onChange}
          renderInput={this.props.renderInput}
          //onChange={(newValue) => setValue(newValue)}
        />
      </LocalizationProvider>
    );
  }
}

export default withStyles(styles)(BasicTimePicker);
