import React from 'react';
import { history } from "../history";
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  Avatar
} from '@material-ui/core';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  media: {
    padding: '15px',
    height: 55,
    width: 55
  },
  CardContent: {
    padding: '14px 0px',
    "&:last-child": {
      padding: '0'
    },
  },
  card: {
    boxShadow: "0px 2px 5px rgba(83, 97, 255, 0.0534986)",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "4px 8px 20px 0 rgba(0, 0, 0, 0.5), 4px 8px 20px 0 rgba(0, 0, 0, 0.5)"
    }
  },
  topDiv: {
    padding: '1rem 0',
  },
  productTitle: {
    padding: '2rem',
    textAlign: 'center',
    color: 'black',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '20px',
    textDecoration: 'none',
  },
  subTitle: {
    color: '#ffffff',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '4rem',
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  }
}));

const ProductCard = props => {
  const { product } = props;
  const classes = useStyles();

  const productModalToggle = (href) => {
    if (ovCid.toString() !== "624e80fff8ee75131034af66") {
      props.productModalToggle()
      history.push(href)
    }
  }

  var ovCid = cookies.get('ovCid')
  return (
    // <Link to={product.href}>
    <Card
      onClick={() => { productModalToggle(product.href) }}
      style={{
        opacity: ovCid.toString() === "624e80fff8ee75131034af66" ? 0.5 : 1,
        backgroundColor: product.backgroundColor ? product.backgroundColor : "red",
        cursor: 'pointer'
      }}
      className={classes.card}
    >
      <CardContent className={classes.CardContent} >
        <Grid
          className={classes.topDiv}
          container
          spacing={0}
        >
          <Grid
            item
            sm={12}
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              style={{ backgroundColor: product.iconColor }}
              className={classes.media}
              src={product.imageUrl}
            />
          </Grid>
          {/* <Grid
            item
            sm={8}
            xs={12}
          >
            <Typography
              className={classes.subTitle}
            >
              Started sending at
            </Typography>
          </Grid> */}

        </Grid>
        <Divider
          style={{ color: "#ffffff" }}
        />

        <Typography
          className={classes.productTitle}
          style={{ fontSize: product.title === "Scheduler" ? '18px' : '20px' }}
        >
          {product.title === "Scheduler" ?
            "Team Scheduler"
            : product.title
          }
        </Typography>
      </CardContent>
    </Card>
    // </Link >
  );
};

export default ProductCard;
