export function hexToRgb(hex) {
  // Remove the hash (#) if present
  hex = hex.replace(/^#/, "");

  // Parse the hex value into individual RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB values as a string
  return `${r}, ${g}, ${b}`;
}
