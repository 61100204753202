import {
  GET_MY_EARNING_LOADING,
  GET_MY_EARNING_DATA,
  GET_TEAM_EARNING_LOADING,
  GET_TEAM_EARNING_DATA,
  GET_USER_EARNING_LOADING,
  GET_USER_EARNING_DATA,
  GET_USER_PAYMENT_LOGS_LOADING,
  GET_USER_PAYMENT_LOGS_DATA,
  GET_EARNING_COMMENTS_LOADING,
  GET_EARNING_COMMENTS_DATA,
  GET_ALL_EARNING_LESSONS_LOADING,
  GET_ALL_EARNING_LESSONS_DATA,
  PAY_USER_EARNING_LOADING,
  PAY_USER_EARNING,
  ADD_EARNING_COMMENT_LOADING,
  ADD_EARNING_COMMENT,
  GET_EARNING_USER_DATA,
  GET_EARNING_USER_LOADING,
} from "./constants";

import { invalidToken } from "../../../../utilities";
import { earningServices } from "./services";

export function fetchMyEarningData(data, page, limit) {
  return function (dispatch) {
    dispatch({
      type: GET_MY_EARNING_LOADING,
      payload: true,
    });

    earningServices.fetchMyEarningData(data, page, limit).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_MY_EARNING_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchTeamEarningData(data) {
  return function (dispatch) {
    dispatch({
      type: GET_TEAM_EARNING_LOADING,
      payload: true,
    });

    earningServices.fetchTeamEarningData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_TEAM_EARNING_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchEarningEmployeesData(data) {
  return function (dispatch) {
    dispatch({
      type: GET_EARNING_USER_LOADING,
      payload: true,
    });

    earningServices.fetchEarningEmployeesData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_EARNING_USER_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchUserEarningData(data, page, limit) {
  return function (dispatch) {
    dispatch({
      type: GET_USER_EARNING_LOADING,
      payload: true,
    });

    earningServices.fetchUserEarningData(data, page, limit).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_USER_EARNING_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchUserPaymentLogsData(data) {
  return function (dispatch) {
    dispatch({
      type: GET_USER_PAYMENT_LOGS_LOADING,
      payload: true,
    });

    earningServices.fetchUserPaymentLogsData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_USER_PAYMENT_LOGS_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchEarningCommentsData(data) {
  return function (dispatch) {
    dispatch({
      type: GET_EARNING_COMMENTS_LOADING,
      payload: true,
    });

    earningServices.fetchEarningCommentsData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_EARNING_COMMENTS_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchAllEarningLessonsData() {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_EARNING_LESSONS_LOADING,
      payload: true,
    });

    earningServices.fetchAllEarningLessonsData().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_ALL_EARNING_LESSONS_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function payUserEarning(data) {
  return function (dispatch) {
    dispatch({
      type: PAY_USER_EARNING_LOADING,
      payload: true,
    });

    earningServices.payUserEarning(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: PAY_USER_EARNING,
          payload: response.data,
        });
      }
    });
  };
}

export function addEarningComment(data) {
  return function (dispatch) {
    dispatch({
      type: ADD_EARNING_COMMENT_LOADING,
      payload: true,
    });

    earningServices.addEarningComment(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: ADD_EARNING_COMMENT,
          payload: response.data,
        });
      }
    });
  };
}
