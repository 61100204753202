import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";

import "./DefaultRating.scss";

export function DefaultRating({ handleRating, rating }) {
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);

  return (
    <Box className={"ratingContainer"}>
      <Rating
        name="hover-feedback"
        value={rating || value}
        precision={1}
        max={5}
        onChange={(event, newValue) => {
          handleRating ? handleRating(newValue) : setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        icon={<FaStar className={"starIcon"} />}
        emptyIcon={<FaRegStar className={"emptyStarIcon"} />}
      />
    </Box>
  );
}
