import {
  SET_GRADING_DATA,
  SET_GRADING_LOADING,
  SET_TEAM_GRADING_DATA,
  SET_TEAM_GRADING_LOADING,
  SET_GRADING_DATA_PAYLOAD,
  SET_GRADING_DATA_PAYLOAD1,
  SET_LESSON_GRADING_LOADING,
  SET_LESSON_DATA,
  SET_STATUS_DATA,
  SET_GRADING_LOADING_LESSON,
  SET_ROLE,
  SET_VIDEO_DATA,
  SET_VIDEO_GRADING_LOADING,
  GET_VIDEO_URL,
  SET_MANAGE_GRADING_LOADING,
  GET_LOCATIONS,
  SET_LOCATION_LOADING,
  GET_ROLES,
  SET_ROLES_LOADING,
  GET_LESSON_NAMES,
  GET_LESSON_NAMES_LOADING,
  GET_USERS,
  GET_USERS_LOADING,
  GET_QUIZ_COUNT_LOADING,
  GET_QUIZ_COUNT_DATA,
} from "./constants";

const initialState = {
  isLoading: true,
  isLoadingLesson: true,
  isLoadingVideoURL: true,
  isStatusLoading: true,
  isLessonPayloadLoading: true,
  isUserGradingLoading: true,
  isSubmitVideoLoading: true,
  isLocationLoading: true,
  isRolesLoading: true,
  isTeamGradingLoading: true,
  lessonNamesData: [],
  isLessonNamesDataLoading: true,
  locationDetails: [],
  roleDetails: [],
  UserData: [],
  GradingPayload: [],
  pageNumber: 1,
  totalPage: 1,
  lessonPageNumber: 1,
  totalLessonPage: 1,
  nPerPage: 10,
  role: "Manager",
  locationLength: "",
  LessonPayload: [],
  LessonDetail: {},
  LessonArray: [],
  statusPayload: [],
  teamGradingPayload: [],
  submitVideoPayload: [],
  videoURL: "",
  pNumber: 1,
  noPerPage: 10,
  isLessonquiz: 1,
  isvideo_quiz: 1,
  practical_quiz: 1,
  manager_quiz: 1,
  pageLength: "",
  isUserLoading: true,
  getAllUsersData: [],
  getAllLocationsData: [],
  getQuizCountLoading: true,
  getQuizCountData: {},
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GRADING_LOADING:
      return {
        ...state,
        isUserGradingLoading: payload,
      };
    case SET_GRADING_LOADING_LESSON:
      return {
        ...state,
        isLoadingLesson: payload,
      };
    case GET_QUIZ_COUNT_LOADING:
      return {
        ...state,
        getQuizCountLoading: payload,
      };
    case GET_QUIZ_COUNT_DATA:
      return {
        ...state,
        getQuizCountLoading: false,
        getQuizCountData: payload,
      };
    case GET_USERS_LOADING:
      return {
        ...state,
        isUserLoading: payload,
      };
    case GET_USERS:
      return {
        ...state,
        isUserLoading: false,
        getAllUsersData: payload.result,
        // getAllLocationsData: payload.locationData,
      };
    case SET_GRADING_DATA:
      return {
        ...state,
        isUserGradingLoading: false,
        UserData: payload.result,
        totalPage: payload.totalPages,
        pageNumber: payload.currentPage,
        // locationLength: payload.locationLength,
      };
    case SET_LESSON_DATA:
      return {
        ...state,
        isLoadingLesson: false,
        LessonDetail: payload,
        LessonArray: payload.lessonData ? payload.lessonData : null,
      };
    case SET_MANAGE_GRADING_LOADING:
      return {
        ...state,
        isStatusLoading: payload,
      };
    case SET_STATUS_DATA:
      return {
        ...state,
        isStatusLoading: false,
        statusPayload: payload,
      };
    case SET_VIDEO_GRADING_LOADING:
      return {
        ...state,
        isSubmitVideoLoading: payload,
      };
    case SET_VIDEO_DATA:
      return {
        ...state,
        isSubmitVideoLoading: false,
        submitVideoPayload: payload,
      };
    case GET_VIDEO_URL:
      return {
        ...state,
        isLoadingVideoURL: false,
        videoURL: payload.result,
      };
    case SET_GRADING_DATA_PAYLOAD:
      return {
        ...state,
        isLoading: false,
        GradingPayload: payload,
      };
    case SET_LESSON_GRADING_LOADING:
      return {
        ...state,
        isLessonPayloadLoading: payload,
      };
    case SET_GRADING_DATA_PAYLOAD1:
      return {
        ...state,
        isLessonPayloadLoading: false,
        LessonPayload: payload.result,
        totalLessonPage: payload.totalPages,
        lessonPageNumber: payload.currentPage,
      };
    case SET_TEAM_GRADING_LOADING:
      return {
        ...state,
        isTeamGradingLoading: payload,
      };
    case SET_TEAM_GRADING_DATA:
      return {
        ...state,
        isTeamGradingLoading: false,
        teamGradingPayload: payload,
      };
    case SET_LOCATION_LOADING:
      return {
        ...state,
        isLocationLoading: payload,
      };
    case GET_LOCATIONS:
      return {
        ...state,
        isLocationLoading: false,
        locationDetails: payload,
      };
    case SET_ROLES_LOADING:
      return {
        ...state,
        isRolesLoading: payload,
      };
    case GET_ROLES:
      return {
        ...state,
        isRolesLoading: false,
        roleDetails: payload,
      };
    case SET_ROLE:
      return { role: payload };
    //     case SET_LESSON_CATEGORY:
    //       return {
    //         ...state,
    //         isLoading: false,
    //         isLessonQuiz: payload,
    //       };
    //       case SET_LESSON_CATEGORYDATA:
    //       return {
    //         ...state,
    //         isLoading: false,
    //         categoryData: payload
    //       };
    // case ADD_ELE_CATEGORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     categoryData: [...state.categoryData, payload]
    //   }
    // case EDIT_ELE_CATEGORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     categoryData: state.categoryData.map((item) => item._id === payload._id ? { ...payload } : item)
    //   }
    // case DELETE_ELE_CATEGORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     categoryData: state.categoryData.filter((item) => item._id !== payload)
    //   }
    case GET_LESSON_NAMES_LOADING:
      return {
        ...state,
        isLessonNamesDataLoading: payload,
      };
    case GET_LESSON_NAMES:
      return {
        ...state,
        isLessonNamesDataLoading: false,
        lessonNamesData: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
