//TextField
import React, { Component } from "react";
import SearchBar from "material-ui-search-bar";
import { withStyles } from "@material-ui/core/styles";
import {
        IconButton
}
        from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Close';
const styles = (theme) => ({
        SearchBar: {
                height: '45px',
                borderRadius: '25px',
                border: '1px solid #B0B0B0',
                color: "#7D7D7D",
                width: "490px"
        },
        icon: {
                color: '#135098'
        }
});

class HelpdeskSearchbar extends Component {
        render() {
                const { classes } = this.props;
                return (
                        <SearchBar
                                placeholder={this.props.placeholder}
                                className={classes.SearchBar}
                                // searchIcon={
                                //         <IconButton size="small"
                                //         >
                                //                 <SearchIcon />
                                //         </IconButton>
                                // }
                                closeIcon={
                                        <IconButton size="small"
                                        >
                                                <ClearIcon
                                                        onClick={this.props.clearSearch}
                                                />
                                        </IconButton>
                                }
                                value={this.props.value}
                                onChange={(value) => { this.props.handleSearch(value) }}
                                style={{
                                        width: window.innerWidth >= 745 ? "490px" : "100%"
                                }}
                        />
                )
        }
}
export default withStyles(styles)(HelpdeskSearchbar);