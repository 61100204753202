import {
    SET_COMPANY_LOADING,
    SET_COMPANY,
} from "./constants";
import { invalidToken } from '../../../../../utilities';
import { companyServices } from './services';

export function fetchData(isReload) {
    return function (dispatch) {
        if (isReload) {
            dispatch({
                type: SET_COMPANY_LOADING,
                payload: true
            });
        }

        companyServices.fetchData()
            .then(response => {
                if (response.data.success === 2) {
                    invalidToken(response.data.message)
                } else {
                    dispatch({
                        type: SET_COMPANY,
                        payload: response.data
                    });
                }
            });
    };
}