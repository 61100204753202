export let paymentLogsTableBody = [
  {
    id: 1,
    LessonName: "Lesson 1",
    PaidAmount: "10 $",
    Points: "10 $",
    Duration: "1 hour 30 min",
  },
  {
    id: 2,
    LessonName: "Lesson 1",
    PaidAmount: "10 $",
    Points: "10 $",
    Duration: "1 hour 30 min",
  },
  {
    id: 3,
    LessonName: "Lesson 1",
    PaidAmount: "10 $",
    Points: "10 $",
    Duration: "1 hour 30 min",
  },
];

export const paymentLogsTableHead = [
  {
    id: 1,
    heading: "Lessons",
  },
  {
    id: 2,
    heading: "Paid Amount",
  },
  {
    id: 3,
    heading: "Points",
  },
  {
    id: 4,
    heading: "Duration",
    dropdown: "",
  },
];

export const paymentLogs = [
  { id: 1, date: "23 Mar 2023", time: "1:23 PM" },
  { id: 2, date: "23 Mar 2023", time: "1:23 PM" },
  { id: 3, date: "23 Mar 2023", time: "1:23 PM" },
  { id: 4, date: "23 Mar 2023", time: "1:23 PM" },
  { id: 5, date: "23 Mar 2023", time: "1:23 PM" },
];
