import React,{useEffect} from "react";
import './Hierarachy.css'
import { MyLeftModal, MyDatePicker, MySelectField, MySelect } from '../../../components';
import { ovHrColor } from "../../../config";
import DeleteModal from "./DeleteModal";
import _ from "lodash";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './Hierarachy.css';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { userImage } from "../../../icons";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupsIcon from '@mui/icons-material/Groups';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { history } from '../../../history';
import { Avatar }from '@material-ui/core';
import Cookies from "universal-cookie";
const cookies = new Cookies();

var ovUid = cookies.get("ovUid");

const useStyles = makeStyles((theme) => ({
    titleModal: {
        marginBottom: 16,
        display: 'flex',
        justifyContent: "space-between"
    },
}));

const CommonModal = (props) => {
    const { onHandleCloseEditModal, editRoleModal, valueTab, handleChange, onHandleCloseDeleteModal,
        deleteRoleModal, assignHelpdeskModal, onHandleCloseAssignHelpdesk, oneItem, onHandleDeleteClick,
        onChangeInput, inputsValue, onHandleEditClick, handleChangeDate, handleChanges, userInputValue,
        RoleData, usersData, handleChangeRole, roleInputValue } = props;
    const classes = useStyles();

    
    var newDat = userInputValue
    // usersData && usersData.map((item) => {
    //     if(item._id === userInputValue){
    //     return (
    //         item._id
    //     )
    //     }
    // })
   
    return (
        <>
            <MyLeftModal
                bgColor={ovHrColor.main}
                title="Edit"
                open={editRoleModal}
                onClose={onHandleCloseEditModal}
                handleSubmit={() => onHandleEditClick(inputsValue, userInputValue, oneItem, roleInputValue)}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={valueTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Edit Role" value="1" />
                                <Tab label="User Profile" value="2" />
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <div className="textMargin">
                                <MySelectField
                                    label="Role Name"
                                    name="title"
                                    type="text"
                                    onChange={handleChangeRole}
                                    value={roleInputValue}
                                    error={false}
                                    helperText=""
                                    placeholder="Select a role"
                                >
                                    {RoleData && RoleData.map((item) => {
                                        return (
                                            <option key={item.id} value={item._id}
                                                onChange={(e) => handleChanges(e, item._id)}>
                                                {item.role_name}
                                            </option>
                                        );
                                    })}
                                </MySelectField>
                            </div>
                            <hr />
                            {oneItem.name === "" || oneItem.name === null || inputsValue.name === null || inputsValue.name === "" ?
                                <Grid item xs={12}>
                                    <MySelectField
                                        label="User name"
                                        name="user_name"
                                        type="text"
                                        onChange={handleChanges}
                                        value={userInputValue}
                                        error={false}
                                        helperText=""
                                        placeholder="Select a person"
                                    >
                                        {usersData && usersData.map((item) => {
                                            return (
                                                <option key={item.id} value={item._id}
                                                    onChange={(e) => handleChanges(e, item._id)}>
                                                    {item.user_name}
                                                </option>
                                            );
                                        })}
                                    </MySelectField>
                                </Grid>
                                :
                                <div>
                                    <div className={classes.titleModal}>
                                        Employee
                                        <div>
                                            <CloseIcon />
                                        </div>
                                    </div>

                                    {/* <Grid md={12} item className="textMargin11">
                                        <div>
                                            Photos
                                        </div>
                                        <TextField id="outlined-basic" variant="outlined" type="file" />

                                    </Grid> */}
                                    <Grid item sx={12} className="textMargin" style={{ marginTop: "8%" }}>
                                        <MySelectField
                                            label="User name"
                                            name="user_name"
                                            type="text"
                                            onChange={handleChanges}
                                            value={userInputValue}
                                            error={false}
                                            helperText=""
                                            placeholder="Select a person"
                                        >
                                            {usersData && usersData.map((item) => {
                                                return (
                                                    <option key={item._id} value={item._id}>
                                                        {item.user_name}
                                                    </option>
                                                );
                                            })}
                                        </MySelectField>
                                    </Grid>
                                    <Grid item sx={12} className="textMargin">
                                        <TextField id="outlined-basic" fullWidth label="Mobile" variant="outlined"
                                            name="mobile_no" onChange={(e) => onChangeInput(e)} value={inputsValue && inputsValue.mobile_no}
                                        />
                                    </Grid>
                                    <Grid item sx={12} className="textMargin">
                                        <MyDatePicker
                                            name="date_of_birth"
                                            label="Date of Birth"
                                            onChange={(date) => { handleChangeDate(date, "date_of_birth") }}
                                            value={inputsValue.date_of_birth}
                                            placeholder="Enter birth date"
                                            style={{ backgroundColor: ovHrColor.main }}
                                        />
                                    </Grid>
                                    <Grid item sx={12} className="textMargin">
                                        <TextareaAutosize
                                            name="address" onChange={(e) => onChangeInput(e)} value={inputsValue && inputsValue.address}
                                            maxRows={4}
                                            aria-label="maximum height"
                                            placeholder="Enter Address"
                                            style={{ width: '-webkit-fill-available', height: 81 }}
                                        />
                                    </Grid>
                                    <Grid item sx={12} className="textMargin">
                                        <TextareaAutosize
                                            name="location" onChange={(e) => onChangeInput(e)} value={inputsValue && inputsValue.location}
                                            maxRows={4}
                                            aria-label="maximum height"
                                            placeholder="Enter Location"
                                            style={{ width: '-webkit-fill-available', height: 81 }}
                                        />
                                    </Grid>

                                </div>

                            }
                        </TabPanel>
                        <TabPanel value="2">
                          
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                {usersData && usersData.map((item) => {
                                  if( item._id === userInputValue) {
                                            return (
                                                <Avatar
                                                variant="circular"
                                                style={{
                                                    width: 'auto',
                                                    height: 225,
                                                    // margin: '0 auto',
                                                    // marginTop: '-3rem'
                                                }}
                                                src={
                                                    item.user_image === "" ? "" : item.user_image
                                                }
                                            />
                                            );
                                  }
                                        })}
                               
                                        {/*
                                    <Avatar
                                        variant="circular"
                                        style={{
                                            width: 'auto',
                                            height: 225,
                                            // margin: '0 auto',
                                            // marginTop: '-3rem'
                                        }}
                                        src={
                                            usersData.user_image === "" ? "" : usersData.user_image
                                        }
                                    /> */}
                                    {/* </CardMedia> */}
                                    <CardContent>
                                        <Typography style={{ fontWeight: 700 }} gutterBottom variant="h5" component="div">
                                            {inputsValue && inputsValue.name}
                                        </Typography>
                                        <Typography style={{ fontSize: "15px", fontWeight: 700 }} gutterBottom component="div">
                                            {inputsValue && inputsValue.title}
                                        </Typography>
                                        <div style={{ display: "flex" }}>
                                            <Typography><FlagIcon />&nbsp; &nbsp;</Typography>
                                            <Typography style={{ fontSize: "15px" }}>Younge 2</Typography>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Typography><LocationOnIcon />&nbsp; &nbsp;</Typography>
                                            <Typography style={{ fontSize: "15px" }}>{inputsValue.address ? inputsValue.address : "Ahmedabad"}</Typography>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Typography><AccessTimeIcon />&nbsp; &nbsp;</Typography>
                                            <Typography style={{ fontSize: "15px" }}>23:00pm</Typography>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <Typography><EmojiEventsIcon />&nbsp; &nbsp;</Typography>
                                            <Typography style={{ fontSize: "15px" }}>Started on 17 Aug 2018</Typography>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary"
                                        onClick={() => history.push('/hr/users?uid='+newDat)}>
                                        Edit Profile
                                    </Button>
                                </CardActions>
                            </Card>
                            <br />
                            <Typography style={{ fontSize: "15px", fontWeight: 700 }} gutterBottom component="div">
                                Contact
                            </Typography>
                            <div>
                                <Typography><EmailIcon />&nbsp; &nbsp; Email</Typography>
                                <Typography style={{ fontSize: "15px", paddingLeft: "45px", color: "blue" }}>{inputsValue && inputsValue.name}2330@gmail.com</Typography>
                            </div>
                            <br />
                            <div>
                                <Typography><PhoneIcon />&nbsp; &nbsp; Mobile</Typography>
                                <Typography style={{ fontSize: "15px", paddingLeft: "45px", color: "blue" }}>{inputsValue && inputsValue.mobile_no}</Typography>
                            </div>

                            <br />
                            <Typography style={{ fontSize: "15px", fontWeight: 700 }} gutterBottom component="div">
                                Group
                            </Typography>
                            <div>
                                <Typography><GroupsIcon />&nbsp; &nbsp;  {inputsValue && inputsValue.title}</Typography>
                            </div>

                            <br />
                            <Typography style={{ fontSize: "15px", fontWeight: 700 }} gutterBottom component="div">
                                About
                            </Typography>
                            <div>
                                <Typography><CardGiftcardIcon />&nbsp; &nbsp; Birthday</Typography>
                                <Typography style={{ fontSize: "15px", paddingLeft: "45px", color: "blue" }}>26 November 2019</Typography>
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </MyLeftModal>

            <DeleteModal
                bgColor={ovHrColor.main}
                desc="Are you sure you want to delete?"
                open={deleteRoleModal}
                onClose={onHandleCloseDeleteModal}
                handleDelete={() => onHandleDeleteClick(inputsValue.id ? inputsValue.id : oneItem.id)}
            />

            <MyLeftModal
                bgColor={ovHrColor.main}
                title="Assign Helpdesk"
                open={assignHelpdeskModal}
                onClose={onHandleCloseAssignHelpdesk}
                handleSubmit={onHandleCloseAssignHelpdesk}>
                <h1>Assign Helpdesk</h1>
            </MyLeftModal>
        </>
    )
};
export default CommonModal;