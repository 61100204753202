import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    TableMyPagination,
    TableRecordNotFound,
    LoadingData,
    DeleteModal,
    MyDatePicker,
    MoreMenu,
    MySearchBar
} from "../../../components";
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { alert } from "../../../utilities";
import Pagination from "@mui/material/Pagination";
import { connect } from "react-redux";
import { history } from "../../../history";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from "@material-ui/icons";
import Cookies from "universal-cookie";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    MenuItem,
    Select,
    Paper,
    Grid,
    Tooltip,
    FormControl,
} from "@material-ui/core";
import { schedulerColor } from "../../../config";
import { fetchData ,deleteData} from "./modules/actions";
import moment from "moment";
import './correction.css';
import AddCorrectionRequest from "./AddMyCorrectionRequest";
import { usersServices } from "./modules/services";
import { getScheduleData } from '../Settings/modules/actions'

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    headCell: {
        padding: '0.3rem 0rem',
        color: '#aa076b',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    rootToolbar: {
        minHeight: "58px",
        padding: theme.spacing(0, 2),
    },
    title: {
        flex: "1 1 100%",
        fontSize: "16px",
        fontWeight: "400",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    TableCell: {
        fontSize: "14px",
        fontWeight: "400",
    },
});

const cookies = new Cookies();

export function fetchCorrectionReqData() {
    window.CorrectionRequest.fetchData(true);
}

class correctionRequest extends Component {
    constructor(props) {
        super(props);
        window.CorrectionRequest = this;
        this.state = {
            actualData: {},
            ovCid: cookies.get('ovCid'),
            UserName: cookies.get('ovUserName'),
            ovUid: cookies.get('ovUid'),
            isShiftStartChecked: 0,
            isNoBreakChecked: 0,
            isBreakChecked: 0,
            isShiftEndChecked: 0,
            startTime: moment().hour(0).minute(0),
            BreakTime: moment().hour(0).minute(0),
            BreakEndTime: moment().hour(0).minute(0),
            shiftEndTime: moment().hour(0).minute(0),
            radioValue: "",
            order: "desc",
            orderBy: "_id",
            selectedValue: "",
            headCells: [
                { id: "id", label: "ID", numeric: false },
                { id: "request", label: "Request Date", numeric: false },
                { id: "correction", label: "Correction Type", numeric: false },
                { id: "status", label: "Status", numeric: false },
                { id: "date", label: "Occurance Date", numeric: false },
            ],
            errors: {},
            errorsHelper: {},
            moreData: [
                {
                    icon: EditIcon,
                    title: "Edit",
                    sortName: "edit",
                    onClick: this.modifyData,
                },
                {
                    icon: DeleteIcon,
                    title: "Delete",
                    sortName: "delete",
                    onClick: this.modifyData,
                },
            ],
            isSelected: "",
            noPayrollUsers: [],
            payrollAssignUsers: [],
            selectedOption: "",
            isEdit: 1,
            page: 1,
            locationData: [],
            empty: "",
            comments: {}
        };
    }

    componentDidMount = () => {
        var isReload = this.props.correctionData.length > 0 ? false : true
        const queryParameters = new URLSearchParams(window.location.search)
        const value = queryParameters.get("page") || 1; // Default to 1 if page parameter is not found
        this.fetchData(isReload, value) 
        if (this.props.correctionData.length > 0) {
            this.setState({
                correctionData: this.props.correctionData,
                correctionDataTemp: this.props.correctionData,
            })
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.correctionData) !== JSON.stringify(this.props.correctionData)) {
            this.setState({
                searchUsers: "",
                searchLevel: "",
                correctionData: this.props.correctionData,
                correctionDataTemp: this.props.correctionData

            })
        }
    }

    handleChangePage = (event, value) => {
        var url = `/scheduler/correction_request/my?page=` + value;
        this.setState({
            page: value
        }, () => {
            this.fetchData(true, value);
            history.push(url);
        })
    }

    fetchData = (isReload, value) => {
        this.setState({
            page: value
        })
        var data = {
            rowsPerPage: 10,
            pageNo: value,
            lid: this.props.scheduler_lid
        }
        this.props.fetchData(data,isReload)
    }

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === "asc";
        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property,
        });
    };

    handleBreakCheckbox = (e) => {
        this.setState({
            isBreakChecked: e.target.checked ? 1 : 0
        })
    };
    handleNoBreakCheckbox = (e) => {
        this.setState({
            isNoBreakChecked: e.target.checked ? 1 : 0
        })
    };
    handleShiftStartCheckbox = (e) => {
        this.setState({
            isShiftStartChecked: e.target.checked ? 1 : 0
        })
    };
    handleShiftEndCheckbox = (e) => {
        this.setState({
            isShiftEndChecked: e.target.checked ? 1 : 0
        })
    };

    onChangeStartTime = (date) => {
        this.setState({
            startTime: date,
        });
    };

    onChangeBreakTime = (date) => {
        this.setState({
            BreakTime: date,
        });
    };

    onChangeBreakEndTime = (date) => {
        this.setState({
            BreakEndTime: date,
        });
    };

    onChangeShiftEndTime = (date) => {
        this.setState({
            shiftEndTime: date,
        });
    };

    handleChangeComment = (e) => {
        var state = e.target.name
        this.setState(prevState => ({
            comments: {
                ...prevState.comments,
                [state]: e.target.value,
            },
        }));
    }

    deleteData = () => {
        this.setState({ deleteModal: false });
        this.props.deleteData(this.state.deleteId);
        this.fetchData(false,this.state.page)
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false,
        });
    };


    viewModalClose = () => {
        this.setState({
            viewModal: false,
        });
    };

    handleSubmit = async () => {
        // var valid_title = await  validationIsEmpty(this.state.selectedValue)
        // if (valid_title) {
        //   // alert("sucess")
        //   this.setState((prevState) => ({
        //     errors: {
        //       ...prevState.errors,
        //       title: true,
        //     },
        //     errorsHelper: {
        //       ...prevState.errorsHelper,
        //       title: "Please select user",
        //     },
        //   }));
        // }

        // if (valid_title) {
        //   return;
        // }
        var data = {
            occurance_date: moment(this.state.occurance_date).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
            lid: this.props.scheduler_lid,
            selected_user: this.state.ovUid,
            connection_type: [
                {
                    isshift_start: this.state.isShiftStartChecked,
                    actual_shirt_start_time: moment(this.state.startTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    shirt_start_comment: this.state.comments.shirt_start_comment ? this.state.comments.shirt_start_comment : "",
                    isno_break: this.state.isNoBreakChecked,
                    no_break_comment: this.state.comments.no_break_comment ? this.state.comments.no_break_comment : "",
                    is_break: this.state.isBreakChecked,
                    break_comment: this.state.comments.break_comment ? this.state.comments.break_comment : "",
                    break_start_time: moment(this.state.BreakTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    break_end_time: moment(this.state.BreakEndTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    isshift_end: this.state.isShiftEndChecked,
                    actual_shift_end_time: moment(this.state.shiftEndTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    shift_end_comment: this.state.comments.shift_end_comment ? this.state.comments.shift_end_comment : ""
                }
            ]
        }
        var dataEdit = {
            occurance_date: moment(this.state.occurance_date).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
            lid: this.props.scheduler_lid,
            selected_user: this.state.ovUid,
            _id:this.state.deleteId,
            connection_type: [
                {
                    isshift_start: this.state.isShiftStartChecked,
                    actual_shirt_start_time: moment(this.state.startTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    shirt_start_comment: this.state.comments.shirt_start_comment ? this.state.comments.shirt_start_comment : "",
                    isno_break: this.state.isNoBreakChecked,
                    no_break_comment: this.state.comments.no_break_comment ? this.state.comments.no_break_comment : "",
                    is_break: this.state.isBreakChecked,
                    break_start_time: moment(this.state.BreakTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    break_comment: this.state.comments.break_comment ? this.state.comments.break_comment : "",
                    break_end_time: moment(this.state.BreakEndTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    isshift_end: this.state.isShiftEndChecked,
                    actual_shift_end_time: moment(this.state.shiftEndTime).locale('en-US').format("YYYY-MM-DDTHH:mm:ss"),
                    shift_end_comment: this.state.comments.shift_end_comment ? this.state.comments.shift_end_comment : ""
                }
            ]
        }

        if (this.state.isEdit) {
            usersServices.editCorrection(dataEdit).then(response => {
                if (response.data.success === 2) {
                    alert.error(response.data.message)
                } else {
                    this.setState({
                        addEditModal: false
                    })
                    this.fetchData(false,this.state.page)
                    alert.success(response.data.message)
                }
            });
        } else {
            usersServices.addCorrection(data).then(response => {
                if (response.data.success === 2) {
                    alert.error(response.data.message)
                } else {
                    this.setState({
                        addEditModal: false
                    })
                    this.fetchData(false,this.state.page)
                    alert.success(response.data.message)
                }
            });
        }

      
    }

    modifyData = (value, item) => {
        // if (value === "add") {
        //     this.props.fetchUsers(false,1);
        // }
        this.setState({
            isEdit: value === "edit" ? 1 : 0,
            addEditModal: value === "edit" ? true : false,
            deleteModal: value === "delete" ? true : false,
        });

    };

    moreMenuClick = (item, value) => {
        this.setState({
            isEdit: 0,
            isShiftStartChecked: 0,
            isNoBreakChecked: 0,
            isBreakChecked: 0,
            selectedValue: "",
            isShiftEndChecked: 0,
            startTime: moment().hour(0).minute(0),
            BreakTime: moment().hour(0).minute(0),
            BreakEndTime: moment().hour(0).minute(0),
            shiftEndTime: moment().hour(0).minute(0),
            occurance_date: moment(new Date()).format("YYYY-MM-DD"),
            comments: {},
            addEditModal: value === "add" ? true : false,
            viewModal: value === "open" ? true : false,
            deleteId: item ? item._id : "",
            errorsHelper: {},
            errors: {}
        });
        if (item) {
            var startTime = new Date(
                item.connection_type[0].actual_shirt_start_time
              );
              var startTime_new = startTime.toLocaleString("en-US", {
                timeZone: this.props.timezone[0].location_timezone,
              });
              var endTime = new Date(
                item.connection_type[0].actual_shift_end_time
              );
              var endTime_new = endTime.toLocaleString("en-US", {
                timeZone: this.props.timezone[0].location_timezone,
              });
              var breakstartTime = new Date(
                item.connection_type[0].break_start_time
              );
              var breakstartTime_new = breakstartTime.toLocaleString("en-US", {
                timeZone: this.props.timezone[0].location_timezone,
              });
              var breakendTime = new Date(
                item.connection_type[0].break_end_time
              );
              var BreakEndTime_new = breakendTime.toLocaleString("en-US", {
                timeZone: this.props.timezone[0].location_timezone,
              });

              var commentsToUpdate = {
                break_comment: null,
                no_break_comment: null,
                shirt_start_comment: null,
                shift_end_comment: null,
            };
            
            if (item.connection_type[0].is_break) {
                commentsToUpdate.break_comment = item.connection_type[0].break_comment;
            }
            if (item.connection_type[0].isno_break) {
                commentsToUpdate.no_break_comment = item.connection_type[0].no_break_comment;
            }
            if (item.connection_type[0].isshift_start) {
                commentsToUpdate.shirt_start_comment = item.connection_type[0].shirt_start_comment;
            }

            if (item.connection_type[0].isshift_end) {
                commentsToUpdate.shift_end_comment = item.connection_type[0].shift_end_comment;
            }
            
            // Handle the default case when none of the conditions are true
            if (!item.connection_type[0].is_break && !item.connection_type[0].isno_break && !item.connection_type[0].isshift_start && !item.connection_type[0].isshift_end) {
                commentsToUpdate.shift_end_comment = item.connection_type[0].shift_end_comment;
            }
            
            this.setState(prevState => ({
                comments: commentsToUpdate,
            }));
            
            

            this.setState({
                occurance_date: moment(item.occurance_date).format("YYYY-MM-DD"), 
                isShiftStartChecked:item.connection_type[0].isshift_start,
                startTime: moment(startTime_new),
                //comments:item.connection_type[0].is_break ? item.connection_type[0].break_comment :item.connection_type[0].isno_break ? item.connection_type[0].no_break_comment : item.connection_type[0].isshift_start ?item.connection_type[0].shirt_start_comment : item.connection_type[0].shift_end_comment   ,
                isNoBreakChecked:item.connection_type[0].isno_break,
                isBreakChecked:item.connection_type[0].is_break,
                BreakTime:moment(breakstartTime_new),
                BreakEndTime:moment(BreakEndTime_new),
                isShiftEndChecked:item.connection_type[0].isshift_end,
                shiftEndTime: moment(endTime_new),

            })
        }
     
        this.setState({
            errors: {},
            errorsHelper: {},
            isEdit: 0,
            addEditModal: value === "add" ? true : false
        })
    };


    handleChangeDate = (date) => {
        this.setState({
            date: date
        })
        var updatedList = this.state.correctionDataTemp;

        if (date) {
            // var datee = this.setState({ filterDate: date})
            updatedList = updatedList.filter(function (item) {

                var dateA = moment(date).locale('en-US').format("YYYY-MM-DD")
                var dateB = moment(item.createdAt).locale('en-US').format("YYYY-MM-DD")
                if (dateA === dateB) {
                    return (
                        item.createdAt
                    )
                }
            })
        }
        var correctionData = updatedList
        this.setState({
            // filterDate: date,
            correctionData
        })
    };


    handleChangeOccuranceDate = (date, state) => {
        let d = moment(new Date(date)).format("YYYY-MM-DD")
        // let newdate = d.getDate();
        this.setState({
            occurance_date: d
        });
    };

    handleChangeDateOcc = (date) => {
        this.setState({
            dates: date
        })
        var updatedList = this.state.correctionDataTemp;

        if (date) {
            // var datee = this.setState({ filterDate: date})
            updatedList = updatedList.filter(function (item) {

                var dateA = moment(date).locale('en-US').format("YYYY-MM-DD")
                var dateB = moment(item.occurance_date).locale('en-US').format("YYYY-MM-DD")
                if (dateA === dateB) {
                    return (
                        item.occurance_date
                    )
                }
            })
        }
        var correctionData = updatedList
        this.setState({
            // filterDate: date,
            correctionData
        })
    };

    handleSearchUser = (target) => {
        var updatedList = this.state.correctionDataTemp;
        if (target) {
            updatedList = updatedList.filter(function (item) {
                return (
                    item.uid.user_name.toLowerCase().search(target.toLowerCase()) !== -1
                );
            });
        }
        var correctionData = updatedList.filter((item) => item.uid.user_name)
        this.setState({
            searchUsers: target,
            correctionData,
        });

    };


    clearSearchUser = () => {
        var correctionData = this.state.correctionDataTemp.filter((item) => item.uid.user_name)
        this.setState({
            searchUsers: "",
            correctionData,
        });
    }


    handleSearchLevel = (target) => {
        var updatedList = this.state.correctionDataTemp;
        if (target) {
            updatedList = updatedList.filter(function (item) {
                if (target === "Level 1" || target === "level 1") {
                    return (
                        item.status === 0
                    );
                }
                if (target === "Level 2" || target === "level 2") {
                    return (
                        item.status === 1
                    );
                }
                if (target === "Level 3" || target === "level 3") {
                    return (
                        item.status === 2
                    );
                }
                if (target === "Approved" || target === "approved") {
                    return (
                        item.status === 3
                    );
                }
            });
        }

        var correctionData = updatedList
        this.setState({
            searchLevel: target,
            correctionData,
        });

    };


    clearSearchLevel = () => {
        var correctionData = this.state.correctionDataTemp.filter((item) => item.holiday_name)
        this.setState({
            searchLevel: "",
            correctionData,
        });
    };

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    }

    handleChangeCash = (e, data) => {
        var value = e.target.value
        this.setState({
            empty: value
        })
        var updatedList = this.state.correctionDataTemp
        updatedList = updatedList.filter(function (item) {
            if (value === "Start Shift") {
                if (item.connection_type[0].isshift_start === 1) {
                    return (
                        item
                    )
                }
            }
            if (value === "End Shift") {
                if (item.connection_type[0].isshift_end === 1) {
                    return (
                        item
                    )
                }
            }
            if (value === "No Break") {
                if (item.connection_type[0].isno_break === 1) {
                    return (
                        item
                    )
                }
            }
            if (value === "Break") {
                if (item.connection_type[0].is_break === 1) {
                    return (
                        item
                    )
                }
            }

        });
        var correctionData = updatedList
        this.setState({
            correctionData,
        });
    };

    handleChangeSelect = (event) => {
        this.setState({
            selectedValue: event.target.value
        })
    };

    addEditModalClose = () => {
        this.setState({
            addEditModal: false
        })
    };

    handleChange = (event) => {
        this.setState({
            age: event.target.value
        })
    };


    render() {
        const { classes, isLoading, pageNo, rowsPerPage, permissionsData, locationData, peData,LocationDetaildata
        } = this.props;
        const { correctionData } = this.state;

        if (isLoading) return <LoadingData />;
        
        var dataNew = LocationDetaildata.locationDetail?.employees_create_correction_requests

        
        return (
            <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12}>

                    {this.state.addEditModal ?
                        <AddCorrectionRequest
                            onClose={this.addEditModalClose}
                            page={this.state.page}
                            occurance_date={this.state.occurance_date}
                            handleChangeOccuranceDate={this.handleChangeOccuranceDate}
                            isShiftStartChecked={this.state.isShiftStartChecked}
                            handleChangeComment={this.handleChangeComment}
                            isNoBreakChecked={this.state.isNoBreakChecked}
                            isShiftEndChecked={this.state.isShiftEndChecked}
                            isBreakChecked={this.state.isBreakChecked}
                            handleBreakCheckbox={this.handleBreakCheckbox}
                            handleNoBreakCheckbox={this.handleNoBreakCheckbox}
                            handleShiftStartCheckbox={this.handleShiftStartCheckbox}
                            handleShiftEndCheckbox={this.handleShiftEndCheckbox}
                            onChangeStartTime={this.onChangeStartTime}
                            onChangeBreakTime={this.onChangeBreakTime}
                            onChangeBreakEndTime={this.onChangeBreakEndTime}
                            onChangeShiftEndTime={this.onChangeShiftEndTime}
                            handleChangeSelect={this.handleChangeSelect}
                            selectedValue={this.state.selectedValue}
                            startTime={this.state.startTime}
                            BreakTime={this.state.BreakTime}
                            BreakEndTime={this.state.BreakEndTime}
                            shiftEndTime={this.state.shiftEndTime}
                            handleSubmit={this.handleSubmit}
                            handleFocus={this.handleFocus}
                            errors={this.state.errors}
                            isEdit={this.state.isEdit}
                            errorsHelper={this.state.errorsHelper}
                            UserName={this.state.UserName}
                            comments={this.state.comments}
                        />
                        :
                        <Paper>
                            <Toolbar variant="regular" className={classes.rootToolbar}>
                                <Typography
                                    display="inline"
                                    className={classes.title}
                                    variant="h6"
                                >
                                                 <b style={{
              color: "#AA076B",
              fontWeight: "500",
              fontSize: "17px",
              marginLeft: "15px",
            }}>My Correction Request</b>  
                                </Typography>
                            </Toolbar>

                            <TableContainer>
                                <div style={{ display: 'flex', marginBottom: '2%' }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', textAlign: '-webkit-center' }}>

                                            <Grid item xs={4} sm={4}>
                                                <div style={{ width: 'fit-content', height: '29px', padding: '0px 4px' }} className="searchBarHeight">
                                                    <MySearchBar
                                                        clearSearch={this.clearSearchLevel}
                                                        handleSearch={this.handleSearchLevel}
                                                        value={this.state.searchLevel}
                                                        placeholder="Search levels"
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={3} sm={3}>
                                                <div style={{ width: 'fit-content', height: 40, marginTop: '5%', padding: '0px 4px' }}>
                                                    <div style={{ width: 'auto' }}>
                                                        <MyDatePicker
                                                            name="holiday_date"
                                                            label="Date of Request"
                                                            onChange={(date) => {
                                                                this.handleChangeDate(date, "holiday_date");
                                                            }}
                                                            onFocus={this.handleFocus}
                                                            value={this.state.date}
                                                            placeholder="Enter Date"
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={3} sm={3}>
                                                <div style={{ width: 'fit-content', height: '40px', marginTop: '5%', padding: '0px 4px' }}>
                                                    <div style={{ width: 'auto', padding: 'inherit' }}>
                                                        <MyDatePicker
                                                            name="holiday_date"
                                                            label="Date of Occurance"
                                                            onChange={(date) => {
                                                                this.handleChangeDateOcc(date, "holiday_date");
                                                            }}
                                                            onFocus={this.handleFocus}
                                                            value={this.state.dates}
                                                            placeholder="Enter Date"
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={3} sm={3}>
                                                <div style={{ width: 'fit-content', height: '29px', padding: '0px 4px' }}>
                                                    <FormControl style={{ width: 180 }} size="small" variant="outlined">
                                                        {/* <InputLabel id="demo-select-small">Age</InputLabel> */}
                                                        
                                                        <Select
                                                            onChange={(e) => this.handleChangeCash(e)}
                                                            value={this.state.empty}
                                                            displayEmpty
                                                            placeholder="Payroll Provider intergration"
                                                        >
                                                            <MenuItem value={""} disabled={true}><em>Correction Type</em></MenuItem>
                                                            <MenuItem value={"Start Shift"}
                                                            // onChange={(e) => this.handleChangeCash(e)}
                                                            >Start Shift</MenuItem>
                                                            <MenuItem value={"End Shift"}
                                                            // onClick={(e) => this.handleChangeCash(e)}
                                                            >End Shift</MenuItem>
                                                            <MenuItem value={"Break"}
                                                            >Break</MenuItem>
                                                            <MenuItem value={"No Break"}
                                                            >No Break</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Grid>

                                            <br />
                                            <Grid item xs={3} sm={3} style={{ textAlign: '-webkit-right', paddingRight: '1%' }}>
                                                <div style={{ width: 'fit-content' }}>
                                                    {/* {this.props.permissionsData.scheduler_payroll_add ? */}
                                                        <>
                                                            <div style={{ textAlign: "right" }}>
                                                            {/* {dataNew === 1 ?  */}
                                                                <Tooltip arrow title="Add">
                                                                    <IconButton
                                                                        // disabled={
                                                                        //     this.props.permissionsData.common_payroll_add ? false : true 
                                                                            
                                                                        // }
                                                                        style={{ float: "right" }}
                                                                        size="small"
                                                                        onClick={() => {
                                                                            this.moreMenuClick(null, "add");
                                                                        }}
                                                                    >
                                                                        <AddIcon
                                                                            style={{
                                                                                border: "3px",
                                                                                borderRadius: "50%",
                                                                                backgroundImage: schedulerColor.main,
                                                                                color: "white",
                                                                                fontWeight: "500",
                                                                            }}
                                                                        />
                                                                    </IconButton> 
                                                                </Tooltip>
                                                                 {/* : ""} */}
                                                                 </div> </> 
                                                                {/* : ""} */}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Table className={classes.table} size="medium">
                                <TableHead style={{ backgroundColor: 'rgba(170, 7, 107, 0.125)' }}>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    sortDirection={
                                                        this.state.orderBy === headCell.id
                                                            ? this.state.order
                                                            : false
                                                    }
                                                >
                                                    <TableSortLabel
                                                        className={classes.headCell}
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={
                                                            this.state.orderBy === headCell.id
                                                                ? this.state.order
                                                                : "asc"
                                                        }
                                                        onClick={this.createSortHandler(headCell.id)}
                                                    >
                                                        {headCell.label}
                                                        {this.state.orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {this.state.order === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                                <TableCell
                                                    style={{ paddingRight: "20px" }}
                                                    className={classes.headCell}
                                                    align="right"
                                                    colSpan={2}
                                                >
                                                    Action
                                                </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {stableSort(correctionData, getComparator(this.state.order, this.state.orderBy)).slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage).map((row, index) => {
                                            if(row.archived === 0){
                                                return (
                                                    <TableRow hover tabIndex={-1}
                                                        key={row._id}
                                                    >
                                                        <TableCell className={classes.TableCell}>
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell className={classes.TableCell}>
                                                            {moment(row.createdAt).locale('en-US').format("YYYY-MM-DD")}
                                                        </TableCell>
                                                        <TableCell className={classes.TableCell}>
                                                            <Typography className={classes.TableCell}>
                                                                {row?.connection_type[0].isshift_start ? "Start Shift " : ""}
                                                            </Typography>
                                                            <Typography className={classes.TableCell}>
                                                                {row?.connection_type[0].isshift_end ? "End Shift " : ""}
                                                            </Typography>
                                                            <Typography className={classes.TableCell}>
                                                                {row?.connection_type[0].isno_break ? "Start Break " : ""}
                                                            </Typography>
                                                            <Typography className={classes.TableCell}>
                                                                {row?.connection_type[0].is_break ? "End Break" : ""}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.TableCell}>
                                                            {row.status === 0 ?
                                                                <div style={{ color: "#8E0C0C" }}>
                                                                    Level 1 Pending
                                                                </div>
                                                                : row.status === 1 ?
                                                                    <div style={{ color: "#8E0C0C" }}>
                                                                        Level 2 Pending
                                                                    </div>
                                                                    : row.status === 2 ?
                                                                        <div style={{ color: "#8E0C0C" }}>
                                                                            Level 3 Pending
                                                                        </div> :
                                                                        <div style={{ color: "#0F640D" }}>
                                                                            Approved
                                                                        </div>
                                                            }
                                                        </TableCell>
                                                        <TableCell className={classes.TableCell}>
                                                            {moment(row.occurance_date).locale('en-US').format("YYYY-MM-DD")}
                                                        </TableCell>
                                                        <TableCell
                                                                align="right"
                                                                className={classes.TableCell}
                                                            >
                                                                <MoreMenu
                                                                    moreMenuClick={() => {
                                                                        this.moreMenuClick(row);
                                                                    }}
                                                                    moreData={this.state.moreData}
                                                                />
                                                            </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                    
                                        })}
                                        {correctionData && correctionData.length < 1 ?
                                            <TableRecordNotFound
                                                colSpan={8}
                                                label="No Data Found.."
                                            />
                                            : ""
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Pagination
                                color="primary"
                                count={this.props.totalPage}
                                variant="outlined"
                                page={parseInt(this.state.page)}
                                onChange={this.handleChangePage}
                                className={classes.pagination}
                            />
                        </Paper>

                    }
                    <DeleteModal
                        bgColor={schedulerColor.main}
                        desc="Are you sure you want to delete?"
                        open={this.state.deleteModal}
                        onClose={this.deleteModalClose}
                        handleDelete={this.deleteData}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.mycorrectionRequest.isLoading,
    pageNo: state.localData.pageNo,
    totalPage: state.mycorrectionRequest.totalPage,
    correctionData: state.mycorrectionRequest.myCorrectionReq,
    LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
    rowsPerPage: state.localData.rowsPerPage,
});

const ConnectWith = connect(mapStateToProps, {
    fetchData,
    getScheduleData,
    deleteData
})(correctionRequest);
export default withStyles(styles)(ConnectWith);
