import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config'
import { MySelectField, MyAutocomplete } from '../../../components'
import {
  TextField,
  Grid,
  ButtonGroup,
  Button,
  FormControlLabel,
  Switch,
  Typography
}
  from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  avatarDay: {
    cursor: "pointer",
    float: 'left',
    // marginRight: '5px',
    textTransform: 'uppercase',
    height: '35px',
    width: '35px',
    fontWeight: 800,
    fontSize: '10px',
    color: '#ffffff'
  },
  avatarText: {
    float: 'left',
    textTransform: 'uppercase',
    height: '35px',
    width: '35px',
    fontWeight: 800,
    fontSize: '15px',
    backgroundColor: '#DFF2F7',
    color: '#8A5EA6'
  },
  userName: {
    color: schedulerColor.main,
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '3px',
    lineHeight: '2.1rem',
    display: "inline",
    float: 'left',
    cursor: 'move'
  },
  toggle: {
    // thumbOnColor: schedulerColor.main,
    // trackOnColor: schedulerColor.main
    '& .MuiSwitch-switchBase': {
      color: schedulerColor.main
    }
  }

});


class AddEditMYAV extends Component {
  render() {
    const { classes,
      myItem,
      columns,
      rolesData,
      errors,
      errorsHelper,
      timeArray,
      startTimeSelectedUnavailable,
      startTimeSelected,
      endTimeSelectedUnavailable,
      endTimeSelected
    } = this.props;
    return (
      <Grid
        container
        spacing={2}
        className={classes.root}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <ButtonGroup
            size="small" >
            <Button
              onClick={() => { this.props.handleChangePrefered(1) }}
              style={{
                borderRadius: '0px',
                border: '0px',
                borderBottom: myItem.is_prefered === 1 ?
                  `3px solid #AA076B` : "3px solid white",
                color: myItem.is_prefered === 1 ? "#AA076B" : "#969ba0"
              }}
            >Preferred</Button>
          </ButtonGroup>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
        >
          <Typography>
            {myItem.dayName}
          </Typography>
        </Grid>

        <Grid
          item
          sm={6}
          xs={12}
        >
          <MyAutocomplete
            // disabled={myItem.is_wholeday ? true : false}
            label="Start Time"
            error={errors.start_time}
            helperText={errors.start_time ? errorsHelper.start_time : ""}
            value={startTimeSelected}
            options={timeArray}
            getOptionLabel={(item) => item.name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeStartTimePrefered}
            disabled={myItem.is_wholeday === 1 ? true : false}
          />
        </Grid>

        <Grid
          item
          sm={6}
          xs={12}
        >
          <MyAutocomplete
            // disabled={myItem.is_wholeday ? true : false}
            label="End Time"
            error={errors.end_time}
            helperText={errors.end_time ? errorsHelper.end_time : ""}
            value={endTimeSelected}
            options={timeArray}
            getOptionLabel={(item) => item.name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeEndTimePrefered}
            disabled={myItem.is_wholeday === 1 ? true : false}
          />
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
        >
          <ButtonGroup
            size="small" >
            <Button
              onClick={() => { this.props.handleChangePrefered(0) }}
              style={{
                borderRadius: '0px', border: '0px',
                borderBottom: myItem.is_prefered === 0 ? `3px solid #AA076B` : "3px solid white",
                color: myItem.is_prefered === 0 ? "#AA076B" : "#969ba0"
              }} >Unavailable</Button>
          </ButtonGroup>
        </Grid>

        <Grid
          item
          sm={6}
          xs={12}
        >
          <MyAutocomplete
            // disabled={myItem.is_wholeday ? true : false}
            label="Start Time"
            error={errors.start_time}
            helperText={errors.start_time ? errorsHelper.start_time : ""}
            value={startTimeSelectedUnavailable}
            options={timeArray}
            getOptionLabel={(item) => item.name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeStartTimeUnavailable}
            disabled={myItem.is_wholeday === 1 ? true : false}
          />
        </Grid>

        <Grid
          item
          sm={6}
          xs={12}
        >
          <MyAutocomplete
            // disabled={myItem.is_wholeday ? true : false}
            label="End Time"
            error={errors.end_time}
            helperText={errors.end_time ? errorsHelper.end_time : ""}
            value={endTimeSelectedUnavailable}
            options={timeArray}
            getOptionLabel={(item) => item.name}
            renderInput={(params) =>
              <TextField {...params}
                variant="outlined"
              />}
            onFocus={this.props.handleFocus}
            onChange={this.props.handleChangeEndTimeUnavailable}
            disabled={myItem.is_wholeday === 1 ? true : false}
          />
        </Grid>


        <Grid
          item
          md={12}
          xs={12}
        >
          <FormControlLabel
            control={<Switch
              // style={{ color: schedulerColor.main }}
              className={classes.toggle}
              checked={myItem.is_wholeday}
              onChange={this.props.handleChangeWholeDay}
              name="is_wholeday" />}
            label="Whole Day"
          />
        </Grid>
{/* 
        <Grid item sm={12} xs={12}>
          <MySelectField
            label="Location"
            name="_id"
            onChange={this.props.handleChange}
            onFocus={this.props.handleFocus}
            value={myItem.lid}
            error={false}
            helperText=""
            placeholder="Please Select Location"
          >
            <option key={0} value={0} >
              All Locations
            </option>
            {this.props.locationData.map((item) => {
              return (
                <option key={item._id} value={item._id} >
                  {item.name}
                </option>
              )
            })}
          </MySelectField>
        </Grid> */}

        {/*


        <Grid item sm={12} xs={12}>
          <MyTextArea
            name="shift_notes"
            label="Notes"
            onChange={this.props.handleChangeItem}
            onFocus={this.props.handleFocus}
            value={myItem.shift_notes}
            type="text"
            error={errors.shift_notes}
            helperText={errors.shift_notes ? errorsHelper.shift_notes : ""}
            placeholder="Leave a note for your employee, lie the address of a job site , and they'll see it when they clock in."
            rows={5}
          />
        </Grid> */}

      </Grid>
    )
  }
}

export default withStyles(styles)(AddEditMYAV);