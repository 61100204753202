import { Suspense, useEffect } from "react";
import "./assets/css/index.css";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import { Routes } from "./routes";
import { Router, Switch, Redirect } from "react-router-dom";
import { history } from "./history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";

const allRoutes = (
  <Switch>
    {Routes.map((prop, key) => {
      return (
        <prop.access
          exact={true}
          path={prop.path}
          layout={prop.layout}
          component={prop.component}
          key={key}
        />
      );
    })}
    <Redirect from="*" to="/404" />
  </Switch>
);

function App() {
  useEffect(() => {
    display();
  }, []);

  function display() {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 3600;
    now.setTime(expireTime);
    document.cookie = "cookie=ok;expires=" + now.toUTCString() + ";path=/";
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            {allRoutes}
            <ToastContainer
              style={{ zIndex: "9999999" }}
              enableMultiContainer
              containerId={"TOP_RIGHT"}
              position={toast.POSITION.TOP_RIGHT}
            />
          </Suspense>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
