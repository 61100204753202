import { HEADER_TOKEN ,MoNGO_DB_API_URL} from "../../../../config";
import axios from 'axios';

export const timeOffServices = {
    fetchData,
    addData,
    editData,
    updateStatus,
    deleteData,
    fetchTeamData
}

function fetchData(data) {
    return axios.get(`${MoNGO_DB_API_URL}/v2/web/scheduler/timeoff/mytimeoff/${data.lid}?nPerPage=${data.rowsPerPage}&pageNumber=${data.pageNo}`, HEADER_TOKEN).then(response => response)
};

function fetchTeamData(data){
    return axios.get(`${MoNGO_DB_API_URL}/v2/web/scheduler/timeoff/teamtimeoff/${data.lid}?nPerPage=${data.rowsPerPage}&pageNumber=${data.pageNo}`, HEADER_TOKEN).then(response => response)
};


function addData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/v2/web/scheduler/timeoff/add`, data , HEADER_TOKEN).then(response => response)
};

function editData(data,id) {
    return axios.put(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/edit/${id}`,  data , HEADER_TOKEN).then(response => response)
};

function updateStatus(data,id) {
    return axios.put(`${MoNGO_DB_API_URL}/v2/web/scheduler/timeoff/update/status/${id}`, data , HEADER_TOKEN).then(response => response)
};

function deleteData(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/app/scheduler/timeoff/${id}`, HEADER_TOKEN).then(response => response)
};