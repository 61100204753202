import { TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";

import "./AddNoteMobile.scss";
import { GradingHeader } from "../../components";
import {
  addEarningComment,
  payUserEarning,
} from "../../../../pages/eLearning/Earning/modules/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "../../../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddNoteHeader from "../../components/TopNav/Earning/AddNoteHeader";
toast.configure();

const AddNoteMobile = (props) => {
  const location = useLocation();
  // const paymentDateAndTime = location.state?.paymentDateAndTime;
  const paymentId = location.state?.paymentId;
  const selectedLessons = location.state?.selectedRows;

  const dispatch = useDispatch();
  const addEarningCommentData = useSelector(
    (state) => state.eLearningEarning.addEarningCommentData
  );
  const addEarningCommentLoading = useSelector(
    (state) => state.eLearningEarning.addEarningCommentLoading
  );

  const payUserEarningData = useSelector(
    (state) => state.eLearningEarning.payUserEarningData
  );
  const payUserEarningLoading = useSelector(
    (state) => state.eLearningEarning.payUserEarningLoading
  );

  const [note, setNote] = useState("");

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleAddNote = async (comment) => {
    if (paymentId) {
      let data = {
        // uid: props.match.params.id,
        earning_comment: comment,
        paymentId: paymentId,
      };

      dispatch(addEarningComment(data));

      history.goBack();
    } else {
      let data = {
        uid: props.match.params.id,
        lessonDetails: selectedLessons,
        comment: comment,
      };

      dispatch(payUserEarning(data));
      history.goBack();
    }
  };

  // useEffect(() => {
  //   if (!payUserEarningLoading && payUserEarningData?.success === 1) {
  //     toast.success(payUserEarningData?.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //     history.goBack();
  //   }
  // }, [payUserEarningData, payUserEarningLoading]);

  return (
    <div className="add-note-root-container">
      <div className="contentContainer">
        <AddNoteHeader userName={"userName"} />

        <div className="text-area-container">
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Add notes..."
            value={note}
            className={"addNotesContainer"}
            onChange={handleNoteChange}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          />
        </div>
      </div>

      <div className={"btnContainer"}>
        <button
          disabled={!note}
          className={"addButton"}
          onClick={() => {
            note && handleAddNote(note);
          }}
        >
          Add
        </button>
        <button className={"cancelButton"} onClick={() => history.goBack()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddNoteMobile;
