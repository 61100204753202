import React, { useState, useEffect } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar,
  Typography,
  Grid
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { HEADER_TOKEN, MONGO_URL, RoutineColor } from '../../config'
import {
    DownArrow,
} from "../../icons";
import {
  Divider
} from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from "axios";

const useStyles = makeStyles(theme => ({
  link: {
    padding: '8px 8px',
    borderRadius: '5px',
    textDecoration: "none",
    marginBottom: '.25rem',
    boxShadow: "0.5px 0.5ppx 0.5ppx rgba(0, 0, 0, 0.25)",
  },
  linkActive: {
    backgroundColor: RoutineColor.main,
    boxShadow: "4px 2px 4px rgba(0, 0, 0, 0.25)",
    textDecoration: 'none',
  },
  schedulerActive: {
    backgroundColor: RoutineColor.main,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: RoutineColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff"
      },
      "& $linkIcon": {
        color: "#ffffff"
      },
      "& $linkTextCount": {
        color: "#ffffff"
      }
    }
  },
  schedulerActiveDrop: {
    background:"rgba(41, 92, 220, 0.12)",
    textDecoration: 'none',
    '&:hover': {
      background:"rgba(41, 92, 220, 0.12)",
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkTextActiveDrop": {
        color: "#295CDC"
      },
      "& $linkIcon": {
        color: "#295CDC"
      },
      "& $linkTextCount": {
        color: "#ffffff"
      }
    }
  },
  avatar: {
    height: 21,
    width: 21,
    marginRight: '10px'
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#B9B9B9",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    textDecoration: 'none',
  },
  linkTextActive: {
    fontWeight: 600,
    color: "#ffffff",
  },
  linkTextHidden: {
    opacity: 0,
    color:"#B9B9B9"
  },
  linkTextActiveDrop: {
    fontWeight: 600,
    color: "red",
  },
  linkTextHiddenDrop: {
    opacity: 0,
    color:"#295CDC"
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color")
  },
}));




export default function RoutineSidebarLink({ handleToggleRoutes, type, isOpen, list, childData, clickLink, path, icon, image, active_image, label, count, location, isSidebarOpen, activeProduct, todoId, tooltip ,divider}) {
  var classes = useStyles();
  var isLinkActive = path && (location.pathname === path);
  const query = new URLSearchParams(location.search);
  var newId = query.get('id')
  var [toolTip, setToolTip] = useState(false);

  const openTooltip = () => {
    if (!isSidebarOpen) {
      setToolTip(true)
    }
  }

  const closeTooltip = () => {
    setToolTip(false)
  }

  return (
    <>
      {type === "divider" ?
        <Divider style={{ margin: ".25rem 0" }} />
        :
        <>
          <Tooltip
            arrow
            placement="right"
            open={toolTip}
            onClose={closeTooltip}
            onOpen={openTooltip}
            title={<p className={classes.popoverTitle}>{label}</p>}
          >
            {childData && childData.length > 0 ?
            <>
                  <ListItem
                onClick={handleToggleRoutes}
                button
                component={path && Link}
                to={path}
                style={{marginBottom:"0px"}}
                classes={{
                  root: classnames(classes.link, {
                    [classes.schedulerActive]: isLinkActive  ||   isOpen ? true : false,
                  }),
                }}
                disableRipple
              >
                 <ListItemIcon
                  className={classnames(classes.linkIcon, {
                    [classes.linkIconActive]: isLinkActive
                  })}
                >
                  {image ?
                    <Avatar
                      variant="square"
                      className={classes.avatar}
                      src={isLinkActive ? active_image : image} />
                    : icon}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classnames(classes.linkText, {
                      [classes.linkTextActive]: isLinkActive,
                      [classes.linkTextHidden]: !isSidebarOpen,
                    }),
                  }}
                  primary={label}
                />
                <ListItemIcon style={{
                  minWidth: "20px",
                  marginRight:"-6px"
                }}>
                  {isOpen ?
                    <ExpandLessIcon sx={{ fontSize: "30px", minWidth: "30px", disArrowDropDown: "flex !important", justifyContent: "end !important",color:"#B9B9B9" }} />
                    :
                    <ExpandCircleDownOutlinedIcon  sx={{ fontSize: "30px", minWidth: "30px", disArrowDropDown: "flex !important", justifyContent: "end !important",color:"#B9B9B9" }}/>
                  
                  
                  }
                </ListItemIcon>
            
              </ListItem>
            </>
        
              :
              <>
                      {/* {list?.length ? list?.map((item, index)=>{
                        return(
                          <ListItem
                          key={index}
                          onClick={clickLink}
                          button
                          component={path && Link}
                          to={`/routine/list/${item._id}`}
                          classes={{
                            root: classnames(classes.link, {
                              [classes.schedulerActive]: isLinkActive ? true : false,
                            }),
                          }}
                          disableRipple
                        >
                          <ListItemIcon
                            className={classnames(classes.linkIcon, {
                              [classes.linkIconActive]: isLinkActive
                            })}
                          >
                            {image ?
                              <Avatar
                                variant="square"
                                className={classes.avatar}
                                src={isLinkActive ? active_image : image} />
                              : icon}
                          </ListItemIcon>
          
                          <ListItemText
                            classes={{
                              primary: classnames(classes.linkText, {
                                [classes.linkTextActive]: isLinkActive,
                                [classes.linkTextHidden]: !isSidebarOpen,
                              }),
                            }}
                            primary={item?.name}
                          />
                                
                        </ListItem>
                         )
                      }):""}  */}
              </>
      
            }
          </Tooltip>
          {childData && isOpen ?
          
            childData.map((item) => (   
              <Tooltip
                arrow
                placement="right"
                open={toolTip}
                onClose={closeTooltip}
                onOpen={openTooltip}
                // title={<p className={classes.popoverTitle}>{item.label}</p>}
              >
                <Grid style={{background:"rgba(41, 92, 220, 0.12)"}}>
                <ListItem
                  onClick={clickLink}
                  button
                  style={{marginBottom:"0px"}}
                  component={item.path && Link}
                  to={item.path}
                  classes={{
                    root: classnames(classes.link, {
                      [classes.schedulerActiveDrop]: location.pathname === item.path  && isLinkActive ? true : false,
                    }),
                  }}
                  disableRipple
                >
                  <ListItemIcon
                    className={classnames(classes.linkIcon, {
                      [classes.linkIconActive]: location.pathname === item.path
                    })}
                  >
                    {item.image ?
                      <Avatar
                        variant="square"
                        className={classes.avatar}
                        style={{
                          height: 20,
                          width: 20,
                          marginLeft: '20px',
                          marginRight: '5px'
                        }}
                        src={location.pathname === item.path ? item.active_image : item.image} />
                      : icon}
                  </ListItemIcon>

                  <ListItemText
                    classes={{
                      primary: classnames(classes.linkText, {
                        [classes.linkTextActive]: location.pathname === item.path &&  isOpen ,
                        [classes.linkTextHidden]: !isSidebarOpen,
                      }),
                    }}
                    primary={<Typography style={{ fontSize: "13px", lineHeight: "5px",
                    fontWeight:location.pathname === item.path &&  isOpen ? "800" : "400",
                    color:location.pathname === item.path &&  isOpen  ? "#295CDC" : "rgba(41, 92, 220, 0.7)" }}>
                      {item.label}
                    </Typography>}
                  />
                </ListItem>
                </Grid>
              </Tooltip>
            
            ))
            : label === "Task Library" && <hr/>}

            
        </>

      }
    </>
  );
}
