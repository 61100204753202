import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { MY_TOKEN } from '../config'
let isAuthenticated = false
if (MY_TOKEN) {
  isAuthenticated = true;
}

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  isAuthenticated === true
    ?
    <Route {...rest} render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )} />
    : <Redirect to='/' />
)

export default PrivateRoute;
