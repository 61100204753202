import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { LoadingData, MySearchBar } from '../../../components'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import { schedulerColor } from "../../../config";

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    ListItem: {
        padding: 0,
        marginBottom: '3px',
        borderBottom: "1px solid #7C7C7C"
    },
    avatarText: {
        textTransform: 'uppercase',
        height: '40px',
        width: '40px',
        fontWeight: 800,
        fontSize: '15px',
        backgroundColor: '#DFF2F7',
        color: theme.palette.primary.main
    }
});

class PayrollUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // payrollUsers: props.payrollAssignUsers,
            // payrollUsersTemp: props.payrollAssignUsers,
            payrollUserSelected: [],
            payrollUserNotSelected: [],
            payrollUsersTemp: [],
            searchUsers: ""
        }
    }

    componentDidMount() {
        var payrollUserSelected = this.props.payrollAssignUsers.filter((item) => item.isSelected)
        var payrollUserNotSelected = this.props.payrollAssignUsers.filter((item) => item.isSelected === 0)
        this.setState({
            searchUsers: "",
            payrollUserSelected,
            payrollUserNotSelected,
            payrollUsersTemp: this.props.payrollAssignUsers,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.payrollAssignUsers) !== JSON.stringify(this.props.payrollAssignUsers)) {
            var payrollUserSelected = this.props.payrollAssignUsers.filter((item) => item.isSelected)
            var payrollUserNotSelected = this.props.payrollAssignUsers.filter((item) => item.isSelected === 0)
            this.setState({
                searchUsers: "",
                payrollUserSelected: payrollUserSelected,
                payrollUserNotSelected: payrollUserNotSelected,
                payrollUsersTemp: this.props.payrollAssignUsers,
            })
        }
    }

    handleSearch = (target) => {
        var updatedList = this.state.payrollUsersTemp;
        if (target) {
            updatedList = updatedList.filter(function (item) {
                return (
                    item.user_name.toLowerCase().search(target.toLowerCase()) !== -1
                     || item.user_email.toLowerCase().search(target.toLowerCase()) !== -1
                );
            });
        }
            var payrollUserSelected = updatedList.filter((item) => item.isSelected)
            var payrollUserNotSelected = updatedList.filter((item) => item.isSelected === 0)
            this.setState({
                searchUsers: target,
                payrollUserSelected,
                payrollUserNotSelected
            });
        
    };

    clearSearch = () => {
        var payrollUserSelected = this.state.payrollUsersTemp.filter((item) => item.isSelected)
        var payrollUserNotSelected = this.state.payrollUsersTemp.filter((item) => item.isSelected === 0)
        this.setState({
            searchUsers: "",
            payrollUserSelected,
            payrollUserNotSelected
        });
    }

    render() {
        const { classes, payrollAssignUsersLoading } = this.props;
        const { payrollUserSelected, payrollUserNotSelected } = this.state;
        if (payrollAssignUsersLoading) return <LoadingData />
        return (

            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <Typography
                        align="center"
                        style={{ lineHeight: "5px", color: schedulerColor.main }}
                        variant="h4"
                    >{this.props.myItem.template_name}</Typography>
                </Grid>

                <Grid item sm={12}>
                    {this.props.myItem.payroll_type === 1 ?
                        <Typography style={{ fontSize: "14px", color: "#7C7C7C" }} align="center"
                            variant="body1">Fixed hourly rate ${this.props.myItem.rate}</Typography> :
                        this.props.myItem.payroll_type === 2 ?
                            <Typography style={{ fontSize: "14px", color: "#7C7C7C" }} align="center"
                                variant="body1">
                                First {this.props.myItem.hours_limit} Hours @ ${this.props.myItem.rate} and then ${this.props.myItem.overlimit_rate}
                            </Typography>
                            :
                            <Typography style={{ fontSize: "14px", color: "#7C7C7C" }} align="center"
                                variant="body1">
                                Always pay minimum {this.props.myItem.rate} hours @ ${this.props.myItem.rate}
                            </Typography>
                    }
                </Grid>

                <Grid item sm={12}>
                    <MySearchBar
                        clearSearch={this.clearSearch}
                        handleSearch={this.handleSearch}
                        value={this.state.searchUsers}
                        placeholder="Search user"
                    />
                </Grid>

                <Grid item sm={12}>
                    <Typography
                        style={{ color: schedulerColor.main }}
                        variant="h4"
                    >Assigned Users</Typography>
                </Grid>

                <Grid item sm={12}>
                    {/* {JSON.stringify(payrollUsers)}
                    if (value.isSelected) */}
                    <List dense disablePadding className={classes.root}>
                        {payrollUserSelected.map((value) => {
                            return (
                                <ListItem
                                    className={classes.ListItem}
                                    key={value} button>
                                    <ListItemIcon>
                                        <Checkbox
                                            style={{ color: schedulerColor.main }}
                                            onChange={(e) => { this.props.toggleAssignUser(e, value._id) }}
                                            color="primary"
                                            edge="end"
                                            checked={value.isSelected}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography
                                            variant="h5"
                                            style={{ fontWeight: "600" }}
                                        >{value.user_name}</Typography>}
                                        secondary={
                                            <>
                                                <Typography style={{ lineHeight: "15px" }} variant="body1">{value.user_email}</Typography>
                                                <Typography style={{ lineHeight: "15px" }} variant="body1">{value.role_name}</Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>



                <Grid item sm={12}>
                    <Typography
                        style={{ color: schedulerColor.main }}
                        variant="h4"
                    >Unassigned Users</Typography>
                </Grid>

                <Grid item sm={12}>
                    <List dense disablePadding className={classes.root}>
                        {payrollUserNotSelected.map((value) => {
                            return (
                                <ListItem
                                    className={classes.ListItem}
                                    key={value} button>
                                    <ListItemIcon>
                                        <Checkbox
                                            style={{ color: schedulerColor.main }}
                                            onChange={(e) => { this.props.toggleAssignUser(e, value._id) }}
                                            color="primary"
                                            edge="end"
                                            checked={value.isSelected}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography
                                            style={{ fontWeight: "600" }}
                                            variant="h5"
                                        >{value.user_name}</Typography>}
                                        secondary={
                                            <>
                                                <Typography style={{ lineHeight: "15px" }} variant="body1">{value.user_email}</Typography>
                                                <Typography style={{ lineHeight: "15px" }} variant="body1">{value.role_name}</Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(PayrollUsers);
