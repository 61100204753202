import * as React from "react";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { themeColor } from "../../../../pages/v5/HR/Evaluation/Form/constant";
import { hexToRgb } from "../hexToRgb";

export function DaySelector() {
  const [alignment, setAlignment] = React.useState("left");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const children = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Stack spacing={2} alignItems="center">
      <ToggleButtonGroup size="large" {...control} aria-label="Large sizes">
        {children.map((day, index) => (
          <ToggleButton
            key={day}
            value={day}
            style={{
              backgroundColor:
                alignment === day
                  ? `rgba(${hexToRgb(themeColor)}, 0.3)`
                  : "transparent",
              borderRight:
                index < children.length - 1 ? "none" : "1px solid #ccc",
              color: alignment === day ? themeColor : "rgba(0, 0, 0, 0.7)",
            }}
          >
            {day}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
