import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@mui/icons-material/Edit';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
    moreMenu: {
        background: "white",
        '&:hover': {
            background: "white"
        }
    },
}));

const DepartmentMoreMenuDropdown = (props) => {
    const classes = useStyles();
    const { onHandleOpenEditDepartModal, onHandleOpenAssignHelpdesk, onHandleOpenDeleteModal, handleClose, anchorEl } = props;

    return (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
            <MenuItem onClick={() => onHandleOpenEditDepartModal("isEdit")} className={classes.moreMenu}>
                <ListItemIcon>
                    <EditIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Edit Role" />
            </MenuItem>
            <MenuItem onClick={onHandleOpenDeleteModal} className={classes.moreMenu}>
                <ListItemIcon>
                    <DeleteIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Delete Role" />
            </MenuItem>
        </Menu>
    )
};
export default DepartmentMoreMenuDropdown;