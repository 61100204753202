import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const hierarachyServices = {
  addHeirarachyChart,
  fetchUserData,
  fetchHeirarachyChartData,
  editHeirarachyChart,
  deleteHeirarachyChart,
  fetchRoleData,
};

// function fetchUserData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/users`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchUserData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/users`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchRoleData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/role/mongo`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchRoleData(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/web/hr/hierarchy/role/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchHeirarachyChartData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/hierarchy`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchHeirarachyChartData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/web/hr/hierarchy`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addHeirarachyChart(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/hierarchy`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function addHeirarachyChart(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/web/hr/hierarchy/create`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editHeirarachyChart(data) {
//   return axios
//     .put(`${MoNGO_DB_API_URL}/hierarchy/${data._id}`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function editHeirarachyChart(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hierarchy/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteHeirarachyChart(id) {
//   return axios
//     .delete(`${MoNGO_DB_API_URL}/hierarchy/${id}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function deleteHeirarachyChart(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/web/hr/hierarchy/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
