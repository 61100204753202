import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingData } from '../../../components'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    ListItem: {
        padding: 0,
        marginBottom: '3px',
        borderBottom: "1px solid #7C7C7C"
    },
    avatarText: {
        textTransform: 'uppercase',
        height: '40px',
        width: '40px',
        fontWeight: 800,
        fontSize: '15px',
        backgroundColor: '#DFF2F7',
        color: theme.palette.primary.main
    }
}));

export default function CheckboxListSecondary(props) {
    const classes = useStyles();
    return (
        props.noPayrollUsersLoading ?
            <Grid container spacing={0}>
                <Grid item sm={12}>
                    <LoadingData />
                </Grid>
            </Grid>
            :
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <List dense disablePadding className={classes.root}>
                        {props.noPayrollUsers.map((value) => {
                            return (
                                <ListItem
                                    className={classes.ListItem}
                                    key={value} button>
                                    <ListItemText
                                        primary={<Typography
                                            variant="h5"
                                            style={{ fontWeight: "600" }}
                                        >{value.user_name}</Typography>}
                                        secondary={
                                            <>
                                                <Typography style={{ lineHeight: "15px" }} variant="body1">{value.user_email}</Typography>
                                                <Typography style={{ lineHeight: "15px" }} variant="body1">{value.role_name}</Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
    );
}