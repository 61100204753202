import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField,Button,Box,Typography } from '@material-ui/core';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = (theme) => ({
        label: {
                fontSize: '18px',
                fontWeight: 500
        },
        input: {
                fontSize: '16px',
                fontWeight: 500,
                backgroundColor: '#f4f2f26b !important',
                borderRadius: 5,
                marginBottom: 0
        },
        labelColor: {
                color: 'gray'
        }
});


class MyIncreDecre extends Component {
        render() {
                const { classes } = this.props;
                return (
                    <>
                      <Box
                        style={{position:'relative' ,width:this.props.incdcrWidth}}
                      >
                        <Typography style={{position:'absolute',top:'-18px',left:'18%'}}>{this.props.label}</Typography>
                                        <TextField
                                                style={{width:this.props.increWidth}}
                                                size="small"
                                                onFocus={this.props.onFocus}
                                                name={this.props.name}
                                                value={this.props.value}
                                                variant="outlined"
                                                error={this.props.error}
                                                helperText={this.props.helperText}
                                                inputlabelprops={{ classes: { root: classes.labelColor } }}
                                                className={classes.input}
                                                // onFocus={(event) => { this.props.onFocus(event) }}
                                                // onChange={(event, newValue) => { this.props.onChange(newValue, null, '') }}
                                                />
                                               
                      <ArrowDropUpIcon onClick= {() => {console.log('hellbent up')}}  style={{position:'absolute',right:'0'}} />
                       
                        <ArrowDropDownIcon onClick= {() => {console.log('hellbent down')}} style={{position:'absolute',right:'0',top: "13px"}} />
                                                </Box>
                    
                                </>
                )
        }
}

export default withStyles(styles)(MyIncreDecre);