import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const MyAvailabilityService = {
  fetchMyAvailabilityData,
  fetchTeamAvaData,
  addAvailability,
  editAvailability,
  approveAvailability,
  deleteAvailability,
  addMyAvailability,
  submitAvailability,
};

function fetchMyAvailabilityData() {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/new/avaliblity/getrecord`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchTeamAvaData(id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/scheduler/myavailability//new/avaliblity/team/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addMyAvailability(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/new/avaliblity/my`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addAvailability(data) {
  data.status = 0;
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/existing/new`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function approveAvailability(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/teamavailability/approve/${data.id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editAvailability(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/${data.id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteAvailability(id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/delete/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
function submitAvailability(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/scheduler/myavailability/new/avaliblity/addforday`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}
