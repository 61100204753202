import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from "../../../config";
import moment from "moment";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  box: {
    padding: "2rem 1rem",
    backgroundColor: "#f6f6f6",
  },
  publishButton: {
    marginTop: "10rem",
    padding: "15px",
    backgroundImage: schedulerColor.main,
    color: "#ffffff",
  },
});

class AddEditShifts extends Component {
  render() {
    moment.locale("en");
    const {
      classes,
      publishStatus,
      publishNotificationsList,
      totalUnpublishedShifts,
      totalUnpublishedScheduleHours,
    } = this.props;
    return (
      <Grid container spacing={4} className={classes.root}>
        <Grid item sm={12} xs={12}>
          <Grid className={classes.box} container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Typography variant="h4" style={{ marginBottom: "20px" }}>
                Summary: {moment(this.props.publishStartDate).format("DD MMM")}{" "}
                - {moment(this.props.publishEndDate).format("DD MMM")}
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12}>
              <Typography display="inline" variant="h5">
                Total Shifts :
              </Typography>
              <Typography
                style={{ float: "right" }}
                display="inline"
                variant="h5"
              >
                {totalUnpublishedShifts}
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12}>
              <Typography display="inline" variant="h5">
                Total Hours :
              </Typography>
              <Typography
                style={{ float: "right" }}
                display="inline"
                variant="h5"
              >
                {totalUnpublishedScheduleHours}&nbsp;Hrs
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              onChange={this.props.handleChange}
              value={parseInt(publishStatus)}
              name="publishStatus"
            >
              {publishNotificationsList.map((item) => {
                return (
                  <FormControlLabel
                    value={item.status}
                    control={<Radio style={{ color: schedulerColor.main }} />}
                    label={item.title}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>

        {totalUnpublishedShifts ? (
          <Grid item sm={12} xs={12}>
            <Button
              disabled={this.props.isPublishDisabled}
              fullWidth
              className={classes.publishButton}
              variant="contained"
              onClick={this.props.publishShifts}
            >
              Publish
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(AddEditShifts);
