import { eLearningColor } from "../../../../config";
import { Dropdown } from "../../../../utilities/v5/common";

export const themeColor = eLearningColor.main;

export let usersDataArray = [
  {
    id: 1,
    Name: "Zunaira Irfan",
    Lessons: "20/25",
    PaidAmount: "123$",
    UnpaidAmount: "23$",
    Date: "23 Mar 2023",
    Status: "Pay Now",
  },
  {
    id: 2,
    Name: "Mishal Irfan",
    Lessons: "20/24",
    PaidAmount: "123$",
    UnpaidAmount: "0$",
    Date: "23 Mar 2023",
    Status: "Paid",
  },
  {
    id: 3,
    Name: "Kamran",
    Lessons: "15/25",
    PaidAmount: "123$",
    UnpaidAmount: "0$",
    Date: "23 Mar 2023",
    Status: "Paid",
  },
  {
    id: 4,
    Name: "Asad Najam",
    Lessons: "16/24",
    PaidAmount: "123$",
    UnpaidAmount: "55$",
    Date: "23 Mar 2023",
    Status: "Pay Now",
  },
];

export const tableBodyMyDataArray = [
  {
    id: 1,
    Name: "Lesson1",
    Point: "5",
    Amount: "123$",
    Duration: "2 Hour",
    Date: "23 Mar 2023",
    Status: "Pending",
  },
  {
    id: 2,
    Name: "Lesson 2",
    Point: "5",
    Amount: "34$",
    Duration: "2 Hour",
    Date: "23 Mar 2023",
    Status: "Pending",
  },
  {
    id: 3,
    Name: "Lesson3",
    Point: "15",
    Amount: "",
    Duration: "2 Hour",
    Date: "24 Mar 2023",
    Status: "Pending",
  },
  {
    id: 4,
    Name: "Lesson4",
    Point: "",
    Amount: "55$",
    Duration: "3 Hour",
    Date: "25 Mar 2023",
    Status: "Paid",
  },
];

export const headRowTeamTabItems = [
  {
    id: 1,
    heading: "Employee",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        buttonText={"Employee"}
        rounded={"6px"}
        textSize={"12px"}
      />
    ),
  },
  {
    id: 2,
    heading: "Lessons",
    dropdown: "",
  },
  {
    id: 3,
    heading: "Paid Amount",
    dropdown: "",
  },
  {
    id: 4,
    heading: "Unpaid",
    dropdown: "",
  },
  {
    id: 5,
    heading: "Date",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        rounded={"6px"}
        buttonText={"Date"}
        textSize={"12px"}
      />
    ),
  },
  {
    id: 6,
    heading: "Status",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        rounded={"6px"}
        buttonText={"Status"}
        textSize={"12px"}
      />
    ),
  },
];

export const headRowMyTabItems = [
  {
    id: 1,
    heading: "Lesson name",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        buttonText={"Lesson name"}
        rounded={"6px"}
        textSize={"12px"}
      />
    ),
  },
  {
    id: 2,
    heading: "Point",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        rounded={"6px"}
        buttonText={"Point"}
        textSize={"12px"}
      />
    ),
  },
  {
    id: 3,
    heading: "Amount",
    dropdown: "",
  },
  {
    id: 4,
    heading: "Duration",
    dropdown: "",
  },
  {
    id: 5,
    heading: "Date",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        rounded={"6px"}
        buttonText={"Date"}
        textSize={"12px"}
      />
    ),
  },
  {
    id: 6,
    heading: "Status",
    dropdown: (
      <Dropdown
        size={{ width: "100px", height: "19px" }}
        bgColor="white"
        arrowColor={"#1B3F8F"}
        textColor={"#1B3F8F"}
        borderColor={"#1B3F8F33"}
        rounded={"6px"}
        buttonText={"Status"}
        textSize={"12px"}
      />
    ),
  },
];

// styles
export const tableHeadColumn = {
  color: "#1B3F8F",
  fontSize: "18px",
  fontWeight: "400",
};

export const tableBodyColumn = {
  ...tableHeadColumn,
  color: "#848484",
  fontWeight: "400",
  fontSize: "16px",
};
