import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MobileLinearLoader } from "../../../utilities";
import { MdFilterList } from "react-icons/md";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  lessonMobileCategoriesRoot: {
    width: "100%",
    height: "50px",
    boxShadow: "5px 5px 21px 4px #00000014",
    display: "flex",
    background: "white",
    gap: "20px",
    padding: "0px 20px 0px 20px",
    position: "relative",
    alignItems: "center",
  },
  lessonMobileCategoriesIcon: {
    fontSize: "23px",
    color: "#1B3F8F",
  },
  lessonMobileCategoriesLoaderContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
    alignItems: "center",
    height: "100%",
    width: "80vw",
  },
  lessonMobileCategoriesTabsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "20px",
    overflowX: "auto",
    height: "50px",
  },
  lessonMobileCategoriesTab: {
    fontSize: "14px",
    lineHeight: "17.17px",
    fontWeight: "400",
    cursor: "pointer",
    whiteSpace: "nowrap",
    color: "#21212199",
  },
  lessonMobileCategoriesActiveTab: {
    fontWeight: "700",
    color: "#1B3F8F",
  },
}));

const LessonCategories = ({ lessonLoading, tabID, handleTabChange, tabs }) => {
  const classes = useStyles();

  return (
    <Box className={classes.lessonMobileCategoriesRoot}>
      <Box className={classes.lessonMobileCategoriesIcon}>
        <MdFilterList />
      </Box>
      {lessonLoading ? (
        <Box className={classes.lessonMobileCategoriesLoaderContainer}>
          <MobileLinearLoader />
        </Box>
      ) : (
        <Box
          className={`${classes.lessonMobileCategoriesTabsContainer} scroll`}
        >
          <Typography
            className={`${classes.lessonMobileCategoriesTab} ${
              tabID === "a1" ? classes.lessonMobileCategoriesActiveTab : ""
            }`}
            onClick={(e) => handleTabChange(e, "a1")}
          >
            All
          </Typography>
          {tabs?.map((tab, index) => (
            <Typography
              key={tab.ecid._id}
              className={`${classes.lessonMobileCategoriesTab} ${
                tabID === tab.ecid._id
                  ? classes.lessonMobileCategoriesActiveTab
                  : ""
              }`}
              onClick={(e) => handleTabChange(e, tab.ecid._id)}
            >
              {tab.ecid.category_name}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default LessonCategories;
