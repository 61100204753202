import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Checkbox,
    FormControlLabel,
    Button,
    Grid
}
    from '@material-ui/core';
import { schedulerColor } from "../../../config"

const styles = (theme) => ({
    button: {
        background: schedulerColor.main,
        borderRadius: 8,
        color: "white",
        padding: 10,
        marginLeft: "auto"
    },
});

class TimeTracking extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid
                justifyContent="space-between"
                container
            >
                {/* <FormControlLabel
                    control={
                        <Checkbox
                            value={this.props.applyToAll}
                            onChange={this.props.handleApplyToAll}
                        />
                    }
                    label="Apply For All Locations"
                /> */}
                <Button
                    className={classes.button}
                    onClick={this.props.handleSave}
                >
                    Save Changes
                </Button>
            </Grid>
        )
    }
}

export default withStyles(styles)(TimeTracking);