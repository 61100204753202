import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function BasicPopover(props) {

  return (
    <div>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ 
          '& .MuiPaper-root': {
            width: '118px', // Set your desired width here
            background:"#eef2fb"
          },
        }}
      >
        {props.permissionsData?.common_users_edit ?
        <Typography sx={{ p: 2 ,padding:"5px 13px 7px 14px",fontSize:"0.8rem"}} onClick={props.handleClickEdit}>Edit</Typography> :""}
          {props.permissionsData?.common_users_delete ?
        <Typography sx={{ p: 2 ,padding:"5px 13px 7px 14px",fontSize:"0.8rem"}} onClick={props.handleClickDelete}>Delete</Typography>:""}
      </Popover>
    </div>
  );
}