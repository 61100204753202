import {
  SET_MYCORRECTIONREQ_LOADING,
    SET_MYCORRECTIONREQ,
    ADD_CORRECTIONREQ,
    DELETE_MY_CORRECTION_REQUEST
  } from "./constants";
  import { alert, invalidToken } from '../../../../utilities';
  import { usersServices } from './services'
  import { COOKIE_MAX_AGE } from '../../../../config'
  import Cookies from 'universal-cookie';
  import { history } from "../../../../history"
  const cookies = new Cookies();
  

  export function fetchData(id) {
    return function (dispatch) {
        dispatch({
          type: SET_MYCORRECTIONREQ_LOADING,
          payload: true
        });
      usersServices.fetchData(id)
        .then(response => {
          if (response.data.success === 2) {
            invalidToken(response.data)
          } else {
            dispatch({
              type: SET_MYCORRECTIONREQ,
              payload: response.data

            });
          }
        });
    };
  }

  export function deleteData(_id) {
    return function (dispatch) {
      usersServices.deleteData(_id)
        .then(response => {
          if (response.data.success === 2) {
            invalidToken(response.data.message)
          } else {
            alert.success(response.data.message)
            dispatch({
              type: DELETE_MY_CORRECTION_REQUEST,
              payload: _id
            });
          }
        });
    };
  }
  
 

