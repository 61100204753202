import React, { createContext, useContext, useState } from "react";

const MobileTeamSearchContext = createContext();

export const TeamFeedbackMobileSearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [formRatingTitle, setFormRatingTitle] = useState("");
  const [formRatingId, setFormRatingId] = useState("");
  const [review, setReview] = useState("");
  const [currentReview, setCurrentReviewIndex] = useState("")
  const [nextButton, setNextButton] = useState("")
  const [previousButton, setPreviousButton] = useState("")
  const [confirmButton, setConfirmButton] = useState("")
  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const resetSearchQuery = () => {
    setSearchQuery("");
  };

  const updateFormRatingTitle = (value) => {
    setFormRatingTitle(value);
  };

  
  const updateFormRatingId = (value) => {
    setFormRatingId(value);
  };

  const ReviewRateTeam = (value) =>{
    setReview(value)
  }

  const currentReviewRateTeam = (value) =>{
    setCurrentReviewIndex(value)
  }

  const updateNextButton = (value) =>{
    setNextButton(value)
  }

  const UpdatePreviousButton = (value) =>{
    setPreviousButton(value)
  }

  const UpdateconfirmButton = (value) =>{
    setConfirmButton(value)
  }


  return (
    <MobileTeamSearchContext.Provider
      value={{
        searchQuery,
        updateSearchQuery,
        resetSearchQuery,
        formRatingTitle,
        updateFormRatingTitle,
        updateFormRatingId,
        formRatingId,
        ReviewRateTeam,
        review,
        currentReviewRateTeam,
        currentReview,
        updateNextButton,
        nextButton,
        UpdatePreviousButton,
        previousButton,
        UpdateconfirmButton,
        confirmButton
      }}
    >
      {children}
    </MobileTeamSearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(MobileTeamSearchContext);
};
