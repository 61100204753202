import {
  GET_PROGRESS_DATA,
  SET_PROGRESS_LOADING,
  GET_MY_LESSON_NAMES,
  GET_MY_LESSON_NAMES_LOADING,
} from "./constants";

import { invalidToken } from "../../../../utilities";
import { progressServices } from "./services";

export function fetchData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_PROGRESS_LOADING,
      payload: true,
    });

    progressServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_PROGRESS_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function getMyLessonNames() {
  return function (dispatch) {
    dispatch({
      type: GET_MY_LESSON_NAMES_LOADING,
      payload: true,
    });
    progressServices.getMyLessonNames().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_MY_LESSON_NAMES,
          payload: response.data.lesson_list,
        });
      }
    });
  };
}
