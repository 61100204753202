import { HEADER_TOKEN,MoNGO_DB_API_URL } from "../../../../config";
import axios from 'axios';

export const timecardServices = {
    fetchData
}

function fetchData(lid) {
    var data = {
        lid: lid
    }
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timecard`, { data }, HEADER_TOKEN).then(response => response)
};

