import {
  SET_ROLES_LOADING,
  SET_ROLES,
  ADD_ROLES,
  EDIT_ROLES,
  DELETE_ROLES,
  SET_USER_PERMISSIONS,
  SET_USER_PERMISSIONS_LOADING
} from "./constants";

const initialState = {
  isLoading: true,
  rolesData: [],
  productsColorsData : [],
  permissionsData: {},
  locationTimezone: [],
  totalPage:1,
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ROLES_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: payload.result,
        totalPage:payload.totalPages,
      };
    case SET_USER_PERMISSIONS_LOADING:
      return {
        ...state,
        permissionsLoading: payload
      };
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissionsLoading: false,
        permissionsData: payload.permissionsData,
        productsColorsData: payload.ProductColor,
        locationTimezone: payload.locationData
      };
    case ADD_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: [...state.rolesData, payload]
      }
    case EDIT_ROLES:
      var myPermissionsData = state.permissionsData._id === payload._id ? payload : state.permissionsData
      return {
        ...state,
        isLoading: false,
        rolesData: state.rolesData.map((item) => item._id === payload._id ? { ...payload } : item),
        permissionsData: myPermissionsData
      }
    case DELETE_ROLES:
      return {
        ...state,
        isLoading: false,
        rolesData: state.rolesData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;