import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const styles = (theme) => ({
        menu: {
                minWidth: '200px',
                borderRadius: '5px',
                backgroundColor: "#fafafa",
        },
        MenuItem: {
                padding: "0px",
                '&:hover': {
                        boxShadow: '4px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                        backgroundColor: theme.palette.primary.secondary,
                }
        },
        Typography: {
                padding: "5px 15px",
                border: "1px solid #f2f2f2",
                width: "100%",
                fontSize: '15px',
                lineHeight: '2rem'
        }
})

class LocationMenus extends Component {
        render() {
                const { classes, locationAnchor, locationData, scheduler_lid } = this.props;
                return (
                        <>
                                <Menu
                                        MenuListProps={{ disablePadding: true }}
                                        anchorEl={locationAnchor}
                                        anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                        }}
                                        style={{height:"420px"}}
                                        getContentAnchorEl={null}
                                        open={Boolean(locationAnchor)}
                                        onClose={(e) => { this.props.handleLocationClose("") }}
                                        PopoverClasses={{
                                                paper: classes.menu
                                        }}
                                >
                                        {/* {locationData.map((location, index) => {
                                                if (scheduler_lid === location._id)
                                                        return (
                                                                <MenuItem
                                                                        disabled={true}
                                                                        key={index}
                                                                        onClick={() => { this.props.handleLocationClose(location._id) }}
                                                                        className={classes.MenuItem}
                                                                >
                                                                        <Typography
                                                                                style={{
                                                                                        backgroundColor: "red",
                                                                                        fontWeight: "bold"
                                                                                }}

                                                                                className={classes.Typography}
                                                                                color="textPrimary"
                                                                                variant="inherit"
                                                                        >
                                                                                {location.name}
                                                                        </Typography>
                                                                </MenuItem>
                                                        )
                                        })} */}
                                        {locationData.map((location, index) => {
                                                        return (
                                                                <MenuItem
                                                                        disabled={scheduler_lid === location._id ? true:false}
                                                                        onClick={() => { this.props.handleLocationClose(location._id) }}
                                                                        className={classes.MenuItem}
                                                                >
                                                                        <Typography
                                                                                style={{
                                                                                        backgroundColor: scheduler_lid === location._id ? "#005F73" : "#fcfcfc",
                                                                                        fontWeight: "normal",
                                                                                        color:scheduler_lid === location._id ? "white": "black"
                                                                                }}
                                                                                className={classes.Typography}
                                                                                color="textPrimary"
                                                                                variant="inherit"
                                                                        >
                                                                                {location.name}
                                                                        </Typography>
                                                                </MenuItem>
                                                        )
                                        })}
                                </Menu>
                        </>
                )
        }
}

export default withStyles(styles)(LocationMenus);