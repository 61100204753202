export const SET_TEAMFEEDBACK_LOADING = "SET_TEAMFEEDBACK_LOADING";
export const SET_TEAM_FEEDBACK_RATE_TEAM = "SET_TEAM_FEEDBACK_RATE_TEAM";
export const SET_TEAM_FEEDBACK_RATE_TEAM_LOADING =
  "SET_TEAM_FEEDBACK_RATE_TEAM_LOADING";
export const SET_TEAM_FEEDBACK_RATE_TEAM_FORM_PAGE =
  "SET_TEAM_FEEDBACK_RATE_TEAM_FORM_PAGE";
export const SET_TEAM_FEEDBACK_RATE_TEAM_FORM_DETAIL_PAGE =
  "SET_TEAM_FEEDBACK_RATE_TEAM_FORM_DETAIL_PAGE";
export const SET_TEAM_FEEDBACK_ALL_LOCATION = "SET_TEAM_FEEDBACK_ALL_LOCATION";
export const SET_TEAM_FEEDBACK_RATING_DATA = "SET_TEAM_FEEDBACK_RATING_DATA";
export const SET_TEAM_FEEDBACK_FORM_PAGE = "SET_TEAM_FEEDBACK_FORM_PAGE";
export const SET_TEAM_FEEDBACK_FORM_DETAIL_PAGE =
  "SET_TEAM_FEEDBACK_FORM_DETAIL_PAGE";
export const SET_TEAM_FEEDBACK_MY_RATING = "SET_TEAM_FEEDBACK_MY_RATING";
export const SET_TEAM_FEEDBACK_MY_RATING_SCORE =
  "SET_TEAM_FEEDBACK_MY_RATING_SCORE";
export const SET_TEAM_FEEDBACK_LOCATION_DASHBOARD_DATA =
  "SET_TEAM_FEEDBACK_LOCATION_DASHBOARD_DATA";
export const SET_TEAM_FEEDBACK_ROLE_DASHBOARD_DATA =
  "SET_TEAM_FEEDBACK_ROLE_DASHBOARD_DATA";
export const SET_TEAM_FEEDBACK_FORM_DASHBOARD_DATA =
  "SET_TEAM_FEEDBACK_FORM_DASHBOARD_DATA";
export const SET_TEAM_FEEDBACK_USER_DASHBOARD_DATA =
  "SET_TEAM_FEEDBACK_USER_DASHBOARD_DATA";
