//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const styles = (theme) => ({
        label: {
                fontSize: '18px',
                fontWeight: 500
        },
        input: {
                fontSize: '16px',
                fontWeight: 500,
                backgroundColor: '#f4f2f26b !important',
                borderRadius: 5,
                marginBottom: 0
        },
        labelColor: {
                color: 'gray'
        }
});


class MyAutocomplete extends Component {
        render() {
                const { classes } = this.props;
                return (
                        <Autocomplete
                                disabled={this.props.disabled === undefined ? false : this.props.disabled}
                                value={this.props.value}
                                options={this.props.options}
                                getOptionLabel={this.props.getOptionLabel}
                                renderInput={(params) =>
                                        <TextField {...params}
                                                size="small"
                                                onFocus={this.props.onFocus}
                                                name={this.props.name}
                                                label={this.props.label}
                                                variant="outlined"
                                                error={this.props.error}
                                                helperText={this.props.helperText}
                                                inputlabelprops={{ classes: { root: classes.labelColor } }}
                                                className={classes.input}
                                        />}
                                onFocus={(event) => { this.props.onFocus(event) }}
                                onChange={(event, newValue) => { this.props.onChange(newValue, null, '') }}
                        />
                )
        }
}

export default withStyles(styles)(MyAutocomplete);