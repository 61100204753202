import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box, Typography } from "@material-ui/core";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, progressColor, value, height, bgColor }) => ({
    height: height || 25,
    width: "100%",
    borderRadius: 18,
    border: `1px solid ${progressColor}`,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: bgColor || "#FFFFFF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 18,
      backgroundColor: progressColor,
    },
  })
);

export function ProgressBar({
  themeColor,
  progressValue,
  height,
  fontSize,
  percentageFlag,
  bgColor,
}) {
  const isSmallBar = progressValue < 10;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: "100%",
      }}
    >
      <BorderLinearProgress
        variant="determinate"
        value={progressValue}
        progressColor={themeColor}
        height={height}
        bgColor={bgColor || "white"}
        // sx={{
        //   background: bgColor || "white",
        //   "& .MuiLinearProgress-bar": {
        //     backgroundColor: themeColor,
        //   },
        // }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: isSmallBar ? "100%" : `${progressValue}%`,
        }}
      >
        {!percentageFlag && (
          <Typography
            variant="caption"
            style={{
              color: isSmallBar ? "#000000" : "#FFFFFF",
              fontSize: fontSize || "16px",
            }}
          >
            {`${Math.round(progressValue)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
