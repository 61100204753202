import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
// import { themeColor } from "../../Pages/Payment/constant";
import "./AddNotesModal.scss"; // Import the SCSS file

export const AddNotesModal = ({ open, handleCloseAddNote, handleAddNote }) => {
  const [note, setNote] = useState("");

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseAddNote}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="modal-container"
      >
        <Box className="add-notes-modal">
          <div className="header">
            <p>Note</p>
          </div>

          <div className="add-notes-content">
            <Typography
              sx={{ fontSize: "20px", fontWeight: "500", color: "#000000CC" }}
            >
              Add Notes
            </Typography>

            <textarea
              type="text"
              className="input-field"
              onChange={handleNoteChange}
            />
          </div>

          <div className="button-container">
            <button
              disabled={!note}
              className="add-notes-button"
              onClick={() => {
                note && handleAddNote(note);
              }}
            >
              Add Notes
            </button>
            <button className="cancel-button" onClick={handleCloseAddNote}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
