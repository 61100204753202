import { Button } from "@mui/material";

export const LoadMore = ({ handlePageChange }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <Button
          style={{
            background: "#303134",
            boxSizing: "border-box",
            cursor: "pointer",
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            width: "300px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderRadius: "18px",
            height: "36px",
            color: "#ffffff",
            textTransform: "initial",
          }}
          onClick={handlePageChange}
        >
          More results
        </Button>
      </div>
    </div>
  );
};
