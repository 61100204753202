//TextField
import React, { Component } from "react";
import SearchBar from "material-ui-search-bar";
import { withStyles } from "@material-ui/core/styles";
import {
        IconButton,
}
        from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Close';
import SearchIcon from '@mui/icons-material/Search';
const styles = (theme) => ({
        SearchBar: {
                height: '40px',
                marginTop: '6px',
                marginBottom:20
        },
        icon: {
                color: 'red',
        }
});

class MySearchRoleBar extends Component {
        render() {
                const { classes } = this.props;
                return (
                        <SearchBar
                                placeholder={this.props.placeholder}
                                className={classes.SearchBar}
                                // searchIcon={
                                //         <IconButton size="small"
                                //         >
                                //                 <SearchIcon />
                                //         </IconButton>
                                // }
                                searchIcon={
                                    <SearchIcon style={{ color: 'grey', height: '25px'}} />
                                }
                                closeIcon={
                                        <IconButton size="small"
                                        >
                                                <ClearIcon
                                                        onClick={this.props.clearSearch}
                                                />
                                        </IconButton>
                                }
                                value={this.props.value}
                                onChange={(value) => { this.props.handleSearch(value) }}
                        />
                )
        }
}
export default withStyles(styles)(MySearchRoleBar);