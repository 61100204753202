//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { TeamFeedbackColor } from "../config";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },
  input: {
    fontSize: "16px",
    fontWeight: 500,
    borderRadius: 5,
    marginBottom: 0,
    backgroundColor: "white",
  },
  labelColor: {
    color: "gray",
  },
  addroles: {
    width: "65%",
    marginTop: "2%",
  },
  teamFeedback: {
    width: "100%",
    marginTop: "2%",
  },
  addtitle: {
    width: "250%",
  },
  addTeamFeedback: {
    width: "100%",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: TeamFeedbackColor.main, // Change to your desired focus color
      },
    },
    "& .MuiFormLabel-root": {
      color: TeamFeedbackColor.main, // Change this to your desired label color
    },
  },

  adddesc: {
    height: "40%",
    width: "250%",
    marginTop: "3.5%",
  },
});

class MyTextField extends Component {
  render() {
    const { classes, styledemo } = this.props;

    return (
      <div>
        <TextField
          disabled={this.props.disabled ? this.props.disabled : false}
          required={this.props.required ? this.props.required : false}
          label={this.props.label}
          name={this.props.name}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          value={this.props.value}
          type={this.props.type}
          error={this.props.error}
          helperText={this.props.helperText}
          placeholder={this.props.placeholder}
          variant="outlined"
          size={this.props.size === undefined ? "small" : this.props.size}
          className={
            (this.props?.product === "TeamFeedback" ? classes.textField : "") +
            " " +
            (styledemo === 1
              ? classes.addroles
              : styledemo === 2
              ? classes.addtitle
              : styledemo === 7
              ? classes.addTeamFeedback
              :styledemo === 8 
              ? classes.teamFeedback
              : classes.adddesc)
          }
          inputlabelprops={{ classes: { root: classes.labelColor } }}
          inputProps={{
            className: classes.input,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(MyTextField);
