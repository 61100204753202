import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import Members from "./Members";
import Organization from "./Organization";
import SubTitleChart from "./SubTitleChart";
import MainAssistant from "./MainAssistant";

function MainTitleChart(props) {
  const { o, parent } = props;
  const [collapsed, setCollapsed] = React.useState(o.collapsed);

  React.useEffect(() => {
    o.collapsed = collapsed;
  });

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
        >
          {props.children}
        </Tree>
      );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {_.map(o.assiatant, (m, index) => (
        <TreeNode key={index} label={<MainAssistant m={m} />}>
          <TreeNode label={<MainTitleChart o={m} parent={o} key={index} />} />
        </TreeNode>
      ))}

      {_.map(o.account, (a, index) => (
        <TreeNode key={index} label={<SubTitleChart a={a} />}>
          <TreeNode label={<Members p={a.product} />} />
        </TreeNode>
      ))}

      {_.map(o.organizationChildRelationship, (m, index) => (
        <TreeNode key={index} label={<MainAssistant m={m} />}></TreeNode>
      ))}
    </T>
  );
}

export default MainTitleChart;
