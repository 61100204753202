import {
  SET_TEAMFEEDBACK_LOADING,
  SET_TEAM_FEEDBACK_RATE_TEAM,
  SET_TEAM_FEEDBACK_RATE_TEAM_LOADING,
  SET_TEAM_FEEDBACK_ALL_LOCATION,
  SET_TEAM_FEEDBACK_RATING_DATA,
  SET_TEAM_FEEDBACK_FORM_PAGE,
  SET_TEAM_FEEDBACK_FORM_DETAIL_PAGE,
  SET_TEAM_FEEDBACK_RATE_TEAM_FORM_PAGE,
  SET_TEAM_FEEDBACK_RATE_TEAM_FORM_DETAIL_PAGE,
  SET_TEAM_FEEDBACK_MY_RATING,
  SET_TEAM_FEEDBACK_MY_RATING_SCORE,
  SET_TEAM_FEEDBACK_LOCATION_DASHBOARD_DATA,
  SET_TEAM_FEEDBACK_ROLE_DASHBOARD_DATA,
  SET_TEAM_FEEDBACK_FORM_DASHBOARD_DATA,
  SET_TEAM_FEEDBACK_USER_DASHBOARD_DATA,
} from "./constant";

const initialState = {
  isLoading: true,
  rateTeamLoading: true,
  rateTeamData: [],
  rateTeamDataFormPage: [],
  ratingData: [],
  locationdata: [],
  totalPagesRating: 1,
  totalPagesRateTeam: 1,
  ratingFormPageData: [],
  formName: "",
  ratingFormDetailPageData: [],
  rateTeamFormDetailpageQuestion: [],
  myRatingData: [],
  myRatingScoreData: [],
  comments: [],
  your_final_score: "",
  isKeepReviewrAnnonymous: "",
  userNameofAssignedFor: "",
  dashboardLocationData: [],
  dashboardLocationData1: [],
  dashboardRoleData: [],
  dashboardRoleData1: [],
  dashboardFormData: [],
  dashboardFormData1: [],
  dashboardUserData: [],
  dashboardUserData1: [],
  userLength: "",
  formLength: "",
  locationLength: "",
  roleLength: "",
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEAMFEEDBACK_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_TEAM_FEEDBACK_LOCATION_DASHBOARD_DATA:
      return {
        ...state,
        isLoading: false,
        dashboardLocationData: payload.result,
        dashboardLocationData1: payload,
        locationLength: payload.total_page,
      };
    case SET_TEAM_FEEDBACK_ROLE_DASHBOARD_DATA:
      return {
        ...state,
        isLoading: false,
        dashboardRoleData: payload.result,
        dashboardRoleData1: payload,
        roleLength: payload.total_page,
      };
    case SET_TEAM_FEEDBACK_FORM_DASHBOARD_DATA:
      return {
        ...state,
        isLoading: false,
        dashboardFormData: payload.result,
        dashboardFormData1: payload,
        formLength: payload.total_page,
      };

    case SET_TEAM_FEEDBACK_USER_DASHBOARD_DATA:
      return {
        ...state,
        isLoading: false,
        dashboardUserData: payload.result,
        dashboardUserData1: payload,
        userLength: payload.total_page,
      };
    case SET_TEAM_FEEDBACK_RATE_TEAM_LOADING:
      return {
        ...state,
        rateTeamLoading: payload,
      };

    case SET_TEAM_FEEDBACK_RATE_TEAM:
      return {
        ...state,
        isLoading: false,
        rateTeamLoading: false,
        rateTeamData: payload.result,
        totalPagesRateTeam: payload.totalPages,
      };

    case SET_TEAM_FEEDBACK_RATE_TEAM_FORM_PAGE:
      return {
        ...state,
        isLoading: false,
        rateTeamDataFormPage: payload.result,
        totalPagesRateTeam: payload.totalPages,
      };

    case SET_TEAM_FEEDBACK_RATE_TEAM_FORM_DETAIL_PAGE:
      return {
        ...state,
        isLoading: false,
        rateTeamFormDetailpageQuestion: payload.result,
        totalPagesRateTeam: payload.totalPages,
      };
    case SET_TEAM_FEEDBACK_ALL_LOCATION:
      return {
        ...state,
        isLoading: false,
        locationdata: payload,
      };
    case SET_TEAM_FEEDBACK_RATING_DATA:
      return {
        ...state,
        ratingData: payload.result,
        totalPagesRating: payload.totalPages,
        isLoading: false,
      };
    case SET_TEAM_FEEDBACK_FORM_PAGE:
      return {
        ...state,
        isLoading: false,
        ratingFormPageData: payload.updateData,
        formName: payload.formName,
        totalPagesRating: payload.totalPages,
      };
    case SET_TEAM_FEEDBACK_FORM_DETAIL_PAGE:
      return {
        ...state,
        isLoading: false,
        ratingFormDetailPageData: payload.feedBackData,
        formName: payload.formName,
        isKeepReviewrAnnonymous: payload.isKeepReviewrAnnonymous,
        userNameofAssignedFor: payload.userNameofAssignedFor,
      };

    case SET_TEAM_FEEDBACK_MY_RATING:
      return {
        ...state,
        isLoading: false,
        myRatingData: payload.result,
      };
    case SET_TEAM_FEEDBACK_MY_RATING_SCORE:
      return {
        ...state,
        isLoading: false,
        myRatingScoreData: payload.finalData,
        comments: payload.comments,
        your_final_score: payload.your_final_score,
        isKeepReviewrAnnonymous: payload.isKeepReviewrAnnonymous,
        formName: payload.formName,
      };
    default:
      return state;
  }
};

export default Reducer;
