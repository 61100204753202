export const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
      display: "none",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#858585",
    },
  },
  mainRoot: {
    overflow: "hidden",
    width: "100% !important",
  },
  root: {
    // padding: theme.spacing(2),
    overflow: "hidden",
  },
  paper: {
    // padding: "15px",
    width: "100%",
    minHeight: "104vh",
    maxHeight: "100%",
  },
  //slider Design
  startTrainn: {
    paddingTop: "1%",
    display: "flex",
    marginBottom: "2%",
    marginTop: "2%",
  },
  names: {
    fontSize: "18px",
    fontWeight: "800",
  },
  indexs: {
    fontSize: "16px",
    fontWeight: "500",
  },
  para: {
    paddingLeft: "13%",
    paddingRight: "12%",
    paddingTop: "3%",
  },
  buttoncourse: {
    // marginLeft: "45%",
    textAlign: "center",
    // height: "100%"
  },
  buttoncoursesss: {
    // marginTop: "1%",
    // marginLeft: "45%",
    textAlign: "center",
    height: "100%",
    // marginBottom: "1%"
  },
  buttonQuiz: {
    // marginTop: "1%",
    // marginLeft: "42.5%",
    textAlign: "center",
    // marginBottom: "3%"
  },
  btnin: {
    marginTop: "8%",
    backgroundColor: "#135098",
    color: "white",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: "700",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#135098",
    },
  },
  btnQuiz: {
    backgroundColor: "#135098",
    color: "#fff",
    fontSize: "13px",
    fontWeight: "700",
    textTransform: "capitalize",
    cursor: "pointer",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:hover": {
      backgroundColor: "#135098",
      // color: 'black'
      // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      // color: "black"
    },
  },
  btnQuizMob: {
    // marginTop: "8%",
    backgroundColor: "#135098",
    color: "#fff",
    fontSize: "10px",
    fontWeight: "700",
    textTransform: "capitalize",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:hover": {
      backgroundColor: "#135098",
      // color: 'black'
      // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      // color: "black"
    },
  },
  icon: {
    fontSize: "55px",
  },
  mainDiv: {
    position: "relative",
    // marginTop: "2%",
    marginLeft: "49px",
    marginRight: "35px",
    padding: "0px 10px",
    height: "90%",
    marginTop: "1%",
    overflowY: "scroll",
    borderRadius: "5px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    // border: "1px solid #e2e5f1",
    borderBottom: "0px !important",
    // paddingBottom: "200px"
  },
  lesstype: {
    padding: "30px 28px",
    paddingBottom: "auto",
    // height: "100vh",
    // width: "80%",
    // textAlign: "center",
    // justifyContent: "center",
    // backgroundColor: "#f5f5f5"
  },
  lessonNames: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "3%",
    marginBottom: "1%",
    fontWeight: "800",
    color: "#135098",
    fontSize: "20px",
  },
  leftGrid: {
    backgroundColor: "rgba(247, 248, 252, 1)",
    height: "100%",
    width: "350px",
    padding: "5% 5px 0 5px",
    overflowY: "scroll",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  centerGrid: {
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    padding: "30px 5px 5px 5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  rightGrid: {
    backgroundColor: "rgba(247, 248, 252, 1)",
    height: "100%",
    width: "100%",
    padding: "5% 40px 40px 40px",
  },
  rightGridTopSection: {
    // height: "100%",
    height: "70%",
    // overflow: "hidden"
  },
  lessonTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    marginTop: "24px",
    marginBottom: "3px",
  },
  lessonTitleS: {
    fontWeight: "bold",
    fontSize: "22px",
    marginLeft: "15px",
    color: "#135098",
  },
  lessonTitleMob: {
    fontWeight: "bold",
    fontSize: "17px",
  },
  lessonsTabs: {
    display: "flex",
    alignItems: "center",
  },
  countRow: {
    // display: "flex",
    // justifyContent: "center",
    textAlign: "center",
    // maxWidth: "400px",
    margin: "auto",
    marginBottom: "16px",
    position: "relative",
  },
  countRowParagraph: {
    fontSize: "16px",
    // color: "gray",
    color: "#135098",
    // margin: "0px",
    fontWeight: "600",
    textAlign: "center",
    // maxWidth: "400px",
    margin: "auto",
    marginBottom: "16px",
    position: "relative",
  },
  lesson_level: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#727272",
    textTransform: "capitalize",
    backgroundColor: "#F4F4F4",
    borderRadius: "6px",
    height: "33px",
    width: "94px",
    marginLeft: "auto",
    marginRight: "30px",
    marginTop: "0px",
    marginBottom: "0px",
  },
  lesson_level_mob: {
    fontSize: "10px",
    fontWeight: "700",
    color: "#727272",
    textTransform: "capitalize",
    backgroundColor: "#F4F4F4",
    borderRadius: "6px",
    height: "20px",
    width: "46px",
    marginLeft: "auto",
    marginRight: "30px",
    marginTop: "0px",
    marginBottom: "0px",
    position: "absolute",
    top: "0",
    right: "0",
  },
  slidesRow: {
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  slidesRowMob: {
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 8px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  lessonSlides: {
    position: "absolute",
    bottom: "15px",
    width: "100%",
    backgroundColor: "white",
  },
  slideType: {
    color: "gray",
    fontWeight: "700",
    fontSize: "14px",
  },
  slideTypeMob: {
    color: "gray",
    fontWeight: "700",
    fontSize: "13px",
  },
  quizTypeText: {
    color: "#DB5E5E",
    // fontWeight: "700",
    fontSize: "14px",
  },
  quizTypeTextMob: {
    color: "#DB5E5E",
    // fontWeight: "700",
    fontSize: "13px",
  },
  mainGrid: {
    backgroundColor: "white",
    border: "1px solid #afafaf",
    marginTop: "10px",
    marginBottom: "10px",
    borderRadius: "10px",
    padding: "17px",
    cursor: "pointer",
  },
  myGrid: {
    display: "flex",
    // margin: "auto",
    // padding: "0.7rem 2rem",
  },
  col1: {
    display: "flex",
  },
  avatar: {
    marginRight: "20px",
    float: "left",
    height: "100px",
    width: "120px",
    border: "1px solid #e2e5f1",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "110px",
      width: "110px",
    },
  },
  title: {
    maxWidth: "80%",
    fontSize: "14px",
    WebkitUserSelect: "none",
    verticalAlign: "middle",
    display: "-webkit-inline-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#000000",
    fontWeight: "bold",
    textTransform: "capitalize",
    width: "100%",
    marginBottom: "0px",
  },
  titles: {
    maxWidth: "80%",
    fontSize: "14px",
    WebkitUserSelect: "none",
    verticalAlign: "middle",
    display: "-webkit-inline-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
    color: "gray",
    width: "100%",
    marginBottom: "0px",
  },
  profileDiv: {
    backgroundColor: "gray",
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    textAlign: "center",
  },
  profileDivMob: {
    backgroundColor: "gray",
    borderRadius: "50%",
    height: "14px",
    width: "14px",
    textAlign: "center",
  },
  lessonStatus: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  overlay: {
    background: "black",
    position: "absolute",
    top: "0",
    height: "100%",
    width: "100%",
    opacity: "0.1",
    borderRadius: "10px",
  },
  overlayVideo: {
    background: "black",
    position: "absolute",
    top: "0",
    height: "100%",
    width: "100%",
    opacity: "0.8",
  },
  slidesCover: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
  },
  completedAcknowledgedBtn: {
    backgroundColor: "lightGrey",
    color: "white",
    cursor: "pointer",
    fontSize: "13px",
    textTransform: "capitalize",
    fontWeight: "700",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  completedAcknowledgedBtnMob: {
    backgroundColor: "lightGrey",
    color: "white",
    cursor: "pointer",
    fontSize: "10px",
    textTransform: "capitalize",
    fontWeight: "700",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  acknowledgedBtn: {
    backgroundColor: "#135098",
    color: "white",
    fontSize: "13px",
    fontWeight: "700",
    textTransform: "capitalize",
    cursor: "pointer",
    paddingLeft: "15px",
    marginBottom: "18px",
    paddingRight: "15px",
    "&:hover": {
      backgroundColor: "#135098 !important",
    },
  },
  acknowledgedBtnMob: {
    backgroundColor: "#135098",
    color: "white",
    fontSize: "10px",
    fontWeight: "700",
    textTransform: "capitalize",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:hover": {
      backgroundColor: "#135098 !important",
    },
  },
  countSlides: {
    color: "#727272",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
  },
  countSlidesMob: {
    color: "#727272",
    fontFamily: "Inter",
    fontSize: "10px",
    fontWeight: "700",
  },
  feedbackBtn: {
    color: "#135098",
    background: "rgba(25, 80, 222, 0.1)",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "700",
    // margin: "0px",
    position: "absolute",
    marginTop: "4px",
    // top: "50%",
    // writingMode: "vertical-rl",
    // textOrientation: "mixed",
    // transform: "translate(20px, -50%)",
    padding: "8px 23px",
    borderRadius: "3px",
    zIndex: "1",
  },
  feedbackModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  feedbackModalContent: {
    width: "500px",
    background: "white",
    margin: "auto",
    padding: "30px",
    borderRadius: "5px",
    "&:focus-visible": {
      outline: "none !important",
    },
  },
  feedbackInfo: {
    fontSize: "14px",
    marginBottom: "5px",
  },
  feedbackInput: {
    fontSize: "14px",
  },
  cancelBtn: {
    backgroundColor: "#F4F4F4",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
    color: "#727272",
    border: "0px",
    height: "35px",
    width: "95px",
  },
  saveBtn: {
    backgroundColor: "#245193",
    borderRadius: "6px",
    fontWeight: "700",
    fontSize: "14px",
    color: "white",
    border: "0px",
    height: "35px",
    width: "95px",
  },
  lessonsModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  lessonsModalContent: {
    width: "80%",
    height: "80%",
    background: "white",
    padding: "10px",
    borderRadius: "0px 10px 10px 0px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&:focus-visible": {
      outline: "none !important",
    },
  },
  lessonsModalContentInner: {
    paddingLeft: "10px",
    width: "90%",
  },
  lessonsHeading: {
    fontSize: "16px",
    fontWeight: "700",
  },
  tooltip1: {
    position: "relative",
    display: "inline-block",
  },
  tooltiptext: {
    minWidth: "250px",
    height: "auto",
    backgroundColor: "black",
    color: "#fff",
    textAlign: "left",
    borderRadius: "6px",
    padding: "5px 10px",
    fontSize: "14px",
    top: "100%",
    left: "0",
    marginLeft: "-60px",
    /* Position the tooltip */
    position: "absolute",
    zIndex: "1",
  },
  searchBar: {
    fontSize: "14px",
  },
  searchBarPopup: {
    fontSize: "14px",
    margin: "0px !important",
    borderRadius: "25px",
  },
  SearchDiv: {
    background: "white",
    width: "36px",
    height: "30px",
    textAlign: "center",
    borderRadius: "7px",
    cursor: "pointer",
  },
  mainBackIcon: {
    marginLeft: "30px",
    cursor: "pointer",
  },
  middle_row: {
    marginTop: "-16px",
    marginBottom: "-16px",
  },
  lessonsDiv: {
    marginTop: "20px",
  },
  popupLessonCard: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 5px 21px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    overflow: "hidden",
    padding: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    textDecoration: "none",
  },
  lesson_title_popup: {
    fontSize: "15px",
    fontWeight: "700",
    color: "#5C5C5C",
  },
  titlesMob: {
    fontSize: "10px",
    color: "#858585",
  },
  videoSlideIcon: {
    fontSize: "25px",
    color: "gray",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  playVideoIcon: {
    // position: "absolute",
    // top: "20%",
    height: "66px",
    width: "66px",
    // transform: "translate(-50%, -50%)",
  },
  fileSlideIcon: {
    fontSize: "22px",
    color: "gray",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  checkIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  playIcons: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  videoBottomRow: {
    position: "absolute",
    bottom: "0",
    borderTop: "1px solid white",
    padding: "10px",
    width: "100%",
  },
  leftRightIcon: {
    width: "25px",
    height: "30px",
    marginLeft: "25px",
    marginRight: "25px",
    cursor: "pointer",
  },
  playIcon: {
    width: "80px",
    height: "97px",
    cursor: "pointer",
  },
  pauseIcon: {
    width: "auto",
    height: "55px",
    cursor: "pointer",
    marginLeft: "12px",
    marginRight: "12px",
  },
  volumeSlider: {
    marginTop: "0px !important",
    marginBottom: "0px !important",
    backgroundColor: "#4d4941 !important",
    width: "100px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  bookmarkDiv: {
    position: "relative",
  },
  bookmarkMenu: {
    background: "white",
    borderRadius: "6px",
    border: "1px solid #858585",
    position: "absolute",
    right: "0",
    bottom: "30px",
    opacity: "1",
    overflow: "hidden",
  },
  bookmarkMenuItem: {
    width: "250px",
    padding: "5px 10px",
    borderBottom: "1px solid #9B9B9B",
    "&:last-child": {
      border: "none",
    },
  },
  bookmarkMenuItemText: {
    color: "black",
    fontSize: "12px",
  },
  leftIcon: {
    position: "absolute",
    top: "50%",
    left: "15px",
    transform: "translate(0%, -50%)",
    zIndex: "1",
  },
  rightIcon: {
    position: "absolute",
    top: "50%",
    right: "15px",
    transform: "translate(0%, -50%)",
    zIndex: "1",
  },
  circular: {
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    fontWeight: "bold",
  },
  updateMain: {
    fontSize: "10px !important",
    marginTop: "6px",
  },
  lessonMain: {
    fontSize: "18px !important",
    // lineHeight: "67px !important",
  },
  middleLesonArr: {
    marginTop: "5px",
    color: "#135098",
  },
  mainCon: {
    marginTop: "22px !important",
  },
  searchRow: {
    // width: "418px",
    position: "relative",
    // paddingLeft: "389px",
    textAlign: "-webkit-center",
  },
  searchInput: {
    fontSize: "14px",
    color: "#7D7D7D",
    width: "455px",
    height: "46px",
    border: "none",
    fontFamily: "century_gothic",
    borderRadius: "22px",
    paddingLeft: "40px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  cardSpaceX: {
    width: "418px",
    // paddingLeft: "1.25rem",
    // paddingRight: ".5rem",
  },
  divSearch: {
    position: "relative",
    width: "335px",
    display: "flex",
    marginLeft: "18px",
  },
  searchIcon: {
    position: "absolute",
    // right: "32px",
    left: "10px",
    fontSize: "23px",
    top: "50%",
    color: "#7D7D7D",
    transform: "translate(0%, -50%)",
  },
  sliderMianDiv: {
    paddingTop: "18px",
    height: "30%",
  },
});
