import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import screenful from "screenfull";
import Controls from "./PlayerControls";

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    width: "100%",
    height: "100%",
    position: "relative"
  }
}));


const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

let count = 0;

function Player(props) {
  const classes = useStyles();
  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  const [state, setState] = useState({
    pip: false,
    playing: true,
    controls: false,
    light: false,
    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
  });

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const {
    playing,
    light,
    muted,
    loop,
    playbackRate,
    pip,
    played,
    volume,
  } = state;

  const onPlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const onRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
  };

  const onFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5);
  };

  const handleProgress = (changeState) => {
    if (count > 3) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    if (controlsRef.current.style.visibility === "visible") {
      count += 1;
    }
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const onSeek = (e, newValue) => {
    setState({ ...state, played: parseFloat(newValue / 100) });
  };

  const onSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };

  const onSeekMouseUp = (e, newValue) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(newValue / 100, "fraction");
  };

  const onDuration = (duration) => {
    setState({ ...state, duration });
  };

  const onVolumeSeekDown = (e, newValue) => {
    setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  };

  const onVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const onToggleFullScreen = () => {
    screenful.toggle(playerContainerRef.current);
  };

  const handleMouseMove = () => {
    controlsRef.current.style.visibility = "visible";
    count = 0;
  };

  const hanldeMouseLeave = () => {
    controlsRef.current.style.visibility = "hidden";
    count = 0;
  };

  const onChangeDispayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remaining" : "normal"
    );
  };

  const onPlaybackRateChange = (rate) => {
    setState({ ...state, playbackRate: rate });
  };

  const onMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
  const elapsedTime =
    timeDisplayFormat === "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;
  const totalDuration = format(duration);

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseLeave={hanldeMouseLeave}
      ref={playerContainerRef}
      className={classes.playerWrapper}
    >
      <ReactPlayer
        ref={playerRef}
        width="50%"
        height="50%"
        url={props.src}
        pip={pip}
        playing={playing}
        controls={false}
        light={light}
        loop={loop}
        playbackRate={playbackRate}
        volume={volume}
        muted={muted}
        onProgress={handleProgress}
      />

      <Controls
        ref={controlsRef}
        onSeek={onSeek}
        onSeekMouseDown={onSeekMouseDown}
        onSeekMouseUp={onSeekMouseUp}
        onDuration={onDuration}
        onRewind={onRewind}
        onPlayPause={onPlayPause}
        onFastForward={onFastForward}
        playing={playing}
        played={played}
        elapsedTime={elapsedTime}
        totalDuration={totalDuration}
        onMute={onMute}
        muted={muted}
        onVolumeChange={onVolumeChange}
        onVolumeSeekDown={onVolumeSeekDown}
        onChangeDispayFormat={onChangeDispayFormat}
        playbackRate={playbackRate}
        onPlaybackRateChange={onPlaybackRateChange}
        onToggleFullScreen={onToggleFullScreen}
        volume={volume}
      />
    </div>
  );
}

export default Player;