import { mobileTheme } from "../../constant";
import "./EventCard.scss";
import { Link } from "react-router-dom";

export const EventCard = ({ image, title }) => {
  return (
    <Link to="/hr/events/publicholiday">
      <article className="eventMobileCardRoot">
        <div className="imageContainer">
          <img src={image} alt="" className="image" />
        </div>
        <div className="titleContainer" style={{ background: mobileTheme }}>
          <h3>{title}</h3>
        </div>
      </article>
    </Link>
  );
};
