import React, { Component, lazy } from "react";
import SidebarLink from "./SidebarLink";
import {
    SupervisorAccountOutlined as ListIcon,
}
    from "@material-ui/icons";
import MainLayout from "../MainLayout";
import { PublicRoute } from "../../accesslevel";
const ToDoDashboard = lazy(() => import("../../pages/Todo/Dashboard/Dashboard"));

class TodoList extends Component {
    render() {
        const { isSidebarOpen, location } = this.props;
        var todoRoutes = []
        this.props.listData.map((page, index) => {
            var routeObject = {
                todoId: page.id,
                path: `/todo/dashboard/list?id=${page._id}`,
                label: page.title,
                icon: <ListIcon />,
                component: ToDoDashboard,
                product: "Todo",
                layout: MainLayout,
                access: PublicRoute
            }
            todoRoutes.push(routeObject)
        })

        return (
            todoRoutes.map((page, key) => {
                return (
                    <SidebarLink
                        todoId={page._id}
                        key={key}
                        location={location}
                        isSidebarOpen={isSidebarOpen}
                        {...page}
                    />
                )
            })
        )
    }
}
export default TodoList;