export const ADD_TASK = "ADD_TASK";
export const GET_TASK = "GET_TASK";
export const GET_TASK_ID = "GET_TASK_ID";
export const GET_TASK_ASSIGNED_TO_ME = "GET_TASK_ASSIGNED_TO_ME";
export const EDIT_TASK = "EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const SET_TASKUPDATE_LOADING = "SET_TASKUPDATE_LOADING";
export const ADD_TAGS = "ADD_TAGS";
export const ADD_REMIND_ME= "ADD_REMIND_ME";
export const REPEAT = "REPEAT";
export const ADD_ASSIGNEE = "ADD_ASSIGNEE";
export const LOCATION_USERS = "LOCATION_USERS";
export const ROLE_USERS = "ROLE_USERS"
export const ROLE_USERS_TAB = "ROLE_USERS_TAB"
export const ACTIVE_INDEX = "ACTIVE_INDEX"
export const LOCATION_USERS_TAB ="LOCATION_USERS_TAB"
export const ESTIMATED_DURATION = "ESTIMATED_DURATION";
export const DEADLINE= "DEADLINE";
export const TASK_SCORE= "TASK_SCORE";
export const LIST_ID = "LIST_ID";
export const ASSIGNIEES= "ASSIGNIEES";
export const SET_LOCATION = "SET_LOCATION";
export const COURSES_LIST = "COURSES_LIST";
export const LESSON_LIST = "LESSON_LIST"