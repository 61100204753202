import {
  ADD_TASK,
  ADD_TAGS,
  ADD_REMIND_ME,
  ESTIMATED_DURATION,
  DEADLINE,
  TASK_SCORE,
  LIST_ID,
  EDIT_TASK,
  GET_TASK,
  DELETE_TASK,
  LOCATION_USERS_TAB,
  ROLE_USERS_TAB,
  LOCATION_USERS,
  ADD_ASSIGNEE,
  REPEAT,
  GET_TASK_ID,
  LESSON_LIST,
  ROLE_USERS,
  COURSES_LIST,
  SET_TASKUPDATE_LOADING,
  ACTIVE_INDEX,
  GET_TASK_ASSIGNED_TO_ME,
} from "./constants";

const initialState = {
  isLoading: true,
  taskUpdateData: [],
  list_id: null,
  tags: null,
  reminder: null,
  repeat:null,
  estimatedDuration: null,
  dueDate: null,  
  taskScore: null,
  assignments: [],
  assigneto:[],
  totalPage:1,
  totalPageAssignedToMe:1,
  locationList:[],
  userTabLocationList:[],
  page:1,
  nextPage:"",
  allTaskLibrary:[],
  courseList:[],
  activeIndex:"location",
  lessonList:[],
  roleUserList:[],
  userTabRoleList:[],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TASKUPDATE_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case ADD_TASK:
      return {
        ...state,
        isLoading: false,
        taskUpdateData:  payload.task,
      };
      case EDIT_TASK:
        return {
          ...state,
          isLoading: false,
          taskUpdateData:payload.task
      };
      case GET_TASK :
        return {
          ...state,
          isLoading: false,
          taskUpdateData:  payload?.result?.docs,
          totalPage:payload?.result?.totalPages,
          page:payload?.result?.page,
          nextPage:payload?.result?.nextPage
        };
        case GET_TASK_ASSIGNED_TO_ME:
          return{
            ...state,
            isLoading: false,
            taskUpdateData:payload?.result?.docs,
            totalPage:payload?.result?.totalPages,
            page:payload?.result?.page,
            nextPage:payload?.result?.nextPage
          }
        case ADD_ASSIGNEE :
          return{
            ...state,
            isLoading: false,
            assigneto:payload
        }
        case LOCATION_USERS:
          return{
            ...state,
            isLoading:false,
            locationList:payload
          }
          case ACTIVE_INDEX:
            return{
              ...state,
              activeIndex:payload
            }
          case ROLE_USERS:
            return{
              ...state,
              isLoading:false,
              roleUserList:payload
            }
            case LOCATION_USERS_TAB:
              return{
                ...state,
                isLoading:false,
                userTabLocationList:payload
              }
              case ROLE_USERS_TAB:
                return{
                  ...state,
                  isLoading:false,
                  userTabRoleList:payload
                }
      case  COURSES_LIST : 
          return{
            ...state,
            isLoading:false,
            courseList:payload
          }
          case LESSON_LIST:{
            return{
              ...state,
              isLoading:false,
              lessonList:payload
            }
          }
      case GET_TASK_ID :
        return {
          ...state,
          isLoading: false,
          taskUpdateData:  payload,
        };
      case LIST_ID:
      return {
        ...state,
        isLoading: false,
        list_id:  payload,
      };
      case ADD_TAGS:
        return{
          ...state,
          isLoading: false,
          tags:  payload,
        };
        case ADD_REMIND_ME:
          return{
            ...state,
            isLoading: false,
            reminder: payload

          };
          case REPEAT:
            return{
              ...state,
              isLoading: false,
              repeat: payload
  
            };
            
          case ESTIMATED_DURATION:
            return{
              ...state,
              isLoading: false,
              estimatedDuration: payload
  
            };
            case DEADLINE:
              return{
                ...state,
                isLoading: false,
                dueDate: payload
    
              };
              case TASK_SCORE:
                return{
                  ...state,
                  isLoading: false,
                  taskScore: payload
      
                }
    // Handle other cases if needed
    default:
      return state; // Return the current state for unrecognized actions
  }
};

export default Reducer;
