import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { RoutineRoutes } from '../../routes/RoutineRoutes';
import RoutineSidebarLink from './RoutineSidebarLink';
import { Link } from "react-router-dom";
import axios from 'axios';
import {
  ListItem,
  ListItemIcon,
  Input,
  Button,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import classnames from "classnames";
import { makeStyles } from '@material-ui/core/styles';
import { HEADER_TOKEN, MONGO_URL, RoutineColor } from '../../config';

const useStyles = makeStyles((theme) => ({
  link: {
    padding: '8px 8px',
    borderRadius: '5px',
    textDecoration: "none",
    marginBottom: '.25rem',
    boxShadow: "0.5px 0.5ppx 0.5ppx rgba(0, 0, 0, 0.25)",
  },
  linkActive: {
    backgroundColor: RoutineColor.main,
    boxShadow: "4px 2px 4px rgba(0, 0, 0, 0.25)",
    textDecoration: 'none',
  },
  avatar: {
    height: 21,
    width: 21,
    marginRight: '10px'
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#B9B9B9",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    textDecoration: 'none',
  },
  linkTextActive: {
    fontWeight: 600,
    color: "#ffffff",
  },
  linkTextHidden: {
    opacity: 0,
    color: "#B9B9B9"
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color")
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const RoutineSidebar = ({ history, location, clickLink, isSidebarOpen, path, icon, image, active_image }) => {
  const classes = useStyles();
  var isLinkActive = path && (location.pathname === path);
  const [newRoutes, setNewRoutes] = useState(RoutineRoutes);
  const [newListName, setNewListName] = useState('');
  const [list, setList] = useState([])

  const handleToggleRoutes = (index, isOpen) => {
    const updatedRoutes = newRoutes.map((item, myIndex) => {
      var cloneItem = item
      if (myIndex === index) {
        cloneItem["isOpen"] = isOpen ? false : true
      }else{
        cloneItem["isOpen"] =  false
      }
      return cloneItem;
    });

    setNewRoutes(updatedRoutes);
  };

  const handleChange = (event) => {
    setNewListName(event.target.value);
  };



  useEffect(() => {
    async function fetchData() {
      await axios.get(`${MONGO_URL}/list/`, HEADER_TOKEN)
        .then((response) => {
          // Handle successful response
          setList(response?.data?.data);
        })
        .catch((error) => {
          // Handle error
          console.error('Error adding list:', error);
        });
    }
    fetchData()
  }, [newListName])


  const handleSubmit = (event) => {
    event.preventDefault();
    let name = newListName;
    if (name === '') {
      name = 'Untitled';
    }
    axios
      .post(`${MONGO_URL}/list/create`, { name }, HEADER_TOKEN)
      .then((response) => {
        if (response?.data?.savedList) {
          const id = response?.data?.savedList?._id;
          history.push(`/routine/list/${id}`);
          setNewListName('')
        }
      })
      .catch((error) => {
        console.error('Error adding list:', error);
      });
  };



  return (
    <div>
      {newRoutes?.map((page, key) => (
        <RoutineSidebarLink
          handleToggleRoutes={() => handleToggleRoutes(key, page.isOpen)}
          key={key}
          location={location}
          isSidebarOpen={isSidebarOpen}
          list={list}
          {...page}
        />
      ))}
      {list?.length ? list.map((item, index) => (
        <Link to={`/routine/list/${item._id}`} style={{ textDecoration: "none" }}>
          <ListItem key={index}
            onClick={clickLink}
            button
            component={path && Link}

            classes={{
              root: classnames(classes.link, {
                [classes.schedulerActive]: isLinkActive ? true : false,
              }),
            }}
            disableRipple>
            <ListItemIcon
              className={classnames(classes.linkIcon, {
                [classes.linkIconActive]: isLinkActive
              })}
            >{
                <Avatar
                  variant="square"
                  className={classes.avatar}
                  src={
                    isLinkActive ? active_image : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFDSURBVHgBzZM9SwNBEIbn3buLEQWxsAyWCgFBEIxWHgRJRNEUaS20sLLxF0QQxMbCRgt/gbGU4EXBnNeY8wObIFiIAbFNikg4c7fj3j9wu3vYYnaKh2H2XaKkgXrD3wfx3CDNOyu53Net97wqwyivJQG1TEHYY6JRK5CLlUrlksNwG8CGjohJfsNxH7fANPXT6R2WSnbXdf3MQFBWR0QBf1DiQO3+ZQJRMFK0Fz7jBjOjWm1ZOpJyOTvATcN/V2ufBMt8fmneq981T9V9XUektv0q1FD9uJYCqXgamOiSLlDnyvPGjZ45Vijk2urZOe6f1GpDOp7dYvGXEgeu3eamSveMJa0D257tOo6f4ZReIIeF0TYNxllcR0b4pL7IhUjjXGVgWUcUyLBjSvCxITEdUt9VIunYa0cG+O3/Gklg8UCJ4w/AmXq2rogT1wAAAABJRU5ErkJggg=="
                  }
                />
              }</ListItemIcon>
            <ListItemText
              classes={{
                primary: classnames(classes.linkText, {
                  [classes.linkTextActive]: isLinkActive,
                  [classes.linkTextHidden]: !isSidebarOpen,
                }),
              }}
              primary={item.name} />
          </ListItem> </Link>
      )) : ""}

      <ListItem style={{ marginTop: "10px" }} disableRipple>
        <ListItemIcon>
          <AddBoxOutlinedIcon style={{ fontSize: "27px", color: "#295CDC", marginTop: "-5px", marginLeft: "-11px" }} />
          <form className={classes.root} noValidate autoComplete="off" style={{ marginTop: "-14px" }}>
            <Input
              placeholder="Add List"
              disableUnderline={true}
              value={newListName}
              onChange={handleChange}
              sx={{
                "& input": {
                  color: 'green',
                }
              }}
              inputProps={{
                'aria-label': 'description',
              }}
            />
            <Button type="submit" style={{ display: "none" }} onClick={handleSubmit}>Submit</Button>
          </form>
        </ListItemIcon>
      </ListItem>
    </div>
  );
};

export default withRouter(RoutineSidebar);
