import React from 'react'
import ReactDom from 'react-dom'
import './modal.css'

function Backdrop(props) {
    return(
        <div className="backdrop_container" onClick={props.handlePopupModal}>
            
        </div>
    )
}

export default Backdrop
