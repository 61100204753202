import React, { useState, useEffect } from "react";
import { Drawer, List } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import SidebarLink from "./SidebarLink";
import BackLink from "./BackLink";
import AddToDoList from "./AddToDoList";
import ViewCategoryList from "./ViewCategoryList";
import ViewMyLessonList from "./ViewMyLessonList";
import ViewTodoList from "./ViewTodoList";
import Cookies from "universal-cookie";
import { Routes } from "../../routes";
import { TopRightModal } from "../../components";
import { settingColor } from "../../config";
import Products from "./Products";
import { Grid, Divider } from "@material-ui/core";
import FeedbackSidebar from "./FeedbackSidebar";
import SchedulerSidebar from "./SchedulerSidebar";
import OvHrSidebar from "./OvHrSidebar";
import RoutineSidebar from "./RoutineSidebar";

const drawerWidth = 230;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    border: "none!important",
    overflowY: "overlay",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: "red",
    minHeight: "65px !important",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sidebarList: {
    marginBottom: "1rem",
  },
  // asad global side bar for all products
  sidebarListOpen: {
    overflowX: "hidden",
    // overflowY: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    padding: "0px 15px",
  },
  sidebarListClose: {
    padding: "0px",
  },
}));

function Sidebar({
  selectMyCourseLesson,
  addListOpen,
  displaySection,
  categoryData,
  myLessons,
  active_course_details,
  active_lesson_details,
  listData,
  activeProduct,
  isSidebarOpen,
  location,
  handleSidebarToggle,
  permissionsData,
  countData,
}) {
  var classes = useStyles();
  var theme = useTheme();
  var [isPermanent, setPermanent] = useState(true);
  var [isMobile, setisMobile] = useState(false);
  var [productModal, setProductModal] = useState(false);
  const cookies = new Cookies();
  const ovCid = cookies.get("ovCid");
  // const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
  //   defaultMatches: true
  // });

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const clickLink = () => {
    if (isMobile) {
      handleSidebarToggle();
    }
  };

  const productModalToggle = () => {
    setProductModal(!productModal);
  };

  var newPath = window.location.search;
  var newRoutes = Routes.map((item) => {
    var cloneItem = item;
    var status = 1;
    if (item.permission !== undefined) {
      status = permissionsData && permissionsData[item.permission] ? 1 : 0;
    }
    if (item.label === "Company") {
      status = ovCid?.toString() === "624e80fff8ee75131034af66" ? 1 : 0;
    }
    cloneItem["status"] = status;
    return cloneItem;
  });

  return (
    <Drawer
      onClose={handleSidebarToggle}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClose]: !isSidebarOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        }),
      }}
      open={isSidebarOpen}
    >
      <List
        disablePadding
        className={classNames(classes.sidebarList, {
          [classes.sidebarListOpen]: isSidebarOpen,
          [classes.sidebarListClose]: !isSidebarOpen,
        })}
      >
        {!isMobile ? (
          <BackLink
            productModalToggle={productModalToggle}
            activeProduct={activeProduct}
            handleSidebarToggle={handleSidebarToggle}
            isSidebarOpen={isSidebarOpen}
          />
        ) : (
          <div className={classes.toolbar} />
        )}

        {activeProduct === "eLearning" &&
        location.pathname.includes("/elearning/mycourses") &&
        displaySection === "lesson" ? (
          <ViewMyLessonList
            active_course_details={active_course_details}
            active_lesson_details={active_lesson_details}
            selectMyCourseLesson={selectMyCourseLesson}
            myLessons={myLessons}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : activeProduct === "eLearning" && newPath.includes("?_id=") ? (
          <ViewCategoryList
            categoryData={categoryData}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : activeProduct === "Feedback" ? (
          <FeedbackSidebar
            countData={countData}
            activeProduct={activeProduct}
            clickLink={clickLink}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : activeProduct === "Routine" ? (
          <RoutineSidebar
            countData={countData}
            activeProduct={activeProduct}
            clickLink={clickLink}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : activeProduct === "Scheduler" ? (
          <SchedulerSidebar
            countData={countData}
            activeProduct={activeProduct}
            clickLink={clickLink}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : activeProduct === "ovHR" ? (
          <OvHrSidebar
            countData={countData}
            activeProduct={activeProduct}
            clickLink={clickLink}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : (
          newRoutes.map((page, key) => {
            if (page.product === activeProduct)
              if (page.status && !page.invisible)
                return (
                  <span>
                    <SidebarLink
                      countData={countData}
                      activeProduct={activeProduct}
                      clickLink={clickLink}
                      key={key}
                      location={location}
                      isSidebarOpen={isSidebarOpen}
                      {...page}
                    />
                    {key % 3 === 0 ? (
                      <Divider style={{ background: "white" }} />
                    ) : (
                      ""
                    )}
                  </span>
                );
          })
        )}

        {activeProduct === "Todo" ? (
          <ViewTodoList
            listData={listData}
            location={location}
            isSidebarOpen={isSidebarOpen}
          />
        ) : (
          ""
        )}

        {activeProduct === "Todo" ? (
          <AddToDoList
            addListOpen={addListOpen}
            isSidebarOpen={isSidebarOpen}
          />
        ) : (
          ""
        )}
      </List>

      <TopRightModal
        bgColor={settingColor.main}
        title="Products"
        open={productModal}
        onClose={productModalToggle}
      >
        <Grid container spacing={0}>
          <Products productModalToggle={productModalToggle} />
        </Grid>
      </TopRightModal>
    </Drawer>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
