import {
  SET_SCHEDULE_LOADING,
  SET_SCHEDULE,
  DELETE_SHIFT,
  DUPLICATE_SHIFT,
  ADD_SHIFT,
  EDIT_SHIFT,
  PUBLISH_SHIFT,
  CLEAR_UNPUBLISH_SHIFT,
  CLEAR_ENTIRE_SCHEDULE,
  INVITE_SCHEDULE_USER,
  EDIT_SCHEDULE,
  VIEW_USER,
  UPDATE_USER
} from "./constants";

const initialState = {
  isLoading: true,
  usersData: [],
  scheduleData :{},
  payrollData: [],
  locationsDetails: {},
  userData : [],
  unique_pin: "",
  payroll_id: ""
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SCHEDULE_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SCHEDULE:
      return {
        ...state,
        isLoading: false,
        usersData: payload.usersData
      };
    case DELETE_SHIFT:
      let deleteUsers = state.usersData.map((user) => {
        var cloneItem = user
        if (user._id === payload.uid) {
          var newItems = cloneItem.myShifts.filter(shift => payload.job_id !== shift.job_id);
          cloneItem.myShifts = newItems
        }
        return cloneItem
      }) 
      return {
        ...state,
        isLoading: false,
        usersData: deleteUsers
      }

    case EDIT_SHIFT:
      let editUsers = state.usersData.map((user) => {
        var cloneItem = user
        if (user._id === payload.uid) {
          var newItems = user.myShifts.map((item) => item.job_id === payload.shift.job_id ? { ...payload.shift } : item)
          cloneItem.myShifts = newItems
        }
        return cloneItem
      })

      return {
        ...state,
        isLoading: false,
        usersData: editUsers
      }
    case ADD_SHIFT:
      let addUsers = state.usersData.map((user) => {
        var cloneItem = user
        if (user._id === payload._id) {
          var newItems = [...cloneItem.myShifts, ...payload.shifts]
          cloneItem.myShifts = newItems
        }
        return cloneItem
      })

      return {
        ...state,
        isLoading: false,
        usersData: addUsers
      }
    case DUPLICATE_SHIFT:
      let duplicateUsers = state.usersData.map((user) => {
        var cloneItem = user
        if (user.uid === payload.uid) {
          var newItems = [...cloneItem.myShifts, payload.shift]
          cloneItem.myShifts = newItems
        }
        return cloneItem
      })
      return {
        ...state,
        isLoading: false,
        usersData: duplicateUsers
      }
    case PUBLISH_SHIFT:
      let publishUsers = state.usersData.map((user) => {
        var cloneItem = user
        if (user.myShifts.length > 0) {
          var newShifts = user.myShifts.map((shift) => {
            var shiftItem = shift
            if (shift.job_id) {
              shiftItem.isPublished = 1
            }
            return shiftItem
          })
          cloneItem.myShifts = newShifts
        }
        return cloneItem
      })

      return {
        ...state,
        isLoading: false,
        usersData: publishUsers
      }
    case CLEAR_UNPUBLISH_SHIFT:
      let unPublishUsers = state.usersData.map((user) => {
        var cloneItem = user
        var newShifts = cloneItem.myShifts.filter(shift => shift.isPublished === 1);
        cloneItem.myShifts = newShifts
        return cloneItem
      })
      return {
        ...state,
        isLoading: false,
        usersData: unPublishUsers
      }

    case CLEAR_ENTIRE_SCHEDULE:
      let entireUsers = state.usersData.map((user) => {
        var cloneItem = user
        var newShifts = cloneItem.myShifts.filter(shift => !shift.job_id);
        cloneItem.myShifts = newShifts
        return cloneItem
      })
      return {
        ...state,
        isLoading: false,
        usersData: entireUsers
      }
    case INVITE_SCHEDULE_USER:
      return {
        ...state,
        isLoading: false,
        usersData: [...state.usersData, payload]
      }
    case EDIT_SCHEDULE:
      return {
        ...state,
        isLoading: false,
        usersData: state.usersData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
      case VIEW_USER:
        return {
          ...state,
          isLoading: false,
          scheduleData:payload,
          payrollData: payload.payrollData,
          locationsDetails: payload.locationsDetails,
          unique_pin:payload.location_pin,
          payroll_id: payload.payroll_id  
        };
      case UPDATE_USER:
        return {
          ...state,
          isLoading: false,
         userData: payload
        };
    default:
      return state;
  }
};

export default Reducer;