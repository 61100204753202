import React, { useEffect } from "react";
import { useState } from "react";
import { Stack } from "@mui/material";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import UploadIcon from "@mui/icons-material/Upload";
import { GradingLoading } from "../../../../pages/eLearning/Grading/utils/GradingLoading";
import { useGradingLessonDetail } from "../../context/gradingContext";
import { GradingHeader } from "../../components";
import VideoModal from "../../../../pages/eLearning/Grading/utils/VideoModal";
import { SlidesModal } from "../../utilities";
import "./MobileSubmitVideoQuiz.scss";

export const MobileSubmitVideoQuiz = ({
  handleSubmit,
  LessonDetail,
  handleRecordVideo,
  handleUploadVideo,
  handleFileSelect,
  fileInputRef,
  isloading,
  isLoadingVideoURL,
  openModal,
  handleCloseModal,
  handleLoading,
  videoURL,
}) => {
  const { userName, role } = useGradingLessonDetail();
  const [openSlides, setOpenSlides] = useState(false);

  const handleSlides = () => {
    setOpenSlides(true);
  };

  const handleCloseSlides = () => {
    setOpenSlides(false);
  };

  useEffect(() => {
    localStorage.setItem("gradingToggelTabState", JSON.stringify("my"));
  }, []);

  return (
    <>
      <div className={"SubmitVideoMainContainer"}>
        <div className={"contentContainer"}>
          <GradingHeader
            LessonDetail={LessonDetail}
            handleSlides={handleSlides}
            role={role}
            userName={userName}
          />
          <div className={"buttonContainer"}>
            <h4 className={"videoQuizTitle"}>Video Quiz</h4>
          </div>
          <article className={"videoContentWrapper"}>
            <p className={"text"}>Record and upload video</p>
            <div className={"paper"}>
              <div className={"buttonGroup"}>
                <button className={"button"} onClick={handleRecordVideo}>
                  <FiberSmartRecordIcon className={"icon"} />
                  <span className={"buttonText"}>Record</span>
                </button>
                <div>
                  <button className={"button"} onClick={handleUploadVideo}>
                    <UploadIcon className={"icon"} />
                    <span className={"buttonText"}>Upload</span>
                  </button>
                  <input
                    type="file"
                    className={"fileInput"}
                    ref={fileInputRef}
                    accept="video/*"
                    onChange={handleFileSelect}
                  />
                </div>
              </div>
              {!isLoadingVideoURL && videoURL && (
                <div className={"videoContainer"}>
                  <video src={videoURL} controls className={"video"}></video>
                </div>
              )}
            </div>
            <VideoModal
              open={openModal}
              handleClose={handleCloseModal}
              handleLoading={handleLoading}
            />
          </article>
        </div>
        <div className={"submitBtnContainer"}>
          {isloading && isLoadingVideoURL ? (
            <Stack spacing={2} m={"1rem"}>
              <GradingLoading />
            </Stack>
          ) : isLoadingVideoURL ? (
            <button className={"submitButton"} disabled>
              Submit
            </button>
          ) : (
            <button className={"submitButton"} onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </div>
      <SlidesModal
        open={openSlides}
        handleClose={handleCloseSlides}
        LessonDetail={LessonDetail}
      />
    </>
  );
};
