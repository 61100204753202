import {
  SET_MY_COURSE_LOADING,
  SET_MY_COURSE,
  // SET_MY_COURSE_LESSONS_Data,
  SET_MY_COURSE_LESSONS_DETAILS,
  SET_MY_COURSE_LESSONS_DETAILS_LOADING,
  SET_DISPLAY_SECTION,
  SET_MY_COURSE_QUESTION_COMPLETE,
  SET_MY_COURSE_INITIAL,
  SET_MY_COURSE_LESSONS,
  GET_QUIZ_SUMMARY_LOADING,
  GET_QUIZ_SUMMARY,
  // SET_MY_LESSONS
  SET_MY_COURSE_LESSON_COUNT_LOADING,
  SET_MY_COURSE_LESSONS_COUNT,
} from "./constants";
import { invalidToken } from "../../../../utilities";
import { myCourseServices } from "./services";

export function questionCompleted(data) {
  return function (dispatch) {
    myCourseServices.questionCompleted(data).then((response) => {
      dispatch({
        type: SET_MY_COURSE_QUESTION_COMPLETE,
        payload: 1,
      });
    });
  };
}

// getLessonDetailsById = (active_lesson_details) => {
//   var lessons_data = {
//       lesson_id: active_lesson_details._id,
//       isQuestions: '1'
//   }
//   myCourseServices.getLessonDetailsById(lessons_data).then(response => {
//       if (response.data.success === 2) {
//           // invalidToken(response.data.message)
//       } else {
//           active_lesson_details["lesson_type"] = response.data.lessonDetails.lesson_type
//           active_lesson_details["active_question_index"] = response.data.lessonDetails.active_question_index
//           active_lesson_details["lesson_content"] = response.data.lessonDetails.lesson_content
//           active_lesson_details["videoUrl"] = response.data.lessonDetails.videoUrl
//           active_lesson_details["pptUrl"] = response.data.lessonDetails.pptUrl
//           active_lesson_details["lessonQuestions"] = response.data.lessonQuestions

//           this.setState({
//               isLoading: false,
//               active_lesson_details: active_lesson_details,
//               // lessonQuestions :
//           })
//       }
//   });
// }

export function getLessonDetailsById(data) {
  return function (dispatch) {
    dispatch({
      type: SET_MY_COURSE_LESSONS_DETAILS_LOADING,
      payload: true,
    });
    myCourseServices.getLessonDetailsById(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_MY_COURSE_LESSONS_DETAILS,
          payload: response.data,
        });
      }
    });
  };
}

// export  function getLessonByCourses(course) {
//   return function (dispatch) {
//     dispatch({
//       type: SET_MY_COURSE_LOADING,
//       payload: true
//     });
//     myCourseServices.getLessonByCourses(course.course_id._id).then(myresponse => {
//       if (myresponse.data.success === 2) {
//         invalidToken(myresponse.data.message)
//       }
//       else if (myresponse.data.success === 1) {
//         // this.setState({
//         //   active_course_details: course.course_id,
//         //   courseLessonsData: myresponse.data.lessonData,
//         //   displaySection: "lesson",
//         //   isLoading: myresponse.data.lessonData.length > 0 ? true : false,
//         // })
//         if (myresponse.data.lessonData.length > 0) {
//           var active_lesson_details = myresponse.data.lessonData[0]
//           // this.setState({
//           //   active_lesson_details: active_lesson_details
//           // })
//           // this.getLessonDetailsById(active_lesson_details);
//           // this.setDisplaySection("lesson");
//           dispatch({
//             type: SET_MY_COURSE_LESSONS_DETAILS,
//             payload: active_lesson_details,
//             // payload: active_course_details
//           });
//         }
//       } else {
//         alert.error(myresponse.data.message)
//         // this.setState({
//         //   active_lesson_details: {},
//         //   active_course_details: course,
//         //   displaySection: "lesson",
//         //   courseLessonsData: [],
//         //   isLoading: false
//         // })
//       }
//     });
//   }
// }

export function getLessonByCourses(data) {
  return function (dispatch) {
    dispatch({
      type: SET_MY_COURSE_LOADING,
      payload: true,
    });
    myCourseServices.getLessonByCourses(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_MY_COURSE_LESSONS,
          payload: response.data,
        });
      }
    });
  };
}

export function getMyLessons(data) {
  return function (dispatch) {
    dispatch({
      type: SET_MY_COURSE_LOADING,
      payload: true,
    });
    myCourseServices.getMyLessons(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_MY_COURSE_LESSONS,
          payload: response.data,
        });
      }
    });
  };
}

export function getMyLessonsCount() {
  return function (dispatch) {
    dispatch({
      type: SET_MY_COURSE_LESSON_COUNT_LOADING,
      payload: true,
    });
    myCourseServices.getMyLessonsCount().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_MY_COURSE_LESSONS_COUNT,
          payload: response.data,
        });
      }
    });
  };
}

export function getQuizSummary(slide_id) {
  return function (dispatch) {
    dispatch({
      type: GET_QUIZ_SUMMARY_LOADING,
      payload: true,
    });
    myCourseServices.getQuizSummary(slide_id).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_QUIZ_SUMMARY,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchData(isReload) {
  return function (dispatch) {
    dispatch({
      type: SET_MY_COURSE_INITIAL,
      payload: true,
    });

    myCourseServices.fetchData().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_MY_COURSE,
          payload: response.data,
        });
      }
    });
  };
}

export function setDisplaySection(section) {
  return function (dispatch) {
    dispatch({
      type: SET_DISPLAY_SECTION,
      payload: section,
    });
  };
}
