import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from 'axios';

export const teamAvailabilityService = {
    fetchData,
    addAvailability,
    editAvailability,
    approveAvailability,
    deleteAvailability
}

function fetchData(data) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/teamavailability/v3/${data.lid}`, HEADER_TOKEN).then(response => response)
};


function addAvailability(data) {
    data.status = 0
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/myavailability/existing/new`, { data }, HEADER_TOKEN).then(response => response)
};

function approveAvailability(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/teamavailability/approve/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function editAvailability(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/myavailability/${data.id}`, { data }, HEADER_TOKEN).then(response => response)
};

function deleteAvailability(id) {
    return axios.delete(`${MoNGO_DB_API_URL}/scheduler/myavailability/delete/${id}`, HEADER_TOKEN).then(response => response)
};
