import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 50,
    width: 50,
    marginBottom: "1rem",
  },
  CardContent: {
    padding: "8px 0px",
    "&:last-child": {
      padding: "0",
    },
  },
  card: {
    width: 230,
    margin: "6px",
    boxShadow: "0px 2px 5px rgba(83, 97, 255, 0.0534986)",
    borderRadius: "8px",
    "&:hover": {
      boxShadow:
        "4px 8px 20px 0 rgba(0, 0, 0, 0.5), 4px 8px 20px 0 rgba(0, 0, 0, 0.5)",
    },
  },
  topDiv: {
    padding: "1rem 0",
  },
  category_description: {
    fontSize: "16px",
    lineHeight: "4rem",
    color: "#ffffff",
    textAlign: "center",
    verticalAlign: "center",
    fontWeight: 500,
    minHeight: "25px",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  category_name: {
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "3rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  positiveImage: {
    display: "flex",
    justifyContent: "center",
    fontSize: 36,
  },
  countSection: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ProductCard = (props) => {
  const { data } = props;
  const classes = useStyles();

  return (
    <Card
      style={{ backgroundColor: data.bgColor ? data.bgColor : "#6FCF97" }}
      className={classes.card}
      sm={4}
      xs={4}
      md={4}
    >
      <CardContent className={classes.CardContent}>
        <Grid className={classes.topDiv} container spacing={0}>
          <Grid item sm={12} xs={12}>
            <Grid style={{ padding: "0 1rem" }} container spacing={0}>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.category_name}>
                  {data.category_name}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid container spacing={0}>
                  <Grid item sm={6} xs={6} className={classes.positiveImage}>
                    😃
                  </Grid>

                  <Grid item sm={6} xs={6} className={classes.positiveImage}>
                    😕
                  </Grid>

                  <Grid item sm={6} xs={6} className={classes.countSection}>
                    <Typography
                      variant="h3"
                      style={{
                        fontWeight: 500,
                        color: "#ffffff",
                      }}
                    >
                      {data.categorypositiveFeedbackCount}
                    </Typography>
                  </Grid>

                  <Grid item sm={6} xs={6} className={classes.countSection}>
                    <Typography
                      variant="h3"
                      style={{
                        fontWeight: 500,
                        color: "#ffffff",
                      }}
                    >
                      {data.categorynegativeFeedbackCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ color: "#ffffff" }} />

        <Typography className={classes.category_description}>
          {data.category_description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
