import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { NoPermission, LoadingData } from '../../../components'
import { getScheduleData, updateScheduleData } from './modules/actions';
import { connect } from "react-redux";
import { schedulerColor } from '../../../config';
import ApplyToAllLocation from "./ApplyToAllLocation";
import {
    Button,
    CardContent,
    Typography,
    FormControl,
    Grid,
    Card,
    Checkbox,
    FormControlLabel,
    OutlinedInput,

}
    from '@material-ui/core';
const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    mainDiv: {
        border: "2px solid #7C7C7C",
        borderRadius: "6px",
        margin: "20px",
        BoxSizing: "border-box"
    },
    HeadCell: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        color: "#232056",
        marginLeft: 10
    },
    buttonMain: {
        textAlign: "right",
        fontWeight: 700
    },
    button: {
        background: "#005F73",
        borderRadius: 8,
        color: "white",
        padding: 10,
    },
    flex: {
        display: "flex",
        margin: "20px",
    },
    Heading: {
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "36px",
        fontWeight: 700
    },
    HeadingValue: {
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "36px",
    },
    HeadingColor: {
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "36px",
        color: "#B71C1C",
        fontWeight: 700
    },
    HeadingCompany: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "33px",
    },
});

export function fetchSCSettingsTimeoff() {
    window.TimeOffPTO.getScheduleData();
}

class TimeOffPTO extends Component {
    constructor(props) {
        super(props)
        window.TimeOffPTO = this;
        this.state = {
            applyToAll: 0,
            emoji: 1,
            anchorEl: null,
            data: {
            },
            ScheduleDay: [
                { value: 1, label: "Monday" },
                { value: 2, label: "Tuesday" },
                { value: 3, label: "Wednesday" },
                { value: 4, label: "Thrusday" },
                { value: 5, label: "Friday" },
                { value: 6, label: "Saturday" },
                { value: 0, label: "Sunday" }
            ],
            ScheduleTime: [
                { value: "00:00:00", label: "12AM" },
                { value: "01:00:00", label: "01AM" },
                { value: "02:00:00", label: "02AM" },
                { value: "03:00:00", label: "03AM" },
                { value: "04:00:00", label: "04AM" },
                { value: "05:00:00", label: "05AM" },
                { value: "06:00:00", label: "06AM" },
                { value: "07:00:00", label: "07AM" },
                { value: "08:00:00", label: "08AM" },
                { value: "09:00:00", label: "09AM" },
                { value: "10:00:00", label: "10AM" },
                { value: "11:00:00", label: "11AM" },
                { value: "12:00:00", label: "12PM" },
                { value: "13:00:00", label: "01PM" },
                { value: "14:00:00", label: "02PM" },
                { value: "15:00:00", label: "03PM" },
                { value: "16:00:00", label: "04PM" },
                { value: "17:00:00", label: "05PM" },
                { value: "18:00:00", label: "06PM" },
                { value: "19:00:00", label: "07PM" },
                { value: "20:00:00", label: "08PM" },
                { value: "21:00:00", label: "09PM" },
                { value: "22:00:00", label: "10PM" },
                { value: "23:00:00", label: "11PM" }
            ],

        }
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    componentDidMount = () => {
        this.getScheduleData()
    }

    getScheduleData = () => {
        this.setState({
            isLoading: true
        })
        var lid = this.props.scheduler_lid
        this.props.getScheduleData(lid)
    }

    componentDidUpdate(prevProps) {
        if (this.props.LocationDetaildata !== prevProps.LocationDetaildata) {
            this.setState({
                isLoading: false,
                data: this.props.LocationDetaildata.locationDetail
            })
        }
    }

    handleChangeData = (e) => {
        let state = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: checked === true ? 1 : 0
            }
        }))
    }

    handleChangeText = (e) => {
        let state = e.target.name
        let value = e.target.value
        if (state) {
            const validMin = value.match(/^[0-9]+$/);
            if (!validMin && value !== '') {
                alert('Not a valid number')
            }
        }
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: value
            }
        }))
    }

    handleChangeDay = (e) => {
        let state = e.target.name
        let value = e.target.value
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [state]: e.target.value
            }
        }))
    };

    onsubmitData = () => {
        var data = {
            lid: this.state.data.lid,
            applyToAll: this.state.applyToAll,
            timeoff_limit: this.state.data.timeoff_limit,
            timeoff_people_per_day: this.state.data.timeoff_people_per_day,
            timeoff_do_not_allow: this.state.data.timeoff_do_not_allow,
            timeoff_do_not_allow_days: this.state.data.timeoff_do_not_allow_days
        }
        this.props.updateScheduleData(data)
    }

    handleApplyToAll = (e) => {
        this.setState({
            applyToAll: e.target.checked ? 1 : 0
        })
    }

    render() {
        const { permissionsData, classes, LocationDetaildata, isLoading } = this.props;
        if (this.state.isLoading) return <LoadingData />
        if (isLoading) return <LoadingData />
        var open = Boolean(this.state.anchorEl)
        return (
            permissionsData.scheduler_settings ?
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} >
                        <ApplyToAllLocation
                            applyToAll={this.state.applyToAll}
                            handleApplyToAll={this.handleApplyToAll}
                            handleSave={this.onsubmitData}
                        />
                         <Typography style={{marginLeft:22,fontSize:10,lineHeight:0}}><span style={{color:"red"}}>*</span> Except payroll rule , geo-fence and Break Rules</Typography>
                    </Grid>

                    <Grid
                        item
                        md={12}
                    >
                        <Grid container spacing={2}
                        >
                            <Grid className={classes.flex}>
                                <Grid
                                    style={{
                                        background: "#005F73",
                                        height: 10,
                                        width: 10
                                    }} />
                                <Typography
                                    style={{ lineHeight: '12px' }}
                                    className={classes.HeadCell}
                                >
                                    Time Off & PTO
                                </Typography>
                            </Grid>
                        </Grid>

                        <Card>
                            <CardContent>
                                <Typography style={{ fontSize: 14, color: 'black', fontWeight: 500 }} gutterBottom>
                                    Time Off Policy
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontSize: 14, fontWeight: 400, color: 'black' }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="timeoff_limit"
                                            checked={this.state.data.timeoff_limit === 0 ? false : true}
                                            onChange={this.handleChangeData}
                                            color={schedulerColor.main} />}
                                    />
                                    Limit time off requests to no more than
                                    <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                        <OutlinedInput
                                            style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                            value={this.state.data.timeoff_people_per_day}
                                            onChange={this.handleChangeText}
                                            name="timeoff_people_per_day"
                                        />
                                    </FormControl>
                                    people per day.
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontSize: 14, color: 'black', fontWeight: 400, }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="timeoff_do_not_allow"
                                            checked={this.state.data.timeoff_do_not_allow ? true : false}
                                            onChange={this.handleChangeData}
                                            color={schedulerColor.main} />}
                                    />
                                    Do not allow time off requests less than
                                    <FormControl variant="outlined" sx={{ m: 1, width: '25ch' }}>
                                        <OutlinedInput
                                            style={{ height: 20, width: 50, marginTop: 10, marginLeft: 10, marginRight: 10 }}
                                            value={this.state.data.timeoff_do_not_allow_days}
                                            onChange={this.handleChangeText}
                                            name="timeoff_do_not_allow_days"
                                        />
                                    </FormControl>
                                    days in advance.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> :
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item md={12} className={classes.buttonMain}>
                        <NoPermission />
                    </Grid>
                </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerSettingsLocationdata.isLoading,
    LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata,
});

const ConnectWith = connect(mapStateToProps, { getScheduleData, updateScheduleData })(TimeOffPTO);
export default withStyles(styles)(ConnectWith);
