import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { check } from "../../../assets/images/mobileView/icons";

import { RxCross2 } from "react-icons/rx";

const styles = (theme) => ({
  dialog: {
    borderRadius: "5px",
  },
  dialogcontent: {
    backgroundColor: "white",
    minHeight: "350px",
    minWidth: "350px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "17px",
  },
  earningContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  iconImage: {
    width: "50px",
    height: "50px",
    objectFit: "contain",
  },
  title: {
    fontSize: "34px",
    fontWeight: "700",
    color: "#1B3F8F",
  },
  contentText: {
    fontSize: "22px",
    fontWeight: "400",
    color: "#000000CC",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "20px",
    fontSize: "20px",
    color: "black",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EarningLessonPopup = (props) => {
  const { classes, open, onClose, points, amount } = props;
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        className={classes.dialog}
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.dialogcontent} dividers>
          <div className={classes.closeIcon} onClick={onClose}>
            <RxCross2 />
          </div>
          <Typography className={classes.title}>Congratulations!</Typography>
          <img src={check} alt="" className={classes.iconImage} />
          <div className={classes.earningContentContainer}>
            <Typography className={classes.contentText}>
              You have earned
            </Typography>
            {points && (
              <Typography className={classes.contentText}>
                {points} Points
              </Typography>
            )}
            {amount && (
              <Typography className={classes.contentText}>
                {amount}$ amount
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(EarningLessonPopup);
