import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class Loading extends Component {
  render() {
    var currentLocation = window.location.pathname;
    let eLearningColorFlag = false;
    eLearningColorFlag = currentLocation.includes("/elearning/") ? true : false;
    let teamFeedbackFlag = false;
    teamFeedbackFlag = currentLocation.includes("/teamfeedback/") ? true : false;
    return (
      //       <div className="loader"></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        {" "}
        <CircularProgress
          style={{ color: eLearningColorFlag ? "#1B3F8F" : teamFeedbackFlag ? "#0C166F" : "secondary" }}
          // color={eLearningColorFlag ? "blue" : "secondary"}
        />
      </div>
    );
  }
}
