import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import {
  feedbackColor,
  eLearningColor,
  todoColor,
  schedulerColor,
  settingColor,
  newsandupdate,
  ovChatColor,
  ovFormsColor,
  ovHrColor,
  ratingColor,
  TeamFeedbackColor,
} from "../../config";

const useStyles = makeStyles((theme) => ({
  link: {
    padding: "6px 8px",
    borderRadius: "0.375rem",
    textDecoration: "none",
    marginBottom: ".15rem",
    borderRadius: "0.375rem",
    marginBottom: "1rem",
  },
  borderDiv: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    height: "45px",
    marginLeft: "-18px",
    marginRight: "15px",
    textDecoration: "none",
  },
  linkActive: {
    backgroundColor: todoColor.main,

    textDecoration: "none",
  },
  ovRoutineActive: {
    backgroundColor: ovFormsColor.main,
    boxShadow: "4px 2px 4px rgba(0, 0, 0, 0.25)",
    textDecoration: "none",
  },
  feedbackActive: {
    backgroundColor: feedbackColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: feedbackColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  ovHRActive: {
    backgroundColor: ovHrColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: ovHrColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  eLearningActive: {
    backgroundImage: eLearningColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundImage: eLearningColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  todoActive: {
    backgroundColor: todoColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: todoColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  NewsActive: {
    backgroundColor: "#8E0C0C",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#8E0C0C",
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  RatingActive: {
    backgroundColor: TeamFeedbackColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: TeamFeedbackColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  OvChatActive: {
    backgroundColor: ovChatColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: ovChatColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  OvRoutineActive: {
    backgroundColor: ovFormsColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: ovFormsColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  schedulerActive: {
    backgroundColor: schedulerColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: schedulerColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  settingActive: {
    backgroundColor: settingColor.main,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: settingColor.main,
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      "& $linkText": {
        color: "#ffffff",
      },
      "& $linkIcon": {
        color: "#ffffff",
      },
      "& $linkTextCount": {
        color: "#ffffff",
      },
    },
  },
  avatar: {
    height: 18,
    width: 18,
    marginRight: "10px",
    opacity: "85%",
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
  },
  linkIconActive: {
    color: "#ffffff",
  },
  linkText: {
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#0000008a",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    textDecoration: "none",
  },
  linkTextCount: {
    WebkitLineClamp: 1,
    overflow: "hidden",
    float: "right",
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextActive: {
    color: "#ffffff",
  },
  linkTextHidden: {
    opacity: 0,
  },
}));

export default function SidebarLink({
  countData,
  clickLink,
  path,
  icon,
  image,
  active_image,
  label,
  count,
  location,
  isSidebarOpen,
  activeProduct,
  todoId,
  tooltip,
}) {
  var classes = useStyles();
  var isLinkActive = path && location.pathname === path;
  const query = new URLSearchParams(location.search);
  var newId = query.get("id");
  var todoIdLinkActive = todoId === parseInt(newId) ? true : false;
  var [toolTip, setToolTip] = useState(false);

  const openTooltip = () => {
    if (!isSidebarOpen) {
      setToolTip(true);
    }
  };

  const closeTooltip = () => {
    setToolTip(false);
  };

  return (
    <Tooltip
      arrow
      placement="right"
      open={toolTip}
      onClose={closeTooltip}
      onOpen={openTooltip}
      title={<p className={classes.popoverTitle}>{label}</p>}
    >
      <Tooltip arrow title={isLinkActive ? tooltip : ""}>
        <ListItem
          onClick={clickLink}
          button
          component={path && Link}
          to={path}
          classes={{
            root: classnames(classes.link, {
              [classes.linkActive]: todoId ? todoIdLinkActive : isLinkActive,
              [classes.feedbackActive]:
                activeProduct === "Feedback" && isLinkActive ? true : false,
              [classes.eLearningActive]:
                activeProduct === "eLearning" && isLinkActive ? true : false,
              [classes.todoActive]:
                activeProduct === "Todo" && isLinkActive ? true : false,
              [classes.NewsActive]:
                activeProduct === "News" && isLinkActive ? true : false,
              [classes.RatingActive]:
                activeProduct === "Team Feedback" && isLinkActive ? true : false,
              [classes.schedulerActive]:
                activeProduct === "Scheduler" && isLinkActive ? true : false,
              [classes.settingActive]:
                activeProduct === "Settings" && isLinkActive ? true : false,
              [classes.ovHRActive]:
                activeProduct === "ovHR" && isLinkActive ? true : false,
              [classes.ovRoutineActive]:
                activeProduct == "Forms" && isLinkActive ? true : false,
            }),
          }}
          disableRipple
        >
          {/* <div
            className={classes.borderDiv}
            style={{
              borderLeft:
                activeProduct === "Feedback" && isLinkActive ?
                  `5px solid ${feedbackColor.main}`
                  : activeProduct === "eLearning" && isLinkActive ?
                    `5px solid ${eLearningColor.main}`
                    : activeProduct === "Todo" && todoId ?
                      `5px solid ${todoColor.main}`
                      // : activeProduct === "Todo" && isLinkActive ?
                      //   `5px solid ${todoColor.main}`
                      : activeProduct === "News" && isLinkActive ?
                        `5px solid ${newsandupdate.main}`
                        : activeProduct === "OV Chat" && isLinkActive ?
                          `5px solid ${ovChatColor.main}`
                          : activeProduct === "Forms" && isLinkActive ?
                            `5px solid ${ovFormsColor.main}`
                            : activeProduct === "Scheduler" && isLinkActive ?
                              `5px solid ${schedulerColor.main}`
                              : activeProduct === "Settings" && isLinkActive ?
                                `5px solid ${settingColor.main}`
                                : activeProduct === "ovHR" && isLinkActive ?
                                  `5px solid ${ovHrColor.main} `
                                  : activeProduct === "360 Rating" && isLinkActive ?
                                    `5px solid ${ratingColor.main}`
                                    : activeProduct === "OV Chat" && isLinkActive ?
                                      `5px solid ${ovChatColor.main}`
                                      : activeProduct === "Forms" && isLinkActive ?
                                        `5px solid ${ovFormsColor.main}`
                                        : activeProduct === "Scheduler" && isLinkActive ?
                                          `5px solid ${schedulerColor.main}`
                                          : activeProduct === "Settings" && isLinkActive ?
                                            `5px solid ${settingColor.main}`
                                            : `5px solid #ffffff`,
            }}
          /> */}

          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: todoId
                ? todoIdLinkActive
                : isLinkActive,
            })}
          >
            {image ? (
              <Avatar
                variant="square"
                className={classes.avatar}
                src={isLinkActive ? active_image : image}
              />
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: todoId
                  ? todoIdLinkActive
                  : isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpen,
              }),
            }}
            primary={label}
          />
          {count !== undefined ? (
            <ListItemText
              classes={{
                primary: classnames(classes.linkTextCount, {
                  [classes.linkTextActive]: todoId
                    ? todoIdLinkActive
                    : isLinkActive,
                  [classes.linkTextHidden]: !isSidebarOpen,
                }),
              }}
              primary={countData && countData[count]}
            />
          ) : (
            ""
          )}
        </ListItem>
      </Tooltip>
    </Tooltip>
  );
}
