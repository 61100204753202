import {
  SET_SC_TEAM_LOADING,
  SET_SC_TEAM
} from "./constants";
import { invalidToken } from '../../../../utilities';
import { scTeamServices } from './services'

export function fetchData(lid, isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_SC_TEAM_LOADING,
        payload: true
      });
    }
    scTeamServices.fetchData(lid).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_SC_TEAM,
          payload: response.data
        });
      }
    });
  };
}