import {
  MoNGO_DB_API_URL,
  API_URL,
  HEADER_TOKEN,
  MY_TOKEN,
} from "../../../../config";
import axios from "axios";
import qs from "qs";

export const categoryServices = {
  fetchData,
  addData,
  editData,
  deleteData,
  fetchCategoryy,
};

// function fetchData(data, value) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/category/v3/library?serchcat=${
//         value ? value : ""
//       }`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

// function fetchData(data, value) {
//   return axios
//     .post(
//       `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${
//         process.env.REACT_APP_PLATFORM
//       }/elearning/category/library?searchCat=${value ? value : ""}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function fetchData(data, value) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category/library`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}
// function fetchData(data, value) {
//   return axios
//     .get(
//       `${process.env.REACT_APP_LOCAL_HOST_URL}/${process.env.REACT_APP_PLATFORM}/elearning/category/library`,
//       {
//         headers: { Authorization: `Bearer ${MY_TOKEN}` },
//         params: data,
//       }
//     )
//     .then((response) => console.log("Response:", MY_TOKEN));
// }
// function fetchData(data, value) {
//   const baseUrl = `${process.env.REACT_APP_LOCAL_HOST_URL}/${process.env.REACT_APP_PLATFORM}/elearning/category/library`;
//   // const newdata = { page: 1, limit: 10 };
//   // const params = new URLSearchParams(data);

//   const config = {
//     method: "get",
//     url: `${baseUrl}`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     params: data,
//   };

//   return axios
//     .get(config)
//     .then((response) => {
//       console.log("Response:", response, "REESSSPONSSEEE");
//       // return response;
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//       throw error;
//     });
// }

// function fetchCategoryy() {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/elearning/category/lesson/permission`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchCategoryy() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category/details`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addData(myData) {
//   const data = new FormData();
//   data.append("category_name", myData.category_name);
//   data.append("file", myData.image, myData.image.name);
//   var config = {
//     method: "post",
//     url: `${MoNGO_DB_API_URL}/elearning/category`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     data: data,
//   };
//   return axios(config).then((response) => response);
// }

function addData(myData) {
  const data = new FormData();
  data.append("category_name", myData.category_name);
  data.append("file", myData.image, myData.image.name);
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category`,
    headers: {
      Authorization: MY_TOKEN,
    },
    data: data,
  };
  return axios(config).then((response) => response);
}

// function editData(myData) {
//   const data = new FormData();
//   data.append("_id", myData._id);
//   data.append("category_name", myData.category_name);
//   data.append("category_lesson", myData.category_lesson);
//   if (myData.changeFile) {
//     data.append("file", myData.image, myData.image.name);
//   }
//   var config = {
//     method: "post",
//     url: `${MoNGO_DB_API_URL}/elearning/category/edit`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     data: data,
//   };
//   return axios(config).then((response) => response);
// }

function editData(myData) {
  const data = new FormData();
  // data.append("_id", myData._id);
  data.append("category_name", myData.category_name);
  data.append("category_lesson", myData.category_lesson);
  if (myData.changeFile) {
    data.append("file", myData.image, myData.image.name);
  }
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category/${myData._id}`,
    headers: {
      Authorization: MY_TOKEN,
    },
    data: data,
  };
  return axios(config).then((response) => response);
}

// function deleteData(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/category/library/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function deleteData(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
