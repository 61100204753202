import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

import { mobileTheme } from "../../constant";

const useStyles = makeStyles((theme) => ({
  FeedbackCardLink: {
    color: "#212121",
    textDecoration: "none !important",
  },
  FeedbackCardArticle: {
    minWidth: "320px",
    maxWidth: "364px",
    minHeight: "57px",
    maxHeight: "auto",
    borderRadius: "12px",
    boxShadow: "5px 5px 21px 4px #00000014",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
    alignItems: "center",
  },
  FeedbackCardContentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    width: "100%",
  },
  FeedbackCardSerialNumber: {
    width: "38.68px",
    height: "35px",
    borderRadius: "6px",
    background: mobileTheme,
    color: "white",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  FeedbackCardInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    width: "85%",
  },
  FeedbackCardInfoContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "2px",
  },
  FeedbackCardDescription: {
    fontSize: "14px",
    fontWeight: "500",
    color: "rgba(33, 33, 33, 0.85)",
  },
  FeedbackCardTagsStatusWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "4px",
  },
  FeedbackCardTagsWrapper: {
    display: "flex",
    width: "100px",
    overflowX: "auto",
    justifyContent: "start",
    alignItems: "center",
    gap: "3px",
    paddingBottom: "2px",
  },
  FeedbackCardLessonWrapper: {
    fontSize: "10px",
    fontWeight: "500",
    color: "#635BFF",
    backgroundColor: "rgba(99, 91, 255, 0.25)",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "18px",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  FeedbackCardSeparator: {
    width: "1px",
    height: "15px",
    backgroundColor: "rgba(99, 91, 255, 0.25)",
  },
  FeedbackCardStatusContainer: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
  },
  FeedbackCardDate: {
    fontSize: "8px",
    fontWeight: "500",
    color: "rgba(33, 33, 33, 0.5)",
  },
  FeedbackCardIcon: {
    fontSize: "15px !important",
    color: (props) =>
      props.status === "new"
        ? "#EB8B45"
        : props.status === "closed"
        ? "#28A745"
        : props.status === "open"
        ? "#DC3030"
        : "",
  },
  FeedbackCardText: {
    textTransform: "uppercase",
    fontSize: "10px !important",
    fontWeight: "400 !important",
    color: (props) =>
      props.status === "new"
        ? "#EB8B45"
        : props.status === "closed"
        ? "#28A745"
        : props.status === "open"
        ? "#DC3030"
        : "",
  },
}));

export const FeedbackCard = ({
  id,
  status,
  description,
  lesson,
  slide,
  date,
  serialNumber,
}) => {
  let inputDate = new Date(date);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = inputDate.toLocaleDateString("en-US", options);

  const classes = useStyles({ status });

  return (
    <Link
      to={"/elearning/feedbacks/" + id}
      className={classes.FeedbackCardLink}
    >
      <Paper className={classes.FeedbackCardArticle}>
        <Box className={classes.FeedbackCardContentWrapper}>
          <Box className={classes.FeedbackCardSerialNumber}>{serialNumber}</Box>

          <Box className={classes.FeedbackCardInfoContainer}>
            <Box className={classes.FeedbackCardInfoContentWrapper}>
              <Typography className={classes.FeedbackCardDescription}>
                {description}
              </Typography>
              <Box className={classes.FeedbackCardTagsStatusWrapper}>
                <Box className={`${classes.FeedbackCardTagsWrapper} scroll`}>
                  {lesson && (
                    <Chip
                      label={lesson}
                      className={classes.FeedbackCardLessonWrapper}
                    />
                  )}
                  {slide && (
                    <Chip
                      label={slide}
                      className={classes.FeedbackCardLessonWrapper}
                    />
                  )}
                </Box>
                <Divider
                  orientation="vertical"
                  className={classes.FeedbackCardSeparator}
                />
                <Box className={classes.FeedbackCardStatusContainer}>
                  <FiberManualRecordIcon className={classes.FeedbackCardIcon} />
                  <Typography className={classes.FeedbackCardText}>
                    {status}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography className={classes.FeedbackCardDate}>
              {formattedDate}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Link>
  );
};

export default FeedbackCard;
