import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { DefaultRating } from "../Rating/DefaultRating";

import "./FeedbackModal.scss";

export const FeedbackModal = ({
  open,
  handleClose,
  handleFeedback,
  handleFeedbackSubmitted,
  handleRating,
  rating,
  feedback,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"FeedbackMobileModalContainer"}>
        <CloseIcon
          className={"FeedbackMobileCloseIcon"}
          onClick={handleClose}
        />
        <div className={"FeedbackMobileContentContainer"}>
          <div className={"FeedbackMobileTitle"}>Give Your Feedback!!</div>
          <div className={"FeedbackMobileSubtitle"}>
            give your honest feedback <br /> so, we can improve
          </div>
          <div className={"FeedbackMobileRatingContainer"}>
            <DefaultRating handleRating={handleRating} rating={rating} />
          </div>
          <textarea
            className={"FeedbackMobileTextarea"}
            value={feedback}
            onChange={(e) => handleFeedback(e.target.value)}
            placeholder="Write Feedback here....."
          />
          <button
            className={"FeedbackMobileSubmitButton"}
            onClick={() => handleFeedbackSubmitted()}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};
