import {
  SET_ELE_CATEGORY_LOADING,
  SET_ELE_CATEGORY,
  ADD_ELE_CATEGORY,
  EDIT_ELE_CATEGORY,
  DELETE_ELE_CATEGORY,
  SET_ELE_CATEGORY1,
  SET_LESSON_CATEGORY,
  SET_LESSON_CATEGORYDATA,
  SET_LESSON_NEWDATA,
  SET_ELE_CATEGORY_LOADING_Data,
  SET_FILTER_DATA,
  FETCH_CATEGORY

} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { categoryServices } from './services'

export function fetchData(data,value) {
  return function (dispatch) {
      dispatch({
        type: SET_ELE_CATEGORY_LOADING_Data,
        payload: true
      });

    categoryServices.fetchData(data,value).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_ELE_CATEGORY,
          payload: response.data
        });
      }
    });
  };
}

export function fetchCategoryy(data) {
  return function (dispatch) {
    categoryServices.fetchCategoryy(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: FETCH_CATEGORY,
          payload: response.data
        });
      }
    });
  };
}

export function getLesson(data) {
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_CATEGORY,
      payload: data
    });
};
}



export function getFilterData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_FILTER_DATA,
      payload: data
    });
};
}

export function getCategoryData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_CATEGORYDATA,
      payload: data
    });
};
}

export function getLessonData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_NEWDATA,
      payload: data
    });
};
}

export function fetchPayload(data) {
  return function (dispatch) {
      dispatch({
        type: SET_ELE_CATEGORY1,
        payload: data
      });
  };
}


export function deleteData(_id) {
  return function (dispatch) {
    dispatch({
      type: SET_ELE_CATEGORY_LOADING,
      payload: true
    });
    categoryServices.deleteData(_id).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else if (response.data.success === 1) {
        alert.success(response.data.message)
        dispatch({
          type: DELETE_ELE_CATEGORY,
          payload: _id
        });
      } else {
        alert.error(response.data.message)
        dispatch({
          type: SET_ELE_CATEGORY_LOADING,
          payload: false
        });
      }
    });
  };
}


export function addData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ELE_CATEGORY_LOADING,
      payload: true
    });
    categoryServices.addData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: ADD_ELE_CATEGORY,
          payload: response.data.post
        });
      }
    });
  };
}

export function editData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ELE_CATEGORY_LOADING,
      payload: true
    });
    categoryServices.editData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        alert.success(response.data.message)
        dispatch({
          type: EDIT_ELE_CATEGORY,
          payload: response.data.post
        });
      }
    });
  };
}