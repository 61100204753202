import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import _ from "lodash";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import './Hierarachy.css';
import AddIcon from '@mui/icons-material/Add';
import {
  fetchDataRoles, addHeirarachyChart, fetchHeirarachyChartData, editHeirarachyChart,
  fetchRoleData, deleteHeirarachyChart, fetchData,
} from "./modules/action";
import { connect } from "react-redux";
import EditDepartmentModal from "./EditDepartmentModal";
import DepartmentMoreMenuDropdown from "./DepartmentMoreMenuDropdown";
import AddLineModal from "./AddLineModal";
import CommonModal from "./CommonModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    borderRadius: 16,
    width: 200,
    height: 85,

    '& span': {
      fontSize: 16,
    },
  },
  departmentRoot: {
    display: "inline-block",
    borderRadius: 16,
    width: 172,
    height: 57,

    '& span': {
      fontSize: 16,
    },
  },
  addIcon: {
    background: '#595fe7',
    color: "white",
    cursor: "pointer",
    borderRadius: "0 150px 150px 0",
    height: 29,

    '& svg': {
      marginTop: 4,
      fontSize: 20,
      marginLeft: -4,
    },

    '& path': {
      color: 'white',
    }
  },
  emaptyCard: {
    width: 140,
    height: 20
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#977c4f"
  },
  titleModal: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: "space-between"
  },
  firstDiv: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  cardHeader: {
    cursor: 'pointer',
    color: 'black',
    fontWeight: '600'
  }
}));

function Organization(props) {
  const { org, onCollapse, collapsed, fetchDataRoles, heirarachyData, addHeirarachyChart, fetchData,
    fetchHeirarachyChartData, editHeirarachyChart, deleteHeirarachyChart, fetchRoleData, usersData,
    RoleData } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editDepartmentModal, setEditDepartmentModal] = React.useState(false);
  const [deleteRoleModal, setDeleteRoleModal] = React.useState(false);
  const [addLineModal, setAddLineModal] = React.useState(false);
  const [isAbove, setIsAbove] = React.useState("");
  const [editRoleModal, setEditRoleModal] = React.useState(false);
  const [userInputValue, setUserInputValue] = React.useState('');
  const [openAdd, setOpenAdd] = React.useState(null);
  const [isName, setIsName] = React.useState(null);
  const [openTopAdd, setOpenTopAdd] = React.useState(null);
  const [openBottomAdd, setOpenBottomAdd] = React.useState(null);
  const [openLeftAdd, setOpenLeftAdd] = React.useState(null);
  const [valueTab, setValueTab] = React.useState('1');
  const [assignHelpdeskModal, setAssignHelpdeskModal] = React.useState(false);
  const [styleAddIcon, setStyleAddIcon] = useState({ display: 'none' });
  const [roleInputValue, setRoleInputValue] = React.useState('');
  const [cardStyleBorder, setCardStyleBorder] = useState({ border: '1px solid rgba(0, 0, 0, 0.12)' });
  const [inputsValue, setInputsValue] = useState({
    name: '',
    mobile_no: '',
    title: '',
    date_of_birth: new Date(),
    address: '',
    location: '',
    id: "",
  });

  let newArrays = {}
  heirarachyData && heirarachyData.map((item) => {
    let returmName = item.departments && item.departments.map((row) => {
      let accountArray = row.department_role && row.department_role.map((child) => {
        let productArray = child && child.department_role_assis.map((parentItem) => {
          let user_obj = {
            name: parentItem.user_id && parentItem.user_id.user_name,
            id: parentItem && parentItem._id,
            title: parentItem.role_id && parentItem.role_id.role_name,
          }
          return user_obj
        })
        let child_obj = {
          name: child.user_id && child.user_id.user_name,
          id: child && child._id,
          title: child.role_id && child.role_id.role_name,
          product: productArray
        }
        return child_obj
      })
      let reurn_obj = {
        name: row && row.department_name,
        colorcode: row.colorcode,
        id: row && row._id,
        account: accountArray,
      }
      return reurn_obj
    })

    let assistantArray = item.assiatant && item.assiatant.map((item) => {
      let assistant_obj = {
        name: item.user_id && item.user_id.user_name,
        id: item && item._id,
        title: item.role_id && item.role_id.role_name,
      }
      return assistant_obj
    })
    newArrays = {
      name: item.user_id && item.user_id.user_name,
      title: item.role_id && item.role_id.role_name,
      id: item.user_id && item._id,
      assiatant: assistantArray,
      organizationChildRelationship: returmName,
    }
  })

  useEffect(() => {
    setInputsValue(org);
  }, [org]);

  useEffect(() => {
    fetchData();
    fetchRoleData();
  }, [])

  //onChange Input Click
  const onChangeInput = (e) => {
    e.persist();
    setInputsValue(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
  };

  //Tab handle change
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  //edit modal handle click
  // const onHandleOpenEditModal = () => {
  //   setEditRoleModal(true);
  //   setAnchorEl(null);
  // };

  // const onHandleCloseEditModal = () => {
  //   setEditRoleModal(false);
  // };

  //AssignHelpDesk modal handle click
  const onHandleOpenAssignHelpdesk = () => {
    setAssignHelpdeskModal(true);
    setAnchorEl(null);
  };

  // const onHandleCloseAssignHelpdesk = () => {
  //   setAssignHelpdeskModal(false);
  // };

  //add Dotted line modal onclick 
  const handleClickAddLineOpen = (title) => {
    setIsAbove(title);
    setAddLineModal(true);
  };
  
  const handleAddLineClose = () => {
    setAddLineModal(false);
  };

  //edit role user onchange 
  const handleChange = (event) => {
    setUserInputValue(event.target.value);
  };

  //edit role onchange 
  const handleChangeRole = (event) => {
    setRoleInputValue(event.target.value);
  };

  //add icon handle click
  const open = Boolean(openAdd);

  const handleAddRoles = (menuTitle, org, id) => {
    if (menuTitle === "Add Assistant") {
      if (org === "first") {
        let newArray = [...newArrays.mainRole, {
          "name": "",
          "title": "",
          "id": ""
        }]
        let objNew = { ...newArrays }
        objNew.mainRole = newArray
        fetchDataRoles(objNew)
      } else {
        const data = {
          user_id: null,
          role_id: null,
          parent: [id.id],
          isroot: 0,
          isrole: 0,
          isassistant: 1,
          isdepartment: 0,
          isuser: 0
        }
        addHeirarachyChart(data);
        setTimeout(() => {
          fetchHeirarachyChartData();
        }, 1000)
      }
    } else if (menuTitle === "Add Department") {
      const data = {
        user_id: null,
        role_id: null,
        parent: [id.id],
        isroot: 0,
        isrole: 1,
        isassistant: 0,
        isdepartment: 0,
        isuser: 0
      }
      addHeirarachyChart(data);
      setTimeout(() => {
        fetchHeirarachyChartData();
      }, 1000)
    } else if (menuTitle === "Add Role") {
      const data = {
        user_id: null,
        role_id: null,
        parent: [id.id],
        isroot: 0,
        isrole: 1,
        isassistant: 0,
        isdepartment: 0,
        isuser: 0
      }
      addHeirarachyChart(data);
      setTimeout(() => {
        fetchHeirarachyChartData();
      }, 1000)
    }
    setOpenAdd(null);
  };

  const handleCloseAddIcon = () => {
    setOpenAdd(null);
  };

  const handleClickAddIcon = (event) => {
    setOpenAdd(event.currentTarget);
  };

  //edit modal handle click
  const onHandleOpenEditDepartModal = () => {
    // setIsName(name);
    setEditDepartmentModal(true);
    setAssignHelpdeskModal(true);
    setAnchorEl(null);
  };

  const onHandleCloseEditDepartModal = () => {
    setEditDepartmentModal(false);
  };

  //delete modal handle click
  const onHandleOpenDeleteModal = () => {
    setDeleteRoleModal(true);
    setAnchorEl(null);
  };

  const onHandleCloseDeleteModal = () => {
    setDeleteRoleModal(false);
  };

  //more menu handle click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //onDelete function
  const onHandleDeleteClick = (id) => {
    deleteHeirarachyChart(id);
    setTimeout(() => {
      fetchHeirarachyChartData();
    }, 1000)
  };

  //onEdit Click function
  const onHandleEditClick = (editValue) => {
    const data = {
      role_name: editValue ? editValue.name : "",
      department_name: editValue ? editValue.name : "",
      colorcode: editValue ? editValue.colorcode : "",
      user_id: null,
      role_id: null,
      _id: org.id
    }
    setInputsValue(editValue);
    editHeirarachyChart(data);
    fetchHeirarachyChartData();
  };

  //onEdit Click function
  // const onHandleEditClick = (editValue, userName, item, roleName) => {
  //   let firstUser = usersData[0] && usersData[0].user_name
  //   let firstUserIDs = usersData[0] && usersData[0]._id
  //   let firstRole = RoleData[0] && RoleData[0].role_name
  //   let firstRoleIDs = RoleData[0] && RoleData[0]._id
  //   const data = {
  //     role_name: roleName !== undefined ? roleName : editValue.title === "" ? firstRole : editValue.title,
  //     user_name: userName !== undefined ? userName : editValue.name === "" ? firstUser : editValue.name,
  //     user_id: userInputValue ? userInputValue : firstUserIDs ? firstUserIDs : null,
  //     role_id: roleInputValue ? roleInputValue : firstRoleIDs ? firstRoleIDs : null,
  //     _id: org.id
  //   }
  //   setInputsValue({
  //     name: userName !== undefined ? userName : editValue.name === "" ? firstUser : editValue.name,
  //     title: roleName !== undefined ? roleName : editValue.title === "" ? firstRole : editValue.title,
  //     id: org.id
  //   })
  //   editHeirarachyChart(data);
  //   setTimeout(() => {
  //     fetchHeirarachyChartData();
  //   }, 1000)
  // }

  //Top Icon handle click
  const openTop = Boolean(openTopAdd);
  const handleCloseAddTopIcon = () => {
    setOpenTopAdd(null);
  };

  const handleClickAddTopIcon = (event) => {
    setOpenTopAdd(event.currentTarget);
  };

  //Bottom Icon handle click
  const openBottom = Boolean(openBottomAdd);
  const handleCloseAddBottomIcon = () => {
    setOpenBottomAdd(null);
  };

  const handleClickAddBottomIcon = (event) => {
    setOpenBottomAdd(event.currentTarget);
  };

  //Left Icon handle click
  const openLeft = Boolean(openLeftAdd);
  const handleCloseAddLeftIcon = () => {
    setOpenLeftAdd(null);
  };

  const handleClickAddLeftIcon = (event) => {
    setOpenLeftAdd(event.currentTarget);
  };


  const onHandleCloseEditModal = () => {
    setEditRoleModal(false);
};


const handleChangeDate = (date, state) => {
  setInputsValue(inputs => ({ ...inputs, [state]: date }));
};


  return (
    <div onMouseEnter={e => {
      setStyleAddIcon({ display: 'block' });
      setCardStyleBorder({ border: '3px solid #595fe7' })
    }}
      onMouseLeave={e => {
        setStyleAddIcon({ display: 'none' });
        setCardStyleBorder({ border: '1px solid rgba(0, 0, 0, 0.12)' })
      }}>

      {org.name === newArrays.name ?
        <div className={"topIcon"}>
          <AddIcon onClick={(event) => handleClickAddTopIcon(event)} style={styleAddIcon} />
          <Menu
            id="basic-menu"
            anchorEl={openTopAdd}
            open={openTop}
            onClose={handleCloseAddTopIcon}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleAddRoles("Add Department", org.name === newArrays.name ? newArrays.name : org.name, org)}>Add Role</MenuItem>
            {/* <MenuItem onClick={() => handleClickAddLineOpen("isAbove")}>Add Dotted Line</MenuItem> */}
          </Menu>
        </div> : ""}

      <div className={classes.firstDiv}>
        {org.name === newArrays.name ?
          <div className={"leftIcon"} style={styleAddIcon}>
            <AddIcon onClick={(event) => handleClickAddLeftIcon(event)} />
            <Menu
              id="basic-menu"
              anchorEl={openLeftAdd}
              open={openLeft}
              onClose={handleCloseAddLeftIcon}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleAddRoles("Add Department", org.name === newArrays.name ? newArrays.name : org.name, org)}>Add Role</MenuItem>
              {/* <MenuItem onClick={() => onHandleOpenEditDepartModal("isAdd")}>Add New Role</MenuItem> */}
            </Menu>
          </div> : ""}

        <Card
          // onClick={org.name == "" ? onHandleOpenEditDepartModal: ""}
          variant="outlined"
          className={org.name === newArrays.name ? classes.root : classes.departmentRoot}
          style={{
            ...cardStyleBorder,
            background: org.colorcode, opacity: 0.6
          }}
        >

          <CardHeader className={classes.mainTitle}
            title={
              org.name ?
                <div 
                onClick={() => onHandleOpenEditDepartModal("isEdit")} 
                className={classes.cardHeader}
                  style={{ marginTop: org.name === newArrays.name ? "" : '-11px' }}>
                  {org.title ? <div className="cardInnerTitle">
                    {org.title}
                  </div> : ""}
                  <div className="cardInnerTitle">
                    {org.name}
                  </div>
                </div> : ""
            }
            action={
              <div className="">
                <IconButton size="small" onClick={(event) => { handleClick(event) }}>
                  <MoreVertIcon />
                </IconButton>
              </div>
            }
          />

          <DepartmentMoreMenuDropdown anchorEl={anchorEl} handleClose={handleClose}
            onHandleOpenEditDepartModal={onHandleOpenEditDepartModal}
            onHandleOpenAssignHelpdesk={onHandleOpenAssignHelpdesk}
            // onHandleOpenEditDepartModal={onHandleOpenEditModal}
            onHandleOpenDeleteModal={onHandleOpenDeleteModal} />

          <IconButton
            style={{ marginTop: org.name === newArrays.name ? "-30px" : '-60px' }}
            size="small"
            onClick={onCollapse}
            className={clsx(classes.expand, {
              [classes.expandOpen]: !collapsed
            })}
          >
            <ExpandMoreIcon />
            <div>
              <Tooltip
                title={`${_.size(
                  org.organizationChildRelationship
                )} Department , ${_.size(org.account)} Sub Assistant`}
                arrow
              >
                <Badge
                  style={{ cursor: "pointer" }}
                  color="secondary"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  showZero
                  invisible={!collapsed}
                  overlap="circle"
                  badgeContent={_.size(org.organizationChildRelationship)}
                  onClick={onCollapse}
                >
                </Badge>
              </Tooltip>
            </div>
          </IconButton>

          {/* <CommonModal onHandleCloseEditModal={onHandleCloseEditDepartModal} editRoleModal={editRoleModal}
            valueTab={valueTab} handleChange={handleChangeTab} onHandleCloseDeleteModal={onHandleCloseDeleteModal}
            deleteRoleModal={deleteRoleModal} assignHelpdeskModal={assignHelpdeskModal} inputsValue={inputsValue} 
            oneItem={org} onHandleCloseAssignHelpdesk={onHandleCloseAssignHelpdesk} name={name}
            RoleData={RoleData} onHandleDeleteClick={onHandleDeleteClick} handleChangeRole={handleChangeRole} roleInputValue={roleInputValue}
            handleChanges={handleChange} userInputValue={userInputValue} usersData={usersData}
            onHandleEditClick={onHandleEditClick} onChangeInput={onChangeInput} 
             
          /> */}

          <EditDepartmentModal onHandleCloseEditDepartModal={onHandleCloseEditDepartModal}
            onHandleCloseDeleteModal={onHandleCloseDeleteModal}
            deleteRoleModal={deleteRoleModal} oneItem={org} isName={isName}
            inputsValue={inputsValue} onChangeInput={onChangeInput}
            onHandleOpenDeleteModal={onHandleOpenDeleteModal} editDepartmentModal={editDepartmentModal}
            onHandleEditClick={onHandleEditClick} onHandleDeleteClick={onHandleDeleteClick} />
        </Card>

        <AddLineModal handleAddLineClose={handleAddLineClose} addLineModal={addLineModal}
          handleChanges={handleChange} userInputValue={userInputValue} isAbove={isAbove}
        />

        {org.name === newArrays.name ?
          <div className={classes.addIcon}>
            <AddIcon onClick={(event) => handleClickAddIcon(event)} style={styleAddIcon} />
            <Menu
              id="basic-menu"
              anchorEl={openAdd}
              open={open}
              onClose={handleCloseAddIcon}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleAddRoles("Add Department", org.name === newArrays.name ? newArrays.name : org.name, org)}>Add Role</MenuItem>

              {/* <MenuItem onClick={() => handleAddRoles(org.name === newArrays.name ? newArrays.name : org.name)}>Add Role</MenuItem> */}
              {/* <MenuItem onClick={handleCloseAddIcon}>Add New Branch</MenuItem> */}
            </Menu>
          </div> : ""}
      </div>

      <div className={"bottomIcon"} style={styleAddIcon}>
        <AddIcon onClick={(event) => handleClickAddBottomIcon(event)} />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={openBottomAdd}
        open={openBottom}
        onClose={handleCloseAddBottomIcon}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        {org.name === newArrays.name ?
          <>
            <MenuItem
              onClick={() => handleAddRoles("Add Assistant", org.name === "OPERATIONS" ? "first" : org.name, org)}>Add Assistant</MenuItem>
            <MenuItem onClick={() => handleAddRoles("Add Department", org.name === newArrays.name ? newArrays.name : org.name, org)}>Add Role</MenuItem>
            {/* <MenuItem onClick={handleClickAddLineOpen}>Add Dotted Line</MenuItem> */}
          </>
          : 
          <MenuItem onClick={() => handleAddRoles("Add Role", org.name === newArrays.name ? newArrays.name : org.name, org)}>Add Role</MenuItem>}
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  addRolesData: state.HierarachyChart.addRolesData,
  heirarachyData: state.HierarachyChart.heirarachyData,
  RoleData: state.HierarachyChart.RoleData,
  usersData: state.HierarachyChart.usersData,
});

const ConnectWith = connect(mapStateToProps, {
  fetchDataRoles, addHeirarachyChart, fetchHeirarachyChartData,
  editHeirarachyChart, deleteHeirarachyChart, fetchRoleData, fetchData
})(Organization);
export default (ConnectWith);