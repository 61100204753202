import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    padding : '8px 12px',
    borderBottom: `1px solid #eeeeee`,
    fontFamily: "proxima_nova_rgregular",
  }
};
