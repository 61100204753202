export const SET_SC_DASHBOARD_LOADING = 'SET_SC_DASHBOARD_LOADING'
export const SET_SC_DASHBOARD_DETAILS_LOADING = 'SET_SC_DASHBOARD_DETAILS_LOADING'
export const SET_SC_DASHBOARD = 'SET_SC_DASHBOARD'
export const SET_SC_DASHBOARD_ROLE = 'SET_SC_DASHBOARD_ROLE'
export const SET_SC_DASHBOARD_USER = 'SET_SC_DASHBOARD_USER'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_NO_SHOW = 'SET_NO_SHOW'
export const SET_EARLY_CLOCKOUT = 'SET_EARLY_CLOCKOUT'
export const SET_LATE_CLOCKIN = 'SET_LATE_CLOCKIN'
export const SET_MISSED_BREAKS = 'SET_MISSED_BREAKS'
export const SET_UNSCHEDULED = 'SET_UNSCHEDULED'
export const SET_AUTO_CLOCKOUT= 'SET_AUTO_CLOCKOUT'
export const SET_TIME_CARDS= 'SET_TIME_CARDS'
export const SET_NO_SHOW_MORE_DATA= 'SET_NO_SHOW_MORE_DATA'

export const SET_EARLY_CLOCKOUT_MORE_DATA  = "SET_EARLY_CLOCKOUT_MORE_DATA"


//////////ROLE NAME CONSTANTS////
export const SET_ROLE_NAME_DETAILS= 'SET_ROLE_NAME_DETAILS'
export const SET_ROLE_MISSED_BREAKS_DETAILS= 'SET_ROLE_MISSED_BREAKS_DETAILS'
export const SET_ROLE_NO_SHOW_DETAILS= 'SET_ROLE_NO_SHOW_DETAILS'
export const SET_ROLE_LATE_CLOCKIN_DETAILS= 'SET_ROLE_LATE_CLOCKIN_DETAILS'
export const SET_ROLE_EARLY_CLOCKOUT_DETAILS= 'SET_ROLE_EARLY_CLOCKOUT_DETAILS'
export const SET_ROLE_UNSCHEDULED_SHIFTS_DETAILS= 'SET_ROLE_UNSCHEDULED_SHIFTS_DETAILS'
export const SET_ROLE_AUTO_CLOCKOUT_DETAILS= 'SET_ROLE_AUTO_CLOCKOUT_DETAILS'
export const SET_ROLE_TOTAL_SCHEDULED_HOURS_DETAILS= 'SET_ROLE_TOTAL_SCHEDULED_HOURS_DETAILS'
export const SET_ROLE_TOTAL_PAID_HOURS_DETAILS= 'SET_ROLE_TOTAL_PAID_HOURS_DETAILS'
export const SET_ROLE_TOTAL_ACTUAL_HOURS_DETAILS= 'SET_ROLE_TOTAL_ACTUAL_HOURS_DETAILS'
export const SET_ROLE_TOTAL_PENDING_TIMECARD_DETAILS= 'SET_ROLE_TOTAL_PENDING_TIMECARD_DETAILS'
export const SET_ROLE_TIME_OFF_DETAILS= 'SET_ROLE_TIME_OFF_DETAILS'
export const SET_ROLE_TOTAL_TIMECARD_DETAILS= 'SET_ROLE_TOTAL_TIMECARD_DETAILS'


//////////////USER NAME CONSTANTS////
export const SET_USER_TOTAL_TIMECARD_DETAILS= 'SET_USER_TOTAL_TIMECARD_DETAILS'
export const SET_USER_TOTAL_MISSED_BREAKS_DETAILS= 'SET_USER_TOTAL_MISSED_BREAKS_DETAILS'
export const SET_USER_TOTAL_NO_SHOW_DETAILS= 'SET_USER_TOTAL_NO_SHOW_DETAILS'
export const SET_USER_TOTAL_LATE_CLOCKIN_DETAILS= 'SET_USER_TOTAL_LATE_CLOCKIN_DETAILS'
export const SET_USER_TOTAL_EARLY_CLOCKOUT_DETAILS= 'SET_USER_TOTAL_EARLY_CLOCKOUT_DETAILS'
export const SET_USER_TOTAL_AUTO_CLOCKOUT_DETAILS= 'SET_USER_TOTAL_AUTO_CLOCKOUT_DETAILS'
export const SET_USER_UNSCHEDULED_SHIFT_DETAILS= 'SET_USER_UNSCHEDULED_SHIFT_DETAILS'
export const SET_USER_SCHEDULED_HOURS_DETAILS= 'SET_USER_SCHEDULED_HOURS_DETAILS'
export const SET_USER_TOTAL_PAID_HOURS_DETAILS= 'SET_USER_TOTAL_PAID_HOURS_DETAILS'
export const SET_USER_TOTAL_ACTUAL_HOURS_DETAILS= 'SET_USER_TOTAL_PAID_HOURS_DETAILS'
export const SET_USER_TOTAL_PENDING_TIMECARD_DETAILS= 'SET_USER_TOTAL_PENDING_TIMECARD_DETAILS'
export const SET_USER_TOTAL_TIMEOFF_REQUEST_DETAILS= 'SET_USER_TOTAL_TIMEOFF_REQUEST_DETAILS'
