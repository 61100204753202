import React from "react";
import { IoMdSearch } from "react-icons/io";
import { Box } from "@material-ui/core";

import "./MobileSearch.scss";

const Search = ({ placeholder, value, handleSearch }) => {
  return (
    <div className={"mobileSearchRoot"}>
      <div>
        <IoMdSearch className={"icon"} />
      </div>
      <input
        className={"input"}
        value={value}
        onChange={handleSearch}
        placeholder={placeholder || ""}
      />
    </div>
  );
};

export { Search };
