import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import GetStartedImg from "../assets/images/elearningGetStarted.png";
import { Link } from "react-router-dom";
import { withWindowSize } from "../mobileView/hoc/withWindowSize";
const cookies = new Cookies();
const styles = (theme) => ({
  root: {
    // display: "flex",
    // maxWidth: "100vw",
    height: "100vh",
    padding: "10px",
    overflow: "hidden",
    backgroundColor: "#135098",
  },
  title: {
    color: "white",
    fontSize: "25px",
    fontWeight: "700",
    textAlign: "center",
    lineHeight: "28px",
  },
  bottomCard: {
    backgroundColor: "white",
    borderRadius: "25px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "30px",
    position: "absolute",
    bottom: "15px",
    left: "15px",
    right: "15px",
    textAlign: "center",
  },
  cardTitle: {
    fontSize: "21px",
    fontWeight: "700",
    color: "black",
    margin: "0px",
  },
  cardContent: {
    marginTop: "20px",
    marginBottom: "35px",
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "16px",
  },
  GetStartedLink: {
    display: "block",
    width: "158px",
    height: "50px",
    borderRadius: "15px",
    backgroundColor: "#135098",
    color: "white",
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      color: "white",
      textDecoration: "none",
    },
  },
  GetStartedImg: {
    // maxWidth: "700px",
    // height: "auto"
  },
});

class ElearningGetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTitle: "",
      activeProduct: "",
      errors: {},
      helperText: {},
    };
  }

  render() {
    const {
      classes,
      children,
      history,
      categoryData,
      myLessons,
      listData,
      locationData,
      displaySection,
      pageNo,
      rowsPerPage,
      locationTimezone,
      permissionsLoading,
      permissionsData,
    } = this.props;

    const { activeProduct } = this.state;
    var ovUserName = cookies.get("ovUserName");
    var ovUserImage = cookies.get("ovUserImage");

    const { width } = this.props.windowSize;
    const isDesktop = width > 768;

    return (
      <div className={classes.root}>
        <p className={classes.title}>eLearning</p>
        <div className="text-center">
          <img
            src={GetStartedImg}
            className={"mt-5 img img-fluid " + classes.GetStartedImg}
          />
        </div>
        <div className={classes.bottomCard}>
          <div>
            <p className={classes.cardTitle}>Discover your next skill</p>
            <p className={classes.cardTitle}>Learn anything you want!</p>
          </div>
          <p className={classes.cardContent}>
            Discover the things you want to learn and go with them.
          </p>

          <Link
            to={isDesktop ? "/elearning/dashboard" : "/elearning/myhelpdesk"}
            className={classes.GetStartedLink}
          >
            Get Started
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displaySection: state.eLearningMyCourses.displaySection,
  active_lesson_details: state.eLearningMyCourses.active_lesson_details,
  active_course_details: state.eLearningMyCourses.active_course_details,
  myLessons: state.eLearningMyCourses.courseLessonsData,
  listData: state.todoDashboard.listData,
  categoryData: state.eLearningCategory.categoryData,
  locationData: state.settingsLocation.locationData,
  isSidebarOpen: state.localData.isSidebarOpen,
  pageNo: state.localData.pageNo,
  rowsPerPage: state.localData.rowsPerPage,
  permissionsLoading: state.settingsRoles.permissionsLoading,
  permissionsData: state.settingsRoles.permissionsData,
  locationTimezone: state.settingsRoles.locationTimezone,
});

const ConnectWith = connect(mapStateToProps, {})(ElearningGetStarted);

const ConnectedWithWindowSize = withWindowSize(ConnectWith);

export default withStyles(styles)(ConnectedWithWindowSize);
