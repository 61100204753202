import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import { menu, settings, notifications, divider } from '../../icons'
import { MyPlain25Modal } from '../../components'
import Settings from './Settings';
import Notifications from './Notifications';
import settingsData from '../../utils/settingsdata';
import { BsChevronDown } from 'react-icons/bs';
import { BiCog } from 'react-icons/bi';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { getNotifications } from '../../pages/common/UsersList/modules/actions';
import { connect } from "react-redux";
import Clock from 'react-live-clock';
import {
  Button,
  Fade,
  Paper,
  Popper,
  Grid,
  Avatar,
  AppBar,
  Toolbar,
  IconButton
} from "@material-ui/core";
import classNames from "classnames";
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FILE_URL, settingColor, ovSupportColor } from "../../config";
import { BiSearch } from "react-icons/bi";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
var moment_timezone = require('moment-timezone');

const drawerWidth = 200;

const styles = (theme) => ({
  root: {
    boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
    marginTop: '0px',
    width: 350,
  },
  paper: {
    padding: '15px 10px',
    minHeight: "500px",
    maxHeight: '500px',
    height: '500px',
    overflowY: 'scroll'
  },
  logotitle: {
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 20,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  topIcon: {
    fontSize: '16px',
    color: '#19212C',
    marginRight: 20,
    '&:hover': {
      color: '#005f73'
    }
  },
  time: {
    color: '#7c7c7c',
    position: 'relative',
    left: '10px',
    top: '2px',
    fontSize: '20px',
    fontWeight: 'bold',
    float: "left"
  },
  avatar: {
    width: 26,
    height: 24,
    color: '#7c7c7c',
    // marginRight: 20,
    marginLeft: "5px",
    marginRight: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    background: "white",
    borderRadius: "5px",
    '&:hover': {
      color: '#005f73'
    }
  },
  upgradeButton: {
    fontSize: "10px",
    textTransform: "capitalize",
    backgroundColor: "#ffffff",
    marginRight: "10px",
    padding: "5px 10px !important",
  },
  avatarButton: {
    backgroundColor: "#DFE0EB",
    marginLeft: 0,
    padding: "2px !important",
  },
  avatarProfile: {
    border: "5px solid #ffffff",
    textTransform: "uppercase",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  appBar: {
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: "100%",
    marginLeft: drawerWidth,
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginLeft: 0,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  grow: {
    flexGrow: 1,
  },
  headerMenu: {
    marginTop: theme.spacing(4),
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },

  headerMenuButtonSandwich: {
    marginLeft: 0,
    '&:hover': { background: 'transparent' },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(1),
  },
  searchDiv: {
    borderRadius: "25px",
    fontSize: "14px",
    width: "450px",
    border: "1px solid #ACACAC",
    boxShadow: "none",
    position: "relative"
  },
  searchInput: {
    borderRadius: "25px",
    border: "none"
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    right: "10px",
    color: "#ACACAC",
    transform: "translate(0%, -50%)",
    cursor: "pointer"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  menuDiv: {
    background: "#E9EBED",
    borderRadius: "6px",
    marginRight: "10px"
  }
});


class SupportHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      open: false,
      isAway: false
    }
  }

  settingsModalClose = () => {
    this.setState({ settingsModal: false })
  }

  settingsModalOpen = () => {
    this.setState({ settingsModal: true })
  }

  handleClickAwayNotifications = () => {
    if (this.state.isAway) {
      this.setState({
        anchorEl: null,
        open: false,
        isAway: false
      });
    }
  };

  handleClickNotifications = (event) => {
    this.props.getNotifications()
    this.setState({
      anchorEl: event.currentTarget,
      open: !this.state.open
    }, () => {
      setTimeout(
        () => {
          this.setState({
            isAway: this.state.open ? true : false
          })
        },
        800
      )
    })

  };

  render() {
    const {
      classes,
      isSidebarOpen,
      userName,
      userImage,
      activeProduct,
      permissionsData,
      handleSidebarToggle,
      notificationsData,
      locationData
    } = this.props;
    var timezone_string = moment_timezone.tz.guess(true);

    return (
      <AppBar
        color="inherit"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !this.props.isFullHeader && isSidebarOpen,
        })}
      >

        <Toolbar
          className={classes.toolbar}
        >
          <Typography className={classes.time}>
            Agent Panel
          </Typography>

          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              margin: "0 auto"
            }}
          >
            <BsArrowLeftShort
              size={27}
              className={classes.cursorPointer}
            />
            <BsArrowRightShort
              size={27}
              className={classes.cursorPointer}
            />
            <AiOutlineReload
              size={22}
              className={classes.cursorPointer + " ml-1 mr-2"}
            />
            <div
              className={classes.searchDiv}
            >
              <input
                type="search"
                value=""
                className={"form-control " + classes.searchInput}
              />
              <BiSearch
                className={classes.searchIcon}
                size={20}
              />
            </div>
          </div>

          <div className="d-flex align-items-center float-right">
            <div className={"d-flex align-items-center " + classes.menuDiv}>
              <IconButton
                onClick={this.settingsModalOpen}
                color="inherit"
                className={classes.headerMenuButtonSandwich}
              >
                <BiCog
                  variant="square"
                  className={classes.avatar}
                  src={settings}
                  size={20}
                />

              </IconButton>

              <IconButton
                onClick={this.handleClickNotifications}
                color="inherit"
                className={classes.headerMenuButtonSandwich}
              >
                <div
                  className={classes.avatar}
                  style={{
                    position: "relative"
                  }}>
                  <Avatar
                    variant="square"
                    src={notifications}
                    style={{
                      width: "16px",
                      height: "18px",
                      position: "relative",
                      top: "50%",
                      transform: "translate(0,-50%)"
                    }}
                  />
                </div>
              </IconButton>
            </div>

            <IconButton
              onClick={this.props.handleLogoutOpen}
              color="inherit"
              className={classes.avatarButton}
            >
              {userImage ?
                <Avatar
                  variant="circular"
                  src={FILE_URL + userImage}
                  className={classes.avatarProfile}
                />
                :
                <Avatar
                  variant="circular"
                  className={classes.avatarProfile} >
                  {userName.charAt(0)}
                </Avatar>
              }
            </IconButton>
          </div>
        </Toolbar>

        <ClickAwayListener onClickAway={this.handleClickAwayNotifications}>
          <Popper
            className={classes.root}
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            placement="bottom"
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  className={classes.paper}
                >
                  <Notifications
                    notificationLoading={this.props.notificationLoading}
                    notificationsData={notificationsData}
                  />
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>

        <MyPlain25Modal
          bgColor={settingColor.main}
          title="Settings"
          open={this.state.settingsModal}
          onClose={this.settingsModalClose}
        >
          <Grid
            container
            spacing={0}
          >
            <Settings
              permissionsData={permissionsData}
              settingsData={settingsData}
            />
          </Grid>
        </MyPlain25Modal>

      </AppBar>
    )
  }
}

const mapStateToProps = (state) => ({
  notificationsData: state.settingsUsers.notificationsData,
  notificationLoading: state.settingsUsers.notificationLoading,
  locationData: state.settingsLocation.locationData,
  permissionsData: state.settingsRoles.permissionsData
});

const ConnectWith = connect(mapStateToProps, { getNotifications })(SupportHeader);
export default withStyles(styles)(ConnectWith);