import {
  SET_DASHBOARD_LOADING,
  SET_DASHBOARD_LOCATION_BASED,
  SET_DASHBOARD_ROLE_BASED,
  SET_DASHBOARD_LESSON_BASED,
  SET_DASHBOARD_USER_BASED,
} from "./constants";

const initialState = {
  isLoadingDashboard: true,
  dashboardLocationData: [],
  dashboardLessonData: [],
  dashboardRoleData: [],
  dashboardUserData: [],
  dashboardLocationData1: [],
  dashboardLessonData1: [],
  dashboardRoleData1: [],
  dashboardUserData1: [],
  locationLength: "",
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DASHBOARD_LOADING:
      return {
        ...state,
        isLoadingDashboard: payload,
      };

    case SET_DASHBOARD_LOCATION_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardLocationData: payload.result,
        dashboardLocationData1: payload,
        locationLength: payload.totalPages,
      };

    case SET_DASHBOARD_ROLE_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardRoleData: payload.result,
        dashboardRoleData1: payload,
        roleLength: payload.totalPages,
      };

    case SET_DASHBOARD_LESSON_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardLessonData: payload.result,
        dashboardLessonData1: payload,
        lessonLength: payload.totalPages,
      };

    case SET_DASHBOARD_USER_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardUserData: payload.result,
        dashboardUserData1: payload,
        userLength: payload.totalPages,
      };

    default:
      return state;
  }
};

export default Reducer;
