import React, { useCallback, useEffect, useState } from "react";
import { LibraryCard } from "../../components/Cards";
import { MobileLoader, Tab } from "../../utilities";
import { mobileTheme } from "../../constant";

import "../style.css";
import { Box, Pagination, Stack } from "@mui/material";
import { useSearch } from "../../context/SearchContext";
import { LessonCategories } from "../../components";

import "./MobileLibraryListing.scss";
import { LoadMoreMobile } from "../../components/LoadMoreMobile";

const MyLibrary = ({
  tabs,
  handleTabChange,
  tabID,
  lessons,
  handleFilters,
  pageCount,
  pageNumber,
  handlePageChange,
  loadMoreData,
  lessonLoading,
  handleSearch,
  handleSearchResults,
}) => {
  const filters = [
    { id: 1, value: "all", name: "All" },
    { id: 2, value: "content", name: "Text" },
    { id: 3, value: "video", name: "Video" },
    { id: 4, value: "ppt", name: "PPT" },
  ];
  const [selectedFilters, setSelectedFilters] = useState("all");

  const getCategoryName = (lesson) => {
    const currentTab = tabs?.find((tab) => tab.ecid._id === lesson.ecid._id);
    return currentTab ? currentTab.ecid.category_name : "Unknown Category";
  };

  const { searchQuery } = useSearch();

  const handleSearchCallback = useCallback(() => {
    handleSearch(searchQuery);
  }, [handleSearch, searchQuery]);

  const handleSearchResultCallback = useCallback(() => {
    handleSearchResults();
  }, [handleSearchResults]);

  useEffect(() => {
    handleSearchCallback();

    const delayDebounceFn = setTimeout(() => {
      handleSearchResultCallback();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [handleSearchCallback, handleSearchResultCallback, searchQuery]);

  return (
    <div className={"myLibraryMobileRoot"}>
      <div className={"myLibraryMobileContainer"}>
        <LessonCategories
          handleTabChange={handleTabChange}
          lessonLoading={lessonLoading}
          tabID={tabID}
          tabs={tabs}
        />

        <Box className={"myLibraryMobileFiltersContainer"}>
          <Box className={`myLibraryMobileFilterTab scroll`}>
            {filters.map((item) => (
              <div
                key={item.id}
                onClick={() => {
                  handleFilters(item.value);
                  setSelectedFilters(item.value);
                }}
              >
                <Tab
                  bgColor={{
                    selectedColor: mobileTheme,
                    unselectedColor: "#7455F626",
                  }}
                  selected={selectedFilters === item.value}
                  size={{ width: "61px", height: "25px" }}
                  text={{ content: item.name, size: "12px" }}
                  textColor={{
                    selectedColor: "#FFFFFF",
                    unselectedColor: "rgba(33, 33, 33, 0.5)",
                  }}
                  rounded={"30px"}
                />
              </div>
            ))}
          </Box>
        </Box>

        {
          <Box className={"myLibraryMobilePaginationStack"}>
            {lessons.map((lesson, index) => (
              <LibraryCard
                key={index}
                lessonData={lesson}
                categoryName={getCategoryName(lesson)}
              />
            ))}
          </Box>
        }

        {lessonLoading && (
          <div className={"myLibraryMobileLoaderContainer"}>
            <MobileLoader />
          </div>
        )}

        {/* <Box className={"myLibraryMobilePaginationContainer"}>
          <Stack spacing={2} m={"1rem"}>
            <Pagination
              size="small"
              color="primary"
              count={pageCount}
              variant="outlined"
              page={pageNumber}
              onChange={handlePageChange}
            />
          </Stack>
        </Box> */}
        {pageNumber < pageCount && (
          <LoadMoreMobile handlePageChange={loadMoreData} />
        )}
      </div>
    </div>
  );
};

export default React.memo(MyLibrary);
