import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function BasicPopoverRole(props) {

  return (
    <div>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ 
          '& .MuiPaper-root': {
            width: '118px', // Set your desired width here
            background:"#eef2fb"
          },
        }}
      >
         {props.permissionsData?.common_roles_edit  === 1?
        <Typography sx={{ p: 2,padding:"5px 13px 7px 14px",fontSize:"0.8rem" }} onClick={props.handleClickEdit}>Edit</Typography>:""}
         {props.permissionsData?.common_roles_delete === 1 ?
        <Typography sx={{ p: 2,padding:"5px 13px 7px 14px" ,fontSize:"0.8rem"}} onClick={props.handleClickDelete}>Delete</Typography>:""}
        
        <Typography sx={{ p: 2,padding:"5px 13px 7px 14px" ,fontSize:"0.8rem"}} onClick={props.CloneItem}>Clone</Typography>
        {/* <Typography sx={{ p: 2,padding:"5px 13px 7px 14px" ,fontSize:"0.8rem"}} onClick={() => props.setAssignUsers(props.Userid)}>Assign User</Typography> */}
      </Popover>
    </div>
  );
}