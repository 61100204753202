import React from "react";
import Rating from "@mui/material/Rating";
// import StarIcon from "@mui/icons-material/Star";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import "./CustomRating.scss";

export function CustomRating({ value }) {
  return (
    <div className={"ratingContainer"}>
      <Rating
        name="hover-feedback"
        value={value}
        precision={1}
        max={4}
        icon={<FaStar style={{ fontSize: "12px" }} className={"starIcon"} />}
        emptyIcon={
          <FaRegStar style={{ fontSize: "12px" }} className={"emptyStarIcon"} />
        }
        sx={{ "& .MuiRating-label": { marginBottom: "0" } }}
      />
      <div className={"labelText"}>{value}.0</div>
    </div>
  );
}
