export const SET_SCHEDULE_LOADING = 'SET_SCHEDULE_LOADING'
export const SET_SCHEDULE = 'SET_SCHEDULE'
export const ADD_SHIFT = 'ADD_SHIFT'
export const EDIT_SHIFT = 'EDIT_SHIFT'
export const DELETE_SHIFT = 'DELETE_SHIFT'
export const DUPLICATE_SHIFT = 'DUPLICATE_SHIFT'
export const PUBLISH_SHIFT = 'PUBLISH_SHIFT'
export const CLEAR_UNPUBLISH_SHIFT = 'CLEAR_UNPUBLISH_SHIFT'
export const CLEAR_ENTIRE_SCHEDULE = 'CLEAR_ENTIRE_SCHEDULE'
export const INVITE_SCHEDULE_USER = 'INVITE_SCHEDULE_USER'


export const ADD_SCHEDULE = 'ADD_SCHEDULE'
export const EDIT_SCHEDULE = 'EDIT_SCHEDULE'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const VIEW_USER = 'VIEW_USER'
export const UPDATE_USER = 'UPDATE_USER'