export const SET_MY_COURSE_LOADING = "SET_MY_COURSE_LOADING";
export const SET_MY_COURSE = "SET_MY_COURSE";
export const SET_DISPLAY_SECTION = "SET_DISPLAY_SECTION";
export const SET_MY_COURSE_LESSONS = "SET_MY_COURSE_LESSONS";
export const SET_MY_COURSE_LESSONS_DETAILS = "SET_MY_COURSE_LESSONS_DETAILS";
export const SET_MY_COURSE_LESSONS_DETAILS_LOADING =
  "SET_MY_COURSE_LESSONS_DETAILS_LOADING";
export const SET_MY_COURSE_QUESTION_COMPLETE =
  "SET_MY_COURSE_QUESTION_COMPLETE";
export const SET_MY_COURSE_INITIAL = "SET_MY_COURSE_INITIAL";
export const GET_QUIZ_SUMMARY_LOADING = "GET_QUIZ_SUMMARY_LOADING";
export const GET_QUIZ_SUMMARY = "GET_QUIZ_SUMMARY_LOADING";
export const SET_MY_COURSE_LESSON_COUNT_LOADING =
  "SET_MY_COURSE_LESSON_COUNT_LOADING";
export const SET_MY_COURSE_LESSONS_COUNT = "SET_MY_COURSE_LESSONS_COUNT";
