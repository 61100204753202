import * as React from "react";
import PropTypes from "prop-types";
// import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularProgressWithLabel(props) {
  const { value, size, progressColor } = props;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <CircularProgress
        variant="determinate"
        size={size}
        sx={{ color: progressColor }}
        {...props}
      /> */}
      <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={size}
          // thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          // disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? progressColor : "#308fe8",
            // animationDuration: "550ms",
            position: "absolute",
            left: 0,
            // [`& .${circularProgressClasses.circle}`]: {
            //   strokeLinecap: "round",
            // },
          }}
          size={size}
          // thickness={4}
          {...props}
          value={value}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={progressColor}
          fontSize={"7px"}
          fontWeight={"400"}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
  /**
   * Size of the circular progress.
   */
  size: PropTypes.number,
  /**
   * Color of the progress indicator.
   */
  progressColor: PropTypes.string,
};
