import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import CommonModal from "./CommonModal";
import _ from "lodash";
import './Hierarachy.css';
import MoreMenuDropdown from "./MoreMenuDropdown";
import AddIcon from '@mui/icons-material/Add';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
    fetchDataRoles, fetchData, deleteHeirarachyChart, fetchHeirarachyChartData, editHeirarachyChart,
    addHeirarachyChart, fetchRoleData
} from "./modules/action";
import { connect } from "react-redux";
import moment from "moment";
import AddLineModal from "./AddLineModal";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "white",
        display: "block!important",
        borderRadius: 16,
        position: 'relative',
        overflow: 'inherit',
        marginBottom: 26,
        cursor: "pointer",

        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-27px',
            height: 20,
            boxSizing: 'border-box',
            left: '50%',
            width: 0,
            borderLeft: '2px solid #bbbbcc',
        },
    },
    avatar: {
        backgroundColor: "#977c4f"
    },
    cardHeader: {
        cursor: 'pointer'
    },
    firstDiv: {
        display: 'inline-flex',
        alignItems: 'baseline',
    },
}));

let name = "";
let oneItem = "";

function Members(props) {
    const { p, usersData, fetchData, heirarachyData, deleteHeirarachyChart,
        editHeirarachyChart, fetchHeirarachyChartData, addHeirarachyChart, RoleData, fetchRoleData } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editRoleModal, setEditRoleModal] = React.useState(false);
    const [deleteRoleModal, setDeleteRoleModal] = React.useState(false);
    const [assignHelpdeskModal, setAssignHelpdeskModal] = React.useState(false);
    const [valueTab, setValueTab] = React.useState('1');
    const [openAdd, setOpenAdd] = React.useState(null);
    const [openTopAdd, setOpenTopAdd] = React.useState(null);
    const [addLineModal, setAddLineModal] = React.useState(false);
    const [isAbove, setIsAbove] = React.useState("");
    const [styleAddIcon, setStyleAddIcon] = useState(null);
    const [userInputValue, setUserInputValue] = React.useState('');
    const [roleInputValue, setRoleInputValue] = React.useState('');
    const [cardStyleBorder, setCardStyleBorder] = useState(null);
    const [inputsValue, setInputsValue] = useState({
        name: '',
        mobile_no: '',
        title: '',
        date_of_birth: new Date(),
        address: '',
        location: '',
        id: "",
    });

    let newArrays = {}
    heirarachyData && heirarachyData.map((item) => {
        let returmName = item.departments && item.departments.map((row) => {
            let accountArray = row.department_role && row.department_role.map((child) => {
                let productArray = child && child.department_role_assis.map((parentItem) => {
                    let user_obj = {
                        name: parentItem.user_id && parentItem.user_id.user_name,
                        id: parentItem && parentItem._id,
                        title: parentItem.role_id && parentItem.role_id.role_name,
                    }
                    return user_obj
                })
                let child_obj = {
                    name: child.user_id && child.user_id.user_name,
                    id: child && child._id,
                    title: child.role_id && child.role_id.role_name,
                    product: productArray
                }
                return child_obj
            })
            let reurn_obj = {
                name: row && row.department_name,
                colorcode: row.colorcode,
                id: row && row._id,
                account: accountArray,
            }
            return reurn_obj
        })

        let assistantArray = item.assiatant && item.assiatant.map((item) => {
            let assistant_obj = {
                name: item.user_id && item.user_id.user_name,
                id: item && item._id,
                title: item.role_id && item.role_id.role_name,
            }
            return assistant_obj
        })
        newArrays = {
            name: item.user_id && item.user_id.user_name,
            title: item.role_id && item.role_id.role_name,
            id: item.user_id && item._id,
            assiatant: assistantArray,
            organizationChildRelationship: returmName,
        }
    })

    useEffect(() => {
        fetchData();
        fetchRoleData();
    }, []);

    //onChange Input Click
    const onChangeInput = (e) => {
        e.persist();
        setInputsValue(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    }

    //edit role user onchange 
    const handleChange = (event) => {
        setUserInputValue(event.target.value);
    };

    //edit role onchange 
    const handleChangeRole = (event) => {
        setRoleInputValue(event.target.value);
    };

    //onChnage date Picker
    const handleChangeDate = (date, state) => {
        setInputsValue(inputs => ({ ...inputs, [state]: date }));
    };

    //Top Icon handle click
    const openTop = Boolean(openTopAdd);
    const handleCloseAddTopIcon = () => {
        setOpenTopAdd(null);
    };

    const handleClickAddTopIcon = (event) => {
        setOpenTopAdd(event.currentTarget);
    };

    //add icon handle click
    const open = Boolean(openAdd);

    const handleCloseAddIcon = () => {
        setOpenAdd(null);
    };

    const handleAddRoles = (name) => {
        const data = {
            user_id: null,
            role_id: null,
            parent: [name],
            isroot: 0,
            isrole: 0,
            isassistant: 0,
            isdepartment: 0,
            isuser: 1
        }
        addHeirarachyChart(data);
        setTimeout(() => {
            fetchHeirarachyChartData();
        }, 1000)
        setOpenAdd(null);
    };

    const handleClickAddIcon = (event) => {
        setOpenAdd(event.currentTarget);
    };

    //add Dotted line modal onclick 
    // const handleClickAddLineOpen = (title) => {
    //     setIsAbove(title);
    //     setAddLineModal(true);
    // };
    const handleAddLineClose = () => {
        setAddLineModal(false);
    };

    //more menu handle click
    const handleClick = (event, name) => {
        let selectedUser;
        usersData && usersData.forEach(item => {
            if (item.user_name === name.name) {
                selectedUser = item
            }
        })

        let selectedRole;
        RoleData && RoleData.forEach(item => {
            if (item.role_name === name.title) {
                selectedRole = item
            }
        })
        setUserInputValue(selectedUser && selectedUser._id);
        setRoleInputValue(selectedRole && selectedRole._id);
        setInputsValue({
            name: selectedUser && selectedUser._id,
            title: selectedRole && selectedRole._id,
            date_of_birth: new Date(),
            id: name.id
        })
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //edit modal handle click
    const onHandleOpenEditModal = () => {
        setEditRoleModal(true);
        setAnchorEl(null);
    };

    const onHandleCloseEditModal = () => {
        setEditRoleModal(false);
    };

    //delete modal handle click
    const onHandleOpenDeleteModal = () => {
        setDeleteRoleModal(true);
        setAnchorEl(null);
    };

    const onHandleCloseDeleteModal = () => {
        setDeleteRoleModal(false);
    };

    //AssignHelpDesk modal handle click
    const onHandleOpenAssignHelpdesk = () => {
        setAssignHelpdeskModal(true);
        setAnchorEl(null);
    };

    const onHandleCloseAssignHelpdesk = () => {
        setAssignHelpdeskModal(false);
    };

    //Tab handle change
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    //onDelete member function
    const onHandleDeleteClick = (id) => {
        deleteHeirarachyChart(id);
        setTimeout(() => {
            fetchHeirarachyChartData();
        }, 1000)
    };

    //onEdit Click function
    const onHandleEditClick = (editValue, userName, oneItem, roleName) => {
        let selectedUser;
        usersData && usersData.forEach(item => {
            if (userName === undefined) {
                selectedUser = usersData[0] && usersData[0].user_name
            } else if (item._id === userName) {
                selectedUser = item
            }
        })
        let selectedRole;
        RoleData && RoleData.forEach(item => {
            if (userName === undefined) {
                selectedUser = RoleData[0] && RoleData[0].role_name
            } else if (item._id === roleName) {
                selectedRole = item
            }
        })

        let firstUser = usersData[0] && usersData[0].user_name
        let firstUserIDs = usersData[0] && usersData[0]._id
        let firstRole = RoleData[0] && RoleData[0].role_name
        let firstRoleIDs = RoleData[0] && RoleData[0]._id
        const data = {
            role_name: roleName !== undefined ? roleName : editValue.title === "" ? firstRole : editValue.title,
            user_name: userName !== undefined ? userName : editValue.name === "" ? firstUser : editValue.name,
            user_id: userInputValue ? userInputValue : firstUserIDs ? firstUserIDs : null,
            role_id: roleInputValue ? roleInputValue : firstRoleIDs ? firstRoleIDs : null,
            _id: oneItem.id,
        }
        setInputsValue({
            name: userName !== undefined ? userName : editValue.name === "" ? firstUser : editValue.name,
            title: roleName !== undefined ? roleName : editValue.title === "" ? firstRole : editValue.title,
            // name: selectedUser.user_name && selectedUser !== undefined ? selectedUser.user_name : selectedUser ? selectedUser : firstUser,
            // title: editValue ? editValue.title : "",
            // address: editValue ? editValue.address : inputsValue.address,
            // location: editValue ? editValue.location : inputsValue.location,
            // mobile_no: editValue ? editValue.mobile_no : inputsValue.mobile_no,
            date_of_birth: editValue ? editValue.date_of_birth : new Date(),
            id: oneItem.id
        })
        editHeirarachyChart(data);
        setTimeout(() => {
            fetchHeirarachyChartData();
        }, 1000)
    };

    return (
        <>
            <div>
                {p && p.map(item => {
                    return (
                        <div onMouseEnter={e => {
                            let str = e.target.innerText;
                            let str1 = str && str.split("\n");
                            let result = [str1 && str1[0], str1 && str1[1]];
                            setCardStyleBorder(result[0])
                            setStyleAddIcon(result[0]);
                        }}
                            onMouseLeave={e => {
                                setCardStyleBorder(null)
                                setStyleAddIcon(null);
                            }}>

                            <div className={"topIcon"}>
                                <AddIcon onClick={(event) => {
                                    name = item.name
                                    handleClickAddTopIcon(event)
                                }}
                                    style={{ display: styleAddIcon === item.title ? 'block' : 'none' }}
                                />
                            </div>

                            <div className={classes.firstDiv}>
                                <Card variant="outlined" className={styleAddIcon === item.title ? "" : classes.root}
                                    style={{
                                        border: cardStyleBorder === item.title ? '3px solid #595fe7' : '1px solid rgba(0, 0, 0, 0.12)'
                                    }}
                                >
                                    <div className="cardHeaderTitle">
                                        <div className="titleName">{item.title}</div>
                                        <div className="">
                                            <IconButton size="small" onClick={(event) => {
                                                oneItem = item
                                                handleClick(event, item)
                                            }}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <CardHeader className="card_padding"
                                        avatar={
                                            <Avatar className={classes.avatar}
                                            //  onClick={(event) => {
                                            //     oneItem = item
                                            //     onHandleOpenEditModal(event)
                                            // }}
                                            >
                                                <AccountCircleIcon />
                                            </Avatar>
                                        }
                                        title={
                                            <div
                                                // onClick={(event) => {
                                                //     oneItem = item
                                                //     onHandleOpenEditModal(event)
                                                // }}
                                                className={classes.cardHeader}>
                                                <div>
                                                    {item.name}
                                                </div>
                                                {item.mobile_no ? <div className="cardInnberMobile">
                                                    <span class="dot"></span> {item.mobile_no}
                                                </div> : ""}
                                                <div className="cardInnberDate">
                                                    <span class="dot"></span>
                                                    {moment(item.date_of_birth ? item.date_of_birth : new Date()).format("MMMM DD")}
                                                </div>
                                            </div>
                                        }
                                    />
                                </Card>
                            </div>

                            <div className={"bottomIcon lineClass"} style={{ display: styleAddIcon === item.title ? 'block' : 'none' }}>
                                <AddIcon onClick={(event) => {
                                    name = item.id
                                    handleClickAddIcon(event)
                                }} />
                            </div>
                        </div>

                    )
                })}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={openTopAdd}
                open={openTop}
                onClose={handleCloseAddTopIcon}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleAddRoles(name)}>Add Assistant</MenuItem>
                {/* <MenuItem onClick={() => handleClickAddLineOpen("isAbove")}>Add Dotted Line</MenuItem> */}
            </Menu>

            <Menu
                id="basic-menu"
                anchorEl={openAdd}
                open={open}
                onClose={handleCloseAddIcon}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleAddRoles(name)}>Add Assistant</MenuItem>
                {/* <MenuItem onClick={handleCloseAddIcon}>Add New Branch</MenuItem>
                <MenuItem onClick={handleClickAddLineOpen}>Add Dotted Line</MenuItem> */}
            </Menu>

            <MoreMenuDropdown anchorEl={anchorEl} handleClose={handleClose}
                onHandleOpenEditModal={onHandleOpenEditModal}
                onHandleOpenDeleteModal={onHandleOpenDeleteModal}
                onHandleOpenAssignHelpdesk={onHandleOpenAssignHelpdesk} />

            <CommonModal onHandleCloseEditModal={onHandleCloseEditModal} editRoleModal={editRoleModal}
                valueTab={valueTab} handleChange={handleChangeTab} onHandleCloseDeleteModal={onHandleCloseDeleteModal}
                deleteRoleModal={deleteRoleModal} assignHelpdeskModal={assignHelpdeskModal} inputsValue={inputsValue}
                onHandleCloseAssignHelpdesk={onHandleCloseAssignHelpdesk} oneItem={oneItem} name={name}
                onHandleDeleteClick={onHandleDeleteClick} onChangeInput={onChangeInput} RoleData={RoleData}
                userInputValue={userInputValue} roleInputValue={roleInputValue} handleChangeRole={handleChangeRole}
                onHandleEditClick={onHandleEditClick} handleChangeDate={handleChangeDate}
                handleChanges={handleChange} usersData={usersData}
            />

            <AddLineModal handleAddLineClose={handleAddLineClose} addLineModal={addLineModal}
                handleChanges={handleChange} userInputValue={userInputValue} isAbove={isAbove}
            />
        </>

    );
}
const mapStateToProps = (state) => ({
    usersData: state.HierarachyChart.usersData,
    RoleData: state.HierarachyChart.RoleData,
    addRolesData: state.HierarachyChart.addRolesData,
    heirarachyData: state.HierarachyChart.heirarachyData,
});

const ConnectWith = connect(mapStateToProps, {
    addHeirarachyChart, fetchRoleData,
    fetchDataRoles, editHeirarachyChart, fetchData, fetchHeirarachyChartData, deleteHeirarachyChart
})(Members);
export default (ConnectWith);
