import {
  products,
  profile,
  company,
  locations,
  users,
  roles,
  payrollImage,
  tickets,
  ideas,
  billings,
  coupons,
  feed_image_dashboard,
  feed_image_dashboard_active,
  feed_image_followups,
  feed_image_followups_active,
  feed_image_campaign,
  feed_image_campaign_active,
  feed_image_reports,
  feed_image_reports_active,
  feed_image_team,
  feed_image_team_active,
  feed_image_settings,
  feed_image_settings_active,
  noun_campaigner,
  noun_campaigner_active,
  sc_image_dashboard,
  sc_image_dashboard_active,
  sc_image_scheduler,
  sc_image_scheduler_active,
  sc_image_myavailability,
  sc_image_myavailability_active,
  sc_image_teamavailability,
  sc_image_teamavailability_active,
  sc_image_timeoffrequest,
  sc_image_timeoffrequest_active,
  sc_image_tradeshift,
  sc_image_tradeshift_active,
  sc_image_covershift,
  sc_image_covershift_active,
  sc_image_timesheets,
  sc_image_timesheets_active,
  sc_image_pay_summary,
  sc_image_pay_summary_active,
  sc_image_roles,
  sc_image_roles_active,
  sc_image_team,
  sc_image_team_active,
  sc_image_back,
  sc_image_settings,
  sc_image_settings_active,
  sc_image_settings_scheduling,
  sc_image_settings_scheduling_active,
  sc_image_settings_timetracking,
  sc_image_settings_timetracking_active,
  sc_image_settings_timeofpto,
  sc_image_settings_timeofpto_active,
  ele_image_dashboard,
  ele_image_dashboard_active,
  ele_image_mycourses,
  ele_image_mycourses_active,
  ele_image_library,
  ele_image_library_active,
  ele_image_courses,
  ele_image_courses_active,
  ele_image_helpdesk,
  ele_image_helpdesk_active,
  ele_image_reports,
  ele_image_reports_active,
  ele_image_team,
  ele_image_team_active,
  sc_image_timecard,
  sc_image_timecard_active,
  sc_image_settings_location,
  sc_image_settings_location_active,
  news_update,
  ovChat,
  ovRoutine,
  device_management,
} from "../icons";

export const OvHrRoutes = [
  {
    label: "Hierarachy chart",
    path: "/hr/hierarachy_chart",
    image: feed_image_dashboard,
    active_image: feed_image_dashboard_active,
  },
  // {
  //     label: "Scheduler",
  //     path: "/scheduler/scheduler",
  //     image: sc_image_scheduler,
  //     active_image: sc_image_scheduler_active,
  // },
  {
    label: "Roles & Permissions",
    path: "/hr/roles",
    image: roles,
    active_image: roles,
  },
  {
    label: "Users",
    path: "/hr/users",
    image: users,
    active_image: users,
  },
  {
    label: "Public Holiday",
    path: "/hr/holiday",
    image: payrollImage,
    active_image: payrollImage,
  },
  {
    label: "Payroll Management",
    path: "/hr/payroll",
    image: payrollImage,
    active_image: payrollImage,
  },
  // {
  //   label: "Department",
  //   path: "/hr/departments",
  //   image: sc_image_roles,
  //   active_image: sc_image_roles_active,
  // },
  // {
  //     type: "divider"
  // },
  // {
  //   path: "/",
  //   label: "Directory",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "Users",
  //       path: "/hr/users",
  //       // image: users,
  //       // active_image: users,
  //     },
  //     {
  //       label: "Roles",
  //       path: "/hr/roles",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     {
  //       label: "Group",
  //       path: "/hr/v5/group",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     // {
  //     //   label: "Payroll Management",
  //     //   path: "/hr/payroll",
  //     //   image: payrollImage,
  //     //   active_image: payrollImage,
  //     // },
  //     // {
  //     //   label: "Public Holiday",
  //     //   path: "/hr/holiday",
  //     //   image: payrollImage,
  //     //   active_image: payrollImage,
  //     // },
  //   ],
  // },
  // {
  //   path: "/",
  //   label: "Boarding List",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "On-boarding",
  //       path: "/hr/v5/On-boarding",
  //       // image: users,
  //       // active_image: users,
  //     },
  //     {
  //       label: "Off-boarding",
  //       path: "/hr/v5/Off-boarding",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     {
  //       label: "Layout",
  //       path: "/hr/v5/Layout",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     // {
  //     //   label: "Payroll Management",
  //     //   path: "/hr/payroll",
  //     //   image: payrollImage,
  //     //   active_image: payrollImage,
  //     // },
  //     // {
  //     //   label: "Public Holiday",
  //     //   path: "/hr/holiday",
  //     //   image: payrollImage,
  //     //   active_image: payrollImage,
  //     // },
  //   ],
  // },
  // {
  //   path: "/",
  //   label: "Events",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "Birthday & holiday",
  //       path: "/hr/v5/events/birthdayandholiday",
  //       // image: users,
  //       // active_image: users,
  //     },
  //     {
  //       label: "Team life events",
  //       path: "/hr/v5/events/teamLifeEvent",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     {
  //       label: "Team milestone",
  //       path: "/hr/v5/events/teamMilestone",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     {
  //       label: "Organizational event",
  //       path: "/hr/v5/events/organizationalEvent",
  //       // image: payrollImage,s
  //       // active_image: payrollImage,
  //     },
  //     {
  //       label: "Promotion rule",
  //       path: "/hr/v5/events/promotionRule",
  //       // image: payrollImage,
  //       // active_image: payrollImage,
  //     },
  //   ],
  // },
  // {
  //   path: "/",
  //   label: "Hiring",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "Job posting",
  //       path: "/hr/v5/jobPosting",
  //       // image: users,
  //       // active_image: users,
  //     },
  //     {
  //       label: "Candidates",
  //       path: "/hr/v5/jobPosting/candidates",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //     {
  //       label: "Interview",
  //       path: "/hr/v5/hiring/interview",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //   ],
  // },
  // {
  //   path: "/",
  //   label: "Evaluation",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "Question",
  //       path: "/hr/v5/evaluation/question",
  //     },
  //     {
  //       label: "Observation",
  //       path: "/hr/v5/evaluation/observation",
  //     },
  //     {
  //       label: "Form",
  //       path: "/hr/v5/evaluation/form",
  //     },
  //     {
  //       label: "Settings",
  //       path: "/hr/v5/evaluation/settings",
  //     },
  //   ],
  // },
  // {
  //   path: "/",
  //   label: "Letter template",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "Template",
  //       path: "/hr/v5/letter/template",
  //       // image: users,
  //       // active_image: users,
  //     },
  //     {
  //       label: "List",
  //       path: "/hr/v5/letter/list",
  //       // image: roles,
  //       // active_image: roles,
  //     },
  //   ],
  // },
];
