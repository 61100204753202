import {
  SET_FOLLOWUP_LOADING,
  SET_FOLLOWUP,
  ADD_FOLLOWUP,
  EDIT_FOLLOWUP,
  DELETE_FOLLOWUP,
  SET_FOLLOWUP_ACTIVE_INDEX,
  ADD_REPLAY,
  FETCH_COMMENTS,
} from "./constants";

const initialState = {
  activeIndex: 1,
  isLoading: true,
  followupData: [],
  followupDatas: [],
  commentData: [],
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FOLLOWUP_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FOLLOWUP_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: payload,
      };
    case SET_FOLLOWUP:
      return {
        ...state,
        isLoading: false,
        followupData: payload.AllData,
      };
    case ADD_FOLLOWUP:
      return {
        ...state,
        isLoading: false,
        followupData: [...state.followupData, payload],
      };
    case EDIT_FOLLOWUP:
      return {
        ...state,
        isLoading: false,
        followupData: state.followupData.map((item) =>
          item._id === payload._id ? { ...payload } : item
        ),
      };
    case DELETE_FOLLOWUP:
      return {
        ...state,
        isLoading: false,
        followupData: state.followupData.filter((item) => item._id !== payload),
      };
    case ADD_REPLAY:
      return {
        ...state,
        isLoading: false,
        followupDatas: [...state.followupDatas, payload],
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        isLoading: false,
        commentData: payload.data,
      };
    default:
      return state;
  }
};

export default Reducer;
