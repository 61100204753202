export const SET_HELPDESK_LOADING = 'SET_HELPDESK_LOADING'
export const SET_HELPDESK = 'SET_HELPDESK'
export const ADD_HELPDESK = 'ADD_HELPDESK'
export const EDIT_HELPDESK = 'EDIT_HELPDESK'
export const DELETE_HELPDESK = 'DELETE_HELPDESK'
export const MANAGE_DEVICE_COUNT = 'MANAGE_DEVICE_COUNT'
export const TOGGLE_HELPDESK_ASSIGN_LESSONS = 'TOGGLE_HELPDESK_ASSIGN_LESSONS'
export const TOGGLE_HELPDESK_ASSIGN_DEVICE = 'TOGGLE_HELPDESK_ASSIGN_DEVICE'
export const SET_VIEW_LESSONS = 'SET_VIEW_LESSONS'
export const TOGGLE_ASSIGN_ROLES = 'TOGGLE_ASSIGN_ROLES'
export const SET_ELE_CATEGORY_LOADING = 'SET_ELE_CATEGORY_LOADING'
export const SET_ELE_CATEGORY = 'SET_ELE_CATEGORY'
export const ADD_ELE_CATEGORY = 'ADD_ELE_CATEGORY'
export const EDIT_ELE_CATEGORY = 'EDIT_ELE_CATEGORY'
export const DELETE_ELE_CATEGORY = 'DELETE_ELE_CATEGORY'
export const ADD_SLIDE = 'ADD_SLIDE'
export const DELETE_SLIDE = "DELETE_SLIDE"
export const FETCH_DEVICE = 'FETCH_DEVICE'
export const FETCH_BINDATA = 'FETCH_BINDATA'
export const BINDATA = 'BINDATA'
export const BINDATA_DELETE = 'BINDATA_DELETE'
export const SET_HELPDESK_DEVICE_LOADING="SET_HELPDESK_DEVICE_LOADING"
