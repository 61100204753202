import {
  sc_image_dashboard,
  sc_image_dashboard_active,
  sc_image_scheduler,
  sc_image_scheduler_active,
  sc_image_myavailability,
  sc_image_myavailability_active,
  sc_image_teamavailability,
  sc_image_teamavailability_active,
  sc_image_timeoffrequest,
  sc_image_timeoffrequest_active,
  sc_image_tradeshift,
  sc_image_tradeshift_active,
  sc_image_req,
  sc_image_req_active,
  sc_image_covershift,
  sc_image_covershift_active,
  sc_image_timesheets,
  sc_image_timesheets_active,
  sc_image_pay_summary,
  sc_image_pay_summary_active,
  sc_image_roles,
  sc_image_roles_active,
  sc_image_team,
  sc_image_team_active,
  sc_image_back,
  sc_image_settings,
  sc_image_settings_active,
  sc_image_settings_scheduling,
  sc_image_settings_scheduling_active,
  sc_image_settings_timetracking,
  sc_image_settings_timetracking_active,
  sc_image_settings_timeofpto,
  sc_image_settings_timeofpto_active,
  ele_image_dashboard,
  ele_image_dashboard_active,
  ele_image_mycourses,
  ele_image_mycourses_active,
  ele_image_library,
  ele_image_library_active,
  ele_image_courses,
  ele_image_courses_active,
  ele_image_helpdesk,
  ele_image_helpdesk_active,
  ele_image_reports,
  ele_image_reports_active,
  ele_image_team,
  ele_image_team_active,
  sc_image_timecard,
  sc_image_timecard_active,
  sc_image_settings_location,
  sc_image_settings_location_active,
  news_update,
  ovChat,
  ovRoutine,
  device_management,
} from "../icons";
import TimelineIcon from "@mui/icons-material/Timeline";

export const SchedulerRoutes = [
  {
    label: "Dashboard",
    path: "/scheduler/dashboard",
    image: sc_image_dashboard,
    active_image: sc_image_dashboard_active,
  },
  // {
  //     label: "Live Tracking",
  //     path: "/scheduler/live_tracking",
  //     image: sc_image_dashboard,
  //     active_image: sc_image_dashboard_active
  // },
  {
    label: "Scheduler",
    path: "/scheduler/scheduler",
    image: sc_image_scheduler,
    active_image: sc_image_scheduler_active,
  },
  {
    type: "divider",
  },
  {
    label: "Availability",
    path: "/scheduler/availability",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
  },
       {
        label: "Team Availability",
        path: "/scheduler/teamavailability",
        image: sc_image_myavailability,
        active_image: sc_image_myavailability_active,
      },

  
  // {
  //   asad hide
  //   path: "/",
  //   label: "Availability",
  //   image: sc_image_myavailability,
  //   active_image: sc_image_myavailability_active,
  //   childData: [
  //     {
  //       label: "Team Availability",
  //       path: "/scheduler/teamavailability",
  //       image: sc_image_myavailability,
  //       active_image: sc_image_myavailability_active,
  //     },
  //     {
  //       label: "My Availability",
  //       path: "/scheduler/MyAvailabilityNew",
  //       image: sc_image_myavailability,
  //       active_image: sc_image_myavailability_active,
  //     },
  //   ],
  // },
  
  {
    type: "divider",
  },
  {
    // asad hide
    path: "/",
    label: "Request",
    image: sc_image_req,
    active_image: sc_image_req_active,
    childData: [
      {
        label: "Trade shift",
        path: "/scheduler/tradeshift/my",
        image: sc_image_tradeshift,
        active_image: sc_image_tradeshift_active,
        count: "scheduler_trade_shifts",
      },
      {
        label: "Cover shift",
        path: "/scheduler/covershift/my",
        image: sc_image_covershift,
        active_image: sc_image_covershift_active,
      },
      {
        label: "Time off",
        path: "/scheduler/timeoffrequest/my",
        image: sc_image_timeoffrequest,
        active_image: sc_image_timeoffrequest_active,
        count: "scheduler_timeoff_requests",
      },
      {
        label: "Correction",
        path: "/scheduler/correction_request/my",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
      // {
      //   label: "My Request",
      //   path: "/scheduler/mycorrection_request",
      //   image: sc_image_pay_summary,
      //   active_image: sc_image_pay_summary_active,
      // },
    ],
  },
  {
    type: "divider",
  },
  {
    label: "Timesheets",
    path: "/scheduler/timesheets",
    image: sc_image_timesheets,
    active_image: sc_image_timesheets_active,
  },
  {
    label: "Pay run",
    path: "/scheduler/pay_summary",
    image: sc_image_pay_summary,
    active_image: sc_image_pay_summary_active,
  },
  {
    type: "divider",
  },
  {
    path: "/",
    label: "Settings",
    childData: [
      {
        label: "Time Tracking",
        path: "/scheduler/settings/time_tracking",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
      {
        label: "Scheduling",
        path: "/scheduler/settings/scheduling",
        image: sc_image_settings_scheduling,
        active_image: sc_image_settings_scheduling_active,
      },
      {
        label: "Payroll",
        path: "/scheduler/settings/payrollNew",
        image: sc_image_pay_summary,
        active_image: sc_image_pay_summary_active,
      },
      // {
      //   label: "Time off & PTO",
      //   path: "/scheduler/settings/timeoff_pto",
      //   image: sc_image_settings_timeofpto,
      //   active_image: sc_image_settings_timeofpto_active,
      // },
    ],
  },
];
