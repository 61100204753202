import {
  SET_PAYROLL_LOADING,
  SET_PAYROLL,
  ADD_PAYROLL,
  EDIT_PAYROLL,
  DELETE_PAYROLL,
  SET_PAYROLL_ASSIGN_USERS
} from "./constants";
import { alert } from '../../../../utilities';
import { payrollServices } from './services';

export function fetchData(isReload, lid) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_PAYROLL_LOADING,
        payload: true
      });
    }
    payrollServices.fetchData(lid).then(response => {
      dispatch({
        type: SET_PAYROLL,
        payload: response.data
      });
    });
  };
}


export function deleteData(_id) {
  return function (dispatch) {
    payrollServices.deleteData(_id).then(response => {
      alert.success(response.data.message)
      dispatch({
        type: DELETE_PAYROLL,
        payload: _id
      });
    });
  };
}

export function addData(data) {
  return function (dispatch) {
    payrollServices.addData(data).then(response => {
      alert.success(response.data.message)
      dispatch({
        type: ADD_PAYROLL,
        payload: response.data.post
      });
    });
  };
}

export function editData(data, id) {
  return function (dispatch) {
    payrollServices.editData(data, id).then(response => {
      alert.success(response.data.message)
      dispatch({
        type: EDIT_PAYROLL,
        payload: response.data.post
      });
    });
  };
}

export function fetchPayrollAssignUsers(id) {
  return function (dispatch) {
    dispatch({
      type: SET_PAYROLL_ASSIGN_USERS,
      payload: true
    });
    payrollServices.fetchPayrollAssignUsers(id).then(response => {
      dispatch({
        type: SET_PAYROLL_ASSIGN_USERS,
        payload: response.data
      });
    });
  };
}