import React, { createContext, useContext, useState } from "react";

const MobileHRSearchContext = createContext();

export const SearchProviderHR = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const resetSearchQuery = () => {
    setSearchQuery("");
  };

  return (
    <MobileHRSearchContext.Provider
      value={{ searchQuery, updateSearchQuery, resetSearchQuery }}
    >
      {children}
    </MobileHRSearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(MobileHRSearchContext);
};
