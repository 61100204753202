import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const LocationServices = {
  fetchData,
  fetchAssignedLocations,
  addData,
  editData,
  fetchAllData,
  deleteData,
};

// function fetchData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/location`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/location`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchAllData() {
  return axios
    .get(`${MoNGO_DB_API_URL}/location/alllocations`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchAssignedLocations() {
  return axios
    .get(`${MoNGO_DB_API_URL}/location/assign`, HEADER_TOKEN)
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(`${MoNGO_DB_API_URL}/location`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function editData(data) {
  return axios
    .put(`${MoNGO_DB_API_URL}/location/${data._id}`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function deleteData(lid) {
  return axios
    .delete(`${MoNGO_DB_API_URL}/location/${lid}`, HEADER_TOKEN)
    .then((response) => response);
}
