import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class Loading extends Component {
  render() {
    return (
      // <div className="upload_loader"></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
}
