import {
  SET_COVER_LOADING,
  SET_COVER,
  ADD_COVER,
  EDIT_COVER,
  DELETE_COVER,
  SET_TEAM_COVER
} from "./constants";

import { alert, invalidToken } from '../../../../utilities';
import { coverServices } from './services'

export function fetchData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_COVER_LOADING,
      payload: true
    });
    coverServices.fetchData(data).then(response => {
      if (response.data.success === 2) {
        invalidToken(response.data.message)
      } else {
        dispatch({
          type: SET_COVER,
          payload: response.data
        });
      }
    });
  };
}

// export function fetchTeamData(data) {
//   return function (dispatch) {
//     dispatch({
//       type: SET_COVER_LOADING,
//       payload: true
//     });
//     coverServices.fetchTeamData(data).then(response => {
//       if (response.data.success === 2) {
//         invalidToken(response.data.message)
//       } else {
//         dispatch({
//           type: SET_TEAM_COVER,
//           payload: response.data
//         });
//       }
//     });
//   };
// }


export function deleteData(id) {
  return function (dispatch) {
    coverServices.deleteData(id)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: DELETE_COVER,
            payload: id
          });
        }
      });
  };
}


export function addData(data) {
  return function (dispatch) {
    coverServices.addData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: ADD_COVER,
            payload: response.data.post
          });
        }
      });
  };
}

export function editData(data) {
  return function (dispatch) {
    coverServices.editData(data)
      .then(response => {
        if (response.data.success === 2) {
          invalidToken(response.data.message)
        } else {
          alert.success(response.data.message)
          dispatch({
            type: EDIT_COVER,
            payload: response.data.post
          });
        }
      });
  };
}