import {
  SET_SC_TEAM_LOADING,
  SET_SC_TEAM
} from "./constants";

const initialState = {
  isLoading: true,
  usersData: []
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SC_TEAM_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SC_TEAM:
      return {
        ...state,
        isLoading: false,
        usersData: payload.usersData
      };
    default:
      return state;
  }
};

export default Reducer;